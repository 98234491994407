/**
 * @package     Blueacorn/CustomFormElements
 * @version     2.0
 * @author      Blue Acorn <code@blueacorn.com>
 * @copyright   Copyright Â© 2016 Blue Acorn.
 */

(function (root, factory) {
    if (typeof exports === 'object') {
        // CommonJS
        module.exports = factory(
            window.jQuery,
            require('./blueacorn-core'),
            require('../vendor/enquire/enquire.min'),
            require('../vendor/bez/bez')
        );
    } else if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery', 'baCore', 'enquireJs'], function (jquery, ba, enquire) {
            return (factory(jquery, ba, enquire));
        });
    } else {
        // Global Variables
        root.ba.header = factory(root.jQuery, root.ba, root.enquire);
    }

}(this, function ($, ba, enquire) {
    'use strict';

    var collapse = {

        settings: {
            triggers: $('.js-collapse-trigger'),
            mobileTriggers: $('.js-collapse-mobile-trigger'),
            collapseItems: $('.js-collapse-item')
        },

        init: function (options) {
            var self = this;
            // Overrides the default settings
            ba.overrideSettings(this.settings, options);

            // Start the debugger
            ba.setupDebugging(this.settings);

            this.initObservers();
        },

        /**
         * enables the collapsible elements after ajax events
         *
         */
        reinitialize: function() {
            var self = this;

            this.settings.triggers = $('.js-collapse-trigger');
            this.settings.mobileTriggers = $('.js-collapse-mobile-trigger');
            this.settings.collapseItems = $('.js-collapse-item');

            this.initObservers();
        },

        calculateTopPosition: function () {
            let topPosition = 0;
            let spaceUnderLine = 5;
            let $pageWrap = $('.pagewrap');
            let $refinements = $pageWrap.find('.js_refinements');
            let $mobileCollapseContainer = $pageWrap.find('.mobile-collapse-container');

            if (!($refinements.length && $mobileCollapseContainer.length)) {
                return topPosition;
            }

            let refinementsBottom = $refinements.offset().top + $refinements.outerHeight();
            let mobileCollapseContainerBottom = $mobileCollapseContainer.offset().top + $mobileCollapseContainer.outerHeight();
            topPosition = refinementsBottom - mobileCollapseContainerBottom + $mobileCollapseContainer.outerHeight() + spaceUnderLine;

            return topPosition;
        },

        setTopPosition: function () {
            var self = this;
            let $openedRefinement = $('.js_refinements .js-refinement-collapse-item.opened');

            if (!$openedRefinement.length) {
                return;
            }

            let $collapseElement = $openedRefinement.children('.js-collapse-element');
            let topPosition = self.calculateTopPosition();
            $collapseElement.css({ 'top': topPosition });
            $(document).trigger('custom:set:refinement:width');
        },

        /**
         * initializes the click event listeners
         *
         */
        initObservers: function() {
            var self = this;

            self.observeCollapseClick(self.settings.triggers);

            $(document)
                .off('click.outside.refinements')
                .on('click.outside.refinements', function (e) {
                    var $target = $(e.target);

                    if (!$target.closest('.js-refinement-collapse-item').length) {
                        self.closeAllRefinements();
                    }
                })
                .off('custom:refinement:state:was:copied')
                .on('custom:refinement:state:was:copied', self.setTopPosition.bind(self));
        },

        /**
         * Collapse click action, checks the active state,
         * where the active state is the element having the class `active`.
         * If active, it closes the accordion, if not, it opens the accordion.
         *
         */
        observeCollapseClick: function(trigger) {
            var self = this;

            trigger.off('click.collapse');
            trigger.on('click.collapse', function() {
                let $collapseItem = $(this).closest('.js-collapse-item');
                let isOpened = $collapseItem.hasClass('opened');

                self.closeAllRefinements();
                $collapseItem.toggleClass('opened', !isOpened);
                self.setTopPosition();
            });

            // Empty click handler prevents iOS safari event propagation error
            this.settings.triggers.on('click', function() { });
        },

        /**
         * Initializes mobile-specific functionality
         *
         */
        mobileInit: function() {
            // set initial 'state' to inactive
            this.settings.collapseItems.addClass('active');

            this.observeCollapseClick(this.settings.mobileTriggers);
        },

        /**
         * Undoes mobile-specific DOM mods just in case page resizes cause
         * visual awkwardness
         */
        mobileUnInit: function() {
            this.settings.mobileTriggers.next('.js-collapse-element').removeAttr('style');
            this.settings.triggers.next('.js-collapse-element').removeAttr('style').parent('.js-collapse-item').addClass('active');
        },

        closeAllRefinements: function () {
            $('.js_refinements').find('.js-refinement-collapse-item').removeClass('opened');
        }
    };

    $(document).on('baCoreReady', function() {

        /**
         * The parameter object is optional.
         * Must be an object.
         */
        collapse.init();
    });

    $(document).on('update:refinements', function() {
        collapse.reinitialize();
    });

    return collapse;
}));
