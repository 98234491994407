/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

var countries = require('./countries'),
    dialog = require('./dialog'),
    minicart = require('./minicart'),
    refinementFlyout = require('./refinement-flyout'),
    stiFlyout = require('./sti-flyout'),
    page = require('./page'),
    rating = require('./rating'),
    searchsuggest = require('./searchsuggest'),
    batooltip = require('./batooltip'),
    tooltip = require('./tooltip'),
    util = require('./util'),
    validator = require('./validator'),
    tls = require('./tls'),
    formPrepare = require('./pages/checkout/formPrepare'),
    debounce = require('lodash.debounce'),
    lazyload =  require('./lazyload'),
    headerEvents =  require('./header'),
    giftCertificate = require('./giftCertificate'),
    CaptchaLoader = require('./blueacorn/blueacorn-captcha'),
    pd_singleLinkCustomDialog = require('./pd/singlelinkcustomdialog'),
    globalVars = require('./global-vars'),
    tradeMembershipRegistration = require('./trade-membership-registration'),
    wishlistDialogs = require('./wishlist-dialogs');

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('./jquery-ext')();
require('./cookieprivacy')();

function handleImgError() {
    $('img').off('error.custom-image-error').on('error.custom-image-error', function () {
        var defaultImg = $(this).data('default-img');

        if (defaultImg) {
            $(this).attr('src', defaultImg);
        }
    });
}

function hidePageLoader() {
    $('.page-loader').fadeOut(250, function () {
        $(this).remove();
    });
}

function initOwlWheelEvent(e, data) {
    var carousel = data.carousel;

    if (!carousel) {
        return;
    }

    $(carousel).off('wheel.owl:custom').on('wheel.owl:custom', '.owl-stage', function (e) {
        var deltaX = e.originalEvent.deltaX;

        if (deltaX !== 0) {
            e.preventDefault();

            if (deltaX > 0) {
                $(carousel).trigger('next.owl.carousel');
            }

            if (deltaX < 0) {
                $(carousel).trigger('prev.owl.carousel');
            }
        }
    })
}

function initializeEvents() {
    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'],
        controlKeyNames = ['-', 'Backspace', 'Delete', 'Control', 'Alt', 'Meta', 'CapsLock', 'Escape', 'Enter', 'Tab', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'ArrowDown'];

    $('body').on('click', '.homepage-action', function(e) {
        e.preventDefault();
        window.location = $(this).data('url');
    });

    $('body')
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        })
        .on('keypress', 'input.postal, #dwfrm_catalogrequest_zip', function(e) {
            // format postal code
            var zip = $.trim($(this).val());

            if (zip.length == 5 && controlKeyNames.indexOf(e.key) < 0) {
                $(this).val(zip + '-');
            }

            if (!/^[0-9-]$/i.test(e.key) || (e.key === '-' && zip.indexOf('-') > -1)) {
                e.preventDefault();
            }
        });

    /**
     * initialize search suggestions
     * */
    var $searchContainer = $('.header-search');
    searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH);
    // add show/hide navigation elements
    $('.secondary-navigation .toggle').on('click', function () {
        $(this).toggleClass('expanded').next('ul').toggle();
    });
    // show dialog if logged in user tries to go to cart with vIP item and does not have a phone
    if ($('.require-phone').length) {
        function initializePhone() {
            $('.phone-continue').on('click', function (e) {
                e.preventDefault();
                dialog.close();
            });
        }
        dialog.open({
            url: Urls.profilePhoneRequired,
            options: {
                open: initializePhone,
                width: 600,
                dialogClass: 'requirephone'
            }
        });
    }
    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();
    $('.toggle').on('click',function (event) {
        $(this).toggleClass('expanded').next('.toggle-content').toggle(0, function () {
            // reinitialize superselects to overcome any styling weirdness which may
            // have resulted from them being initialized inside a hidden container
            $(document).trigger('update:selects');
        });

        // prevent window from jumping to the top of the document
        event.preventDefault();
    });

    $('.privacy-policy').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                height: 600
            }
        });
    });

    $('.user-account').on('click', function (e) {
        e.preventDefault();
        $(this).parent('.user-info').toggleClass('active');
    });

    $(document)
        .on('captcha:loaded', function(){
            new CaptchaLoader();
        })
        .on('custom:handle:image:error', handleImgError);

    $('body').on('click', '#dwfrm_profile_customer_commpref', function(e) {
        if ($(this).data('flyout') == true) {
            $('.agree-to-text').show();
        } else {
            $('.agree-to-text').hide();
        }
    });

    // ensure synchrony modal displays correctly
    $(document).on('show.bs.modal', '#digBuyModal', function(e) {
        $(e.relatedTarget.attributes['data-target'].value).appendTo('body');
    });


    $('body').on('click', '.pt_order-confirmation button[name*="dwfrm_profile_confirm"]', function(e) {
        e.preventDefault();
        dialog.open({
            url: Urls.guestConfirmCreateAccount,
            options: {
                dialogClass: 'guest-intercept-modal',
                title: Resources.GUEST_ORDERCONF_MODAL_HEADING
            },
            callback: function () {
                $('input[id*="customer_firstname"]').val($('.mini-fname').html());
                $('input[id*="customer_firstname"]').parent().parent(".form-row").addClass("input-focused");
                $('input[id*="customer_lastname"]').val($('.mini-lname').html());
                $('input[id*="customer_lastname"]').parent().parent(".form-row").addClass("input-focused");
                $('input[id*="requestaddress_address1"]').val($('.mini-address1').html());
                $('input[id*="requestaddress_address1"]').parent().parent(".form-row").addClass("input-focused");
                var $address2 = $('input[id*="requestaddress_address2"]');
                $address2.val($('.mini-address2').html());
                if ($address2.val() !== '') {
                    $address2.parent().parent(".form-row").addClass("input-focused");
                }
                $('input[id*="requestaddress_city"]').val($('.mini-city').html());
                $('input[id*="requestaddress_city"]').parent().parent(".form-row").addClass("input-focused");
                $('select[id*="customer_modalregisterstate"]').val($('.mini-state').html());
                $('select[id*="customer_modalregisterstate"]').parent().parent(".form-row").addClass("input-focused dropdown");
                $('input[id*="requestaddress_postal"]').val($('.mini-zip').html());
                $('input[id*="requestaddress_postal"]').parent().parent(".form-row").addClass("input-focused");
                $('input[id*="customer_storisphone"]').val($('.mini-phone').html());
                $('input[id*="customer_storisphone"]').parent().parent(".form-row").addClass("input-focused");
                $(document).trigger('update:selects');
                new CaptchaLoader();
                formPrepare.init({
                    formSelector: 'form[id$="RegistrationFormModal"]',
                    continueSelector: 'none' // formPrepare expects this to be passed but we want to be able to click the button
                });
                var pastDate = new Date();
                pastDate = pastDate.getFullYear() - 130;

                $('input.datepicker').datepicker({
                    autoclose: true,
                    changeYear: true,
                    changeMonth: true,
                    dateFormat: 'mm/dd/yy',
                    yearRange: pastDate +':2019',
                    onChangeMonthYear: function(year, month){
                        $(this).datepicker( "setDate", month + '/1/' + year );
                    },
                    onSelect: function(selectedDate) {
                        $('#ui-datepicker-div table td a').attr('href', 'javascript:;');
                        $('#ui-datepicker-div').hide();
                        $(this).next('input.datepicker').trigger('focus');
                        $(this).val(selectedDate);
                    }
                });

                $('#RegistrationFormModal').on('submit', function(e) {
                    e.preventDefault();
                    var captcha = $('[name$="_captcha"]').val(),
                        $captchaWrapper = $('.data-captcha-box');
                    if (!$(this).valid() || captcha === '') {
                        $captchaWrapper.find('.error').remove();
                        $('.registration-modal-header').hide();
                        $('.error-header').show();
                        if (captcha === '') {
                            $captchaWrapper.find('.data-captcha').append('<span class="error">' + Resources.VALIDATE_REQUIRED + '</span>');
                        }
                        return false;
                    }

                    var url = util.appendParamToURL($(this).attr('action'), 'format', 'ajax');
                    var applyName = $(this).find('button[type="submit"]').attr('name');
                    var options = {
                        url: url,
                        data: $(this).serialize() + '&' + applyName + '=x',
                        type: 'POST'
                    };
                    $.ajax(options).done(function (data) {
                        if (data.success && window.dataLayer) {
                            window.dataLayer.push({
                                event: 'gtmEvent',
                                eventCategory: 'engagement',
                                eventAction: 'account',
                                eventLabel: 'account creation success',
                                interactionEvent: false
                            });
                        }
                        window.location.href = (data.redirectUrl);
                    });
                }).on('click', '.button[name$="cancel"]', function(e) {
                    e.preventDefault();
                    dialog.close();
                });

                $('#RegistrationFormModal input.required, #RegistrationFormModal input-select.required').on('blur', function(e) {
                    $(e.target).valid();
                });

                if (window.dataLayer) {
                    window.dataLayer.push({
                        event: 'gtmEvent',
                        eventCategory: 'engagement',
                        eventAction: 'account',
                        eventLabel: 'login modal view',
                        interactionEvent: false
                    });
                }
            }
        });

        $(window).on('resize', debounce(function() {
            dialog.$container.dialog("option", "position", { my: "center", at: "center", of: window });
        }, 150));
    });

    $(document).on('click', '.share-icon.shareemail, .emailus', function(e) {
        e.preventDefault();

        if ($(this).hasClass('emailus')) {
            var url = Urls.emailus + '?format=ajax&emailto='+$(this).attr('data-emailto');
        } else {
            var url = Urls.sharewithemail + '?format=ajax&pdpUrl='+$(this).attr('data-pdpUrl');
        }

        dialog.open({
            url: url,
            options: {
                width: 460,
                position: {my: "center", at: "center", of: window},
                dialogClass: 'email-form-modal',
                open: function () {
                    validator.init();
                    $(window).on('resize', function() {
                        $("#dialog-container").dialog("option", "position", {my: "center", at: "center", of: window});
                    });
                    var $form = $("#emailModalForm");
                    $('.thankyoumessage').hide();
                    $('.send-email button').on('click', function(e) {
                        e.preventDefault();
                        if($form.valid()) {
                            $.ajax({
                                url: $form.data('action'),
                                method: 'POST',
                                data: $form.serialize(),
                                dataType: 'json',
                                success: function (data) {
                                    if (!data.error) {
                                        $('.email-modal-wrapper').hide();
                                        $('.thankyoumessage').show();
                                        $("#dialog-container").dialog("option", "position", {my: "center", at: "center", of: window});
                                        $('.thankyoumessage #close').on('click', function() {
                                            $('#dialog-container').dialog('close');
                                        });
                                    } else {
                                        $('.email-modal-wrapper').find('.alert-error').show();
                                    }
                                }
                            });
                        }
                    });
                    var $textArea = $form.find('.input-textarea');
                    var dataLimit = $textArea.attr('data-character-limit');
                    $textArea.on('keyup', function() {
                        if ($(this).val().length >= dataLimit) {
                            $(this).next('.form-caption').text('You have reached to maximum characters ' + dataLimit);
                        } else {
                            $(this).next('.form-caption').text('');
                        }
                    });
                }
            }
        });
    });

    $('body').on('click', '.equal-pay-link', function(e) {
        e.preventDefault();
        dialog.open({
            url: Urls.equalPayDescription,
            options: {
                dialogClass: 'financing-details',
                open: function () {
                    var equalPrice = $('.js-equal-pay-msg').attr('data-price-per-month');
                    $('.financing-details .js-changed-equal-pay').text(equalPrice);
                    var equalMonth = Math.trunc($('.js-equal-pay-msg').attr('data-months'));
                    $('.financing-details .js-changed-equal-pay-months').text(equalMonth);
                    $('.financing-details .modal-link').on('click', function(e){
                        e.preventDefault();
                        $('.financing-details .ui-button').trigger('click');
                    })
                }
            }
        });
    });

    var bannerCarousel = $('.global-banner-carousel'),
        bannerCarouselOptions = {
            loop: true,
            nav: false,
            items: 1,
            dots: false,
            autoplay: bannerCarousel.hasClass('autoplay'),
            autoplayTimeout: bannerCarousel.data('autoplaytimeout') || 5000,
            autoplayHoverPause:true
        };

    if (window.innerWidth < globalVars.breakpoints.large) {
        bannerCarousel
            .on('initialized.owl.carousel changed.owl.carousel', function() {
                $(document).trigger('custom:lazy:load:update');
            })
            .on('initialized.owl.carousel', function() {
                $(document).trigger('custom:owl:carousel:initialized', {
                    carousel: $(this)
                });
            })
            .owlCarousel(bannerCarouselOptions);
    } else {
        bannerCarousel.addClass('off');
    }

    $(window).on('resize', function() {
        if (window.innerWidth < globalVars.breakpoints.large) {
            if ( $('.global-banner-carousel').hasClass('off') ) {
                bannerCarousel
                    .on('initialized.owl.carousel changed.owl.carousel', function() {
                        $(document).trigger('custom:lazy:load:update');
                    })
                    .on('initialized.owl.carousel', function() {
                        $(document).trigger('custom:owl:carousel:initialized', {
                            carousel: $(this)
                        });
                    })
                    .owlCarousel(bannerCarouselOptions);
                bannerCarousel.removeClass('off');
            }
        } else if ( !$('.global-banner-carousel').hasClass('off') ) {
            bannerCarousel.addClass('off').trigger('destroy.owl.carousel');
            bannerCarousel.find('.owl-stage-outer').children(':eq(0)').unwrap();
        }
    });

    $(window).load(function () {
        hidePageLoader();
    });

    $('body').on('click', '#customer-logout', function () {
        window.dataLayer.push({
            'event': 'log_out_success'
        });
    });

    $('#ProcessDesignRequest, #dwfrm_swatchrequest').on('submit', function(e) {
        var captcha = $('[name$="_captcha"]').val();
        var $captchaWrapper = $('.data-captcha-box');
        $captchaWrapper.find('.captcha-error').remove();
        if (!$(this).valid() || captcha == '') {
            e.preventDefault();
            if (captcha == '') {
                $captchaWrapper.find('.data-captcha').children('span').after('<span class="captcha-error">' + Resources.INVALID_CAPTCHA + '</span>');
            }
            return false;
        }
    });

    $(document).on('custom:owl:carousel:initialized', initOwlWheelEvent);
}

/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();
}

/**
 * @private
 * @function
 * @description persists search term when a search has been made, removing search term on page refresh
 */
function persistSearchTerm() {
    if (window.location.href.indexOf('q=') > -1 && performance.navigation.type !== 1) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        var searchTerm = '';
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] === 'q') {
                searchTerm = sParameterName[1];
            }
        }
        $('input#q')[0].value = decodeURIComponent(searchTerm).replace(/\+/g,' ');
    } //removed else condition as we just want to persist Search Term on search result page not on CLP.
    if ($('input#q').length > 0 && $('input#q')[0].value.length > 0) {
        $('.clear-search').show();
    }
    $('input#q').on('input', function () {
        $('.clear-search').show();
    });
    $('input#q').on('blur', function () {
        if ($(this)[0].value.length === 0) {
            $('.clear-search').hide();
        }
    });
    $('form[name="simpleSearch"]').on('submit', function (e) {
        if (!$('input#q').hasClass('searched')) {
            e.preventDefault();
            if (sessionStorage) {
                sessionStorage.setItem('searchTerm', $('input#q')[0].value);
            }
            $('input#q').addClass('searched');
            $('form[name="simpleSearch"]').trigger('submit');
        }
    });
    $('.clear-search').on('click', function () {
        $('input#q').val('').trigger('focus');
        $(this).hide();
    });
}
/**
 * @private
 * @function
 * @description OPEN EMAIL MODAL ON SECOND PAGE LOAD EXPIRY IS SITE PREF DRIVEN.
 */
function EmailModal() {
    function resizeAdjsment(dialogID) {
        $(window).on('resize', function() {
            var $dialogIDSelector = $('#' + dialogID);
            var $dialogClassSelector = $('.' + dialogID);
            $dialogIDSelector.dialog('option', 'position', {my: 'center', at: 'center', of: window});

            if(window.innerWidth < 450) {
                $dialogIDSelector.dialog('option', 'width', '300');
            } else if(window.innerWidth < globalVars.breakpoints.medium) {
                $dialogIDSelector.dialog('option', 'width', '450');
            } else {
                $dialogIDSelector.dialog('option', 'width', '750');
            }
            if($dialogClassSelector.css('top').includes('-')) { //checking the negative value.
                $dialogClassSelector.css('top','0px');
            }
        });
    }

    function openSuccessModal() {
        dialog.close();
        dialog.open({
            url: Urls.emailSignupsuccess,
            target: '#email-success-dialog',
            width: '450',
            options: {
                closedOnEscape : true,
                open: function () {
                    $('#email-success-dialog').closest('.ui-dialog').addClass('email-success-dialog');
                    resizeAdjsment('email-success-dialog');
                }
            }
        });
    }

    function saveEmail() {
        $(document).on('form:ajax:response', function (e, data) {
            if (!data.response.status) {
                return;
            }

            var $form = $(data.form.el);
            if (!$form.hasClass('email-alert-signup-modal')) {
                return;
            }

            var $email = $form.find('input[type="email"]');
            var email = $email.val();

            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'gtmEvent',
                    eventCategory: 'engagement',
                    eventAction: 'email signup',
                    eventLabel: 'email capture modal',
                    interactionEvent: false,
                    email: email && email.length ? email.toLowerCase() : email
                });
            }

            openSuccessModal();
        });
    }

    var showEmailModalCookie = util.getCookie('showEmailModal');

    if (showEmailModalCookie === 'true') {
        util.setCookie('showEmailModal', false , window.SitePreferences.Email_Modal_Frequency);

        $.ajax({
            type: 'GET',
            url: Urls.emailSignup,
            success: function (response) {
                try {
                    var $response = $(response);
                    var html = $response.get(0);

                    if (html) {
                        dialog.open({
                            html: html,
                            target: '#email-signup-dialog',
                            width: '750',
                            options: {
                                closedOnEscape : true,
                                open: function() {
                                    $('#email-signup-dialog').closest('.ui-dialog').addClass('email-signup-dialog');
                                    validator.init();
                                    resizeAdjsment('email-signup-dialog');
                                    saveEmail();
                                }
                            }
                        });
                    }
                } catch (e) {
                }
            }
        });
    }

    if (showEmailModalCookie === '') {
        util.setCookie('showEmailModal', true , window.SitePreferences.Email_Modal_Frequency);
    }
}

var pages = {
    account: require('./pages/account'),
    cart: require('./pages/cart'),
    catalog: require('./pages/catalog'),
    design: require('./pages/designservices'),
    swatch: require('./pages/swatch'),
    checkout: require('./pages/checkout'),
    confirmation: require('./pages/confirmation'),
    compare: require('./pages/compare'),
    product: require('./pages/product'),
    productV2: require('./pages/product'),
    registry: require('./pages/registry'),
    search: require('./pages/search'),
    error: require('./pages/search'),
    wishlist: require('./pages/wishlist'),
    storelocator: require('./pages/storelocator')
};

/**
 * @private
 * @function
 * @description showMemberRenewModal.
 */
function showMemberRenewModal() {
    dialog.open({
        url: Urls.memberRenewModal,
        target: '#memberRenewDialog',
        options: {
            width: '600px',
            closedOnEscape : true,
            open: function() {
                $(window).on('resize', function() {
                    var $dialogIDSelector = $('#memberRenewDialog');
                    var $dialogClassSelector = $dialogIDSelector.closest('.ui-dialog');
                    $dialogIDSelector.dialog('option', 'position', {my: 'center', at: 'center', of: window});
                    if($dialogClassSelector.css('top').includes('-')) { //checking the negative value.
                        $dialogClassSelector.css('top','0px');
                    }
                });
            }
        }
    });
}

function footerEmailEvent() {
    $(document).on('form:ajax:response', function (e, data) {
        if (!data.response.status) {
            return;
        }

        var $form = $(data.form.el);
        if (!$form.hasClass('email-alert-signup')) {
            return;
        }

        var $email = $form.find('input[type="email"]');
        var email = $email.val();

        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'gtmEvent',
                eventCategory: 'engagement',
                eventAction: 'email signup',
                eventLabel: 'footer',
                interactionEvent: false,
                email: email && email.length ? email.toLowerCase() : email
            });
        }
    });
}

function openChat() {
    if (window.zE) {
        zE('webWidget', 'open');
    }
}

var app = {
    init: function () {
        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }
        initializeDom();
        initializeEvents();

        // init specific global components
        lazyload.init();
        wishlistDialogs.init();
        headerEvents.init();
        countries.init();
        batooltip.init();
        tooltip.init();
        minicart.init();
        refinementFlyout.init();
        stiFlyout.init();
        validator.init();
        rating.init();
        giftCertificate.init();
        tradeMembershipRegistration.init();
        pd_singleLinkCustomDialog.init();

        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }
        //format phone number
        var $phoneInput = $('.input-text.phone');

        $phoneInput.mask('Z(999) 999-9999', {'translation': {'Z': {pattern: /[1]/, optional: true}, 9: {pattern: /[0-9*]/}}});

        $phoneInput.on('change blur', function() {
            if ($(this).val().startsWith('1')) {
                $(this).val($(this).val().substr(1));
            } else {
                var last = $(this).val().substr($(this).val().indexOf('-') + 1);

                if (last.length == 5) {
                    var move = $(this).val().substr($(this).val().indexOf('-') + 1, 1);
                    var lastfour = last.substr(1,4);
                    var first = $(this).val().substr(0, 9);
                    $(this).val(first + move + '-' + lastfour);
                }
            }
        });

        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }

        $(document).on('click', '.js_product-tile', function (e) {
            var target = e.target;
            if ($(target).closest('.owl-controls, .tool-tip').length || $(target).hasClass('js_btn-quickview') || $(target).hasClass('js_wishlist-link')) {
                return;
            }

            var url = $(this).data('href');
            if (e.ctrlKey) {
                window.open(url, '_blank');
            } else {
                window.location.replace(url);
            }
        });

        /*PDP back-to-top button*/
        $(window).on('scroll.BTT', function () {
            let $btt = $('.BTT');

            if (!$btt.length) {
                return;
            }

            if ($(window).scrollTop() > 500) {
                $btt.fadeIn();
            } else {
                $btt.fadeOut();
            }
        });

        $(document).on('click', '.BTT', function () {
            $('html, body').animate({ scrollTop: 0 }, 'slow');
        });

        $(document).on('click', '.js_open-chat', openChat);

        if ($('body').find('#showMemberRenewModal').length) {
            showMemberRenewModal();
        }
        // persists search term when switching pages
        persistSearchTerm();
        footerEmailEvent();

        // Checked if customer is already subscribed for newsletter (for registered customer only)
        if(!window.Constants.SUBSCRIBED_TO_NEWSLETTER) {
            // open email modal
            EmailModal();
        }
    }
};

// initialize app
$(document).on('ready', function () {
    app.init();
});

/**
 * Custom BlueAcorn Modules Init
 */
window.ba = require('./blueacorn/blueacorn-core');

var responsiveNote = require('./blueacorn/blueacorn-responsive-note'),
    customFormElements = require('./blueacorn/blueacorn-custom-form-elements'),
    superSelects = require('./blueacorn/blueacorn-super-selects'),
    forms = require('./blueacorn/blueacorn-forms'),
    header = require('./blueacorn/blueacorn-header'),
    footer = require('./blueacorn/blueacorn-footer'),
    collectionZoom = require('./blueacorn/blueacorn-collection-zoom'),
    collapse = require('./blueacorn/blueacorn-collapse'),
    product = require('./blueacorn/blueacorn-product'),
    cart = require('./blueacorn/blueacorn-cart'),
    destroyableAlert = require('./blueacorn/blueacorn-destroyable-alerts'),
    ajaxForm = require('./blueacorn/blueacorn-ajax-form'),
    FormMessages = require('./blueacorn/blueacorn-form-messages');
const gtm = require("./pages/gtm");
