'use strict';

var ajax = require('../../ajax'),
    dialog = require('../../dialog'),
    progress = require('../../progress'),
    util = require('../../util'),
    globalVars = require('../../global-vars');

var endsWith = function(string, search, length) {
    if (length === undefined || length > string.length) {
        length = string.length;
    }
    return string.substring(length - search.length, length) === search;
};

/**
 * Updates the query string paramters specifically checking
 * if a paramter name ends with the a key of the valuesOutOfSync
 * object.  If a match is found then the parameter value is updated
 * and the query string parameter is removed and then appended to
 * the url.
 *
 * @param {String} url
 * @param {Object} valuesOutOfSync
 * @returns {String} Updated url, if necessary
 */
var updateQueryStringParameters = function(url, valuesOutOfSync) {
    let returnUrl = url;
    let qs = util.getQueryString(url);
    let qsParams = util.getQueryStringParams(qs);
    Object.keys(valuesOutOfSync).forEach(key => {
        Object.keys(qsParams).some(p => {
            if (endsWith(p, key)) {
                returnUrl = util.removeParamFromURL(returnUrl, p)
                returnUrl = util.appendParamToURL(returnUrl, p, valuesOutOfSync[key]);
                return true;
            }
        });
    });
    return returnUrl;
}

module.exports = function () {
    var $productCustomizationContainer = $('.product-customization-container');

    if (!$productCustomizationContainer.length) {
        return;
    }

    const classNames = {
        active: 'active',
        tooltipContent: 'tooltip-content',
        selected: 'selected',
        show: 'show',
        hide: 'hide',
        borderBottomNone: 'border-bottom-0',
        sticky: 'sticky'
    };

    const selectors = {
        active: '.' + classNames.active,
        optionValueSelector: '.product-customization-option-value-selector',
        selectedValueName: '.product-customization-selected-value-name',
        selectedValueDescrption: '.product-customization-selected-value-description',
        tooltipContent: '.' + classNames.tooltipContent,
        selected: '.' + classNames.selected,
        checked: ':checked'
    };

    const dataNames = {
        step: 'step',
        prevStep: 'prevstep',
        nextStep: 'nextstep',
        optionId: 'oid',
        valueId: 'vid',
        updatedUrl: 'updatedurl',
        url: 'url'
    };

    const transitionValues = {
        slow: 'slow',
        fast: 'fast',
        immediately: 0
    };

    const eventNames = {
        click: 'click',
        change: 'change',
        scroll: 'scroll',
        focus: 'focus mouseover',
        blur: 'blur mouseleave'
    };

    /**
     * Check if it is needed to uppdate entire customization 
     * 
     * @returns {boolean}
     */
    function isNewCustomization() {
        return $('.js_product-customizations').hasClass('js_update-customization');
    }

    /**
     * Updates the browser url
     * @param {String} url
     * the url parameter will be removed if present.
     */
    function setBrowserUrl(url) {
        history.replaceState(null, '', url);
        $('#pdpMain.v2').trigger('updateHQData');
    }

    /**
     * Sets the current selected values on a hidden input which is part
     * of the form submission for Add to Cart/Wishlist.
     */
    function setSelectedValuesForAddToCart() {
        // Update hidden input value for the Add to Cart/Wishlist functionality
        $('#customizedProductValues').val(JSON.stringify(window.mgbwCustomizationSelections));
    }

    function updatePid($response) {
        var $responsePid = $response.find('#updatedPid'),
            $pid = $('.product-detail-content .content .product-add-to-cart .main-ctas input[name="pid"]'),
            $pidid = $('input[name="pidid"]');

        // Update the hidden input pid
        // This value is passed when clicking Add to Cart
        if ($pid.length && $responsePid.length) {
            $pid.val($responsePid.html());
        }

        // Update the hidden input pidid
        // This value is used for most AJAX calls
        if ($pidid.length && $responsePid.length) {
            $pidid.val($responsePid.html());
        }
    }

    function setCustomProductSku() {
        var customPid = $('.js_product-customizations').data('custompid'),
            $productSKU = $('.product-number span.productID');

        $productSKU.text(customPid);
    }

    /**
     * Helper function to update sticky bar prices.
     * @param {Object} $responsePrice jQuery response
     */
    function updateStickyBarPrice($responsePrice) {
        let $stickyBarPrice = $('.js-product-sticky-price .js-product-price');

        // Update product price info
        if ($stickyBarPrice.length >= 1 && $responsePrice.length >= 1) {
            $stickyBarPrice.html($responsePrice.html());
        }
    }

    /**
     * Helper function to update the prices.
     * @param {Object} $response jQuery response
     */
    function updateNamePricePromoContainer($response) {
        const $namePricePromo = $response.find('.js_product-name-price-promo');
        $('.js_product-name-price-promo').replaceWith($namePricePromo.eq(0));
    
        const $responsePrice = $namePricePromo.find('.js-product-price');
        updateStickyBarPrice($responsePrice);
    }

    /**
     * Helper function to update the Wishlist link.
     * @param {Object} $response jQuery response
     */
    function updateWishlistLink($response) {
        $('.wishlist-wrapper').replaceWith($response.find('.wishlist-wrapper'));
    }

    /**
     * Helper function to update the cylindo images.
     * This will update the product and features, if necessary.
     * @param {Object} $response jQuery response
     */
    function updateCylindoImages($response) {
        let $responseCylindoProductId = $response.find('#cylindoProductId'),
            responseCylindoPid,
            responseCylindoFeatures,
            responseCylindoFrames,
            cylindoUpdates = {};

        if (!$responseCylindoProductId.length) {
            return;
        }

        $('.js-product-ajax-container').data('image-url', $responseCylindoProductId.data('cylindo-image-url'));

        responseCylindoPid = $responseCylindoProductId.data('cylindopid');
        responseCylindoFeatures = $responseCylindoProductId.data('features');
        responseCylindoFrames = $responseCylindoProductId.data('frames');

        // Update the Cylindo product, if necessary
        if (responseCylindoPid) {
            cylindoUpdates.productCode = responseCylindoPid;
        }

        // Update the Cylindo features, if necessary
        if (responseCylindoFeatures) {
            cylindoUpdates.features = responseCylindoFeatures;
        }

        // Update the Cylindo frames, if necessary
        if (responseCylindoFeatures) {
            cylindoUpdates.frames = responseCylindoFrames;
        }

        $(document).trigger('cylindoviewer:update', cylindoUpdates);
    }

    /**
     * Helper function to udpate the print view Shown In string
     * @param {Object} $response jQuery response (variation or customization)
     */
    function updateShownIn($response) {
        var $responseShownIn = $response.find('#updatedShownIn'),
            $shownIn = $('.shownin-content > span');

        if (!$responseShownIn.length) {
            // Check if there is a different version of the response (variant update)
            $responseShownIn = $response.find('.shownin-content > span');
        }

        // Update Shown In string
        if ($shownIn.length && $responseShownIn.length) {
            $shownIn.html($responseShownIn.html());
        }
    }

    /**
     * Updates the product content based on variation values being selected,
     * and when the content is being AJAX loaded in.  Then, it
     * re-caches the elements of the page that were replaced from the
     * ajax content.
     *
     * Updates:
     * - product price
     *
     * Re-caches jQuery elements:
     * - start customizing button
     * - resume customizing button
     * - price
     *
     * @param event - jQuery event
     * @param response - AJAX response content
     * @param productSet - Coming from Product Set?
     */
    function updateProductContent(event, response, productSet) {
        var $response = $(response);

        // Product Sets and Bundles load the content twice,
        // so we do not need to reload the information again.
        if (productSet) return;

        updateNamePricePromoContainer($response);
        $('#update-name-price-promo-container').remove();

        updateCylindoImages($response);
        $('#update-images').remove();

        updateShownIn($response)

        // Update the url to reflect all current customization selections.
        // This is to keep the size (and possibly others) in sync
        // via the url after an variation value was selected.
        let values = getItemMappingValues();
        if (Object.keys(values).length) {
            let url = updateQueryStringParameters(window.location.href, values);
            setBrowserUrl(url);
        }
    }

    // Handle the product:update event to re-establish
    // the jquery elements which are part of the ajax
    // update after calling Product-Variation
    $(document).on('product:update', updateProductContent);
    $(document).on('bmattribute:select', setAccordionItemHeading);

    /**
     * Scrolls customization list to beginning or to selection
     * and, for mobile, also the active option in view.
     */
    function scrollToSelection() {
        const $activeCustomizationBodyStep = $('.product-customization-body-step').filter(selectors.active);
        const $activeOptionScroll = $activeCustomizationBodyStep.children('.product-customization-option-scroll');
        const $selectedOption = $activeCustomizationBodyStep.find('input[type="radio"]:checked');
        const $activeOptionValueSelector = $('.product-customization-option-value-selector').filter("[data-oid='" + $selectedOption.val() + "']");
        const $selectedValue = $activeOptionValueSelector.find('li.selected');

        // Scroll to selected option value swatch or beginning of list
        if ($selectedValue.length && !$selectedValue.parent().hasClass('text-option')) {
            // Swatch Option
            const selectedValueTop = $selectedValue[0].offsetTop;
            $activeOptionScroll.scrollTop(selectedValueTop - $activeOptionScroll[0].offsetTop - 35);
        } else {
            // Text Option
            $activeOptionScroll.scrollTop(0);
        }
    }

    function moveState($old) {
        $('.js_product-attribute-accordion-item').each(function (index) {
            var $newItem = $(this);
            var $oldItem = $old.eq(index);
            $newItem.toggleClass('active', $oldItem.hasClass('active'));
            $newItem.find('.js_product-customization-dropdown-filters').each(function (idx) {
                $(this).replaceWith($oldItem.find('.js_product-customization-dropdown-filters').eq(idx));
            });
        });

        $(document)
            .trigger('update:form:inputs')
            .trigger('update:refinements');

        $('.js_product-customization-dropdown-filter-body .input-checkbox:checked').each(function () {
            addSelectedFilterSwatch($(this));
        });

        applyFilter();
        toggleSelectedFilterSwatches();
    }

    /**
     * Updates for after refreshing the entire customizer
     * @param {String} response HTML
     */
    function customizationUpdate(response) {
        var $response = $(response);

        updateCylindoImages($response);
        updateNamePricePromoContainer($response);
        updateWishlistLink($response);
        updateShownIn($response);
        updatePid($response);

        const $accordionItems = $('.js_product-attribute-accordion-item');

        // Update the dom with the html
        $('.product-customization-container').html(response);

        // Remove the updated components element from the dom
        $('#updates').remove();

        // Initialize the functionality of the newly replaced html elements
        new Customizer();

        moveState($accordionItems);

        // Get the new url if not provided
        let newUrl = $('.js_product-customizations').data(dataNames.updatedUrl);

        // Update the url to reflect all current customization selections
        setBrowserUrl(newUrl);
        setCustomProductSku();
        setSelectedValuesForAddToCart();
    }

    /**
     * Updates for after selecting a value without refreshing
     * the entire customizer
     * @param {String} response HTML
     */
    function partialCustomizationUpdate(response) {
        var $response = $(response);

        updateCylindoImages($response);
        updateNamePricePromoContainer($response);
        updateWishlistLink($response);
        updateShownIn($response);
        setCustomProductSku();

        // Get the new url
        let newUrl = $response.filter('#updates').data(dataNames.updatedUrl);

        // Update the url to reflect all current customization selections
        setBrowserUrl(newUrl);

        // Update hidden input value for the Add to Cart functionality
        setSelectedValuesForAddToCart();
    }

    /**
     * Gets an object with the oIds as the keys and the vvs as the values
     * @returns {Object}
     */
     function getItemMappingValues() {
        let values = {},
            variationAttributes = $('.product-variations').data('attributes');

        if (variationAttributes && $('.product-customization-option-value-selector').length && window.mgbwCustomizationSelections) {
            // Check if the item mapping variation values differ from the selected option values
            $('.product-customization-option-value-selector').filter('[data-hasitemmapping="true"]').each(function() {
                let oId = this.dataset[dataNames.optionId];
                let va = variationAttributes[oId];
                if (va) {
                    let vv = va.value;
                    if (vv) {
                        values[oId] = vv;
                    }
                }
            });
        }

        return values;
    }

    /**
     * Check if all reuired values were selected
     * @returns {Bollean}
     */
    function missingReuiredValues() {
        let emptyReuiredValues = false;
        $('.js_product-attribute-accordion-item').each(function () {
            $(this)
                .find('.product-customization-option-value-selector[data-mandatory="true"]')
                .each(function () {
                    if (!(emptyReuiredValues || $(this).find('.value.selectable.selected').length)) {
                        emptyReuiredValues = true;
                    }
                });
        });
        return emptyReuiredValues;
    }

    function updateSwatchDetailElement(elementName, $element) {
        $element.text(!(elementName === 'null' || elementName === '') ? elementName : '');
    }

    function updateDialogSwatchDetailElement(elementName, $element) {
        if(!(elementName === 'null' || elementName === '')) {
            $element.text(elementName).prev().removeClass('d-none');
        } else {
            $element.text('').prev().addClass('d-none').prev().removeClass('d-none');
        }
    }

    function toggleAddToCart() {
        var isDisabled = missingReuiredValues();
        $('#add-to-cart').prop('disabled', isDisabled);
    }

    function toggleOptionDetails() {
        $('.js_product-customization-selected-option-value-details-wrapper').each(function () {
            var $wrapper = $(this);
            var $tabs = $wrapper.closest('.js_product-attribute-accordion-item').find('.product-customization-option-value-selector');
            $tabs.filter('.active').each(function () {
                var $selected = $(this).find('.value.selectable.selected');
                $wrapper.toggleClass('d-none', !$selected.length);
            });
        });
    }

    function customizationAjaxCall(url, params, thisOptionValueSelector, selectedLi) {
        const oId = thisOptionValueSelector.data(dataNames.optionId);
        const requiresFullUpdate = params.requiresFullUpdate || false;
        // Execute the ajax call for the selection
        ajax.load({
            url: util.appendParamsToUrl(url, params),
            callback: function (response, status) {
                if (status === 'error') {
                    alert('An error occured and the selection could not be made.');
                } else {
                    if (!selectedLi.hasClass(classNames.selected)) {
                        // Select
                        selectCustomizationValue(selectedLi, thisOptionValueSelector);
                    } else {
                        // Deselect
                        deselectCustomizationValue(selectedLi, thisOptionValueSelector);
                    }
                    if (requiresFullUpdate) {
                        // Update the customizer, summary, price, and url
                        customizationUpdate(response);

                        if (!$('.product-customization-option > input[type="radio"]').filter('[value='+oId+']:checked').length) {
                            // Select the option which was selected before update
                            $('.product-customization-option > input[type="radio"]').filter('[value='+oId+']').click();
                        }
                        $(document).trigger('custom:lazy:load:update');
                    } else {
                        // Update prices and url
                        partialCustomizationUpdate(response);
                    }

                    // skip Optional welt option
                    if (oId !== 30) {
                        displaySwatchDetails();
                    }

                    toggleOptionDetails();
                    setAccordionItemHeading();
                    toggleAddToCart();
                    $('#pdpMain').trigger('updateHQData');
                }
            }
        });
    }

    function setAccordionItemHeading() {
        $('.js_product-attribute-accordion-item').each(function () {
            const $activeValueSelector = $(this);
            const $productCustomizationSwatchDetails = $activeValueSelector.find('.product-attribute-accordion-item__heading-selected-value');
            // if we have product with several steps for same tab e.g. Base Cover and Optional Welt
            let $textOption;
            const steps = $activeValueSelector.find('.product-customization-option-value-selector');
            if (steps.length > 1) {
                $textOption = steps.first().find('.product-customization-values.text-option');
            } else {
                $textOption = $activeValueSelector.find('.product-customization-values.text-option');
            }
            if ($activeValueSelector.find('.product-customization-values').length) {
                if ($textOption.length) {
                    updateSwatchDetailElement($textOption.find('.value.selected').data('name'), $productCustomizationSwatchDetails);
                } else {
                    const $swatchDetail = $activeValueSelector.find('.swatch.selected .swatch-detail-hover');
                    const swatchDetailContent = $swatchDetail.data('swatchdetail');

                    if (swatchDetailContent) {
                        updateSwatchDetailElement(swatchDetailContent.swatchName, $productCustomizationSwatchDetails);
                    }
                }
            }
        });
    }

    function displaySwatchDetails() {
        $('.js_product-attribute-accordion-item').each(function () {
            const $activeValueSelector = $(this);
            const $productCustomizationSwatchDetails = $activeValueSelector.find('.product-customization-selected-option-details');
            $productCustomizationSwatchDetails.closest('.js_product-customization-selected-option-value-details-wrapper').addClass('d-none');

            if ($activeValueSelector.closest('.js_product-attribute-accordion').hasClass('bm-attributes')) {
                const $swatchDetail = $activeValueSelector.find('.swatches .selected');
                const $swatchDetailContainer = $swatchDetail.find('.swatch-detail-overlay');

                if ($swatchDetailContainer.length) {
                    updateSwatchDetailElement($swatchDetailContainer.data('bmv-name'), $productCustomizationSwatchDetails.find('.customization-swatch-name'));
                    $productCustomizationSwatchDetails.find('.img img').attr('src', $swatchDetailContainer.data('bmv-img'));
                    $productCustomizationSwatchDetails.closest('.js_product-customization-selected-option-value-details-wrapper').removeClass('d-none');
                }
            } else {
                const $swatchDetail = $activeValueSelector.find('.product-customization-option-value-selector.active .swatch.selected .swatch-detail-hover');
                const swatchDetailContent = $swatchDetail.data('swatchdetail');

                if (swatchDetailContent) {
                    $productCustomizationSwatchDetails.data('swatchdetail', swatchDetailContent);
                    updateSwatchDetailElement(swatchDetailContent.swatchName, $productCustomizationSwatchDetails.find('.customization-swatch-name'));
                    $productCustomizationSwatchDetails.find('.img img').attr('src', swatchDetailContent.image);
                    $productCustomizationSwatchDetails.closest('.js_product-customization-selected-option-value-details-wrapper').removeClass('d-none');
                }
            }
        });
    }

    function showDialogSwatchDetails($item) {
        let $swatchDetails;

        if ($item.closest('.js_product-attribute-accordion').hasClass('bm-attributes')) {
            const $swatchDetail = $item.find('.swatches .selected');
            const $swatchDetailContainer = $swatchDetail.find('.swatch-detail-overlay');

            if ($swatchDetailContainer.length) {
                $swatchDetails = $('<div/>').append($swatchDetailContainer.clone());
            }
        } else {
            $swatchDetails = $('.product-customization-swatch-theme').clone();
            const $productCustomizationSwatchDetails = $item.find('.product-customization-selected-option-details');
            const swatchDetailContent = $productCustomizationSwatchDetails.data('swatchdetail');

            if (swatchDetailContent) {
                updateDialogSwatchDetailElement(swatchDetailContent.swatchName, $swatchDetails.find('.customization-swatch-name'));
                updateDialogSwatchDetailElement(swatchDetailContent.descriptionContents, $swatchDetails.find('.description-contents'));
                updateDialogSwatchDetailElement(swatchDetailContent.description, $swatchDetails.find('.description-detail'));
                updateDialogSwatchDetailElement(swatchDetailContent.grade, $swatchDetails.find('.description-grade'));
                updateDialogSwatchDetailElement(swatchDetailContent.descriptionCareinstructions, $swatchDetails.find('.description-careinstructions'));
                $swatchDetails.find('.img img').attr('src', swatchDetailContent.image);
            }
        }

        if ($swatchDetails) {
            dialog.open({
                html: $swatchDetails.html(),
                options: {
                    open: function () {
                        $(document).trigger('custom:lazy:load:update');
                    },
                    dialogClass: 'product-customization-swatch-theme-dialog',
                    position: {
                        my: 'center',
                        at: 'center',
                        of: window
                    }
                }
            });
        }
    }

    // Click on start customizing - should hide the right column and only show the customization container.
    // An ajax call will be made to seed the customizer with the values from the selected variation product.
    function initCustomization() {
        const defaultCustomizationValues = $('.js_product-customizations').data('default-customization');

        if (defaultCustomizationValues){
            window.mgbwCustomizationSelections = defaultCustomizationValues;
        }
    }

    function updateCountDisplayedOptions() {
        // Set the number of displayed options
        $('.js_product-customizations')
            .find('.js_product-attribute-accordion-item')
            .each(function () {
                var $swatches = $(this).find('.product-customization-option-value-selector.active li.swatch');
                var $visibleSwatches =
                    $swatches.filter(function () {
                        return $(this).css('display') !== 'none';
                    });

                $(this)
                    .find('.js_count-options')
                    .text($visibleSwatches.length + ' ' + Resources.CUSTOMIZATION_OPTIONS);
            });
    }

    function applyFilter() {
        $('.product-customization-option > input[type="radio"]').each(function () {
            let oId = $(this).val(),
                filterValues = [],
                filterSelector,
                $selectedFilters = $('.js_product-customization-filters-count-wrapper').filter('[data-oid=' + oId + ']').find('.product-customization-filter-value > input[type=checkbox]:checked'),
                $currentSlector = $('.product-customization-option-value-selector').filter('[data-oid=' + oId + ']'),
                $allOptionValues = $currentSlector.find('li'),
                $allFamilyNames = $currentSlector.find('.product-customization-option-value-family-name'),
                $filteredSwatches,
                uniqueFilteredFamilies = new Set();

            // Get the selected filter values
            $selectedFilters.each(function (i, e) {
                filterValues.push(e.value);
            });

            // Hide all families and swatches
            $allFamilyNames.hide();
            $allOptionValues.hide();

            // Are filters selected?
            if (filterValues.length) {
                // Apply the filter to the option value swatches
                filterSelector = filterValues.join(', .swatch.');
                $filteredSwatches = $allOptionValues.filter('.swatch.' + filterSelector);
                $filteredSwatches.show();

                // Find which families are among the filtered swatches and show them
                $filteredSwatches.each(function (i, e) {
                    uniqueFilteredFamilies.add(e.dataset.familyid);
                });
                filterValues = [];
                uniqueFilteredFamilies.forEach(function (f) {
                    filterValues.push('[data-familyid="' + f + '"]');
                });
                filterSelector = filterValues.join(',');

                // Filter family names for desktop but call displaySwatchFamilyName for mobile
                $allFamilyNames.filter(filterSelector).show();
            } else {
                // No filters applied.  Show everything.
                $allOptionValues.show();
                $allFamilyNames.show();
            }
        });

        // Update the filter button filter count
        updateCountDisplayedOptions();
    }

    function toggleSelectedFilterSwatches() {
        $('.product-customization-selected-filters-wrapper').each(function () {
            $(this).toggleClass('d-none', $(this).find('button').length < 3);
        });
    }

    function addSelectedFilterSwatch($checkbox) {
        var $container = $checkbox.closest('.js_product-customization-filters-count-wrapper');
        var $filterSwatch = $container.find('.js_product-customization-selected-filter-template').eq(0).clone();
        $filterSwatch
            .attr('data-id', $checkbox.attr('id'))
            .removeClass('js_product-customization-selected-filter-template d-none')
            .find('.product-customization-selected-filter__text')
            .html($checkbox.data('text'));
        var $clearAll = $container.find('.js_product-customization-selected-filter-clear-all');
        $filterSwatch.insertBefore($clearAll);
    }

    function removeSelectedFilterSwatch($checkbox) {
        $checkbox
            .closest('.js_product-customization-filters-count-wrapper')
            .find('.product-customization-selected-filter[data-id=' + $checkbox.attr('id') + ']')
            .remove();
    }

    function removeAllSelectedFilterSwatch() {
        $('.js_product-customization-filters-count-wrapper.active')
            .find('.checkbox-label.checked')
            .each(function () {
                $(this)
                .find('span')
                .first()
                .trigger('click');
            });
    }

    function selectCustomizationValue($selectedli, $thisOptionValueSelector) {
        let $selectedImg,
            oId,
            vId,
            $selectedOptionRadio,
            $dimsList;

        $thisOptionValueSelector = $thisOptionValueSelector || $selectedli.parents(selectors.optionValueSelector);
        oId = $thisOptionValueSelector.data(dataNames.optionId);
        vId = $selectedli.data(dataNames.valueId);

        // Update the selected <li>
        $selectedli.parent().parent().find('li.selected').removeClass(classNames.selected);
        $selectedli.addClass(classNames.selected);

        $selectedOptionRadio = $('.product-customization-option > input[type="radio"]').filter('[value='+oId+']');

        // Look for the image dot in the selected option
        $selectedImg = $selectedli.find('> button > img');
        if ($selectedImg.length) {
            // Update the tiny image dot in the selected option
            $selectedOptionRadio.next('label').find('img')
                .attr('src', $selectedImg.attr('data-img'))
                .attr('alt', $selectedImg.attr('alt'))
                .show();
        } else {
            // No image dot on this option so update it with the selected value text
            $selectedOptionRadio.next('label').find('.value')
                .text($selectedli.find('button > .name').text())
                .show();

            // Update the selected value name and description, if it exists
            $thisOptionValueSelector.find(selectors.selectedValueName).removeClass('d-none').text($selectedli.data('name'));
            $thisOptionValueSelector.find(selectors.selectedValueDescrption).removeClass('d-none').text($selectedli.data('description'));
        }

        // Show the Remove button with the option radio  (Remove button exists only if option is not mandatory)
        $selectedOptionRadio.nextAll('.remove').removeClass('d-none');

        // Show the See Details link and content - mobile
        if (window.innerWidth < globalVars.breakpoints.large) {
            displaySwatchDetails();
        }

        // Update the dimensions, if necessary
        $dimsList = $thisOptionValueSelector.find('ul.dimensions-list');
        if ($dimsList.length) {
            $('#pdpMain .product-tabs').addBack('.print-product-tabs').find('ul.dimensions-list').html($dimsList.html());
        }

        // Update the stored selections
        window.mgbwCustomizationSelections[oId] = vId;
    }

    function deselectCustomizationValue($selectedli, $thisOptionValueSelector) {
        let oId,
            $selectedOptionRadio;

        $thisOptionValueSelector = $thisOptionValueSelector || $selectedli.parents(selectors.optionValueSelector);
        oId = $thisOptionValueSelector.data(dataNames.optionId);

        // Update the selected <li>
        $selectedli.parent().find('li.selected').removeClass(classNames.selected);

        $selectedOptionRadio = $('.product-customization-option > input[type="radio"]').filter('[value='+oId+']');

        // Remove the tiny image dot in the selected option
        $selectedOptionRadio.next('label').find('img')
            .hide()
            .attr('src', null)
            .attr('alt', null);

        // Remove the text value in the selected option
        $selectedOptionRadio.next('label').find('.value')
            .hide()
            .text('');

        // Hide the Remove button with the option radio
        $selectedOptionRadio.nextAll('.remove').addClass('d-none');

        // Remove the selected value name and description
        $thisOptionValueSelector.find(selectors.selectedValueName).addClass('d-none').text('');
        $thisOptionValueSelector.find(selectors.selectedValueDescrption).addClass('d-none').text('');

        if (window.innerWidth < globalVars.breakpoints.large) {
            displaySwatchDetails();
        }

        // Update the stored selections
        delete window.mgbwCustomizationSelections[oId];
    }

    function showCorrectFilters() {
         $('.js_product-customization-filters-count-wrapper').removeClass('active');

        $('.product-customization-option > input[type="radio"]:checked').each(function () {
            $(this)
                .closest('.js_product-attribute-accordion-item')
                .find('.js_product-customization-filters-count-wrapper[data-oid=' + $(this).val() + ']')
                .addClass('active');
        });
    }

    $(document)
        .on(eventNames.click, function (e) {
            var $target = $(e.target);
            if ($target.closest('.js_product-customization-dropdown-filters').length || $target.hasClass('js_product-customization-dropdown-filter-heading')) {
                return;
            }

            $('.js_product-customization-dropdown-filter').removeClass('active');
        })
        .on(eventNames.click, '.js_product-customization-dropdown-filter-heading', function () {
            var $currentItem = $(this).closest('.js_product-customization-dropdown-filter');

            if ($currentItem.hasClass('active')) {
                $currentItem.removeClass('active');
                return;
            }

            $(this).closest('.js_product-customization-dropdown-filters').find('.js_product-customization-dropdown-filter').removeClass('active');
            $currentItem.addClass('active');
        })
        .on('formElement:clicked', '.js_product-customization-dropdown-filter-body .input-checkbox', function () {
            if ($(this).prop('checked')) {
                addSelectedFilterSwatch($(this));
            } else {
                removeSelectedFilterSwatch($(this));
            }

            applyFilter();
            toggleSelectedFilterSwatches();
        })
        .on(eventNames.click, '.js_product-customization-selected-filter-clear-all', function () {
            removeAllSelectedFilterSwatch();
        })
        .on(eventNames.click, '.product-customization-selected-filter[data-id]', function () {
            $('.checkbox-label.checked[for=' + $(this).attr('data-id') + ']')
                .find('span')
                .first()
                .trigger('click');
        })
        .on(eventNames.click, '.js_product-customization-selected-option-value-see-details', function (e) {
            showDialogSwatchDetails($(this).closest('.js_product-attribute-accordion-item'));
        })
        .on(eventNames.click, function (e) {
            if (!$('.product-customization-swatch-theme-dialog').is(':visible')) {
                return;
            }

            var $target = $(e.target);

            if (!($target.closest('.product-customization-swatch-theme-dialog').length || $target.hasClass('js_product-customization-selected-option-value-see-details'))) {
                dialog.close();
            }
        })
        .on(eventNames.click, '.js_close-product-customization-swatch-theme', function () {
            dialog.close();
        })
        .on(eventNames.change, '.product-customization-option > input[type="radio"]', function (e) {
            // Click on custiomization option - should show the correct option values within the current body step
            let $activeBodyStepOptionValueSelector = $(this).parents('.product-customization-body-step').find(selectors.optionValueSelector);
            $activeBodyStepOptionValueSelector
                .removeClass(classNames.active)
                .prev('.product-customization-filter-button-wrapper')
                .removeClass(classNames.active);
            $activeBodyStepOptionValueSelector
                .filter("[data-oid='" + e.target.value + "']")
                .addClass(classNames.active)
                .prev('.product-customization-filter-button-wrapper')
                .addClass(classNames.active);

            showCorrectFilters();
            updateCountDisplayedOptions();
            displaySwatchFamilyName();
            displaySwatchDetails();
            toggleOptionDetails();
            scrollToSelection();
        })
        .on(eventNames.click, '.product-customization-option > .remove', function (e) {
            // Click on Remove option selection - should deselect the selected option value
            e.preventDefault();
            $('.product-customization-option-value-selector').filter('[data-oid='+$(this).data(dataNames.optionId)+']').find('li.selected > button').click();
            toggleOptionDetails();
        })
        .on(eventNames.click, '.product-customization-option-value-family-name', function () {
            if (window.innerWidth >= globalVars.breakpoints.large) {
                $(this).toggleClass('hide');
            }
        })
        .on(eventNames.click, '.product-customization-values li.selectable > button', function(e) {
            // Click on customization value - should update the selection and make an ajax call to update the state
            e.preventDefault();
            let $this = $(this);
            let $selectedli = $this.parent();
            let $thisOptionValueSelector = $this.parents(selectors.optionValueSelector);
            let oId = $thisOptionValueSelector.data(dataNames.optionId);
            let vId = $selectedli.data(dataNames.valueId);
            let newCustomization = isNewCustomization();
            let requiresFullUpdate = newCustomization || $thisOptionValueSelector.data('hasitemmapping');
            let params;
            let url = $('.js_product-customizations').data(dataNames.url);
            const missingValues = missingReuiredValues();

            if ($selectedli.hasClass(classNames.selected) && $thisOptionValueSelector.data('mandatory') == true) {
                // Clicked value is the selected one which is also mandatory.
                // Do not allow deselection.
                return;
            }

            progress.show($('#pdpMain'));

            // Update the selection data
            if (window.mgbwCustomizationSelections[oId] == vId && !missingValues) {
                // Remove the selection if the value is the same and all required values were slected.
                // That means it was deselected.
                delete window.mgbwCustomizationSelections[oId]
            } else {
                window.mgbwCustomizationSelections[oId] = vId
            }

            // Construct the parameters to be appended to the url for the ajax call
            // TODO change this to make only first call with requiresFullUpdate
            params = {
                format: 'ajax',
                requiresFullUpdate: requiresFullUpdate
            };
            updateCustomizationParamsWithValues(params, window.mgbwCustomizationSelections);

            // Execute the ajax call for the selection
            customizationAjaxCall(url, params, $thisOptionValueSelector, $selectedli);
        })
        .on('custom:customization:update:count:displayed:options', updateCountDisplayedOptions)
        .on('custom:customization:display:swatch:details', displaySwatchDetails)
        .on('custom:customization:update:window', initCustomization);

    /**
     * Updates the params object with the parameter values
     * in the proper format for the Product-Customization
     * ajax call.
     *
     * Example Updated Params:
     * { "oid-12":"34" }
     *
     * @param {Object} params
     * @param {Object|null} paramValues
     */
    function updateCustomizationParamsWithValues(params, paramValues) {
        const htmlPrefix = 'oid-';
        Object.keys(paramValues).forEach(key => {
            params[htmlPrefix + key] = paramValues[key];
        });
    }

    // Displays the family name for the visible swatch list on mobile
    function displaySwatchFamilyName() {
        let visibleSwatchFamilyName;
        let visibleSwatchFamilyNameWrapper;
        let leftScrolledSwatchFamily;
        let leftScrolledSwatchFamilies = [];

        if (window.innerWidth >= globalVars.breakpoints.large) {
            return;
        }

        const $activeCustomizationStep = $('.product-customization-body-step.'+classNames.active);
        const $activeOptionValueSelector = $activeCustomizationStep.find(selectors.optionValueSelector+'.'+classNames.active);

        // Get all the family names and family swatch lists for the active customization option
        const $swatchFamilyNames = $activeOptionValueSelector.find('.product-customization-option-value-family-name');
        const $swatchFamilies = $activeOptionValueSelector.find('.product-customization-option-value-family');

        if(!$swatchFamilies.length) {
            return;
        }

        // Loop through swatch families to find the swatch list currently visible in the viewport
        $swatchFamilies.each(function (){
            let swatchFamilyPosition = $(this).position().left;
            let positionFirstSwatch = 50;

            // If the swatch list position is a negative value it is either scrolled to the left
            // of the viewport or currently in the viewport
            if (swatchFamilyPosition <=  positionFirstSwatch) {
                leftScrolledSwatchFamilies.push($(this));
            }
        });

        // The last swatch family in array is the current list in the viewport
        leftScrolledSwatchFamily = leftScrolledSwatchFamilies.pop();
        if (leftScrolledSwatchFamily) {
            visibleSwatchFamilyName = leftScrolledSwatchFamily.data('familyid');

            visibleSwatchFamilyNameWrapper = $swatchFamilyNames.filter('[data-familyid="' + visibleSwatchFamilyName + '"]');

            if(visibleSwatchFamilyNameWrapper.not(':visible')) {
                $swatchFamilyNames.not(visibleSwatchFamilyNameWrapper).fadeOut(transitionValues.fast);
                visibleSwatchFamilyNameWrapper.fadeIn(transitionValues.slow);
            }
        }
    }

    /**
     * Initializes the customizer and summary elements and functionality
     */
    function Customizer() {
        // Initialize the customizer

        // Init the data object with the current selections
        window.mgbwCustomizationSelections = {};

        // Update the UI with the selected values
        $('.product-customization-option-value-selector').find('.product-customization-values li.selectable').filter(selectors.selected).each(function (i, element) {
            selectCustomizationValue($(element));
        });

        setBrowserUrl($('.js_product-customizations').data(dataNames.updatedUrl));
        updateCountDisplayedOptions();
    }

    // Initialize the customizer, if found on the page
    if ($productCustomizationContainer.find('.js_product-customizations').length) {
        new Customizer();

        let missingValues = missingReuiredValues();

        if (missingValues) {
            initCustomization();
        } else {
            setCustomProductSku();
            setSelectedValuesForAddToCart();
        }

        toggleOptionDetails();
        displaySwatchDetails();
        setAccordionItemHeading();
        scrollToSelection();
    }
};
