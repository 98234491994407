'use strict';

var dialog = require('./dialog'),
    util = require('./util'),
    gtm = require('./pages/gtm'),
    fppSeeDetailsDialogClass = 'furniture-protection-see-details-ui-dialog';

function setFurnitureProtectionPricing(assetID) {
    var url = util.appendParamToURL(Urls.furnitureProtectionPricing, 'cid', assetID);

    $.ajax({
        type: 'GET',
        url: url,
        success: function (response) {
            $('.' + fppSeeDetailsDialogClass)
                .find('.furniture-pricing-rows')
                .replaceWith(response);
        }
    });
}

function addFppProduct($elem) {
    var pid = $elem.data('fpp-pid');
    var data = {
        pid: pid,
        fpp: true
    };
    var uuidToCover = $elem.data('uuidtocover');
    if(uuidToCover) {
        data.uuidToCover = uuidToCover;
    }


    var $gtmContainer = $('.js_gtm-fpp-offer[data-fpp-pid="' + pid + '"]');
    var fppId = $gtmContainer.data('fpp-name');
    var fppName = $gtmContainer.data('fpp-name');
    var fppPrice = $gtmContainer.data('fpp-price-value');

    $.ajax({
        type: 'POST',
        url: util.appendParamToURL(Urls.addProduct, 'format', 'json'),
        data: data,
        success: function(data) {
            if (data && 'uuid' in data && 'quantity' in data) {
                $(document).trigger('custom:update:cart', {
                    uuid: data.uuid,
                    qty: data.quantity
                });

                gtm.fppAddToCart({
                    isCartPage: true,
                    id: fppId,
                    name: fppName,
                    price: fppPrice
                });
            }
        }
    });
}

function initializeEvents() {
    $('body')
        .on('click', '.js_add-fpp-to-cart', function (e) {
            e.preventDefault();
            addFppProduct($(this));
        })
        .on('click', '.js_fpp-see-details', function (e) {
            var assetID = $(this).data('asset-id');
            if (!assetID || assetID.length === 0) {
                return;
            }

            var url = $(this).attr('href');
            if (!url || url.length === 0) {
                return;
            }

            var price, fppPid;
            var $container = $(this).closest('.js_fpp-offer__container');
            var $containerLineItem = $(this).closest('.cart-row');
            var isLineItem = $containerLineItem.length > 0;

            if ($container.length > 0) {
                price = $container.find('.fpp-offer__price').html();
                fppPid = $container.find('.js_add-fpp-to-cart').data('fpp-pid');
            } else if (isLineItem) {
                price = $containerLineItem.find('.fpp-line-item__price').first().html();
                fppPid = $containerLineItem.data('pid');
            } else {
                return;
            }

            e.preventDefault();


            dialog.open({
                url: url,
                options: {
                    open: function () {
                        var dialog = $(this);

                        dialog
                            .find('.js_furniture-protection-see-details-dialog__price').html(price)
                            .addBack()
                            .find('.js_furniture-protection-see-details-dialog__add').attr('data-fpp-pid', fppPid);

                        setFurnitureProtectionPricing(assetID);
                        $(window).scrollTop(0);

                        if (isLineItem) {
                            dialog
                                .find('.furniture-protection-see-details-dialog__actions')
                                .find('input[type="checkbox"], .js_furniture-protection-see-details-dialog__add').attr('disabled', true);
                        }

                        dialog
                            .on('click', '.js_close', function () {
                                dialog.dialog('close');
                            })
                            .on('click', '.js_furniture-protection-see-details-dialog__add', function (e) {
                                e.preventDefault();
                                addFppProduct($(this));
                                dialog.dialog('close');
                            });
                    },
                    dialogClass: fppSeeDetailsDialogClass
                }
            });
        })
        .on('click', '.js_furniture-protection-see-details-dialog__show-more', function () {
            $(this).closest('.furniture-protection-see-details-dialog').toggleClass('full');
        });
}

exports.init = function () {
    initializeEvents();
};
