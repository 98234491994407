/**
 * @package     Blueacorn/product
 * @version     2.0
 * @author      Blue Acorn <code@blueacorn.com>
 * @copyright   Copyright © 2016 Blue Acorn.
 */

(function (root, factory) {
    if (typeof exports === 'object') {
        // CommonJS
        module.exports = factory(
            window.jQuery,
            require('./blueacorn-core'),
            require('../vendor/enquire/enquire.min'),
            require('./blueacorn-product-sticky'),
            require('imagesloaded'),
            require('../progress'),
            require('../dialog'),
            require('../video'),
            require('./blueacorn-plugin-change-text'),
            require('./blueacorn-owlCarousel-patch')
        );
    } else if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery', 'baCore', 'enquireJs', 'productSticky', 'imagesloaded', 'progress', 'dialog'], function (jquery, ba, enquire, productSticky, imagesloaded, progress, dialog, video) {
            return (factory(jquery, ba, productSticky, imagesloaded, progress, dialog, video));
        });
    } else {
        // Global Variables
        root.ba.product = factory(root.jQuery, root.ba, root.enquire, root.productSticky, root.progress, root.dialog, root.video);
    }

}(this, function ($, ba, enquire, productSticky, imagesloaded, progress, dialog, video) {
    'use strict';

    var product = {

        settings: {
            inventory: $('.main-ctas .inventory, .add-sub-product .inventory'),
            swatchCarouselLimitPS: 4,
            readMoreText: $('.desc-readmore').textChange(),
            $collectionPieces: $('#collection-pieces .tiles-container'),
            $collectionTiles: $('#collection-pieces .tiles-container'),
            $personalizeIframe: $('.product-personalize-iframe'),

            /**
             * Limit settings for each carousel
             * type on the page
             */
            swatchCarouselLimit: 9,
            productCarouselLimit: 4,
            collectionsCarouselLimit: 3,

            /**
             * When on mobile, we need to truncate the values
             * @type {number}
             */
            collectionMobileValueLimit: 17
        },

        init: function (options) {
            // Overrides the default settings
            ba.overrideSettings(this.settings, options);

            // Start the debugger
            ba.setupDebugging(this.settings);

            this.settings.$collectionTiles = this.settings.$collectionPieces.find('.grid-tile').find('.product-image');

            this.setObservers();
            this.checkCourselArrows();
            this.productCarousels();
            this.recommedationsCarouselInit();
            this.productRecentlyViewedCarousel();
            this.refreshCarouselArrows();
            this.tearSheetClick();
            this.initTearSheetTooltip();
            if ($(this.settings.$personalizeIframe)) {
                this.createIframe();
                this.createCustomShiv();
            }
            var vars = this.getUrlVars()
            if (vars['draping'] == 'true') {
                this.updateProduct(true);
            }
            this.changePriceDisplay();
        },

        respondswatchCarouselLimit: function () {
            var self = this;

            enquire
                .register('screen and (min-width:' + ba.bp.xxsmall + 'px) and (max-width:' + ba.bp.medium + 'px)', function () {
                    self.settings.swatchCarouselLimit = 5;
                    self.swatchCarousel();
                })
                .register('screen and (min-width:' + (ba.bp.medium + 1) + 'px) and (max-width:' + ba.bp.large + 'px)', function () {
                    self.settings.swatchCarouselLimit = 6;
                    self.swatchCarousel();
                })
                .register('screen and (min-width:' + (ba.bp.large + 1) + 'px) and (max-width:' + ba.bp.xlarge + 'px)', function () {
                    self.settings.swatchCarouselLimit = 7;
                    self.swatchCarousel();
                })
                .register('screen and (min-width:' + (ba.bp.xlarge + 1) + 'px)', function () {
                    self.settings.swatchCarouselLimit = 7;
                    self.swatchCarousel();
                });
        },
        getUrlVars: function() {
            var vars = [], hash;
            var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for(var i = 0; i < hashes.length; i++) {
                hash = hashes[i].split('=');
                vars.push(hash[0]);
                vars[hash[0]] = hash[1];
            }
            return vars;
        },
        setObservers: function () {
            var self = this;
            this.qtyButtonObservers();

            // AJAX product update
            $(document).on('product:update', self.productUpdateCallback.bind(self));
            $(document).on('product:update', self.updateProductContent);
            $(document).on('product:update', self.createIframe.bind(self));

            $('.simple.gift-registry').on('click', self.addToRegistry);
            $('.desc-readmore').on('click', self.showHideProductDescription.bind(self));

            enquire.register('screen and (max-width:600px)', function () {
                self.toggleReadMoreDesc(true);
            }).register('screen and (min-width:601px)', function () {
                self.toggleReadMoreDesc(false);
            });

            enquire.register('screen and (max-width:707px)', function () {
                self.collectionCarousel(true);
            }).register('screen and (min-width:708px)', function () {
                self.collectionCarousel(false);
            });

            // Adjusting the maxHeight on product desciption on phones
            $(window).on('delayed-resize', self.resizeProductDescription.bind(self));
        },

        /**
         * Updates the product content based on variants being selected,
         * and when the content is being AJAX loaded in.
         *
         * Updates:
         * - product SKU
         * - product price
         * - product actions
         *
         * @param event - jQuery event
         * @param response - AJAX response content
         * @param productSet - Coming from Product Set?
         */
        updateProductContent: function (event, response, productSet) {
            var $response = $(response);

            // Product Sets and Bundles load the content twice,
            // so we do not need to reload the information again.
            if (productSet) return;

            var $pid = $response.find('input[name="pid"]'),
                $pidL = $pid.length,
                $productSKU = $('.product-number span.productID'),
                $productPrice = $response.find('.product-price'),
                $stickyProductPrice = $('.product-stickyheader .product-price'),
                $socialSharing = $('.product-detail-container .product-desc .socialsharing'),
                $pdpFormActions = $('form.pdpForm .product-actions');

            // update product SKU
            if ($pidL >= 1 && $productSKU.length >= 1) {
                $productSKU.text($pid.val());
            }

            // update product price
            if ($stickyProductPrice.length >= 1 && $productPrice.length >= 1) {
                $stickyProductPrice.html($productPrice.html());
            }

            // update product actions
            if ($pdpFormActions.length >= 1) {
                $('.product-detail-container .product-desc .product-actions').html($pdpFormActions.html()).append($socialSharing);
            }

            let productTabs = ['.tab-dimensions', '.tab-design', '.tab-shipping'];

            productTabs.forEach(function(item){
                let $currentResponseTab = $response.find(item);
                let $currentRealTab = $(item);

                if ($currentRealTab.length && $currentResponseTab.length) {
                    $currentRealTab.html($currentResponseTab.html());
                }
            })
        },

        /**
         * If Add To Cart button is disabled,
         * which means all variant attributes
         * have not been selected, then we disabled
         * the print this page feature.
         */
        tearSheetClick: function () {
            var self = this;

            $('a.tearsheet').on('click', function (event) {
                var $variations = $('.product-variations');
                var attributeObj = $variations.data('attributes');

                if ($variations.length && typeof attributeObj === 'object' && Object.keys(attributeObj).length === 0) {
                    event.preventDefault();
                    $('a.tearsheet:visible').tooltipster('open', function () {
                        setTimeout(function () {
                            $('a.tearsheet:visible').tooltipster('close');
                        }, 2000);
                    });
                }
            });
        },

        initTearSheetTooltip: function () {
            this.tearSheetTooltip = $('a.tearsheet').tooltipster({
                theme: 'tooltipster-borderless',
                trigger: 'custom',
                debug: false,
                maxWidth: (typeof pageContext !== 'undefined' && pageContext.ns === 'product') ? 164 : null,
                side: ['bottom', 'top', 'right', 'left'],
                content: 'Please choose all options to print tear sheet'
            });
        },

        changePriceDisplay: function() {
            if ($('.login-link a').attr('title') === 'Log Out') {
                // Add logged-in class to product-price for styling
                $('.product-add-to-cart .product-price').addClass('logged-in');
            }
        },

        /**
         * Updates the product page features, based on a subset of specifications
         * listed below; specifically for AJAX'd content refresh
         */
        productUpdateCallback: function () {
            this.settings.inventory = $('.main-ctas .inventory, .add-sub-product .inventory');
            this.qtyButtonObservers();
            this.productCarousels();
            this.tearSheetClick();
            this.initTearSheetTooltip();
            this.changePriceDisplay();
        },

        productCarousels: function () {
            var $primarySlides = $('.js_product-primary-image-carousel');

            if ($primarySlides.hasClass('slick-initialized')) {
                return;
            }

            var $thumbnailSlides = $('.js_pdp-thumbnails-carousel');
            var thumbSlidesToShow = 4;

            $primarySlides
                .slick({
                    arrows: false,
                    asNavFor: '.js_pdp-thumbnails-carousel',
                    dots: false,
                    infinite: true,
                    slidesToScroll: 1,
                    slidesToShow: 1
                })
                .on('afterChange', function (event, slick, currentSlide) {
                    var $currentSlide = $(slick.$slides.get(currentSlide));

                    if ($currentSlide.find('.product-video-player').length) {
                        $('.product-primary-image').addClass('show-video');
                        video.setDimensions();
                        video.play();
                    } else {
                        $('.product-primary-image').removeClass('show-video');
                        video.pause();
                    }
                });

            $thumbnailSlides
                .on('init', function () {
                    if ($(this).find('.thumb').length <= thumbSlidesToShow) {
                        $(this).addClass('no-carousel');
                    }
                })
                .slick({
                    arrows: true,
                    asNavFor: '.js_product-primary-image-carousel',
                    dots: false,
                    focusOnSelect: true,
                    infinite: true,
                    slidesToScroll: 1,
                    slidesToShow: thumbSlidesToShow,
                    vertical: true,
                    responsive: [
                        {
                            breakpoint: 1127,
                            settings: {
                                vertical: false
                            }
                        }
                    ]
                });
        },

        recommedationsCarouselInit: function (reInit) {
            var $container = reInit ? this.destroyCarousel('#carousel-recommendations .tiles-container') : $('#carousel-recommendations .tiles-container'),
                self = this;

            if ($container.length > 0) {
                var $items = $container.find('.js_grid-tile');
                var itemsCount = $items.length;

                this.recommedationsCarousel = $container
                    .on('initialized.owl.carousel changed.owl.carousel', function() {
                        $(document).trigger('custom:lazy:load:update');
                    })
                    .on('initialized.owl.carousel', function() {
                        $(document).trigger('custom:owl:carousel:initialized', {
                            carousel: $(this)
                        });
                    })
                    .owlCarousel({
                        loop: true,
                        mouseDrag: true,
                        nav: true,
                        items: 4,
                        responsive: {
                            0: {
                                items: 2,
                                nav: false,
                                dots: itemsCount > 2
                            },
                            1128: {
                                items: 3,
                                dots: itemsCount > 3
                            },
                            1440: {
                                items: 4,
                                dots: itemsCount > 4
                            }
                        }
                    });

                if ($container.data('owlCarousel')._items.length >= self.settings.productCarouselLimit || ($('html').hasClass('resp-mobile') && $container.data('owlCarousel')._items.length !== 1)) {
                    $container.find('.owl-controls').show();
                }
            }
        },

        checkCourselArrows: function () {
            /*
             * TODO: Code Cleanup
             * this is identical to cart.checkCarouselArrows in
             * blueacorn-cart.js
             *
             */
            var self = this;

            enquire
                .register('screen and (min-width:' + ba.bp.xxsmall + 'px) and (max-width:459px)', function () {
                    self.settings.productCarouselLimit = 1;
                    self.settings.collectionsCarouselLimit = 1;
                })
                .register('screen and (min-width:450px) and (max-width:' + ba.bp.medium + 'px)', function () {
                    self.settings.productCarouselLimit = 2;
                    self.settings.collectionsCarouselLimit = 1;
                })
                .register('screen and (min-width:' + (ba.bp.medium + 1) + 'px)', function () {
                    self.settings.productCarouselLimit = 4;
                    self.settings.collectionsCarouselLimit = 3;
                });
        },

        refreshCarouselArrows: function () {
            /*
             * TODO: Code Cleanup
             * this is identical to cart.refreshCarouselArrows in
             * blueacorn-cart.js
             *
             */
            var whiteList = ['recommedationsCarousel', 'recentlyViewedCarousel', 'collectionCarouselContainer'],
                self = this, $container, item, limit;

            $.each(whiteList, function (index) {
                $container = self[whiteList[index]];

                if (typeof $container !== 'undefined') {
                    $container.on('resized.owl.carousel', function (event) {
                        var $that = $(this);
                        item = event.item.count;
                        limit = ($that.parent('#collection-pieces').length > 0) ? self.settings.collectionsCarouselLimit : self.settings.productCarouselLimit;

                        if (item >= limit) {
                            $that.find('.owl-controls').show();
                        } else {
                            $that.find('.owl-controls').hide();
                        }
                    });
                }
            });
        },

        productRecentlyViewedCarousel: function () {
            /*
             * TODO: Code Cleanup
             * this is virtually identical to cart.productCarouselInit in
             * blueacorn-cart.js
             *
             */
            var $container = $('.recently-viewed .search-result-items.tiles-container'),
                self = this;

            if ($container.children().length > 1){
                this.recentlyViewedCarousel = $container
                    .on('initialized.owl.carousel changed.owl.carousel', function() {
                        $(document).trigger('custom:lazy:load:update');
                    })
                    .on('initialized.owl.carousel', function() {
                        $(document).trigger('custom:owl:carousel:initialized', {
                            carousel: $(this)
                        });
                    })
                    .owlCarousel({
                        loop: true,
                        nav: true,
                        items: 4,
                        dots: false,
                        responsive: {
                            0: {
                                items: 1
                            },
                            450: {
                                items: 2
                            },
                            768: {
                                items: 3
                            },
                            1440: {
                                items: 4
                            }
                        }
                    });

                if ($container.data('owlCarousel')._items.length >= self.settings.productCarouselLimit || ($('html').hasClass('resp-mobile') && $container.data('owlCarousel')._items.length !== 1)) {
                    $container.find('.owl-controls').show();
                }
            } else {
                $container.addClass('no-carousel');
            }
        },

        /**
         * Iterates over all variation attributes.
         * If swatch count > 9, then it becomes a carousel.
         * It also will activate the selected and completed states.
         */
        swatchCarousel: function () {
            /*
             * TODO: Code Cleanup
             * this is identical to quickview.swatchCarousel in
             * quickview.js
             *
             */
            var $pdpMain = $('#pdpMain'),
                currentContainer,
                selected,
                selectedValue,
                limit = this.settings.swatchCarouselLimit,
                self = this;

            // If on product set or bundle do not use carousel
            if ($pdpMain.hasClass('bundle')) {
                limit = self.settings.swatchCarouselLimitPS;
            }

            $('.swatches').each(function (ind, element) {
                currentContainer = self.destroyCarousel($(element));
                selected = currentContainer.children('li.selected');
                selectedValue = currentContainer.children('li.selected-value');

                // Moves the currently selected
                // value to appropriate place in the DOM
                if (selectedValue.length >= 1 && !$pdpMain.hasClass('bundle')) {
                    self.addSelectedValue(currentContainer.prev().children('.value'), selectedValue.detach().text(), false);
                    currentContainer.closest('.attribute').children('.select-label').children('.step').addClass('completed');
                } else if (selectedValue.length >= 1 && $pdpMain.hasClass('bundle')) {
                    self.addSelectedValue(currentContainer.parent().prev(), selectedValue.detach().text(), false);
                } else if (selected.length === 0 && $pdpMain.hasClass('bundle')) {
                    var $label = currentContainer.parent().prev();
                    self.addSelectedValue($label.eq(0), $label.data('label'));
                }

                if (currentContainer.children('li').length > limit) {
                    if (selected.length >= 1) {
                        var index = currentContainer.children('li.selected').index();
                    }

                    if ($pdpMain.hasClass('bundle')) {
                        currentContainer.closest('.attribute').addClass('owl-carousel');
                    }

                    currentContainer
                        .on('initialized.owl.carousel changed.owl.carousel', function() {
                            $(document).trigger('custom:lazy:load:update');
                        })
                        .on('initialized.owl.carousel', function() {
                            $(document).trigger('custom:owl:carousel:initialized', {
                                carousel: $(this)
                            });
                        })
                        .owlCarousel({
                            loop: true,
                            nav: true,
                            items: 9,
                            dots: false,
                            startPosition: ($pdpMain.hasClass('bundle') && index && index >= limit) ? index : 0,
                            responsive: self.getSwatchCarouselRespond($pdpMain)
                        });

                    if (!$pdpMain.hasClass('bundle')) {
                        self.determineCarouselStartPos(index, limit, currentContainer);
                    }
                }
            });
        },

        determineCarouselStartPos: function (index, limit, owl) {
            var placement = 3;

            enquire
                .register('screen and (max-width:' + ba.bp.large + 'px)', function () {
                    placement = 2;
                })
                .register('screen and (min-width:' + (ba.bp.large + 1) + 'px) and (max-width:' + ba.bp.xlarge + 'px)', function () {
                    placement = 3;
                })
                .register('screen and (min-width:' + (ba.bp.xlarge + 1) + 'px)', function () {
                    placement = 4;
                });

            owl.trigger('to.owl.carousel', [(typeof index != 'undefined') ? (index - placement) : 0, 100, true]);
        },

        /**
         * OwlCarousels method of removing the carousel does not work,
         * so here is one that does. :)
         * @param carousel
         * @returns {*}
         */
        destroyCarousel: function (carousel) {
            /*
             * TODO: Code Cleanup
             * this is identical to quickview.destroyCarousel in
             * quickview.js
             *
             */
            if (typeof carousel.data('owlCarousel') !== 'undefined') {
                carousel.data('owlCarousel').destroy();
                carousel.removeClass('owl-carousel owl-loaded');
                carousel.find('.owl-stage-outer').children().unwrap();
                carousel.removeData();
            }

            return carousel;
        },

        updateIframe: function(iframeUrl) {
            var sku = $('input[name="pidid"]').val(),
                iframeContainer = $('.product-personalize-iframe'),
                iframe = iframeContainer.find('.iframe-container'),
                closeBtn = $('.product-personalize-iframe .close'),
                url = '/on/demandware.store/Sites-MGBW-Site/default/Draping-URL?sku=' + sku;

            if (iframeUrl) {
                iframe.html('<div style="-webkit-overflow-scrolling:touch"><iframe src=\'' + iframeUrl + '\'></iframe></div>');
            } else {
                $.getJSON(url, function (data) {
                    var html = '<div style="-webkit-overflow-scrolling:touch">';
                    html += '<script src="https://files.microdinc.com/css/1473/scripts/iframe-sizer.js"></script>';
                    html += '<iframe id="MDFrame" scrolling="no" style="width: 1px; min-width: 100%; *width: 100%; border:0;" src="';
                    html += data.url;
                    html += '"></iframe></div>';

                    iframe.html(html);
                    window.sessionKey = data.sessionKey;
                });
            }
        },

        createIframe: function() {
            var iframeContainer = $('.product-personalize-iframe'),
                button = $('.button-personalize'),
                editButton = $('.personalize-data'),
                closeBtn = $('.product-personalize-iframe .close'),
                sizeSwatches = $('.product-detail-content .product-variations .attribute.size ul'),
                sizeSelected = Boolean(sizeSwatches.find('li.selected').length);

            editButton.addClass('visually-hidden');
            closeBtn.addClass('visually-hidden');


            //Check to make sure a size is selected
            if (!sizeSwatches.length || sizeSelected) {
                button.removeAttr('disabled');
                // Create iframe

                //Tie in customize click button functionality
                button.on('click', function (e) {
                    e.preventDefault();
                    iframeContainer.removeClass('hidden');
                    closeBtn.removeClass('visually-hidden');
                    this.updateIframe();
                }.bind(this));

                closeBtn.on('click', function(){
                    iframeContainer.addClass('hidden');
                    closeBtn.addClass('visually-hidden');
                });

            } else {
                button.attr('disabled', 'disabled');
            }

            editButton.on('click', function(){
                iframeContainer.removeClass('hidden');

                $('html, body').animate({ scrollTop: 0 }, 'fast');
            });
        },

        /**
         * Create custom shiv + Update page based on returned json data
         * Note: Variables are worthless since json doesn't care about your scope
         */
        createCustomShiv: function() {
            var swatchAnchorCustom = $('.swatchanchor.CUSTOM'),
                iframeContainer = $('.product-personalize-iframe');
            //Hide Custom swatch throughout the process
            swatchAnchorCustom.parent().addClass('visually-hidden');

            //Grab all current selected options from select
            //Inject into their relevant container

            // Tie in close button functionality
            window.addEventListener('message', function (event) {
                if (event.data === 'done'){
                    this.updateProduct();
                    $('.product-personalize-iframe .close').click();
                }
            }.bind(this), false);
        },

        updateProduct: function(initWithLineItem) {
            var sku = $('input[name="pidid"]').val(),
                uuid = $('input[name="uuid"]').val(),
                url = '/on/demandware.store/Sites-MGBW-Site/default/Draping-GetConfig?sku=' + sku + (window.sessionKey ? '&sessionKey=' + window.sessionKey : '') + (initWithLineItem ? '&uuid=' + uuid : ''),
                customizedSession = $('[data-customized-session]');

            progress.show();

            $.getJSON(url, function (data) {
                $('#print-personalize-data').html('');
                $('#main').addClass('customized-product-loaded');

                var jsonObj = data.options,
                    price = data.price.standard,
                    ccPrice = data.price.comfortClub,
                    image = data.base64Image,
                    imageSrc = 'data:image/png;base64,' + image;

                if (initWithLineItem) {
                    this.updateIframe(data.iframeUrl);
                    window.sessionKey = data.sessionKey;
                }

                // Set sessionID on form for add to cart
                if (customizedSession.length > 0){
                    customizedSession.attr('value', window.sessionKey);
                }

                if (image) {
                    //Change the primary image
                    $('.primary-image').attr('src', imageSrc);
                    //Change the first zoom image
                    $('.zoomImages li').first().attr('data-img', imageSrc);
                }

                $('.personalize-data ul').remove();

                $('.estimated-shipping-original').addClass('visually-hidden');
                $('.stock-msg-label').addClass('visually-hidden');
                $('.estimated-shipping-draping').removeClass('visually-hidden');
                $('.stock-msg-label.instocklabel, .stock-msg-label.mtolabel').removeClass('visually-hidden');
                $('.pdp-main ul.swatches').css('padding-top', '10px');
                $('#add-to-cart').prop('disabled', false);
                $('#add-to-cart').removeClass('add-to-cart-disabled');
                $('#add-to-cart').addClass('add-to-cart');
                $('#pid').attr('value', sku);
                require('../pages/product/addToCart');
                $('ul.fabric .selected').removeClass('selected')
                var firstFabricName = $('.fabric-name')[0];
                $(firstFabricName).css('display', 'block');
                $(firstFabricName).find('.name').css('display', 'none');
                $('.choices.imageSwatchAttrsection').removeClass('show-swatch-detail');
                $('.show-swatch-detail').css('padding-top', '8px');
                $(firstFabricName).find('.mobile-fabric-detail').css('display', 'none');
                $('ul.fabric').siblings('.label').children('.value').empty();

                //Use Keys to find respective select, and force select the relevant option
                jsonObj.forEach(function (value) {
                    // Container Buildin
                    // images2.imgix.net/p4dbimg/1707/fabrics/coffee%20bean.jpg?fit=crop&w=120&h=90&fm=pjpg
                    if (value.visible == false) return;

                    var swatchDirectURL = 'http://images2.imgix.net/p4dbimg/1707/' + (value.type === 'Fabric' || value.type === 'Finish' ? 'fabrics' : 'images') + '/',
                        encodedVal = encodeURIComponent(value.value).toLocaleLowerCase(),
                        swatchTailURL = '.jpg?fit=crop&w=120&h=90&fm=pjpg',
                        swatchCompleteURL = swatchDirectURL + encodedVal + swatchTailURL;

                    if (!$('.personalize-data ul').length) {
                        $('.personalize-data').prepend('<ul/>');
                    }

                    //Expected Syntax <li><img src="#"/>Body Fabric: Keswick- Lake</li>
                    if (encodedVal.length) {
                        //Swatch Exist
                        $('.personalize-data ul').append('<li><img src = "' + swatchCompleteURL + '" alt="' + value.name + ': ' + value.value + '"/>' + value.name + ': ' + value.value + '</li>');
                    } else {
                        //Swatch DOES NOT Exist
                        $('.personalize-data ul').append('<li>' + value.name + ': ' + value.value + '</li>');
                    }
                    $('#print-personalize-data').append('<div><span class="label">' + value.name + ':</span><span class="value">' + value.value + '</span></div>');

                });

                //Show correct elements / hide correct elements

                // If there are product thumbs - hide em
                $('.product-image-container #thumbnails').hide();

                //We need to modify the "Select Styles" label to show "Custom Order"
                // There will be only one of these - no need to check for additions
                if ($('.custom-order').length === 0) {
                    $('.Styles, .Fabric').parent().append('<span class="custom-order">Custom Order</span>');
                }
                $('.Styles, .Fabric').addClass('completed');

                //Add session key to dom for AE manipulation - only if it doesn't already exist
                if (!$('.custom-key').length) {
                    $('.pdpForm').append('<p class="visually-hidden custom-key">' + window.sessionKey + '</p>');
                }

                if ($('.equal-pay-price').length > 0) {
                    var equalPayPrice,
                        equalPayMonths;

                    if ($('.product-price').hasClass('vip-in-cart')) {
                        equalPayPrice = parseInt(ccPrice);
                        equalPayMonths = window.SitePreferences.Equal_Pay_Months;
                    } else {
                        equalPayPrice = parseInt(price);
                        equalPayMonths = window.SitePreferences.Equal_Pay_Months_Non_Members;
                    }
                    var places = !isNaN(places = Math.abs(places)) ? places : 2;
                    var equalPayPriceFormattted = parseInt(equalPayPrice = Math.abs(+equalPayPrice || 0).toFixed(places), 10) + '';
                    var months = equalPayMonths || 24;
                    var equalPayPricePerMonth = (equalPayPriceFormattted / months).toFixed(2);
                    $('.js-equal-pay-msg').text('As low as $' + equalPayPricePerMonth + '/Mo with');
                    $('.js-equal-pay-msg').attr('data-price-per-month', equalPayPricePerMonth);
                }

                //Add the data price + shown price

                $('.product-detail-content .add-to-cart-section .product-add-to-cart, .product-stickyheader .right , .product-detail-content   .product-price').find('span[class^="price-st"]').each(function () { // changed span[class^="price-s"] because customization callback was putting prices in both price-standard and price-sales on entire page
                    var updatedPrice;

                    if ($(this).closest('.product-club-pricing').length) {
                        updatedPrice = parseInt(ccPrice);
                    } else {
                        updatedPrice = parseInt(price);
                    }
                    //if the product had a sales price, remove it - no sales on customized products
                    //check for text in sale containers of PDP product only
                    var saleLength = 0;
                    $('.js-product-ajax-container .price-sales').each(function(){
                        saleLength += ($(this).html().length);
                    })
                    if (saleLength > 0) {
                        $('.price-sales').empty();
                        $('.price-standard.line-through').removeClass('line-through');
                    }

                    var places = !isNaN(places = Math.abs(places)) ? places : 2,
                        symbol = symbol !== undefined ? symbol : '$',
                        thousand = thousand || ',',
                        decimal = decimal || '.',
                        number = updatedPrice,
                        negative = number < 0 ? '-' : '',
                        i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + '',
                        j = (j = i.length) > 3 ? j % 3 : 0;

                    $(this).text(symbol + negative + (j ? i.substr(0, j) + thousand : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : ''));
                });
            }.bind(this)).always(function () {
                var $pdpMain = $('#pdpMain');
                var $selectedSwatch = $pdpMain.find('.swatches > li.selected');
                if ($selectedSwatch.length < 1) {
                    $pdpMain.find('.swatches > li').css('margin-bottom', 7);
                }
                progress.hide();
            });
        },

        addToRegistry: function (e) {
            var sessionID = $('input[name="customizedSessionID"]').val();
            if (sessionID) {
                e.preventDefault();
                var sku = $('input[name="pidid"]').val();
                window.location = e.target.getAttribute('href')+'&customizedSessionID='+sessionID+'&pidid='+sku;
            }
        },

        /**
         * Formats a integer into a formated currency based string
         *
         * @param price - int
         * @param places
         * @param symbol
         * @param thousand
         * @param decimal
         * @returns {string}
         */
        formatMoney: function (price, places, symbol, thousand, decimal) {
            places = !isNaN(places = Math.abs(places)) ? places : 2;
            symbol = symbol !== undefined ? symbol : '$';
            thousand = thousand || ',';
            decimal = decimal || '.';

            var number = price,
                negative = number < 0 ? '-' : '',
                i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + '',
                j = (j = i.length) > 3 ? j % 3 : 0;

            return symbol + negative + (j ? i.substr(0, j) + thousand : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : '');
        },

        addSelectedValue: function (element, selectedValue, withSeperator) {
            /*
             * TODO: Code Cleanup
             * this is identical to quickview.addSelectedValue in
             * quickview.js
             *
             */
            element.html((withSeperator ? '<span>|</span>' : '') + selectedValue);
        },

        getSwatchCarouselRespond: function (pdpMain) {
            /*
             * TODO: Code Cleanup
             * this is identical to quickview.getSwatchCarouselRespond in
             * quickview.js
             *
             */
            var itemLimitPS = this.settings.swatchCarouselLimitPS,
                respond = {};

            if (pdpMain.hasClass('bundle')) {
                respond = {
                    0: {
                        items: 3
                    },
                    400: {
                        items: itemLimitPS
                    }
                };
            } else {
                respond = {
                    0: {
                        items: 5
                    },
                    930: {
                        items: 6
                    },
                    1024: {
                        items: 7
                    },
                    1280: {
                        items: 7
                    }
                };
            }

            return respond;
        },

        qtyButtonObservers: function () {
            var self = this;

            self.settings.inventory.children('.js_custom-iterator').children('.minus, .plus').off().on("keydown click", self.changeQtyInput.bind(self));
        },

        /**
         * Add/Subtracts QTY from the qty input based on the plus and minus triggers
         *
         * @param event
         */
        changeQtyInput: function (event) {
            if (event.which === 13 || event.type === 'click') {
                var $target = $(event.target);

                if ($target.closest('.quantity').hasClass('disabled')) {
                    return;
                }

                var $qtyInput = $('.input-quantity, input#customQuantity'),
                    $qty = parseInt($qtyInput.val()),
                    step = 1,
                    minQty = 1,
                    newQty = minQty;


                // If on product set, we change the input to the current sub item
                if ($target.parent().children('.sub-product-quantity').length > 0) {
                    $qtyInput = $target.parent().children('input');
                    $qty = parseInt($qtyInput.val());
                }

                if (!isNaN($qty)) {
                    if ($target.hasClass('minus')) {
                        newQty = $qty - step;
                    }

                    if ($target.hasClass('plus')) {
                        newQty = $qty + step;
                    }
                }

                newQty = Math.floor(newQty);

                if (newQty < minQty) {
                    newQty = minQty;
                }

                $qtyInput.val(newQty).trigger('blur');
                $(document).trigger('update:qty');
            }
        },

        toggleReadMoreDesc: function (showHide) {
            var $content = $('.product-description .right');

            if ($content.length > 0 && $content.height() < 168) {
                $content.parent().parent().addClass('hide-show-more');
            }

            if (!showHide) {
                $content.removeAttr('style').removeClass('hide-ellipse');
                $('.product-description').removeClass('show');
                this.settings.readMoreText.updateText();
            }
        },

        /**
         * show/hide description feature
         *
         * @see $.fn.textChange
         * @param event - jQuery event
         */
        showHideProductDescription: function (event) {
            var $this = $(event.target),
                self = this,
                $parent = $this.closest('.product-description'),
                $right = $parent.find('.right'),
                rHeight = parseInt($right.get(0).scrollHeight);

            event.preventDefault();

            if (!$parent.hasClass('show') || (rHeight < 168)) {
                $parent.addClass('show');
                self.adjustProductDescriptionHeight($right, rHeight);
                $right.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
                    self.settings.readMoreText.updateText('Read Less');
                    $right.addClass('hide-ellipse');
                });
            } else {
                $parent.removeClass('show');
                self.adjustProductDescriptionHeight($right, 168).one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
                    self.settings.readMoreText.updateText();
                    $right.removeClass('hide-ellipse');
                });
            }
        },

        /**
         * resizes the maxHeight on smaller screens
         */
        resizeProductDescription: function () {
            var $element = $('.product-description .right');

            if ($(window).width() <= 600 && $element.hasClass('hide-ellipse')) {
                this.adjustProductDescriptionHeight($element, parseInt($element.get(0).scrollHeight))
            }
        },

        adjustProductDescriptionHeight: function (element, height) {
            return element.css('maxHeight', height);
        },

        skuTruncate: function (sku) {
            return sku.truncate(this.settings.collectionMobileValueLimit);
        },

        collectionCarousel: function (activate) {
            var self = this,
                $tiles = self.settings.$collectionTiles;

            if (self.settings.$collectionPieces.length > 0) {
                if ($tiles.length === 0) return;

                imagesloaded('#collection-pieces .tiles-container', function () {

                    $tiles.syncHeight().each(function (idx) {
                        var $this = $(this),
                            $sku = $(this).siblings('.collection-data').find('.sku .value'),
                            skuText = $sku.text();

                        $sku.text(self.skuTruncate(skuText));
                        $(this).data('idx', idx);
                    });

                    // Don't want to use a carousel if there are less than two products
                    if ($tiles.length === 1 || $tiles.length <= 2 && !activate) {
                        self.destroyCarousel(self.settings.$collectionPieces);
                        self.settings.$collectionPieces.parent().addClass('no-carousel');
                        return;
                    }

                    self.collectionCarouselContainer = self.settings.$collectionPieces
                        .on('initialized.owl.carousel changed.owl.carousel', function() {
                            $(document).trigger('custom:lazy:load:update');
                        })
                        .on('initialized.owl.carousel', function() {
                            $(document).trigger('custom:owl:carousel:initialized', {
                                carousel: $(this)
                            });
                        })
                        .owlCarousel({
                            loop: true,
                            nav: false,
                            items: 3,
                            dots: true,
                            responsive: {
                                0: {
                                    items: 1
                                },
                                707: {
                                    items: 2
                                },
                                1100: {
                                    items: 3
                                }
                            }
                        });

                    if (self.settings.$collectionPieces.data('owlCarousel')._items.length > self.settings.collectionsCarouselLimit || $('html').hasClass('resp-mobile')) {
                        self.settings.$collectionPieces.find('.owl-controls').show();
                    }
                });
            }
        }
    };

    $(document).on('baCoreReady', function () {

        /**
         * The parameter object is optional.
         * Must be an object.
         */

        if (typeof pageContext !== 'undefined' && (pageContext.ns === 'product' || pageContext.ns === 'productV2')) {
            product.init();
            productSticky.init();
        }
        $('body').on('click', '.read-more-prop65', function(e) {
            e.preventDefault();
            var $prop65wrapper = $(this).closest('.prop65-wrapper');
            if (!$prop65wrapper.length) {
                $prop65wrapper = $(this).parent().parent();
            }
            if ($prop65wrapper.find('.prop65-full-message').css('display') == 'none') {
                $prop65wrapper.find('.prop65-full-message').show();
                $prop65wrapper.find('.prop65-preview-message').hide();
            } else {
                $prop65wrapper.find('.prop65-full-message').hide();
                $prop65wrapper.find('.prop65-preview-message').show();
            }
        });

        $('body').on('click', '.prop65-read-less .read-less', function(e) {
            e.preventDefault();
            var $prop65wrapper = $(this).closest('.prop65-wrapper');
            if (!$prop65wrapper.length) {
                $prop65wrapper = $(this).parent().parent();
            }
            $prop65wrapper.find('.prop65-full-message').hide();
            $prop65wrapper.find('.prop65-read-less').hide();
            $prop65wrapper.find('.prop65-preview-message').show();
            $prop65wrapper.find('.read-more').show();
        });

        $('body').on('click', '.prop65-callout .read-more', function(e) {
            e.preventDefault();
            var $prop65wrapper = $(this).closest('.prop65-wrapper');
            if (!$prop65wrapper.length) {
                $prop65wrapper = $(this).parent().parent();
            }
            $prop65wrapper.find('.prop65-full-message').show();
            $prop65wrapper.find('.prop65-read-less').show();
            $prop65wrapper.find('.read-more').hide();
        });

        /*****
         * This event controls click on underline club price that shows the tooltip on non-logged on users on pdp
        */
        $('body').on('click', '.product-addtocart-wrapper .club-underline, .product-tile .club-underline, .add-sub-product .club-underline, .product-add-to-cart .club-underline', function(e) {
            //if(!$(e.target).is('.pdp-main #carousel-recommendations .product-price .product-club-pricing.club-underline span.label')) {
            if ($(e.target).closest('.pdp-main #carousel-recommendations .product-club-pricing.club-underline').length === 1) {
                // do nothing
            } else if ($(e.target).closest('.pdp-main .recently-viewed .product-club-pricing.club-underline').length === 1) {
                // do nothing
            } else {
                $('.club-pricing-tooltip').hide();
                $('.tooltip-content').hide();
                $('.tooltip-content .content-asset').hide();
                e.stopPropagation();
                $(this).find('.club-pricing-tooltip').show();
                $(this).find('.tooltip-content').show();
                $(this).find('.tooltip-content .content-asset').show();
                $('html').click(function(){
                    $(this).find('.club-pricing-tooltip').hide();
                });
            }
        });
        $('body')
            .on('click', '.product-addtocart-wrapper .club-underline, .product-price .club-underline, .product-tile .club-underline, .add-sub-product .club-underline, .product-add-to-cart .club-underline', function(e) {
                $('.club-pricing-tooltip').hide();
                e.stopPropagation();
                $(this).find('.club-pricing-tooltip').show();
                $('html').click(function(){
                    $(this).find('.club-pricing-tooltip').hide();
                });
            })
            .on('mouseenter', '.club-price-tooltip', function() {
                $('.club-pricing-tooltip').hide();
                $(this).closest('.product-club-pricing').find('.club-pricing-tooltip').show();
            })
            .on('mouseleave', '.club-price-tooltip', function() {
                $('.club-pricing-tooltip').hide();
            });
    });

    return product;
}));
