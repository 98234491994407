'use strict';

var ajax = require('../../ajax'),
    dialog = require('../../dialog'),
    minicart = require('../../minicart'),
    page = require('../../page'),
    util = require('../../util'),
    TPromise = require('promise'),
    lodashmap = require('lodash.map');

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @param {object} [gtmData] Gtm data object
 * @returns {Promise}
 */
var addItemToCart = function (form, gtmData) {
    var $form = $(form),
        $qty = $form.find('input[name="Quantity"]'),
        gtmObj;

    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) < 1) {
        $qty.val('1');
    }

    if (typeof gtmData !== 'undefined' && typeof gtmData === 'object') {
        gtmObj = {
            event: 'eecEvent',
            eventCategory: 'Ecommerce',
            eventAction: 'Add to Cart',
            eventLabel: gtmData.name ? gtmData.name.toUpperCase() : '',
            eventValue: 0,
            interactionEvent: true,
            ecommerce: {
                currencyCode: $form.find('input[name="currency"]').val(),
                add: {
                    products: []
                }
            }
        }
        gtmObj.ecommerce.add.products.push(gtmData);
        window.dataLayer.push(gtmObj);
    } else {
        var $mainContainer = $('#pdpMain');
        var variant = $form.find('input[name="pid"]').val() ? $form.find('input[name="pid"]').val() : $form.find('input[name="masterId"]').val();

        var $customizedProductValues = $('.product-start-customizing');
        var isCustomized = $customizedProductValues.length > 0;

        var isCustomizedProduct = false;
        if ($mainContainer.find('.product-customizations').length > 0 && $mainContainer.find('.js-customproduct-check').is(':visible')) {
            variant = $('body').find('.product-customizations').attr('data-custompid');
            isCustomizedProduct = true;
        }

        gtmObj = {
            event: 'eecEvent',
            eventCategory: 'Ecommerce',
            eventAction: 'Add to Cart',
            eventLabel: $form.find('input[name="name"]').val(),
            eventValue: 0,
            interactionEvent: true,
            ecommerce: {
                currencyCode: $form.find('input[name="currency"]').val(),
                add: {
                    products: [{
                        name: $form.find('input[name="name"]').val(),
                        id: $form.find('input[name="masterId"]').val(),
                        price: $form.find('input[name="price"]').val(),
                        brand: 'Mitchell Gold + Bob Williams',
                        category: $form.find('input[name="category"]').val() ? $form.find('input[name="category"]').val().replace(/ & | &amp; /g, " and ") : '',
                        quantity: Number.parseInt($qty.val()),
                        variant : variant,
                        dimension10: isCustomized,
                        dimension11: isCustomizedProduct ? 'customized' : (isCustomized ? 'customizable not customized' : 'not customizable')
                    }]
                }
            }
        };

        if ($mainContainer.hasClass('pdp-bundle') || $mainContainer.hasClass('pdp-set') || $mainContainer.hasClass('pdp-set-alt') || $mainContainer.hasClass('js_pdp-fake-set')) {
            gtmObj.ecommerce.add.actionField = {
                list: 'Product Set'
            };
        }

        window.dataLayer.push(gtmObj);
    }

    return TPromise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.addProduct),
        data: $form.serialize()
    }));
};

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function (e) {
    e.preventDefault();

    if (e.target.classList.contains('add-all-to-cart')) {
        return
    }

    var $form = $(this).closest('form');

    if ($form.find('input[name="Quantity"]').hasClass('error')) {
        return;
    }

    if (e.target.classList.contains('js-add-to-cart-title')) {
        addItemToCart($form, $(this).closest('.product-tile').data('gaitem')).then(function (response) {
            minicart.show(response);
        })
        return
    }
    var loyaltyProds = SitePreferences.LOYALTY_PRODUCT_IDS;
    var loyaltyProds_array = loyaltyProds ? loyaltyProds.split(',') : false;
    addItemToCart($form).then(function (response) {
        var $uuid = $form.find('input[name="uuid"]');
        if ($('input[name="source"]').val() && $('input[name="source"]').val() === 'cart') {
            page.redirect(Urls.cartShow);
        } else if (!$('input[name="draping"]').val() && $uuid.length > 0 && $uuid.val().length > 0) {
            page.refresh();
        } else {
            // do not close quickview if adding individual item that is part of product set
            // @TODO should notify the user some other way that the add action has completed successfully
            if (!$(this).hasClass('sub-product-item')) {
                dialog.close();
            }
            minicart.show(response);
            // if loyalty product has been added to the cart, disable the A2C button
            if (loyaltyProds_array && loyaltyProds_array.length) {
                for (var i=0; i < loyaltyProds_array.length; i++){
                    if (loyaltyProds_array[i].trim() == $form.find('input[name="pidid"]').val()) {
                        $('.add-to-cart.loyalty').attr('disabled', 'disabled');
                        $('.cc-added-message').removeClass('d-none');
                    }
                }
            }
        }
    }.bind(this));
};

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
    e.preventDefault();
    var $productForms = $('#product-set-list .js-add-sub-product form');

    if ($productForms.find('input[name="Quantity"]').hasClass('error')) {
        return;
    }

    TPromise.all(lodashmap($productForms, addItemToCart))
        .then(function (responses) {
            dialog.close();
            // show the final response only, which would include all the other items
            minicart.show(responses[responses.length - 1]);
        });
};

function scrollToPLI(e) {
    e.preventDefault();
    var $anchor = $('.js_product-set-list-title');
    if ($anchor.length) {
        util.scrollBrowser($anchor.eq(0).offset().top);
    }
}
$(document).on('custom:init:add:to:cart:event', function () {
    $(document)
        .off('click.addToCart')
        .on('click.addToCart', 'button.add-to-cart', addToCart);
});

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
module.exports = function () {
    $('.add-to-cart[disabled]').attr('title', $('.availability-msg').text());
    $(document)
        .off('click.addToCart')
        .on('click.addToCart', 'button.add-to-cart', addToCart);
    $('.js-add-all-to-cart').on('click', addAllToCart);
    $('.js_scroll-to-pli').on('click', scrollToPLI);
};
