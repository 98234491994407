'use strict';

var naPhone = /^\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/;
var regex = {
    ascii: /^[\n\r -~]*$/,
    phone: {
        us: naPhone,
        ca: naPhone,
        fr: /^0[1-6]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/,
        it: /^(([0-9]{2,4})([-\s\/]{0,1})([0-9]{4,8}))?$/,
        jp: /^(0\d{1,4}- ?)?\d{1,4}-\d{4}$/,
        cn: /.*/,
        gb: /^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
    },
    postal: {
        us: /^\d{5}(-?\d{4})?$/,
        ca: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
        fr: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
        it: /^([0-9]){5}$/,
        jp: /^([0-9]){3}[-]([0-9]){4}$/,
        cn: /^([0-9]){6}$/,
        gb: /^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$/
    },
    notCC: /^(?!(([0-9 -]){13,19})).*$/,
    name: /^([ \u00c0-\u01ffa-zA-Z'\-\.])+$/,
    captcha: /^([a-zA-Z0-9_-]+)$/,
    email: /\S+@\S+\.\S/,
    address: /\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/mi
};

// global form validator settings
var settings = {
    errorClass: 'error',
    errorElement: 'span',
    onkeyup: false,
    onfocusout: function (element) {
        if (!this.checkable(element)) {
            $(document).trigger('form:message:clear form:alert:clear');
            this.element(element);
        }
    },
    showErrors: function() {
        var i, elements, error;
        for (i = 0; this.errorList[i]; i++) {
            error = this.errorList[i];
            if (this.settings.highlight) {
                this.settings.highlight.call(this, error.element, this.settings.errorClass, this.settings.validClass);
            }
            this.showLabel(error.element, error.message);
        }
        if (this.errorList.length) {
            this.toShow = this.toShow.add(this.containers);
        }
        if (this.settings.success) {
            for (i = 0; this.successList[i]; i++) {
                this.showLabel(this.successList[i]);
            }
        }
        if (this.settings.unhighlight) {
            for (i = 0, elements = this.validElements(); elements[i]; i++) {
                this.settings.unhighlight.call(this, elements[i], this.settings.errorClass, this.settings.validClass);
            }
        }
        this.toHide = this.toHide.not(this.toShow);
        this.hideErrors();
        this.addWrapper(this.toShow).show();

        $(document).trigger('validation:errors:shown');
    }
};

/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhone = function (value, el) {
    var isOptional, countryCode;

    if ($(el).closest('#email-alert-signup-keepintouchpage').length) {
        // Keep in touch form (newslettersignup.isml)
        isOptional = !$(el).val().length;
        countryCode = 'us';
    } else {
        var country = $(el).closest('form').find('select.country');
        if (country.length === 0 || country.val().length === 0 || !regex.phone[country.val().toLowerCase()]) {
            return true;
        }

        countryCode = country.val().toLowerCase();
        isOptional = this.optional(el);
    }

    var rgx = regex.phone[countryCode];
    var isValid = rgx.test($.trim(value));

    return isOptional || isValid;
};
/*
* @function
* @description Validates a given postal code against the countries postal regex
* @param {String} value The postal code which will be validated
* @param {String} el The input field
*/
var validatePostal = function (value, el) {
    var country = $(el).closest('form').find('input.country');
    if (country.length === 0) {
        country = $(el).closest('form').find('select.country');
    }
    if (country.length === 0 || country.val().length === 0 || !regex.postal[country.val().toLowerCase()]) {
        return true;
    }

    var rgx = regex.postal[country.val().toLowerCase()];
    var isOptional = this.optional(el);
    var isValid = rgx.test($.trim(value));

    return isOptional || isValid;
};

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
    var isValid = regex.notCC.test($.trim(value));
    return isValid;
};

var validateExpDateYear = function (value, el) {
    var month = $(el).closest('form').find('select.month');
    var year = $(el).closest('form').find('select.year');
    var curDate = new Date();
    var isValid = false;
    var enteredDate = new Date();
    enteredDate.setMonth((month.val() - 1));
    enteredDate.setFullYear(year.val());
    if (curDate <= enteredDate) {
        isValid = true;
    }
    return isValid;
}

var validateExpirationDate = function(value) {
    var isValid = false;

    if (!(value && value.length)) {
        return isValid;
    }

    var monthYear = value.split('/');

    if (monthYear.length < 2) {
        return isValid;
    }

    var month, year;

    try {
        month = Number(monthYear[0]);
        year = Number(monthYear[1]);
    } catch (e) {
        return isValid;
    }

    if (month > 12 || month === 0) {
        return isValid;
    }

    var curDate = new Date();
    var enteredDate = new Date();
    enteredDate.setMonth((month - 1));
    enteredDate.setFullYear(year);
    isValid = curDate <= enteredDate;

    return isValid;
}

/**
 * @function
 * @description Validates that a credit card number is a number using a mod10 alogithm is not a Credit card number
 * @param {String} value The cc number which will be validated
 */
var validateCCNumber = function (value) {
    var isValid = true
    // takes the form field value and returns true on valid number
    // accept only digits, dashes or spaces
    if ((/[^0-9-\s\*]+/).test(value)) {
        isValid =  false;
        return isValid;
    }

    if (!((/[*]/).test(value))) {
        // The Luhn Algorithm. It's so pretty.
        var nCheck = 0,
            nDigit = 0,
            bEven = false;
        value = value.replace(/\D/g, '');

        for (var n = value.length - 1; n >= 0; n--) {
            var cDigit = value.charAt(n),
                nDigit = parseInt(cDigit, 10);

            if (bEven) {
                if ((nDigit *= 2) > 9) nDigit -= 9;
            }
            nCheck += nDigit;
            bEven = !bEven;
        }
        if ((nCheck % 10) === 0) {
            isValid = true;
        } else {
            isValid = false;
        }
    }
    return isValid;
};

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateName = function (value) {
    var isValid = regex.name.test($.trim(value));
    return isValid;
};

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateCaptcha = function (value) {
    return regex.name.test($.trim(value));
};

/**
 * function
 * @param {String} value The install date which will be validated
 */
var validateDate = function (value) {
    var installDate = new Date(value);
    var currDate = new Date();
    if (value && installDate && currDate) {
        return installDate < currDate;
    } else {
        return false;
    }
};

/**
 * function
 * @param {String} value The reason for joining option which will be validated
 */
var validateReasonForJoining = function (value) {
    var isValid = false;
    if (value != "none") {
        isValid = true;
    }
    return isValid;
};

/**
 * function validateMatchingEmails checks email fields to match
 * @param {String} value email confirm field
 */
var validateMatchingEmails = function (value) {
    var isValid = false;
    if (value == $('input[id$="customer_email"]').val()) {
        isValid = true;
    }
    return isValid;
};

/**
 * function validateMatchingPasswords checks email fields to match
 * @param {String} value email confirm field
 */
var validateMatchingPasswords = function (value) {
    var isValid = false;
    if (value == $('input.passwordconfirm').parent().parent().siblings().find('input.password').val()) {
        isValid = true;
    }
    return isValid;
};

/**
 * function validateCheckbox determine if check box is checked
 * @param {String} value
 */
var validateCheckbox = function(value, el) {
    var $checkbox = $(el);
    return $checkbox.attr('checked') == 'checked';
}

function validateEmail(value) {
    return regex.email.test(value);
}

function validateAddToAdress(value, el) {
    var $checkbox = $(el);

    if (!$checkbox.prop('checked')) {
        return true;
    }

    var $form = $checkbox.closest('form');
    var $address1 = $form.find('[name$="_address1"]');
    var $city = $form.find('[name$="_city"]');
    var $postal = $form.find('[name$="_postal"]');
    var $registerstate = $form.find('[name$="_registerstate"]');
    var $modalregisterstate = $form.find('[name$="_modalregisterstate"]');
    var $storisphone = $form.find('[name$="_storisphone"]');

    return $address1.val()
        && $city.val()
        && $postal.val()
        && ($registerstate.val() || $modalregisterstate.val())
        && $storisphone.val();
}

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod('nameValidate', validateName, Resources.INVALID_NAME);

/**
 * Add reason for joining validation method to jQuery validation plugin.
 * Text fields must have 'reasonForCreatingAccount' css class to be validated as reasonForCreatingAccount
 */
$.validator.addMethod('reasonForCreatingAccount', validateReasonForJoining, Resources.INVALID_REASON);

/**
 * Add reason for joining validation method to jQuery validation plugin.
 * Text fields must have 'modalregisterstate' css class to be validated as reasonForCreatingAccount
 */
$.validator.addMethod('modalregisterstate', validateReasonForJoining, Resources.INVALID_REASON);

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod('phone', validatePhone, Resources.INVALID_PHONE);

/**
* Add postal validation method to jQuery validation plugin.
* Text fields must have 'postal' css class to be validated as postal
*/
$.validator.addMethod('postal', validatePostal, Resources.INVALID_ZIP);

/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('owner', validateOwner, Resources.INVALID_OWNER);

/**
 * Add CCNumber validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('cc-number', validateCCNumber, Resources.INVALID_CCNUMBER);

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'year' css class to be validated as expDate
 */
$.validator.addMethod('year', validateExpDateYear, Resources.INVALID_EXP_DATE);


/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('captcha', validateCaptcha, Resources.INVALID_CAPTCHA);

$.validator.addMethod('email', validateEmail, Resources.INVALID_EMAIL);

$.validator.addMethod('add-to-address', validateAddToAdress, Resources.INVALID_USE_ADDRESS_FOR_SHIPPING);

$.validator.methods.email = function(value, element) {
    return this.optional(element) || (/\S+@\S+\.\S/ig).test(value);
}


/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod('gift-cert-amount', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 5000);
    return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod('positivenumber', function (value) {
    if ($.trim(value).length === 0) { return true; }
    return (!isNaN(value) && Number(value) >= 0);
}, Resources.VALIDATE_POSITIVE_NUMBER);

/**
 * Add positive integer (more then 0) validation method to jQuery validation plugin.
 * Text fields must have 'positivinteger' css class to be validated as positivinteger
 */
$.validator.addMethod('positivinteger', function (value) {
    if ($.trim(value).length === 0) {
        return true;
    }

    var val = Number(value);
    return (!isNaN(val) && Number.isInteger(val) && val > 0);
}, Resources.VALIDATE_POSITIVE_INTEGER);

$.validator.addMethod('ascii', function (value) {
    return regex.ascii.test($.trim(value));
}, Resources.VALIDATE_ASCII);

$.validator.addMethod('installdate', validateDate, Resources.PAST_DATE_INVALID);
$.validator.addMethod('emailconfirm', validateMatchingEmails, Resources.EMAIL_MUST_MATCH);
$.validator.addMethod('passwordconfirm', validateMatchingPasswords, Resources.PASSWORDS_MUST_MATCH);
$.validator.addMethod('required-checkbox', validateCheckbox, Resources.VALIDATE_REQUIRED);
$.validator.addMethod('expirationdate', validateExpirationDate, Resources.INVALID_EXPIRATIONDATE);
$.extend($.validator.messages, {
    required: Resources.VALIDATE_REQUIRED,
    remote: Resources.VALIDATE_REMOTE,
    email: Resources.INVALID_EMAIL,
    url: Resources.VALIDATE_URL,
    date: Resources.VALIDATE_DATE,
    dateISO: Resources.VALIDATE_DATEISO,
    number: Resources.VALIDATE_NUMBER,
    digits: Resources.VALIDATE_DIGITS,
    creditcard: Resources.VALIDATE_CREDITCARD,
    expirationdate: Resources.VALIDATE_DATE,
    equalTo: Resources.VALIDATE_EQUALTO,
    maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
    minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
    rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
    range: $.validator.format(Resources.VALIDATE_RANGE),
    max: $.validator.format(Resources.VALIDATE_MAX),
    min: $.validator.format(Resources.VALIDATE_MIN)
});

var validator = {
    regex: regex,
    settings: settings,
    init: function () {
        var self = this;
        $('form:not(.suppress)').each(function () {
            $(this).validate(self.settings);
        });
    },
    initForm: function (f) {
        return $(f).validate(this.settings);
    }
};

module.exports = validator;
