'use strict';

var compareWidget = require('../compare-widget'),
    productTile = require('../product-tile'),
    progress = require('../progress'),
    productCarousels = require('./product/productCarousels'),
    util = require('../util'),
    globalVars = require('../global-vars');

function setRefinementWidth() {
    var $refinements = $('.js_refinements');
    var $openedRefinements = $refinements.find('.js-refinement-collapse-item.opened');
    var $refinementList = $openedRefinements.find('.js-collapse-element.higher');

    if (!$refinementList.length) {
        return;
    }

    $refinementList.css({ 'max-width': 'initial' });

    var offsetLeft = $refinementList.offset().left;
    var refinementListWidth = $refinementList.outerWidth();
    var windowWidth = window.innerWidth;
    var scrollWidth = windowWidth >= globalVars.breakpoints.large ? 17 : 0;
    var rightPosition = windowWidth - scrollWidth

    if (rightPosition < (offsetLeft + refinementListWidth)) {
        $refinementList.css({ 'max-width': rightPosition - offsetLeft });
    }
}

/**
 * @private
 * @function
 * @description replaces refinements after each selection
 */
function updateRefinements(url, showRefinements, openedRefinements) {
    if (!url) {
        return;
    }
    progress.show($('#search_results_top .abslt-dropdown'));
    $.ajax({
        url: util.appendParamToURL(url, 'format', 'ajax')
    }).done(function (data) {
        var refinements = $(data).find('#search_results_top .abslt-dropdown').html();
        $('#main #search_results_top .abslt-dropdown').html(refinements);
        if (showRefinements) {
            $('#search_results_top .abslt-dropdown').show();
        }
        compareWidget.init();
        productTile.init();

        $(document).trigger('update:selects');
        $(document).trigger('update:refinements');
        $(document).trigger('update:tooltipster');
        $(document).trigger('gaTagging:search:reint');

        if (showRefinements) {
            $('.apply-abslt-dropdown').show();
        }

        if (openedRefinements && openedRefinements.length > 0) {
            openedRefinements.forEach(function (element) {
                $('#' + element).find('.js-refinements-toggle').trigger('click');
            });
        }

        $('#search_results_top').data('url', url);
    });
}

function createEventObject(gtmObj, customDimensions) {
    var gtmObj = {
        event : typeof gtmObj.event !== "undefined" ? gtmObj.event : 'eecEvent',
        eventCategory : typeof gtmObj.eventCategory !== "undefined" ? gtmObj.eventCategory : 'Ecommerce',
        eventAction : typeof gtmObj.eventAction !== "undefined" ? gtmObj.eventAction : 'Product Impressions',
        eventLabel : typeof gtmObj.eventLabel !== "undefined" ? gtmObj.eventLabel : 'None',
        eventValue : 0,
        interactionEvent : false,
        ecommerce : {
            currencyCode : typeof gtmObj.currency !== "undefined" ? gtmObj.currency : 'USD',
            impressions : []
        }
    }
    if (typeof customDimensions !== "undefined") {
        $.extend(gtmObj, customDimensions);
    }
    return gtmObj;
}

/**
 * Send products impressions for GTM
 */
function productImpressions(items) {
    var data = $('#search-result-items').attr('data-gtm-custom-dimensions');

    if (!(data && data.length)) {
        return;
    }

    var $gtmList = $('.js-last-crumb').attr('data-gtm-list');
    var listName = typeof($gtmList) !== 'undefined' ? $gtmList : 'Search Result';
    var customDimensions = JSON.parse(data);
    var obj = createEventObject({}, customDimensions);
    const $productTiles = typeof items !== 'undefined' ? items : $('.product-tile');
    const tilesAmount = $productTiles.length;
    $productTiles.each(function (index) {
        var $impressionData = $(this).data('gaitem');
        if (typeof $impressionData === 'undefined' || $impressionData === null) {
            return true;
        }
        obj.ecommerce.impressions.push({
            'name': $impressionData.name,                      // Name or ID is required.
            'id': $impressionData.id,
            'price': $impressionData.price,
            'category': $impressionData.category,
            'variant': $impressionData.variant,
            'dimension10': $impressionData.customizable,
            'dimension11': $impressionData.dimension11,
            'position': $impressionData.position,
            'brand': $impressionData.brand,
            'list': listName
        });
        if (tilesAmount > 20 && (index + 1) % 20 === 0) {
            if (obj.ecommerce.impressions.length > 0) {
                window.dataLayer.push(obj);
                obj = createEventObject({}, customDimensions);
            }
        }
    });
    window.dataLayer.push(obj);
}

function setGridSlotSize() {
    let $gridSlotTiles = $('.grid-slot-tile');
    let $gridTiles = $('.grid-tile:not(.grid-slot-tile)');

    if (!($gridSlotTiles.length && $gridTiles.length)) {
        return;
    }

    let productImageHeight = $gridTiles.find('.product-image').height();
    $gridSlotTiles.css({ 'height': productImageHeight });
}

function copyState(newDOM) {
    let $main = $('#main');
    let $openedRefinement = $main.find('.js_refinements .js-refinement-collapse-item.opened');
    let $refinementFlyoutMainContent = $main.find('.js_refinement-flyout__main-content');
    let $refinementFlyoutMainContentScrollTop = $refinementFlyoutMainContent.scrollTop();

    $main.html(newDOM);

    $main.find('.js_refinement-flyout__main-content').scrollTop($refinementFlyoutMainContentScrollTop);

    if (!$openedRefinement.length) {
        return;
    }

    $main.find('.js_refinements [data-refinement-id="' + $openedRefinement.data('refinement-id') + '"]').addClass('opened');
    $(document).trigger('custom:refinement:state:was:copied');
}

/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing(url) {
    if (!url || url === window.location.href) {
        return;
    }

    url = util.propagateParam(url, 'roomscene');
    let _url = util.appendParamToURL(url, 'format', 'ajax');
    let openRefinements = [];
    let showMoreRefinements = [];
    $('.js_refinement-flyout .js-refinement-collapse-item').each(function (index, current) {
        let $current = $(current);
        let $currentID = $current.data('refinement-id');

        if ($current.hasClass('opened')) {
            openRefinements.push($currentID);
        }

        if ($current.find('.ref-view-opened').length) {
            showMoreRefinements.push($currentID);
        }
    });
    _url = util.appendParamToURL(_url, 'open_refinements', openRefinements.join(','));
    _url = util.appendParamToURL(_url, 'show_more_refinements', showMoreRefinements.join(','));

    progress.show($('#main'));

    $.ajax({
        type: 'GET',
        url: _url,
        success: function (response) {
            copyState(response);
            setRefinementWidth();
            compareWidget.init();
            productTile.init();
            progress.hide();

            $(document).trigger('update:selects');
            $(document).trigger('update:refinements');
            $(document).trigger('update:tooltipster');
            $(document).trigger('gaTagging:search:reint');
            $(document).trigger('custom:lazy:load:update');

            history.pushState(undefined, '', url);

            // history.pushState() removes scroll events
            $(window)
                .off('scroll.infinitePLPScroll', infinitePLPScroll)
                .on('scroll.infinitePLPScroll', infinitePLPScroll);

            if ((typeof affirm !== 'undefined') && affirm.ui && (typeof affirm.ui.refresh === 'function')) {
                affirm.ui.refresh();
            }

            const $dataWrapper = $('.js-data-container');
            const dataTitle = $dataWrapper.data('meta-title');
            const dataDescription = $dataWrapper.data('meta-description');
            if (dataTitle && dataTitle.length) {
                document.title = dataTitle;
            }

            if (dataDescription && dataDescription.length) {
                $('meta[name=description]').attr('content', dataDescription);
            }

            if ($('#wrapper').data('canonical-update')) {
                var link = $('head').find("link[rel='canonical']");
                link.attr("href", url.split('/?')[0]);
            }

            removeEmptyGridSlotTiles();
            productImpressions();
            setGridSlotSize();
        }
    });
}

function infinitePLPScroll() {
    var $viewMoreBtn = $('.view-more-button');

    if (!$viewMoreBtn.length || !$viewMoreBtn.hasClass('with-infinite-scroll') || $viewMoreBtn.hasClass('sent') || $viewMoreBtn.is(':disabled')) {
        return;
    }

    var $lastTile = $('.grid-tile').last();

    if (($lastTile.offset().top - $(window).scrollTop()) < $(window).height()) {
        $viewMoreBtn
            .addClass('sent')
            .trigger('click');
    }
}

// Setup the sticky header functionality
function toggleStickyHeader() {
    var $wrapper = $('#wrapper');
    var $stickyAnchor = $('.js_sticky-refinements-anchor');
    var $refinements = $('.js_refinements');

    if (!$stickyAnchor.length) {
        return;
    }

    var scrollTop = $(window).scrollTop(),
        scrollTrigger = $stickyAnchor.offset().top,
        newStickyAnchorHeight = scrollTop > scrollTrigger ? $refinements.outerHeight() : 0;

    $wrapper.toggleClass('sticky-refinements', scrollTop > scrollTrigger);
    $stickyAnchor.css({ 'height': newStickyAnchorHeight });
}

function initializeLifestyleCarousel() {
    $('.js_lifestyle-slot-carousel').each(function () {
        var $carousel = $(this).find('.js_lifestyle-slot-carousel__images');
        var $controls = $(this).find('.js_lifestyle-slot-carousel__controls');
        var $prev = $controls.find('.js_lifestyle-slot-carousel__control-prev');
        var $next = $controls.find('.js_lifestyle-slot-carousel__control-next');
        var $counter = $controls.find('.js_lifestyle-slot-carousel__counter');

        $carousel
            .on('initialized.owl.carousel changed.owl.carousel', function(e) {
                $(document).trigger('custom:lazy:load:update');

                if (!e.namespace) {
                    return;
                }

                $counter.html(
                    e.relatedTarget.relative(e.item.index) + 1 + ' of ' + e.item.count
                );
            })
            .on('initialized.owl.carousel', function(e) {
                $(document).trigger('custom:owl:carousel:initialized', {
                    carousel: $(this)
                });
            })
            .owlCarousel({
                loop: true,
                mouseDrag: true,
                nav: false,
                items: 1,
                dots: true,
                responsive: {
                    1024: {
                        dots: false
                    }
                }
            });

        $prev.on('click', function () {
            $carousel.trigger('prev.owl.carousel');
        });

        $next.on('click', function () {
            $carousel.trigger('next.owl.carousel');
        });
    });
}

function initializeLifestyleEvents() {
    if (!$('.pt_categorylanding_lifestyle').length) {
        return;
    }

    $(document)
        .on('click', '.js_lifestyle-slot__about', function () {
            $(this).closest('.lifestyle-slot__accordion').toggleClass('collapsed');
        });

    initializeLifestyleCarousel();
}

function initL2CategoryCarousel() {
    $('.js_l2-sub-category-carousel').each(function () {
        let $carousel = $(this);

        $carousel
            .trigger('destroy.owl.carousel')
            .removeClass('owl-carousel owl-loaded')
            .find('.owl-stage-outer')
            .children()
            .unwrap();

        var $controls = $carousel.closest('.js_l2-sub-category-section__body').find('.l2-sub-category-carousel__controls');

        if ($carousel.find('.l2-sub-category-carousel-item').length > 4) {
            var $prev = $controls.find('.custom-owl-prev');
            var $next = $controls.find('.custom-owl-next');

            $prev
                .off('click')
                .on('click', function () {
                    $carousel.trigger('prev.owl.carousel');
                });

            $next
                .off('click')
                .on('click', function () {
                    $carousel.trigger('next.owl.carousel');
                });
        } else {
            $controls.remove();
        }

        $carousel
            .on('initialized.owl.carousel changed.owl.carousel', function () {
                $('body').trigger('resetlazy', $(this)[0]);
            })
            .on('initialized.owl.carousel', function () {
                $(document).trigger('custom:owl:carousel:initialized', {
                    carousel: $(this)
                });
            })
            .owlCarousel({
                items: 2,
                dots: false,
                nav: true,
                margin: 10,
                stagePadding: 30,
                loop: true,
                lazyLoad: true,
                responsive: {
                    768: {
                        items: 4,
                        stagePadding: 0,
                        margin: 24
                    },
                    1024: {
                        items: 4,
                        margin: 50
                    }
                }
            });
    });
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
    var $main = $('#main');

    // Overriding css max-width attribute on the L2 Category LP
    if ($('.js_l2-category-primary-content').length) {
        $main.addClass('default-max-width');
    }

    // compare checked
    $main.on('click', 'input[type="checkbox"].compare-check', function () {
        var cb = $(this);
        var tile = cb.closest('.product-tile');

        var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
        var itemImg = tile.find('.product-image a img').first();
        func({
            itemid: tile.data('itemid'),
            uuid: tile[0].id,
            img: itemImg,
            cb: cb
        });

    });

    $main.on('click', '.refinements a, .pagination a, .breadcrumb-refinement-value a, .js_refinement-flyout__clear-all, .js_refinement-flyout .js-refinement-collapse-item a', function (e) {
        // don't intercept for category and folder refinements, as well as unselectable
        if ($(this).parents('.category-refinement').length > 0 || $(this).parents('.folder-refinement').length > 0 || $(this).parent().hasClass('unselectable')) {
            return;
        }

        e.preventDefault();
        updateProductListing(this.href);
    });

    // handle events item click. append params.
    $main.on('click', '.product-tile a:not(".js_btn-quickview"):not(".js_wishlist-link")', function () {
        var a = $(this);
        // get current page refinement values
        var wl = window.location;

        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

        // merge hash params with querystring params
        var params = $.extend(hashParams, qsParams);
        if (!params.start) {
            params.start = 0;
        }
        // get the index of the selected item and save as start parameter
        var tile = a.closest('.product-tile');
        var idx = tile.data('idx') ? +tile.data('idx') : 0;

        // convert params.start to integer and add index
        params.start = (+params.start) + (idx + 1);
        // set the hash and allow normal action to continue
        a[0].hash = $.param(params);
    });

    // handle sorting change
    $main.on('change', '.sort-by select', function (e) {
        e.preventDefault();
        updateProductListing($(this).find('option:selected').val());
    })
        .on('change', '.items-per-page select', function () {
            var refineUrl = $(this).find('option:selected').val();
            if (refineUrl === 'INFINITE_SCROLL') {
                $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
            } else {
                $('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
                updateProductListing(refineUrl);
            }
        });

    $main.on('click', '.js-refinements-toggle', function (e) {
        var $trigger = $(this),
            $parent = $trigger.parents('.js-refinements-list'),
            $toggledRefinements = $parent.find('.js-show-more-less');

        if ($trigger.data('state') === 'more') {
            $toggledRefinements.removeClass('show-less');
            $trigger.data('state', 'less').text($trigger.data('less'));
            $trigger.addClass('ref-view-opened')
        } else {
            $toggledRefinements.addClass('show-less');
            $trigger.data('state', 'more').text($trigger.data('more'));
            $trigger.removeClass('ref-view-opened')
        }
    });

    $(document).on('click', '.view-more-button', function () {
        if ($(this).is(':disabled')) {
            return;
        }

        var url = $(this).data('btnurl');
        url = util.propagateParam(url, 'roomscene');
        $(this)
            .hide()
            .closest('.view-more-container')
            .find('.view-more-tiles-loader')
            .addClass('show');

        $.ajax({
            method: 'GET',
            url: url
        }).done(function (response) {
            var scrollPosY = window.scrollY;
            var listToAppend = $('.search-result-items');
            var viewMoreContainerToReplace = $('.view-more-container');
            var listItemsToAppend = $(response).find('.search-result-items > li');
            var viewMoreContainerReplacement = $(response).find('.view-more-container');

            $(listToAppend).append(listItemsToAppend);
            $(viewMoreContainerToReplace).replaceWith(viewMoreContainerReplacement);
            removeEmptyGridSlotTiles();
            productImpressions(listItemsToAppend.find('.product-tile'));
            productTile.mobileAltCarousel();
            $(document).trigger('update:tooltipster');
            $(document).trigger('custom:lazy:load:update');
            window.scrollTo(0, scrollPosY);
        });
    });

    $(window)
        .off('scroll.infinitePLPScroll', infinitePLPScroll)
        .on('scroll.infinitePLPScroll', infinitePLPScroll);

    $(document).on('click', '.close-abslt-dropdown', function () {
        var resetURL = $('#search-result-items').data('search-url');
        updateRefinements(resetURL, false);
        $('#refinements-collapse').trigger('click');
    });

    $(document).on('click', '.apply-abslt-dropdown', function () {
        if ($('#search_results_top').data('url')) {
            updateProductListing($('#search_results_top').data('url'));
        }
        $('#refinements-collapse').trigger('click');
    });

    if ($('body').find('.product-tile').length > 0) {
        $(window).on('load', function () {
            productImpressions();
        });
    }
    removeEmptyGridSlotTiles();
    toggleStickyHeader();
    setGridSlotSize();
    setRefinementWidth();
    initL2CategoryCarousel();

    /**
     * @listener
     * @description Listens for the scroll event on the window to toggle the sticky header when appropriate
     */
    $(window).on('scroll', toggleStickyHeader);

    util.smartResize(function () {
        toggleStickyHeader();
        setGridSlotSize();
        setRefinementWidth();
    });

    $(document)
        .off('click.js_tct')
        .on('click.js_tct', '.js_tct', function () {
            let tctClass = $(this).data('tct-class');
            let $container = $(this).closest('.pagewrap');
            let $lifestyleContainer = $(this).closest('.js_lifestyle-product-list');

            if ($lifestyleContainer.length) {
                $container = $lifestyleContainer;
            }

            if ($container.hasClass(tctClass)) {
                return;
            }

            $container.removeClass('grid-one-column grid-two-columns');
            $container.addClass(tctClass);

            productTile.mobileAltCarousel();
            productTile.setProductTileImageHeight(true);
            setGridSlotSize();

            // Update owl carousels
            window.dispatchEvent(new Event('resize'));
        })
        .off('custom:set:grid:slot')
        .on('custom:set:grid:slot', setGridSlotSize)
        .off('custom:set:refinement:width')
        .on('custom:set:refinement:width', setRefinementWidth);
}

function removeEmptyGridSlotTiles() {
    // remove empty slot tiles
    $('.grid-slot-tile').each(function () {
        if (!($(this).children().length || $(this).text().trim().length)) {
            $(this).remove();
        }
    });
}

exports.init = function () {
    compareWidget.init();
    productTile.init();
    initializeEvents();
    productCarousels.initCompleteLookCarousel();
    productCarousels.waitForRecommendations();
    initializeLifestyleEvents();
};
