'use strict';

var dialog = require('../../dialog');

module.exports = {
    init: function () {
        if (!$('.company-callouts').length) {
            return;
        }

        $(document)
            .on('click', '.js_company-callout-show-modal', function () {
                const html = $(this).closest('.company-callout').find('.js_company-callout-modal').html();
                dialog.open({
                    html: html,
                    options: {
                        open: function () {
                            $(this).closest('.js_company-callouts-dialog').focus();
                        },
                        dialogClass: 'company-callouts-dialog js_company-callouts-dialog'
                    }
                });
            })
            .on('click', '.js_company-callouts-dialog .ui-dialog-titlebar-close', function () {
                dialog.close();
            })
            .on('click', function (e) {
                if (!$('.js_company-callouts-dialog').is(':visible')) {
                    return;
                }

                var $target = $(e.target);
                if (!($target.closest('.company-callouts').length || $target.closest('.js_company-callouts-dialog').length)) {
                    dialog.close();
                }
            });
    }
}
