'use strict';

var globalVars = require('../global-vars');

/**
 * @package     Blueacorn/header
 * @version     2.0
 * @author      Blue Acorn <code@blueacorn.com>
 * @copyright   Copyright © 2016 Blue Acorn.
 */

(function (root, factory) {
    if (typeof exports === 'object') {
        // CommonJS
        module.exports = factory(window.jQuery, require('./blueacorn-core'), require('../vendor/enquire/enquire.min'));
    } else if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery', 'baCore', 'enquireJs'], function (jquery, ba, enquire) {
            return (factory(jquery, ba, enquire));
        });
    } else {
        // Global Variables
        root.ba.header = factory(root.jQuery, root.ba, root.enquire);
    }

}(this, function ($, ba, enquire) {
    'use strict';

    var header = {

        settings: {
            headerBanner: $('.header-banner'),
            navigation: $('#navigation'),
            b2bNavigation: $('#b2c-navigation'),
            subMenuActiveClass: 'open',
            navHasSubNavClass: 'has-sub-menu',
            navHasSubCategoriesNavClass: 'has-sub-categories',
            navIsAnimating: false,
            navCurrentOpenClass: 'currentOpen'
        },

        init: function (options) {

            // Overrides the default settings
            ba.overrideSettings(this.settings, options);

            // Start the debugger
            ba.setupDebugging(this.settings);

            this.setObservers();
            this.setAccountLinksObservers();
            this.toggleNavigationDisplay();
        },
        b2bMenuPrepare: function(){
            $('.level-2 ul.menu-vertical').each(function(){
                var ul = this;
                $(ul).find('li[item-number]').each(function(){
                    var itemNumber = this.getAttribute('item-number');
                    if (itemNumber > 3){
                        var upperElement = $(ul).find('li[item-number=' + (itemNumber - 4) +']');
                        $(this).css('margin-top', '-' + ($(this).position().top - upperElement.outerHeight(true) - upperElement.position().top) + 'px');
                    }
                })
            });
        },
        setCssPosition: function () {
            var self = this;
            var $headerBanner = $('.header-banner');
            var $topBanner = $('.top-banner');
            var $promoTimer = $('.js_countdown-container');
            var $headerInnerMobile = $('.header__inner_mobile');
            var lvl2Height = $headerBanner.height() + $topBanner.height() + $promoTimer.height() + $headerInnerMobile.height();
            var headerHeight = lvl2Height;

            $('.wrapper-overlay').css('top', headerHeight);
            self.settings.navigation
                .css('top', headerHeight)
                .css('height', 'calc(100vh - ' + headerHeight + 'px)');
            $('.level-2').css('height', 'calc(100vh - ' + lvl2Height + 'px)');
        },
        setAccountLinksObservers: function() {
            var self = this;
            self.settings.navigation.find('.menu-utility-user').find('a').on('click', self.menuCategoriesClick.bind(self));
        },
        setObservers: function() {
            var self = this;
            var debounce = require('lodash.debounce');
            var b2bMenuCategoriesShowDebounced = debounce(self.b2bMenuCategoriesShow.bind(self), 100);

            $('.b2c-navigation-menu').on('mouseleave', function (e) {
                b2bMenuCategoriesShowDebounced.cancel();
                self.outerMouseClick(e);
            });
            self.settings.navigation.find('.menu-category, .additional-menu').find('a').on('click', self.menuCategoriesClick.bind(self));
            self.settings.b2bNavigation.find('.menu-category a').on('click', self.b2bMenuCategoriesShow.bind(self));
            self.settings.b2bNavigation.find('.menu-category .mainCat > a').on('mouseover', b2bMenuCategoriesShowDebounced);

            $('body')
                .on('mouseover', '.b2c-navigation-menu .level-2 > ul > li > a', function () {
                    var parentCat = $(this).parent().data('topCat');
                    if (typeof parentCat != 'undefined') {
                        var liAnchors = $('.b2c-navigation-menu .level-2 > ul > li > a');
                        $.each(liAnchors, function () {
                            if ($(this).parent().data('cat') === parentCat) {
                                $(this).closest('a').addClass('underlined');
                            }
                        });
                    }
                })
                .on('mouseout', '.b2c-navigation-menu .level-2 > ul > li > a', function () {
                    var parentCat = $(this).parent().data('top-cat');
                    if (typeof parentCat != 'undefined') {
                        var liAnchors = $('.b2c-navigation-menu .level-2 > ul > li > a');
                        $.each(liAnchors, function () {
                            if ($(this).parent().data('cat') === parentCat) {
                                $(this).closest('a').removeClass('underlined');
                            }
                        });
                    }
                })
                .on('click', '.mobile-search-icon', function(e) {
                    e.preventDefault();
                    var $wrapper = $('#wrapper');

                    if($wrapper.hasClass('menu-active') && !$(this).hasClass('mobile-search-icon-open')) {
                        $('body').removeClass('hide-live-chat');
                        $wrapper.removeClass('menu-active');
                        $('#navigation').removeClass('sub-active');
                        $('.has-sub-menu').removeClass('open');
                    }

                    if ($('.mobile-search-form-wrapper').hasClass('open')) {
                        self.closeSearch();
                    } else {
                        self.openSearch();
                    }
                })
                .on('click', '.js_as-search', function() {
                    $('.mobile-search-icon').trigger('click');
                })
                .on('click', '.clear-search', function() {
                    // This call will show the X to clear the field if we can get the event to fire on click.
                    $('#q').val('');
                    $(this).hide();
                })
                .on('click', '.close-search', function() {
                    self.closeSearch();
                });

            // Escape key closeout of navigation
            $(document).keyup(function(event) {
                if (event.keyCode === 27) {
                    var $wrapper = $('#wrapper');

                    if ($wrapper.hasClass('menu-active')) {
                        self.navigationCloseCallback();
                    }

                    if ($wrapper.hasClass('search-active')) {
                        self.closeSearch();
                    }
                }
            });

            $('.wrapper-overlay').click(function () {
                self.navigationCloseCallback();
                self.closeSearch();
            });

            // main menu toggle - taken directly from Sitegenesis
            $('.menu-toggle').on('click', function () {
                var $mobileSearchForm = $('.mobile-search-form-wrapper');
                self.settings.navigation.children('.content').removeClass('overflow');
                $('body').addClass('hide-live-chat');
                $('#wrapper').addClass('menu-active');
                ba.utils.bindHtmlClick('navigation', true, self.navigationClose.bind(self));
                self.setCssPosition();
                if ($mobileSearchForm.hasClass('open')) {
                    $mobileSearchForm.removeClass('open');
                    $('.mobile-search-icon').removeClass('mobile-search-icon-open');
                    $('#q').blur();
                }
            });
        },

        /**
         * Easy IE 9 hack for transform 3D
         */
        toggleNavigationDisplay: function(){
            if (!$('html').hasClass('nine')){
                this.settings.navigation.show();
            }
        },

        openSearch: function () {
            $('.mobile-search-form-wrapper, .search-link').addClass('open');
            $('.mobile-search-icon').addClass('mobile-search-icon-open');
            $('.wrapper-overlay').removeAttr('style');
            $('#wrapper').addClass('search-active');
            $('#q').focus();
        },

        closeSearch: function () {
            $('.mobile-search-form-wrapper, .search-link').removeClass('open');
            $('.mobile-search-icon').removeClass('mobile-search-icon-open');
            $('#wrapper').removeClass('search-active');
            $('#search-suggestions').empty();
            $('#q')
                .val('')
                .trigger('blur');
        },

        /**
         * Adds the ability to close out the menu, since we have some weird markup
         * allow the use of positioned elementes outside the container, we need to have
         * additional checks that will trigger depending on menu states
         *
         * @param event - jQuery even
         */
        navigationClose: function(event) {
            var $target = $(event.target),
                $html = $('html');

            // for desktop
            if (!$target.hasClass('close') && ($target.closest('.content').length || $target.hasClass('content') || $target.closest('#navigation.sub-active').length || $target.hasClass(this.settings.navHasSubNavClass))){
                return;
            }

            // for mobile
            if (!$target.hasClass('close') && ($html.hasClass('resp-mobile') || $html.hasClass('resp-tablet'))) {
                return
            }

            if ($target.hasClass('close') || (!$target.closest('.menu-toggle').length && !$target.closest('#navigation.sub-active').length)) {
                this.navigationCloseCallback($target);
            }
        },

        navigationCloseCallback: function($target) {
            var self = this,
                $html = $('html');

            // using fadeout to time these animations correctly
            $('#wrapper .wrapper-overlay').fadeOut(1, function() {
                $(this).removeAttr('style');
                $('body').removeClass('hide-live-chat');
                $('#wrapper').removeClass('menu-active');
                $('#navigation').removeClass('sub-active');
                $('.has-sub-menu').removeClass('open');
            });

            // If we are on mobile
            if ($target && $target.closest('.level-2') && ($html.hasClass('resp-mobile') || $html.hasClass('resp-tablet'))){
                this.settings.navigation.addClass('closing');

                setTimeout(function(){
                    self.navigationCloseAction();
                }, 600);
            } else {
                this.navigationCloseAction();
            }
            ba.utils.bindHtmlClick('navigation', false);
        },

        navigationCloseAction: function() {
            var self = this;

            if (self.settings) {
                self.settings.b2bNavigation.find('.' + self.settings.navHasSubNavClass).removeClass(self.settings.navCurrentOpenClass);
                self.settings.navigation.find('.' + self.settings.navHasSubNavClass).removeClass(self.settings.subMenuActiveClass);
                self.settings.navigation.removeClass('sub-active').removeClass('closing');
            }
        },

        subNavigationClose: function(e) {
            e.preventDefault();
            var self = this,
                $target = $(e.target).closest('a'),
                isLevel3 = $target.closest('.level-3').length > 0;

            self.menuCategoriesHideL3();

            if (!isLevel3) {
                self.settings.navigation.removeClass('sub-active');
                self.settings.navigation.find('.' + self.settings.navHasSubNavClass).removeClass(self.settings.subMenuActiveClass);
                self.settings.b2bNavigation.find('.' + self.settings.navHasSubNavClass).removeClass(self.settings.navCurrentOpenClass);
                self.settings.navigation.children('.content').removeClass('overflow');
            }
        },
        // if the target of the click isn't the container nor a descendant of the container
        outerMouseClick: function(e){
            var self = this,
                $container = $('.b2c-navigation-menu'),
                $menu = $('.b2c-navigation-menu .level-2');

            if (!$container.is(e.toElement) && $container.has(e.toElement).length === 0) {
                $menu.hide();
                $menu.removeClass(self.settings.subMenuActiveClass);
                $container.find('.' + self.settings.navHasSubNavClass).removeClass(self.settings.navCurrentOpenClass);
            }
        },

        menuCategoriesHideL2: function () {
            var self = this,
                $content = self.settings.navigation.children('.content');

            self.settings.navIsAnimating = true;
            self.settings.navigation.removeClass('sub-active');

            // Allows the animation to run, no callbacks for removeClass
            self.finsihedAnimation(0, function(){
                $content.removeClass('overflow');
            });
        },

        menuCategoriesHideL3: function () {
            var self = this,
                $content = self.settings.navigation.children('.content');

            self.settings.navIsAnimating = true;
            self.settings.navigation.removeClass('sub-active-2');
            self.finsihedAnimation(0, function(){
                $content.find('.' + self.settings.navHasSubCategoriesNavClass).removeClass(self.settings.subMenuActiveClass);
            });
        },

        menuCategoriesShowL2: function (e) {
            var self = this,
                $target = $(e.target).closest('a'),
                $anchors = self.settings.navigation.find('.' + self.settings.navHasSubNavClass),
                $content = self.settings.navigation.children('.content');

            e.preventDefault();

            if (self.settings.navIsAnimating) {
                return;
            }

            if ($target.hasClass(self.settings.subMenuActiveClass)) {
                // Secondary click of an active menu, will close it out, with
                // some additional magic for animations.
                $anchors.removeClass(self.settings.subMenuActiveClass);
                self.menuCategoriesHideL2();
                self.menuCategoriesHideL3();
            } else {
                // Initial click of sub menu
                self.menuCategoriesHideL3();
                self.settings.navIsAnimating = true;

                if (window.innerWidth < globalVars.breakpoints.medium) {
                    self.settings.navigation.scrollTop(0);
                }

                // open up the current selected sub menu
                $anchors.removeClass(self.settings.subMenuActiveClass);
                $target.addClass(self.settings.subMenuActiveClass);
                $content.addClass('overflow');
                self.settings.navigation.hide(0).show(0);
                self.settings.navigation.addClass('sub-active');
                self.finsihedAnimation(0);
            }
        },

        menuCategoriesShowL3: function (e) {
            var self = this,
                $target = $(e.target).closest('a'),
                $anchors = self.settings.navigation.find('.' + self.settings.navHasSubCategoriesNavClass);

            e.preventDefault();

            if (self.settings.navIsAnimating) {
                return;
            }

            if ($target.hasClass(self.settings.subMenuActiveClass)) {
                // Secondary click of an active menu, will close it out, with
                // some additional magic for animations.
                $anchors.removeClass(self.settings.subMenuActiveClass);
                self.menuCategoriesHideL3();
            } else {
                // Initial click of sub menu
                self.settings.navIsAnimating = true;

                if (window.innerWidth < globalVars.breakpoints.medium) {
                    self.settings.navigation.find('.level-2').scrollTop(0);
                }

                // open up the current selected sub menu
                $anchors.removeClass(self.settings.subMenuActiveClass);
                $target.addClass(self.settings.subMenuActiveClass);
                self.settings.navigation.addClass('sub-active-2');
                self.finsihedAnimation(0);
            }
        },
        /**
         * Main navigation sub menu activation.
         * Toggles the open class on the level2 sub classes when
         * a parent navigation has a sub menu, and is clicked
         *
         * @param e - jQuery event
         */
        menuCategoriesClick: function(e){
            var self = this,
                $target = $(e.target).closest('a');

            if ($target.hasClass('js_back')) {
                self.subNavigationClose(e);
                return;
            }

            if ($target.hasClass(self.settings.navHasSubNavClass)) {
                self.menuCategoriesShowL2(e);
            } else if ($target.hasClass(self.settings.navHasSubCategoriesNavClass)) {
                self.menuCategoriesShowL3(e);
            }
        },
        b2bMenuCategoriesShow: function(event){
            var self = this,
                $target = $(event.target),
                $targetMenu = $target.parent().find('.level-2'),
                $menu = $('.level-2');

            // this code will not remove the flyout if user do hover back to selected a
            if ($target.hasClass(self.settings.navCurrentOpenClass)) {
                return;
            }

            $('.has-sub-menu').removeClass(self.settings.navCurrentOpenClass);

            if ($target.hasClass(self.settings.navHasSubNavClass)) {
                $target.addClass(self.settings.navCurrentOpenClass);

                if ($('.menu-toggle').is(':visible')) {
                    event.preventDefault();
                }
            }

            var numberOfColumnsNeeded = $target.parent().attr('data-column');
            var newMenuWidth = numberOfColumnsNeeded * 190 + 300;

            $targetMenu.css({
                width: newMenuWidth + 'px'
            });

            if ($target.hasClass(self.settings.navHasSubNavClass)){
                if ($targetMenu.hasClass(self.settings.subMenuActiveClass)) {
                    // Secondary click of an active menu, will close it out
                    $targetMenu
                        .removeClass(self.settings.subMenuActiveClass)
                        .hide();
                } else {
                    // Initial click of sub menu
                    $menu
                        .removeClass(self.settings.subMenuActiveClass)
                        .hide();

                    setTimeout(function () {
                        if (!$target.hasClass(self.settings.navCurrentOpenClass)) {
                            return;
                        }

                        $targetMenu
                            .addClass(self.settings.subMenuActiveClass)
                            .fadeTo(0, 0)
                            .fadeTo(0, 1);
                    }, 200);
                }
            } else {
                $menu
                    .removeClass(self.settings.subMenuActiveClass)
                    .hide();
            }
        },
        finsihedAnimation: function(timeout, cb) {
            var self = this;
            setTimeout(function(){
                self.settings.navIsAnimating = false;
                if (typeof cb === 'function') cb();
            }, timeout || 10);
        },

        updateLoginDropdown: function () {
            var self = this;
            $.ajax({
                type: 'GET',
                url: Urls.accountIconAndDropdown,
                success: function (response) {
                    $('.js_menu-utility-user').replaceWith($(response).find('.js_menu-utility-user'));
                    $('.js_login-link').replaceWith(response);
                    self.setAccountLinksObservers();
                }
            });
        }
    };

    $(document).on('baCoreReady', function() {

        /**
         * The parameter object is optional.
         * Must be an object.
         */
        header.init();
        var $mobileMenuUl = $('#navigation .content ul.menu-category.level-1');
        var $inspiredLi = $mobileMenuUl.find('#Inspiration');
        $mobileMenuUl.find($inspiredLi).appendTo($mobileMenuUl);
        header.setCssPosition();

        $('.banner-featured-mobile').on('click', function (){
            $(this).addClass('open');
            $(this).next('.banner-featured-mobile-content').show();
        });
    })
        .on('custom:update:login:dropdown', header.updateLoginDropdown.bind(header));

    return header;
}));
