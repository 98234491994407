'use strict';

var addressAutocomplete = require('../address-autocomplete'),
    globalVars = require('../global-vars'),
    progress = require('../progress'),
    util = require('../util');

function sendGTMEvent() {
    if (!window.dataLayer) {
        return;
    }

    let dataObj = {
        event: 'gtmEvent',
        eventCategory: 'engagement',
        eventAction: 'order swatches',
        interactionEvent: false
    };

    let colors = $('#dwfrm_swatchrequest_colors').val();
    dataObj.eventLabel =
        colors
            .split(':')
            .filter(function (value, index) {
                return index % 2 !== 0;
            })
            .join(', ');

    window.dataLayer.push(dataObj);
}

function sendEmailSignupGTMEvent($form) {
    if (!window.dataLayer) {
        return;
    }

    var $checkbox = $form.find('.js_email-opt-in input[type="checkbox"]');

    if ($checkbox.is(':checked')) {
        var $email = $form.find('input[type="email"]');
        var email = $email.val();

        window.dataLayer.push({
            event: 'gtmEvent',
            eventCategory: 'engagement',
            eventAction: 'email signup',
            eventLabel: 'order swatches',
            interactionEvent: false,
            email: email && email.length ? email.toLowerCase() : email
        });
    }
}

function isAlreadySent() {
    return $('#dwfrm_swatchrequest').hasClass('order-sent');
}

function showSelectedSwatchesErrorMessage() {
    $('.js_selected-swatches-error-message').removeClass('d-none');
}

function hideSelectedSwatchesErrorMessage() {
    $('.js_selected-swatches-error-message').addClass('d-none');
}

function showSelectedSwatchLimitErrorMessage() {
    $('.js_selected-swatch-limit-error-message').removeClass('d-none');
}

function hideSelectedSwatchLimitErrorMessage() {
    $('.js_selected-swatch-limit-error-message').addClass('d-none');
}

function hideSwatchRequestForm() {
    $('#dwfrm_swatchrequest').addClass('order-sent');
}

function handleAddButtons() {
    var swatchIDs = [];
    $('.js_selected-swatches-wrapper .remove-swatch').each(function () {
        swatchIDs.push($(this).data('sid') + '');
    });

    $('.js_button-swatch').each(function () {
        if (swatchIDs.indexOf($(this).data('sid') + '') > -1) {
            $(this)
                .removeClass('add-swatch')
                .addClass('remove-swatch')
                .text($(this).data('title-remove'));
        } else {
            $(this)
                .removeClass('remove-swatch')
                .addClass('add-swatch')
                .text($(this).data('title-add'));
        }
    });

    $('.swatches-wrapper .add-swatch').prop('disabled', $('.selected-swatch').length > 9);
}

function handleClearCTA() {
    $('.js_clear-selected-swatches').toggleClass('d-none', !$('.selected-swatch').length);
}

function handleStickyElements() {
    var windowTop = $(window).scrollTop();
    var stickyElementWrapper = $('.right-part');
    var stickyElementWrapperOffset = stickyElementWrapper.offset().top;
    var stickyElement = $('.selected-swatches-block');

    if (window.innerWidth > globalVars.breakpoints.medium) {
        if (stickyElementWrapperOffset < windowTop && stickyElementWrapper.height() + stickyElementWrapperOffset - stickyElement.height() > windowTop) {
            stickyElement.addClass('sticky-wrapper');
        } else {
            stickyElement.removeClass('sticky-wrapper');
        }
        if (stickyElementWrapperOffset < windowTop && stickyElementWrapper.height() + stickyElementWrapperOffset > windowTop) {
            stickyElementWrapper.addClass('reverse');
        } else {
            stickyElementWrapper.removeClass('reverse');
        }
    } else {
        if (windowTop + stickyElement.height() < stickyElementWrapperOffset) {
            stickyElement.addClass('sticky-mob-wrapper');
        } else {
            stickyElement.removeClass('sticky-mob-wrapper');
        }
    }
}

function toggleQuickAddSwatches() {
    $('.js_quick-add-swatches').toggleClass('closed');
}

function toggleSwatchAnchorsMenu() {
    $('.js_swatch-anchor-accordion').toggleClass('closed');
}

function initStateSwatchAnchorsMenu() {
    $('.js_swatch-anchor-accordion').toggleClass('closed', window.innerWidth < globalVars.breakpoints.medium);
}

function collapseSwatchWrapper() {
    $('.selected-swatches-block').addClass('selected-swatches-block--closed');
}

/**
 * @private
 * @function
 * @description Binds the events of the swatch page to swatch page
 */
function initializeEvents() {
    if (SitePreferences.GOOGLE_ADDRESS_AUTOCOMPLETE_ENABLED) {
        addressAutocomplete.init();
    }

    handleAddButtons();
    updateSwatchForm();
    initStateSwatchAnchorsMenu();

    $(window)
        .on('load', function () {
            handleStickyElements();
        })
        .on('delayed-resize', function(){
            $('.selected-swatches-block').removeClass('sticky-wrapper sticky-mob-wrapper');
            $('.right-part').removeClass('reverse');
            handleStickyElements();
        })
        .on('scroll', handleStickyElements);

    $(document)
        .on('form:ajax:response', function (e, data) {
            if (!(data && data.response)) {
                return;
            }

            var $form = $(data.form.el);
            if (!$form.hasClass('js_swatch-request')) {
                return;
            }

            $('.order-swatch-success-message').html(data.response.message);
            hideSelectedSwatchLimitErrorMessage();

            if (!data.response.status || isAlreadySent()) {
                return;
            }

            showSuccessMessage();
            hideSwatchRequestForm();
            sendGTMEvent();
            sendEmailSignupGTMEvent($form);
        })
        .on('click', '.selected-swatches-button', function () {
            hideSelectedSwatchLimitErrorMessage();

            if (!$('.selected-swatch').length) {
                showSelectedSwatchesErrorMessage();
                return;
            }

            var $anchor = $('.js_swatch-request-form-anchor');

            if (!$anchor.length) {
                return;
            }

            collapseSwatchWrapper();
            util.scrollBrowser($anchor.offset().top);
        });

    var selectedSwatchesWrapper = $('.js_selected-swatches-wrapper')[0];
    var observer = new MutationObserver(function () {
        updateSwatchForm();
    });
    observer.observe(selectedSwatchesWrapper, {subtree: true, childList: true});

    $('.selected-swatches-arrow').on('click', toggleSwatchWrapper);
    $('.js_quick-add-swatches__head').on('click', toggleQuickAddSwatches);
    $('.js_swatch-anchor-accordion__head').on('click', toggleSwatchAnchorsMenu);
    $(document)
        .on('click', '.add-swatch, .remove-swatch', handleSwatch)
        .on('click', '.js_clear-selected-swatches', clearSwatches);
}

function updateSwatchForm() {
    if (isAlreadySent()) {
        return;
    }

    var colors = [];
    var fabrics = [];

    $('.js_selected-swatches-wrapper .selected-swatch').each(function () {
        var catID = $(this).children('.swatch-category').data('catid');
        var swatchID = $(this).children('.swatch-name').data('sid');
        var swatchColor = $(this).children('.swatch-name').text().trim();
        if (swatchID && catID) {
            colors.push(swatchID + ':' + swatchColor + ':' + catID);
            fabrics.push(catID);
        }
    });

    $('#dwfrm_swatchrequest_colors').val(colors.join(','));
    $('#dwfrm_swatchrequest_fabrics').val(fabrics.join(','));
}

function handleSwatches(swatchID, action) {
    const mainCategory = $('.js-swatch-category').data('category');

    var url = util.ajaxUrl(Urls.handleSwatch);
    url = util.appendParamsToUrl(url, {
        sid: swatchID,
        category: mainCategory,
        action: action
    });

    progress.show();
    $.ajax({
        type: 'GET',
        url: url,
        success: function (response) {
            if (response.success) {
                if (action === 'add') {
                    $('.js_selected-swatches-wrapper').append(response.selectedSwatchesHTML);

                    if (response.isLimitExceed) {
                        showSelectedSwatchLimitErrorMessage();
                    }
                } else {
                    swatchID.split(',').forEach(function (sid) {
                        $('.selected-swatch')
                            .find('[data-sid="' + sid + '"]')
                            .closest('.selected-swatch')
                            .remove();
                    });
                }

                hideSuccesMessage();
                handleAddButtons();
                handleClearCTA();
                hideSelectedSwatchesErrorMessage();
            }
        },
        error: function (response) {
            throw new Error(response.error);
        },
        complete: function () {
            progress.hide();
        }
    });
}

function handleSwatch() {
    hideSelectedSwatchLimitErrorMessage();

    if (isAlreadySent()) {
        return;
    }

    $(this).blur();
    var swatchID = $(this).data('sid');
    handleSwatches(swatchID + '', $(this).hasClass('add-swatch') ? 'add' : 'remove');
}

function clearSwatches() {
    hideSelectedSwatchLimitErrorMessage();

    if (isAlreadySent()) {
        return;
    }

    var swatchIDs = [];
    $('.js_selected-swatches-wrapper .remove-swatch').each(function () {
        swatchIDs.push($(this).data('sid'));
    });
    handleSwatches(swatchIDs.join(','), 'remove');
}

function hideSuccesMessage() {
    var $button = $('#swatchRequestSubmit');

    if (!$button.length) {
        return;
    }

    var $orderSwatchSuccessMessage = $('.order-swatch-success-message');

    if(!$orderSwatchSuccessMessage.hasClass('d-none')) {
        $button.prop('disabled', false);
        $orderSwatchSuccessMessage.addClass('d-none');
        $('.order-swatch-success-overlay').addClass('d-none');
        $('.formContainer .field-wrapper .required').each(function () {
            $(this).removeClass('valid');
        });
    }
}

function showSuccessMessage() {
    var $button = $('#swatchRequestSubmit');

    if (!$button.length) {
        return;
    }

    $button.prop('disabled', true);
    $('.order-swatch-success-message').removeClass('d-none');
    $('.order-swatch-success-overlay').removeClass('d-none');
    $('.formContainer .field-wrapper .required').each(function () {
        $(this).parents('.form-row').removeClass('input-focused');
    });
}

function toggleSwatchWrapper() {
    $('.selected-swatches-block').toggleClass('selected-swatches-block--closed');
}

var swatch = {
    init: initializeEvents
};

module.exports = swatch;
