'use strict';

var util = require('./util');

function open() {
    $('body').addClass('hide-live-chat');
    $('#wrapper').addClass('refinement-flyout-open overlay-active');
}

function openClick(e) {
    e.preventDefault();
    e.stopImmediatePropagation();

    $('.js_refinements').find('.js-refinement-collapse-item').removeClass('opened');
    $('.wrapper-overlay').removeAttr('style');
    open();
    util.scrollBrowser(0);
}

function closeCallback() {
    if (!$('#wrapper').hasClass('refinement-flyout-open')) {
        return;
    }

    // using fadeout to time these animations correctly
    $('#wrapper .wrapper-overlay').fadeOut(200, function() {
        $(this).removeAttr('style');
        $('body').removeClass('hide-live-chat');
        $('#wrapper').removeClass('refinement-flyout-open overlay-active');
    });
}

function close(e) {
    var $target = $(e.target);

    if ($target.hasClass('js_refinement-flyout__close') || $target.hasClass('js_refinement-flyout__view-results-action') || !$target.closest('.js_refinement-flyout, .js-collapse-element, .loader').length){
        closeCallback();
    }
}

function initObservers() {
    $(document)
        .on('click', '.js_show-more-refinements, .js_show-fas', openClick)
        .on('click', close)
        .keyup(function(e) {
            // Escape key closeout of flayout refinement
            if (e.keyCode === 27 && $('#wrapper').hasClass('refinement-flyout-open')) {
                closeCallback();
            }
        });
}

var refinementFlyout = {
    init: function() {
        initObservers();
    }
};

module.exports = refinementFlyout;
