/**
 * @package     Blueacorn/footer
 * @version     2.0
 * @author      Blue Acorn <code@blueacorn.com>
 * @copyright   Copyright © 2016 Blue Acorn.
 */

(function (root, factory) {
    if (typeof exports === 'object') {
        // CommonJS
        module.exports = factory(
            window.jQuery,
            require('./blueacorn-core'),
            require('../vendor/enquire/enquire.min'),
            require('../vendor/bez/bez')
        );
    } else if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery', 'baCore', 'enquireJs'], function (jquery, ba, enquire) {
            return (factory(jquery, ba, enquire));
        });
    } else {
        // Global Variables
        root.ba.header = factory(root.jQuery, root.ba, root.enquire);
    }

}(this, function ($, ba, enquire) {
    'use strict';

    var footer = {

        settings: {
            blocks: $('.footer-container .footer-item'),
            h5: $('.footer-container .footer-item h5'),
            ul: $('.footer-container .footer-item ul'),
            defaultActive: 0, // this is the index value of the default activated
            slideSpeed: 300,
            transformArray: [0.645, 0.045, 0.355, 1]
        },

        init: function (options) {

            // Overrides the default settings
            ba.overrideSettings(this.settings, options);

            // Start the debugger
            ba.setupDebugging(this.settings);

            this.initObservers();
        },

        initObservers: function() {
            var self = this;

            /**
             * need to use (bp.medium + 1 since at 768 and smaller, they need the accordion)
             * weird but theres an edge case here
             */
            enquire.register('(max-width: 1126px)', {
                match: function () {
                    self.deactivateBlocks();
                    self.observeFooterClick();
                    self.activateFirstBlock();
                }
            });

            /**
             * need to use (bp.medium + 2 to accommodate for the above edge case)
             */
            enquire.register('(min-width: 1127px)', {
                match: function() {
                    self.unInit();
                }
            });
        },

        /**
         * Footer accordion click action, checks the active state,
         * where the active state is the element having the class `active`.
         * If active, it closes the accordion, if not, it opens the accordion.
         *
         * @param event - jQuery event
         *
         * @see this.activateBlock
         * @see this.deactivateBlock
         */
        observeFooterClick : function() {
            var self = this;

            this.settings.h5.off('click');
            this.settings.h5.on('click', function() {
                var $block = $(this).parents('.footer-item');

                if (self.debug) console.log('Clicked footer link dropdown.');

                if (!$block.hasClass('active')) {
                    self.deactivateBlocks();
                }
                self.activateBlock($block);
            });

            // Empty click handler prevents iOS safari event propagation error
            this.settings.h5.on('click', function() { });
        },

        activateFirstBlock : function() {
            // pulled from the pageContext.type property
            var contexts = ['storefront'];

            if (typeof pageContext !== 'undefined' && contexts.indexOf(pageContext.type) !== -1){

                this.activateBlock(this.settings.blocks.eq(this.settings.defaultActive));
            }
        },

        activateBlock: function(block) {
            var self = this;

            block.toggleClass('active');
            block.find('.menu-footer').slideToggle({
                duration: self.settings.slideSpeed,
                step: function() {
                    self.slideToggleFix($(this));
                },
                complete: function() {
                    self.slideToggleFix($(this));
                }
            }, $.bez(self.settings.transformArray));
        },

        /**
         * Since $.slideToggle saves the default state
         * for the method as the last known css display type,
         * we have hack it to always be display:block.
         *
         * This is an edge case from print this page feature
         *
         * @param element - jQuery object
         */
        slideToggleFix: function(element){
            if (element.css('display') === 'inline-block') {
                element.css('display', 'block');
            }
        },

        deactivateBlocks: function() {
            var self = this;

            this.settings.blocks.removeClass('active').find('.menu-footer').slideUp(self.settings.slideSpeed, $.bez(self.settings.transformArray));
        },

        unInit: function() {
            this.settings.blocks.removeClass('active').find('.menu-footer').removeAttr('style');
            this.settings.h5.off('click');
        }
    };

    $(document).on('baCoreReady', function() {

        /**
         * The parameter object is optional.
         * Must be an object.
         */
        footer.init();
    });

    return footer;
}));
