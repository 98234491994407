'use strict';

var util = require('../../util');
var shipping = require('./shipping');
var ajax = require('../../ajax');

/**
 * @function
 * @description Selects the first address from the list of addresses
 */
exports.init = function () {
    var $form = $('.address');
    // select address from list
    $('select[name$="_addressList"]', $form).on('change', function () {
        var selected = $(this).children(':selected').first();
        var selectedAddress = $(selected).data('address');
        if (!selectedAddress) { return; }

        util.fillAddressFields(selectedAddress, $form);
        // shipping.updateShippingMethodList();
        shipping.updateAddressType(selectedAddress.ID);
        // re-validate the form
        if ($form.valid()){
            var $continueToBilling = $('.continue-to-billing');
            var $continueToReview = $('.continue-to-review');
            if ($continueToBilling.length > 0) {
                $continueToBilling.removeAttr('disabled');
            } else if ($continueToReview.length > 0) {
                var $paymentForm = $('form[id="dwfrm_billing"]');
                if ($paymentForm.find('.error').length === 0) {
                    $continueToReview.prop('disabled', false);
                }
            }
        } else {
            $form.validate().form();
        }


        var cardUUID = $('#creditCardList').val();
        if (!cardUUID) {
            $(document).find('[data-value="Visa"]').trigger('click');
            return;
        }
        populateCreditCardForm(cardUUID);
    });
};
function populateCreditCardForm(cardID) {
    // load card details
    var url = util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID);
    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert(Resources.CC_LOAD_ERROR);
                return false;
            }
            setCCFields(data);
        }
    });
}
function setCCFields(data) {
    var $creditCard = $('[data-method="CREDIT_CARD"]');
    $creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger('change');
    $creditCard.find('select[name$="_type"]').val(data.type).trigger('change');
    $creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');
    $creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger('change');
    $creditCard.find('[name$="_year"]').val(data.expirationYear).trigger('change');
    $creditCard.find('input[name$="_cvn"]').val('').trigger('change');

    $(document).trigger('update:form:inputs');
}
