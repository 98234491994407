/**
 * @package     Blueacorn/Utilities
 * @version     1.0
 * @author      Blue Acorn <code@blueacorn.com>
 * @copyright   Copyright © 2016 Blue Acorn.
 *
 * @todo create dynamic utility to create new methods that are automatically attached to BA. Do I need to?
 */

(function (root, factory) {
    if (typeof exports === 'object') {
        // CommonJS
        module.exports = factory();
    } else if (typeof define === 'function' && define.amd) {
        // AMD
        define(factory());
    }

}(this, function () {
    'use strict';

    /**
     *  String#truncate([length = 30[, suffix = '...']]) -> String
     *
     *  Truncates a string to given `length` and appends `suffix` to it (indicating
     *  that it is only an excerpt).
     *
     *  ##### Examples
     *
     *      'A random sentence whose length exceeds 30 characters.'.truncate();
     *      // -> 'A random sentence whose len...'
     *
     *      'Some random text'.truncate();
     *      // -> 'Some random text.'
     *
     *      'Some random text'.truncate(10);
     *      // -> 'Some ra...'
     *
     *      'Some random text'.truncate(10, ' [...]');
     *      // -> 'Some [...]'
     **/
    if (typeof String.truncate !== 'function'){
        String.prototype.truncate = function (length, truncation) {
            length = length || 30;
            truncation = typeof truncation === 'undefined' ? '...' : truncation;
            return this.length > length ? this.substring(0, length) + truncation : this;
        };
    }

    /**
     * Polyfill for ES6 startsWith
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
     */
    if (typeof String.startsWith !== 'function') {
        String.prototype.startsWith = function(searchString, position){
            position = position || 0;
            return this.substr(position, searchString.length) === searchString;
        };
    }

    /**
     *  RegExp.escape(str) -> String
     *  - str (String): A string intended to be used in a `RegExp` constructor.
     *
     *  Escapes any characters in the string that have special meaning in a
     *  regular expression.
     *
     *  Use before passing a string into the `RegExp` constructor.
     */
    if (typeof RegExp.escape !== 'function'){
        RegExp.escape = function(str) {
            return String(str).replace(/([.*+?^=!:${}()|[\]\/\\])/g, '\\$1');
        };
    }


    /**
     *  String#strip() -> String
     *
     *  Strips all leading and trailing whitespace from a string.
     *
     *  ##### Example
     *
     *      '    hello world!    '.strip();
     *      // -> 'hello world!'
     **/
    if (typeof String.strip !== 'function'){
        String.prototype.strip = function () {
            return this.replace(/^\s+/, '').replace(/\s+$/, '');
        };
    }

    /**
     *  String#format() -> String
     **/

    if (typeof String.format !== 'function'){

        String.format = function () {
            var s = arguments[0];
            var i, len = arguments.length - 1;
            for (i = 0; i < len; i++) {
                var reg = new RegExp('\\{' + i + '\\}', 'gm');
                s = s.replace(reg, arguments[i + 1]);
            }
            return s;
        };
    }

    /**
     * Add core utility methods here,
     * will traverse down to all BA modules.
     *
     * To access use ba.utils
     */
    var utils = {

        /**
         * Binds a callback to the whole HTML document.
         * For allowing the use of clicking outside of containers, and
         * calling the callback specified in the event. Also allows unbinding
         * that event based on the namespace provided
         *
         * @param nameSpace - named used for the event - eg click.namespace
         * @param bindUnbind - boolean
         * @param callback
         */
        bindHtmlClick: function(nameSpace, bindUnbind, callback) {
            var $html = $('html'),
                event = 'click.' + nameSpace;

            if (bindUnbind && typeof callback !== 'function'){
                return false;
            }

            if (bindUnbind){
                $html.on(event, callback).addClass('clickout-active');
            } else {
                $html.off(event).removeClass('clickout-active');
            }
        },

        /**
         * Checks for the existence of local storage
         */
        localStorageExists: function() {
            return window.hasOwnProperty('localStorage');
        },

        /**
         * Sets a local storage key based on the key value pair
         *
         * @param key - ID of the local storage key
         * @param value - value of the local storage key
         * @returns null|true - returns true if value is set
         */
        setLocalStorageValue: function(key, value){
            if (this.localStorageExists()){
                localStorage.setItem(key, value);
                return true;
            }

            return false;
        },

        /**
         * Gets a local storage key based on the key value pair
         *
         * @param key - ID of the local storage key to access
         */
        getLocalStorageValue: function(key) {
            if (this.localStorageExists()){
                return localStorage.getItem(key);
            }
        }
    };

    return utils;
}));
