(function (root, factory) {
    if (typeof exports === 'object') {
        // CommonJS
        module.exports = factory(window.jQuery, require('./blueacorn-core'), require('../util'));
    } else if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery', 'baCore', 'util'], function (jquery, ba, util) {
            return (factory(jquery, ba, util));
        });
    } else {
        // Global Variables
        root.ba.superSelects = factory(root.jQuery, root.ba, root.util);
    }
}(this, function ($, ba, util) {
    'use strict';

    var destroyableAlert = {
        alert: {
            el: null,
            msgContainer: null,
            destroy: null
        },
        init: function () {
            var el = $('[data-alert]');

            if (el.length > 0) {
                this.alert.el           = el;
                this.alert.msgContainer = this.alert.el.find('[data-alert-message]');
                this.alert.destroy    = this.alert.el.find('[data-alert-destroy]');

                this.setObservers();
            }
        },
        setObservers: function () {
            $(document).on('form:alert:show', this, function (e) {
                e.data.show();
            });

            $(document).on('form:alert:add', this, function (e, data) {
                e.data.addMsg(data.message, data.cssClass);
            });

            $(document).on('form:alert:hide', this, function (e) {
                e.data.hide();
            });

            $(document).on('click', '[data-alert-destroy]', this, function (e) {
                e.preventDefault();
                e.data.hide();
            });

            $(document).on('form:ajax:done', this, function (e) {
                var el = e.data.alert.el;

                if (el.length > 0){
                    var position = parseInt(el.offset().top - 150)
                    util.scrollBrowser(position);
                }
            });
        },
        addMsg: function (msg, klass) {
            this.clearMsg();
            this.alert.msgContainer.html('<p>' + msg + '</p>');
            this.alert.el.addClass('alert-' + klass);
        },
        clearMsg: function () {
            this.alert.el.removeClass('active alert-success alert-error');
            this.alert.msgContainer.html('');
        },
        show: function (klass) {
            this.alert.el.addClass('active');
        },
        hide: function () {
            this.clearMsg();
        }
    };

    $(document).on('baCoreReady', function () {
        destroyableAlert.init();
    });

    return destroyableAlert;
}));
