/**
 * @package     Blueacorn/FormMessages
 * @version     1.0
 * @author      Blue Acorn <code@blueacorn.com>
 * @copyright   Copyright © 2016 Blue Acorn.
 */

(function (root, factory) {
    if (typeof exports === 'object') {
        module.exports = factory(window.jQuery, require('./blueacorn-core'));
    } else if (typeof define === 'function' && define.amd) {
        define(['jquery', 'baCore'], function (jquery, ba) {
            return (factory(jquery, ba));
        });
    } else {
        root.ba.header = factory(root.jQuery, root.ba);
    }
}(this, function ($, ba) {

    var FormMessages = {
        el: null,
        init: function (el) {
            if (el.length > 0) {
                this.el = el;
                this.setObservers();
            }

            $(document).trigger('form:message:clear');
        },
        setObservers: function () {
            $(document).on('form:message:show', this, function (e, msgObj) {
                e.data.clearMsg();
                e.data.showMsg(msgObj);
            });

            $(document).on('form:message:clear', this, function (e) {
                e.data.clearMsg();
            });

            $(document).on('form:message:hide', this, function (e, timeout) {
                e.data.hideMsg();
            });
        },
        showMsg: function (msgObj) {
            if (typeof msgObj == 'object') {
                this.el.html(msgObj.message);
                this.el.addClass(msgObj.cssClass);
                this.el.show();
            }
        },
        hideMsg: function () {
            this.el.hide();
            this.clearMsg();
        },
        clearMsg: function () {
            this.el.removeClass();
            this.el.html('');
        }
    };

    $(document).on('form:message:init', function (e, data) {
        FormMessages.init($(data));
    });

    return FormMessages;
}));
