'use strict';

function fppImpression(args) {
    if (!args) {
        return;
    }

    let obj = {
        event: 'eecEvent',
        eventCategory: 'furniture protection plan',
        eventAction: 'Product Impressions',
        eventLabel: 'None',
        eventValue: 0,
        interactionEvent: false,
        ecommerce: {
            currencyCode: window.Constants.CURRENT_CURRENCY,
            impressions: []
        }
    };

    if (args.isCartPage) {
        let $container = $('.js_gtm-fpp-offer');

        if (args.oldFppIDs) {
            $container = $container.filter(function (index, elem) {
                let pid = $(elem).data('fpp-pid');
                let isOldFpp = args.oldFppIDs.includes(pid);
                return !isOldFpp;
            });
        }

        if (!$container.length) {
            return;
        }

        $container.each(function (index, elem) {
            let $this = $(elem);

            obj.ecommerce.impressions.push({
                name: $this.data('fpp-name'),
                id: $this.data('fpp-name'),
                price: $this.data('fpp-price-value'),
                category: 'Protection Plan',
                variant: '',
                dimension10: false,
                dimension11: 'not customizable',
                position: index + 1,
                brand: window.Resources.GTM_BRAND,
                list: 'Upsell > Cart'
            });
        });
    }

    if (args.isMiniCart) {
        let $container = $('.js_gtm-fp-mini-cart');
        if (!($container.length && $container.is(':visible'))) {
            return;
        }

        obj.ecommerce.impressions.push({
            name: $container.data('fpp-name'),
            id: $container.data('fpp-name'),
            price: $container.data('fpp-price-value'),
            category: 'Protection Plan',
            variant: '',
            dimension10: false,
            dimension11: 'not customizable',
            position: 1,
            brand: window.Resources.GTM_BRAND,
            list: 'Upsell > Mini-Cart'
        });
    }

    if (window.dataLayer) {
        window.dataLayer.push(obj);
    }
}

function fppAddToCart(args) {
    if (!args) {
        return;
    }

    let list = '';

    if (args.isCartPage) {
        list = 'Upsell > Cart';
    }

    if (args.isMiniCart) {
        list = 'Upsell > Mini-Cart';
    }

    let obj = {
        event: 'eecEvent',
        eventCategory: 'furniture protection plan',
        eventAction: 'Add to Cart',
        eventLabel: args.name,
        eventValue: 0,
        interactionEvent: true,
        ecommerce: {
            add: {
                actionField: {
                    list: list
                },
                products: [
                    {
                        name: args.name,
                        id: args.id,
                        price: args.price,
                        category: 'Protection Plan',
                        variant: '',
                        dimension10: false,
                        dimension11: 'not customizable',
                        position: 1,
                        brand: window.Resources.GTM_BRAND,
                        quantity: 1
                    }
                ]
            }
        }
    };

    if (window.dataLayer) {
        window.dataLayer.push(obj);
    }
}

exports.fppImpression = fppImpression;
exports.fppAddToCart = fppAddToCart;
