'use strict';

var imagesLoaded = require('imagesloaded'),
    util = require('./util'),
    globalVars = require('./global-vars'),
    quickview = require('./quickview'),
    prevViewport;

function setProductTileImageHeight(recalculate) {
    let $productImage = $('.js_product-tile .default-image.loaded:visible');

    if (!$productImage.length) {
        return;
    }

    var $searchResultItems = $('#search-result-items');
    var propertyName = '--product-tile-image-height';
    var propertyDefaultValue = 'auto';

    if (!(recalculate || getComputedStyle($searchResultItems.get(0)).getPropertyValue(propertyName) === propertyDefaultValue)) {
        return;
    }

    $searchResultItems.get(0).style.setProperty(propertyName, propertyDefaultValue);
    let height = $productImage.get(0).getBoundingClientRect().height; // it needs for getting float value
    $searchResultItems.get(0).style.setProperty(propertyName, height + 'px');
}

function getCurrentViewportString() {
    return window.innerWidth < globalVars.breakpoints.large ? 'mobile' : 'desktop';
}

function setPrevViewport() {
    prevViewport = getCurrentViewportString();
}

function gridViewToggle() {
    $('.toggle-grid').on('click', function () {
        $('.search-result-content').toggleClass('wide-tiles');
        $(this).toggleClass('wide');
    });
}

function mobileAltCarousel() {
    let isMobile = window.innerWidth < globalVars.breakpoints.large;

    //collect all the tiles that have alt images
    $('.grid-tile .js_owl-carousel-container').each(function () {
        if (isMobile && $(this).find('.alt-image').length > 0) {
            if ($(this).hasClass('owl-loaded')) {
                return;
            }

            //init the carousel
            $(this)
                .on('initialized.owl.carousel changed.owl.carousel', function () {
                    $(document).trigger('custom:lazy:load:update');
                })
                .on('initialized.owl.carousel', function () {
                    $(document).trigger('custom:owl:carousel:initialized', {
                        carousel: $(this)
                    });
                })
                .owlCarousel({
                    loop: false,
                    mouseDrag: true,
                    navRewind: false,
                    items: 1,
                    margin: 2,
                    dots: true
                });
        } else if ($(this).hasClass('owl-loaded')) {
            $(this).trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
            $(this).find('.owl-stage-outer').children().unwrap();
        }
    });
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
    setPrevViewport();
    gridViewToggle();

    $(document)
        .off('mouseenter.product-tile')
        .on('mouseenter.product-tile', '.js_product-tile .product-image', function (e) {
            let $productTile = $(this).closest('.product-tile');
            let $thumbLink = $productTile.find('.thumb-link');
            if ($thumbLink.length > 1 && window.innerWidth >= globalVars.breakpoints.large) {
                e.preventDefault();
                e.stopPropagation();
                let $defaultImage = $thumbLink.find('.default-image');

                if (!$defaultImage.length) {
                    return;
                }

                let $altImage = $thumbLink.find('.alt-image');

                $defaultImage.hide();
                $altImage.show();

                if (!$altImage.hasClass('loaded')) {
                    $(document).trigger('custom:lazy:load:update');
                }
            }
        })
        .off('mouseleave.product-tile')
        .on('mouseleave.product-tile', '.js_product-tile .product-image', function (e) {
            let $productTile = $(this).closest('.product-tile');
            let $thumbLink = $productTile.find('.thumb-link');
            if ($thumbLink.length > 1 && window.innerWidth >= globalVars.breakpoints.large) {
                e.preventDefault();
                e.stopPropagation();
                $productTile.find('.product-image').removeAttr('style');
                $thumbLink.find('.alt-image').hide();
                $thumbLink.find('.default-image').show();
            }
        })
        .off('click.swatch')
        .on('click.swatch', '.swatch-list .swatch', function (e) {
            e.preventDefault();
            e.stopPropagation();

            if ($(this).hasClass('selected')) { return; }
            var $tile = $(this).closest('.product-tile');
            var $wrapper = $('#wrapper');

            if ($wrapper.hasClass('pt_product-search-result') ||
                $wrapper.hasClass('pt_categorylanding_lifestyle') ||
                $wrapper.hasClass('pt_wish-list') ||
                $(this).closest('.sti-flyout').length) {

                var currentSwatchPID = $(this).data('pid');
                var cgid = $(this).data('cgid');
                var showCylindoBackground = $('.pt_product-search-result').length > 0;
                var productTileParams = {
                    pid: currentSwatchPID,
                    showswatches: true,
                    showalt: true,
                    showwishlist: true,
                    showCylindoBackground: showCylindoBackground,
                    cgid: cgid,
                    isSearchPageOrPlp: $(this).data('search-plp')
                };

                $.ajax({
                    type: 'GET',
                    url: util.appendParamsToUrl(Urls.getProductTile, productTileParams),
                    success: function (response) {
                        $tile.closest('.grid-tile').html(response);
                        initializeEvents();
                        $('.product-image').removeAttr('style');
                    }
                });
            } else {
                // I left this part of the code so as not to break the functionality if another team/company will add swatch-list not on pdp
                // Otherwise, you can delete the else part and if condition
                $(this).closest('.swatch-list').find('.swatch.selected').removeClass('selected');
                $(this).addClass('selected');
                $tile.find('.thumb-link').attr('href', $(this).attr('href'));
                $tile.find('name-link').attr('href', $(this).attr('href'));

                var data = $(this).children('img').filter(':first').data('thumb');
                var $thumb = $tile.find('.product-image .thumb-link img').eq(0);
                var data2 = $(this).children('img').filter(':first').data('thumb2');
                var $thumb2 = $tile.find('.product-image .thumb-link img').eq(1);
                var currentAttrs = {
                    src: data.src,
                    alt: data.alt,
                    title: data.title
                };
                if (data2 != 'null' && data2 != null) {
                    var currentAttrs2 = {
                        src: data2.src,
                        alt: data2.alt,
                        title: data2.title
                    };
                    $thumb2.attr(currentAttrs2);
                    $thumb2.data('current', currentAttrs2);
                }
                $thumb.attr(currentAttrs);
                $thumb.data('current', currentAttrs);
            }
        });

    $(window).on('delayed-resize', function () {
        setProductTileImageHeight(true);

        if (prevViewport !== getCurrentViewportString()) {
            mobileAltCarousel();
            setPrevViewport();
        }

        var $tiles = $('.tiles-container .product-tile');
        if ($tiles.length === 0) { return; }

        $tiles.removeAttr('style');
        $tiles.syncHeight()
            .each(function (idx) {
                $(this).data('idx', idx);
            });
    });

    if ($('.login-link a').attr('title') === 'Log Out') {
        // User is logged in
        $('.club-price').each(function () {
            $(this).addClass('logged-in');
            $(this).removeClass('tooltip-cc');
        });

        $('.reg-price').each(function () {
            $(this).addClass('logged-in');
        });
    }

    $(document).on('mouseenter', '.tooltip-container', function (event) {
        $(this).find('.club-pricing-tooltip').stop(true).fadeIn();
        $(this).find('.tooltip-content').stop(true).fadeIn();
    }).on('mouseleave','.tooltip-container',  function(){
        $(this).find('.club-pricing-tooltip').stop(true, true).fadeOut();
        $(this).find('.tooltip-content').stop(true, true).fadeOut();
    });

    var $tooltips = $('.tooltip-cc'),
        tooltipConfig = {
            theme: 'tooltipster-cc',
            debug: false,
            maxWidth: (typeof pageContext !== 'undefined' && pageContext.ns === 'search') ? 164 : null,
            side: (typeof pageContext !== 'undefined' && pageContext.ns === 'search') ? ['bottom', 'top', 'right', 'left'] : ['top', 'bottom', 'right', 'left']
        }

    $tooltips.each(function (i, val) {
        var $currentTooltip = $(this);

        // check to see if HTML content is provided as a child or sibling node
        if ($currentTooltip.find('.tooltip-content').length || $currentTooltip.next('.tooltip-content').length) {

            // modify the tooltipConfig object to read the HTML content
            tooltipConfig.functionInit = function (instance, helper) {
                var content;

                // set the content accordingly
                if ($(helper.origin).find('.tooltip-content').length) {
                    content = $(helper.origin).find('.tooltip-content').removeClass('tooltip-content').detach();
                } else {
                    content = $(helper.origin).next('.tooltip-content').removeClass('tooltip-content').detach();
                }

                instance.content(content);
            }

            // Allow users to click elements inside
            tooltipConfig.interactive = true;

            // set a maxWidth - Changed per designs
            tooltipConfig.maxWidth = 242;
        }

        // and initialize tooltipster
        $currentTooltip.tooltipster(tooltipConfig);
    });

    //Jump to Top Button
    var tileDistance = $('.tiles-container').offset().top,
        jumpTop = $('.jump-top');

    $(window).off('scroll.productTile').on('scroll.productTile', function(){
        if ($(window).scrollTop() >= tileDistance) {
            jumpTop.addClass('shown');
        } else {
            jumpTop.removeClass('shown');
        }
    });

    jumpTop.on('click', function(){
        $('html, body').animate({scrollTop: 0}, 500);
    })

    $(document).on('click', '.product-tile', function () {
        var gaItem = $(this).data('gaitem');
        if (gaItem) {
            var gtmCustomDimensions = $('#search-result-items').attr('data-gtm-custom-dimensions');

            if (!(gtmCustomDimensions && gtmCustomDimensions.length)) {
                return;
            }

            var $gtmList = $('.js-last-crumb').attr('data-gtm-list');
            var customDimensions = JSON.parse(gtmCustomDimensions);
            var listName = typeof($gtmList) !== 'undefined' ? $gtmList : "Search Result";
            var obj = {
                event: 'eecEvent',
                eventCategory: 'Ecommerce',
                eventAction: 'Product Click',
                eventLabel: gaItem.name,
                eventValue: 0,
                interactionEvent: true,
                'ecommerce': {
                    'click': {
                        'actionField': {'list': listName},
                        'products': [{
                            'name': gaItem.name,                      // Name or ID is required.
                            'id': gaItem.id,
                            'price': gaItem.price,
                            'category': gaItem.category,
                            'variant': gaItem.variant,
                            'dimension10': gaItem.customizable,
                            'dimension11': gaItem.dimension11,
                            'position': gaItem.position,
                            'brand': gaItem.brand
                        }]
                    }
                },
                'eventCallback': function() {
                    document.location = gaItem.url
                }
            };
            $.extend(obj, customDimensions);
            window.dataLayer.push(obj);
        }
    });

    $(document).on('click', '.js_btn-quickview', function (e) {
        e.preventDefault();
        e.stopPropagation();
        quickview.show({
            url: $(this).attr('href'),
            source: 'quickview',
            dialogClass: 'ui-dialog-quickview js_ui-dialog-quickview'
        });
    });

    $(document)
        .trigger('custom:lazy:load:update')
        .on('custom:lazy:load:loaded', function () {
            setProductTileImageHeight(false);
        });

    mobileAltCarousel();
}

exports.mobileAltCarousel = mobileAltCarousel;
exports.setProductTileImageHeight = setProductTileImageHeight;

exports.init = function () {
    var $tiles = $('.tiles-container .product-tile');
    if ($tiles.length === 0) { return; }

    imagesLoaded('.tiles-container', function () {
        $tiles.syncHeight()
            .each(function (idx) {
                $(this).data('idx', idx);
            });
    });

    initializeEvents();
};
