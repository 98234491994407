'use strict';

function getGTMdata() {
    var result = {};
    var $mainContainer = $('.js_trade-membership-registration');
    var formsiteData = $mainContainer.attr('data-form-site');

    try {
        formsiteData = JSON.parse(formsiteData);
    } catch (e) {
        return result;
    }

    result = {
        event: formsiteData.event,
        eventCategory: formsiteData.eventCategory,
        PageType: formsiteData.PageType,
        trade_account: formsiteData.trade_account,
        email: formsiteData.email,
        loggedInStatus: formsiteData.loggedInStatus,
        login_status: formsiteData.login_status,
        membership_status: formsiteData.membership_status,
        registered_user: formsiteData.registered_user,
        user_id: formsiteData.user_id,
        membership_date: formsiteData.membership_date
    };

    return result;
}

function gtm(data) {
    if (window.dataLayer) {
        window.dataLayer.push(data);
    }
}

function initObservers() {
    var $mainContainer = $('.js_trade-membership-registration');

    if (!$mainContainer.length) {
        return;
    }

    window.onbeforeunload = function (e) {
        var isChanged = $mainContainer.attr('changed');

        if (isChanged && isChanged == 'true') {
            var gtmData = getGTMdata();
            gtmData.eventAction = 'Not completed';
            gtm(gtmData);
        }
    };

    $(window).on('message', function (e) {
        var originalEvent = e.originalEvent;

        if (!originalEvent) {
            return;
        }

        var formsiteData = $mainContainer.attr('data-form-site');

        try {
            formsiteData = JSON.parse(formsiteData);
        } catch (e) {
            return;
        }

        if (formsiteData.formsiteScriptSrc.indexOf(originalEvent.origin) !== 0) {
            return;
        }

        var obj;
        try {
            obj = JSON.parse(originalEvent.data);
        } catch (e) {
            return;
        }

        if (obj.changed == 'true') {
            $mainContainer.attr('changed', true);
        }

        if (obj.submitted == 'true') {
            $mainContainer.attr('changed', false);
            var gtmData = getGTMdata();
            gtmData.eventAction = 'Submitted';
            gtm(gtmData);
        }
    });
}

var tradeMembershipRegistration = {
    init: function() {
        initObservers();
    }
};

module.exports = tradeMembershipRegistration;
