'use strict';

var globalVars = require('../../global-vars'),
    productStickyBar = require('./productStickyBar');

/**
 * Adding a require for this file/block will create the instances
 * of the Cylindo viewer.  There is one instance for the
 * main viewer and another for the detail viewer overlay from the
 * tray on mobile.
 */
module.exports = function () {
    var $productCylindoViewer = $('#product-cylindo-viewer'),
        viewerInstance,
        isPrevMobileSize,
        isPrevDesktopSize,
        cylindoViewerConfig,
        $window,
        isQV = $('#QuickViewDialog').length,
        isFirstRotation = true;

    if (!$productCylindoViewer.length) {
        // Cylindo Viewer Placeholder not found.
        // Do not load.
        return;
    }

    $window = $(window);
    cylindoViewerConfig = $productCylindoViewer.data('cylindoconfig');

    function getThumbLocation() {
        return !isQV && $window.width() >= globalVars.breakpoints.large ? 'left' : '';
    }

    function setPrevViewportState() {
        let windowWidth = $window.width();

        isPrevDesktopSize = windowWidth >= globalVars.breakpoints.large;
        isPrevMobileSize = windowWidth < globalVars.breakpoints.large;
    }

    function startRotation() {
        if (viewerInstance && cylindoViewerConfig.isAutoRotate) {
            viewerInstance.rotate(360, 10000);
        }
    }

    function setMainImgSide(index, baseCylindoUrlPrintBase, frameArray, frameCylindoUrlPrint) {
        if(frameArray[index] != undefined) {
            $('.product-main-img-side' + index).attr('src', baseCylindoUrlPrintBase + "frames/"+ frameArray[index] +"/"+ frameCylindoUrlPrint);
        } else {
            $('.product-main-img-side' + index).hide();
        }
    }

    function stickyBarImageUpdate() {
        let currentCylindoUrl = viewerInstance.getCurrentImageUrl(60, ['https']),
            baseCylindoUrl = currentCylindoUrl.split("frames/"),
            frameCylindoUrl =  baseCylindoUrl[1].split("/"),
            finalImageUrl = baseCylindoUrl[0] + "frames/" +  cylindoViewerConfig.frames[0] +"/" + frameCylindoUrl,
            currentCylindoUrlPrint = viewerInstance.getCurrentImageUrl(550, ['https']),
            baseCylindoUrlPrint = currentCylindoUrlPrint.split("frames/"),
            frameCylindoUrlPrint =  baseCylindoUrlPrint[1].split("/"),
            frameArray = cylindoViewerConfig.rawFrames;

        $('.product-sticky-bar-image').attr('src',finalImageUrl);
        $('.product-main-img').attr('src', baseCylindoUrlPrint[0] + "frames/"+ frameArray[0] +"/"+ frameCylindoUrlPrint);
        for (let i = 1; i < 4; i++) {
            setMainImgSide(i, baseCylindoUrlPrint[0], frameArray, frameCylindoUrlPrint);
        }
        $(document).trigger('updateSetImages');
    }

    function cylindoCreate() {
        const cylindoOptions = {
            accountID: cylindoViewerConfig.accountID,
            SKU: cylindoViewerConfig.SKU,
            country:'us',
            containerID:'#product-cylindo-viewer',
            presentation:'threesixty',
            progressBar:true,
            alternateContentZoom:true,
            fullscreen:!isQV,
            imageTimeout:0,
            startFrame:1,
            styleshots:true,
            styleshotsZoom:true,
            thumbs:true,
            thumbLocation: getThumbLocation(),
            threeSixty:true,
            tooltipCarouselText:'Click to Zoom',
            tooltipStackedText:'Click to Zoom',
            zoom:!isQV,
            tooltipStackedPosition:'1',
            format:'jpg',
            zoomBackgroundColor:'FFFFFF',
            features: cylindoViewerConfig.features,
            backgroundColor:'FFFFFF',
            debug:false,
            missingCombinationErrorText:'Sorry, we do not have images for the requested feature set.',
            thumbPageSize:4,
            tooltipAltImgText:'Click to Zoom',
            tooltipDragText:'Drag to Rotate, Click to Zoom',
            tooltipStyleshotText:'Click to Zoom',
            tooltipZoomText:'Move the Mouse to Pan',
            thumbCount:3,
            title:'360 HD product view',
            maxZoom:'4k',
            frames: cylindoViewerConfig.frames,
        };

        if (typeof cylindo !== 'undefined' && cylindo) {
            cylindo.on('ready', function () {
                try {
                    viewerInstance = cylindo.viewer.create(cylindoOptions);
                    viewerInstance.on(viewerInstance.events.THUMB_CLICKED, function () {
                        $('.cylindo-drag-to-rotate-tooltip').removeClass('visually-hidden');
                    });
                    viewerInstance.on(viewerInstance.events.ALT_THUMBS_CLICKED, function () {
                        $('.cylindo-drag-to-rotate-tooltip').addClass('visually-hidden');
                    });
                    viewerInstance.on(viewerInstance.events.VIEWER_COMPLETE, function () {
                        stickyBarImageUpdate();

                        if (isFirstRotation) {
                            isFirstRotation = false;
                            startRotation();
                        }
                    });
                    viewerInstance.on(viewerInstance.events.ZOOM_ENTER, function () {
                        $('.cylindo-drag-tooltip:not(.cylindo-drag-to-rotate-tooltip)').addClass('visually-hidden');
                    });
                } catch (e) {
                    console.error('The Cylindo Viewer was not loaded successfully! Product images cannot be displayed.');
                }
            });
        }
    }

    cylindoCreate();
    setPrevViewportState();

    $window.on('delayed-resize', function () {
        if ($('.cylindo-viewer-container').hasClass('full-screen')) {
            return;
        }

        if (viewerInstance) {
            let windowWidth = $window.width(),
                cylindoFeatures = viewerInstance.getFeatures();

            if (windowWidth >= globalVars.breakpoints.large && isPrevMobileSize) {
                cylindoCreate();
                viewerInstance.setFeatures(cylindoFeatures);
            } else if (windowWidth < globalVars.breakpoints.large && isPrevDesktopSize) {
                cylindoCreate();
                viewerInstance.setFeatures(cylindoFeatures);
                productStickyBar();
            }

            setPrevViewportState();
        }
    });

    // Handle the cylindoviewer:update event
    $(document).on('cylindoviewer:update', function (e, updates) {
        let currentProductCode;

        if (viewerInstance && updates) {

            if (updates.productCode) {
                currentProductCode = viewerInstance.getProduct();
                if (currentProductCode != updates.productCode) {
                    viewerInstance.setProduct(updates.productCode);
                }
            }

            if (updates.features) {
                viewerInstance.setFeatures(updates.features);
            }

            if (updates.frames) {
                viewerInstance.update('alternateContent', updates.frames);
            }
        }
    });

    $('.product-full-screen').on('click', function () {
        $('.product-dialog').addClass('show').removeClass('m-hide');
        const cylindoTrayOptions = {
            accountID: cylindoViewerConfig.accountID,
            SKU: viewerInstance.getProduct(),
            country:'us',
            containerID: '#product-dialog-image',
            presentation:'threesixty',
            progressBar:true,
            startFrame:1,
            thumbs:false,
            threeSixty:true,
            format:'jpg',
            features: viewerInstance.getFeatures(),
            missingCombinationErrorText:'Sorry, we do not have images for the requested feature set.',
            frames: cylindoViewerConfig.frames,
        };
        cylindo.viewer.create(cylindoTrayOptions);
    });

    $('.product-dialog-close').on('click', function () {
        $('.product-dialog').removeClass('show').addClass('m-hide');
    });

    if ($window.width() < globalVars.breakpoints.large) {
        productStickyBar();
    }
};
