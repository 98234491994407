'use strict';

var globalVars = require('../global-vars');

function toggleLoading(status) {
    $('.js_stores-list-loading').toggleClass('active', status);
}

function activateLoading() {
    toggleLoading(true);
}

function deactivateLoading() {
    toggleLoading(false);
}

function findStores($form) {
    var url = $form.attr('action');
    var data = $form.serialize();

    activateLoading();
    $.ajax({
        type: 'POST',
        url: url,
        data: data,
        success: function (response) {
            $('.js_stores-list').html(response);
            deactivateLoading();
        }
    });
}

function clearFilters($filterWrappers) {
    $filterWrappers.each(function (index, item) {
        $(item).find('.input-checkbox').removeAttr('checked');
        $(item).find('.checkbox-label').removeClass('checked');
    });
}

function setSelectedCount($form) {
    $form
        .find('.js_find-store-locator-form__inputs-wrapper')
        .each(function (index, item) {
            var selectedCount = $(item).find('.input-checkbox:checked').length;
            $(item)
                .toggleClass('selected', selectedCount > 0)
                .find('.js_find-store-locator-form__inputs-label-selected')
                .html(selectedCount > 0 ? '(' + selectedCount + ')' : '');
        });
}

function checkboxChanged($form) {
    $form
        .find('.checkbox-label')
        .closest('.form-row')
        .removeClass('selected')
        .find('.checkbox-label.checked')
        .closest('.form-row')
        .addClass('selected');
}

exports.init = function () {
    if (typeof total_stores !== 'undefined') {
        $('#total-stores').text(Math.round(total_stores));
        $('.header-storelocator')
            .attr('data-results-count', total_stores)
            .attr('data-sig-stores-only', onlySignatureStores)
            .attr('data-search-type', searchType);
    }

    $(document)
        .on('formElement:clicked', '.js_find-store-locator-form .input-checkbox', function () {
            var $form = $(this).closest('.js_find-store-locator-form');
            clearFilters(
                $form.find('.js_find-store-locator-form__inputs-wrapper').not(
                    $(this).closest('.js_find-store-locator-form__inputs-wrapper')
                )
            );
            setSelectedCount($form);
            checkboxChanged($form);
            findStores($form);
        })
        .on('click', '.js_find-store-locator-form__clear-filters-link', function () {
            var $form = $(this).closest('.js_find-store-locator-form');
            clearFilters($form.find('.js_find-store-locator-form__inputs-wrapper'));
            setSelectedCount($form);
            checkboxChanged($form);
            findStores($form);
        })
        .on('click', '.js_find-store-locator-form__inputs-label', function () {
            var $wrapper = $(this).closest('.js_find-store-locator-form__inputs-wrapper');

            if ($wrapper.hasClass('active')) {
                $wrapper.removeClass('active');
            } else {
                $('.js_find-store-locator-form__inputs-wrapper').removeClass('active');
                $wrapper.addClass('active');
            }
        })
        .on('click', '.js_find-store-locator-form-title', function () {
            if (window.innerWidth >= globalVars.breakpoints.large) {
                return;
            }

            $(this).closest('.js_find-store-locator-form-wrapper').toggleClass('active');
        })
        .on('click.storelocator', function (e) {
            if ($(e.target).closest('.js_find-store-locator-form__inputs-wrapper').length) {
                return;
            }

            $('.js_find-store-locator-form__inputs-wrapper').removeClass('active');
        });
};
