'use strict';

var account = require('./account'),
    loyalty = require('./product/loyalty'),
    gtm = require('./gtm'),
    bonusProductsView = require('../bonus-products-view'),
    dialog = require('../dialog'),
    cartStoreInventory = require('../storeinventory/cart'),
    util = require('../util'),
    formPrepare = require('../pages/checkout/formPrepare'),
    CaptchaLoader = require('../blueacorn/blueacorn-captcha'),
    debounce = require('lodash.debounce'),
    ajax = require('../ajax'),
    productCarousels = require('./product/productCarousels'),
    tooltip = require('../batooltip'),
    zipEvents = require('../shipping-zip'),
    furnitureProtection = require('../furniture-protection');

function getComfortClubSavings() {
    var $savings = $('.js-order-totals-table .js-can-savings-source-value');
    return $savings.length ? Number($savings.html().replace(',', '')) : 0;
}

/**
 * @private
 * @function
 * @description calculates the total cart savings for Comfort Club members
 */
function calculateComfortClubSavings() {
    var $savings = $('.js-order-totals-table .js-can-savings-source-value');

    if ($savings.length) {
        var totalSavings = getComfortClubSavings();

        if (totalSavings > 0) {
            $('.js-savings-replace').html($('.js-can-savings-source').html());
        } else {
            $('.js-total-club-savings').hide();
        }
    }
}

function changeCCMessage() {
    var $container = $('.js_comfort-club-product__in-cart');

    if ($container.length) {
        $('.comfort-club-product__opted-in-message').show();
    } else {
        $('.js_comfort-club-product__savings-estimate').html($('.js-can-savings-source').html());
        $('.comfort-club-product__declined-message').show();
    }
}

function updateCCProductLineItem() {
    var $container = $('.js_comfort-club-product__container');

    if (!$container.length) {
        return;
    }

    $container.find('input[type="radio"]').next('.label').removeClass('checked');
    $container.find('input[type="radio"]:checked').next('.label').addClass('checked');

    changeCCMessage();
}

//init coupon functionality
function couponToggleInit() {
    //wire up click to show/hide the coupon code entry section
    $('.coupon-fake-checkbox').on('click', function () {
        $('.cart-coupon-code').toggleClass('open')
    });

    // Wire up events to resize/relocate the coupon code field's label
    $('#dwfrm_cart_couponCode').on('focusin', function () {
        $(this).parent().addClass('active');
    }).focusout(function () {
        if ($(this).val().length <= 0) {
            $(this).parent().removeClass('active');
        }
    });
}

function toggleOldCCMessages() {
    var isHideOldCCMessages = $('.js_comfort-club-product__in-cart, .js_comfort-club-product__container').length > 0 || $('.js_vip-status').length > 0;
    $('.js_special-offer').toggleClass('d-none', isHideOldCCMessages);
}

function updateMinicart(itemsQty){
    var miniCartQty = Math.floor(itemsQty);
    $('.mini-cart-link').toggleClass('mini-cart-empty', miniCartQty === 0);
    $('.minicart-quantity').html(miniCartQty);
}

function updateCartBannerSlot() {
    $.ajax({
        method: 'GET',
        url: Urls.cartBannerSlot,
        success: function (response) {
            $('.js_cart-banner-slot').html(response);
        }
    });
}

function updateApproachingDiscounts() {
    $.ajax({
        method: 'GET',
        url: Urls.cartApproachingDiscounts,
        success: function (response) {
            $('.js_approaching-discounts').html(response);
        }
    });
}

function updateCartMobileTopTotals() {
    $.ajax({
        method: 'GET',
        url: Urls.cartMobileTopTotals,
        success: function (response) {
            $('.js_cart-order-totals-top').replaceWith(response);
        }
    });
}

function updateCartOrderPromotions() {
    $.ajax({
        method: 'GET',
        url: Urls.cartOrderPromotions,
        success: function (response) {
            $('.js_cart-order-promotions-message').html(response);
        }
    });
}

function updateCart(uuid, qty){
    var $cartTableWrapper = $('#cart-table-wrapper');
    var $loader = $('#table-loading');
    var $priceContainer = $('.js-cart-order-totals');

    var oldFppIDs = [];
    $('.js_gtm-fpp-offer').each(function (index, elem) {
        oldFppIDs.push($(elem).data('fpp-pid'));
    });

    var url;
    if(typeof uuid == 'undefined' && typeof qty == 'undefined') {
        url = util.appendParamsToUrl(Urls.cartTable);
    } else {
        url = util.appendParamsToUrl(Urls.cartEditProduct, {'uuid': uuid, 'qty': qty});
        if(qty == 0) {
            var $fppLineItem = $cartTableWrapper.find('.cart-row.fpp-line-item[data-uuid="'+ uuid +'"]')
            if($fppLineItem.length && $fppLineItem.data('covereduuid')) {
                url = util.appendParamsToUrl(url, {'uuidToUncover' : $fppLineItem.data('covereduuid') });
            }
        }
    }

    $loader.addClass('loading-wrapper--show');
    ajax.load({
        url: url,
        target: $cartTableWrapper,
        callback: function (response) {
            if (response === '') {
                var $globalContainer = $('.main-pagewrap');
                ajax.load({
                    url: Urls.cartShowEmpty,
                    target: $globalContainer,
                    callback: function () {
                        $globalContainer.removeClass('pagewrap');
                        productCarousels.waitForRecommendations();
                        $loader.removeClass('loading-wrapper--show');
                    }
                });
                $('.js_cart-also-love-carousel').remove();
                updateMinicart(0);
            } else {
                updateCartBannerSlot();
                updateApproachingDiscounts();
                updateCartOrderPromotions();
                setAttributeSize();

                url = util.appendParamsToUrl(Urls.getCartPrice, {'uuid': uuid, 'qty': qty});
                ajax.load({
                    url: url,
                    target: $priceContainer,
                    callback: function () {
                        updateCartMobileTopTotals();
                        var productsAmount = $('#order-price').data('cart-qty') || 0;

                        updateMinicart(productsAmount);
                        calculateComfortClubSavings();
                        couponToggleInit();

                        if ((typeof affirm !== 'undefined') && affirm.ui && (typeof affirm.ui.refresh === 'function')) {
                            affirm.ui.refresh();
                        }

                        if ($('.js-checkout-button-main').prop('disabled')) {
                            $('.js-checkout-button-secondary').prop('disabled', 'true');
                        } else {
                            $('.js-checkout-button-secondary').removeAttr("disabled");
                        }

                        updateCCProductLineItem();
                        toggleOldCCMessages();
                        tooltip.init();
                        $loader.removeClass('loading-wrapper--show');
                        gtm.fppImpression({ isCartPage: true, oldFppIDs: oldFppIDs });
                    }
                });
            }
        }
    });
}

/**
 * @private
 * @function
 * @description finds missing profile fields for intercept modal
 */
function checkForMissingFieldsInProfile(email) {
    var url = Urls.registeredCustFields;
    return Promise.resolve($.ajax({
        url: url,
        method: 'POST',
        data: {un: email},
        dataType: 'json'
    })).then(function (data) {
        if (data.email == '' ||
            data.firstname == '' ||
            data.lastname == '' ||
            data.address1 == '' ||
            data.city == '' ||
            data.state == '' ||
            data.postal == '' ||
            data.phone == '') {
            return true;
        } else {
            return false;
        }
    });
}
/**
 * @private
 * @function
 * @description finds missing profile fields for intercept modal
 */
function findAndFillFieldsInModal(email) {
    var url = Urls.registeredCustFields;
    var customer;
    $.ajax({
        url: url,
        method: 'POST',
        data: {un: email},
        dataType: 'json',
    })
        .done(function (data) {
            customer = data;
            if (customer.email == null) {
                return false;
            } else {
                if (customer.email != '' && customer.email != null) {
                    $('input[id*="customer_email"]').val(customer.email);
                    $('input[id*="customer_email"]').parent().parent(".form-row").addClass("input-focused");
                }
                if (customer.reason != '' && customer.reason != null) {
                    $('select[id*="customer_reasonForCreatingAccount"]').val(customer.reason);
                    $('select[id*="customer_reasonForCreatingAccount"]').parent().parent(".form-row").addClass("input-focused");
                }
                if (customer.firstname != '' && customer.firstname != null) {
                    $('input[id*="customer_firstname"]').val(customer.firstname);
                    $('input[id*="customer_firstname"]').parent().parent(".form-row").addClass("input-focused");
                }
                if (customer.lastname != '' && customer.lastname != null) {
                    $('input[id*="customer_lastname"]').val(customer.lastname);
                    $('input[id*="customer_lastname"]').parent().parent(".form-row").addClass("input-focused");
                }
                if (customer.address1 != '' && customer.address1 != null) {
                    $('input[id*="requestaddress_address1"]').val(customer.address1);
                    $('input[id*="requestaddress_address1"]').parent().parent(".form-row").addClass("input-focused");
                }
                if (customer.address2 != '' && customer.address2 != null) {
                    $('input[id*="requestaddress_address2"]').val(customer.address2);
                    $('input[id*="requestaddress_address2"]').parent().parent(".form-row").addClass("input-focused");
                }
                if (customer.city != '' && customer.city != null) {
                    $('input[id*="requestaddress_city"]').val(customer.email);
                    $('input[id*="requestaddress_city"]').parent().parent(".form-row").addClass("input-focused");
                }
                if (customer.state != '' && customer.state != null) {
                    $('select[id*="customer_modalregisterstate"]').val(customer.state);
                    $('select[id*="customer_modalregisterstate"]').parent().parent(".form-row").addClass("input-focused");
                }
                if (customer.postal != '' && customer.postal != null) {
                    $('input[id*="requestaddress_postal"]').val(customer.postal);
                    $('input[id*="requestaddress_postal"]').parent().parent(".form-row").addClass("input-focused");
                }
                if (customer.phone != '' && customer.phone != null) {
                    $('input[id*="customer_storisphone"]').val(customer.phone);
                    $('input[id*="customer_storisphone"]').parent().parent(".form-row").addClass("input-focused");
                }
                if (customer.birth != '' && customer.birth != null) {
                    $('input[id*="customer_birthday"]').val(customer.birth);
                    $('input[id*="customer_birthday"]').parent().parent(".form-row").addClass("input-focused");
                }
                if (customer.anniv != '' && customer.anniv != null) {
                    $('input[id*="customer_anniversary"]').val(customer.anniv);
                    $('input[id*="customer_anniversary"]').parent().parent(".form-row").addClass("input-focused");
                }
                $('#RegistrationFormModal').valid();
                $(document).trigger('update:selects');
                return true;
            }
        });
}

/**
 * Push add or remove event to GTM
 * @param {JQuery} $container jquery container object
 * @param {string} eventName gtm event name
 * @param {Number} quantity quantity
 */
function gtmAddOrRemoveEvent($container, eventName, quantity) {
    var gtmData = $container.attr('data-gtm-data');
    var productInfo = gtmData && gtmData.length ? JSON.parse(gtmData) : {};
    if (quantity) {
        productInfo = $.extend(productInfo, { quantity: quantity });
    }
    var addOrRemoveObject = {
        add: {
            products: []
        }
    };
    addOrRemoveObject.add.products.push(productInfo);

    var gtmObj = {
        event: 'eecEvent',
        eventCategory: 'Ecommerce',
        eventAction: eventName,
        eventLabel: productInfo.name,
        eventValue: 0,
        interactionEvent: true,
        ecommerce: {}
    }

    if (eventName === 'Remove from Cart') {
        addOrRemoveObject = {
            remove: {
                products: []
            }
        };
        addOrRemoveObject.remove.products.push(productInfo);
    }
    gtmObj.ecommerce = $.extend(gtmObj.ecommerce, addOrRemoveObject);
    // gtmObj.ecommerce.add.products.push(productInfo);
    window.dataLayer.push(gtmObj);
}

function removeProduct($container) {
    var uuid = $container.data('uuid');
    updateCart(uuid, 0);
    gtmAddOrRemoveEvent($container, 'Remove from Cart', parseInt($container.find('input[name*="_quantity"]').val()));
    SmarterHQDataPush();
}

function ccProductAdded(e, data) {
    var $response = $('<div/>');

    if (data && data.response) {
        $response = $(data.response)
    }

    var pid = 'Comfort_Club';
    var $miniCartProductLineItemContainer = $response.find('.js_mini-cart-product[data-pid=' + pid + ']');
    var uuid = $miniCartProductLineItemContainer.data('uuid');
    var $container = $('.js_comfort-club-product__container');
    $container.data('pid', pid).data('uuid', uuid);

    updateCart(uuid, 1);
    gtmAddOrRemoveEvent($container, 'Add to Cart', 1);
}

function notagreeAddCCSelected() {
    var $CCbuttons = $('input[name="CCbutton"]');
    if (!$CCbuttons.length) {
        return;
    }

    $CCbuttons.prop('checked', false).next('.js_add-cc-type-radio-label').removeClass('checked');
    $('.notagreeAddCC-type-radio').prop('checked', true).next('.js_add-cc-type-radio-label').addClass('checked');
}

function agreeAddCCSelected() {
    var $CCbuttons = $('input[name="CCbutton"]');
    if (!$CCbuttons.length) {
        return;
    }

    $CCbuttons.prop('checked', false).next('.js_add-cc-type-radio-label').removeClass('checked');
    $('.agreeAddCC-type-radio').prop('checked', true).next('.js_add-cc-type-radio-label').addClass('checked');
}

function notagreeAddCC() {
    if (!$('.js_comfort-club-product__in-cart').length) {
        return;
    }

    notagreeAddCCSelected();

    var $container = $('.js_comfort-club-product__container');

    dialog.open({
        url: Urls.comfortClubRemoveDialog,
        options: {
            open: function () {
                if ($('.main-pagewrap').hasClass('registered-user')) {
                    $('.js_remove-loyalty-pli-dialog').find('.remove-loyalty__signin').remove();
                }

                var totalSavings = getComfortClubSavings();
                if (totalSavings > 200) {
                    $('.js_remove-loyalty-save').html($('.js-can-savings-source').html());
                } else {
                    $('.js_remove-loyalty__main-title').hide();
                    $('.js_remove-loyalty__zero-title').show();
                }

                $('.remove-loyalty__agree').on('click', function () {
                    // change remove behavior
                    dialog.close();
                    removeProduct($container);
                });

                $('.remove-loyalty__cancel, .js_remove-loyalty-pli-dialog .ui-dialog-titlebar-close').on('click', function (e) {
                    e.preventDefault();
                    agreeAddCCSelected();
                    dialog.close();
                });
            },
            dialogClass: 'remove-loyalty-pli-dialog js_remove-loyalty-pli-dialog'
        }
    });
}

function agreeAddCC() {
    if ($('.js_comfort-club-product__in-cart').length) {
        return;
    }

    addCCItem();
    agreeAddCCSelected();
}

function initCCEvents() {
    $('body').on('click', 'input[name="CCbutton"], .js_add-cc-type-radio-label', function(e) {
        e.preventDefault();
        if ($(this).closest('.field').hasClass('remove-js-loyalty')) {
            notagreeAddCC();
        } else {
            agreeAddCC();
        }
    });
}

function setAttributeSize() {
    $('.js_attributes-wrapper').each(function () {
        var $labels = $(this).find('.label');
        $labels
            .css({
                'min-width': '0'
            })
            .closest('div')
            .css({
                'display': 'block'
            });

        var width = 0;
        var marginRight = 10;
        $labels.each(function () {
            var newWidth = $(this).width();
            if (newWidth > width) {
                width = newWidth;
            }
        });

        $labels
            .css({
                'min-width': width + marginRight + 'px'
            })
            .closest('div')
            .css({
                'display': 'flex'
            });
    });
}

function initLoyaltyItemCheckDialog() {
    if ($('#loyaltyItemCheck').length){
        dialog.open({
            url: Urls.cartLoyaltyErrorModal,
            options: {
                open: function () {
                    var $dialog = $(this);

                    var $container = $('#loyaltyItemCheck');
                    $('.js_loyalty-dialog__expiration-date').html($container.data('expiration-date'));

                    $dialog.dialog('option', 'position', { my: 'center', at: 'center', of: window });

                    $(document).one('click', '.ui-widget-overlay, .js_close, .js_loyalty-dialog__btn-close', function () {
                        $dialog.dialog('close');
                    });
                },
                dialogClass: 'loyalty-ui-dialog'
            }
        });
    }
}

function checkAutoFill() {
    var hasValue = $(".checkoutlogin input[name*='_login_username']").val().length > 0;//Normal
    if(!hasValue) {
        hasValue = $(".checkoutlogin input[name*='_login_username']:-webkit-autofill").length > 0;//Chrome
    }
    if (hasValue) {
        $(".checkoutlogin input[name*='_login_username']").parent().parent().addClass('input-focused');
        $(".checkoutlogin input[name*='_login_password']").parent().parent().addClass('input-focused');
        clearInterval(window.chromeCheck);
    }
}

function addCCItem() {
    loyalty.addLoyaltyProduct();
}

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
    initLoyaltyItemCheckDialog();
    SmarterHQDataPush();
    zipEvents.init();

    $('body').on('click', '.checkoutlogin #dwfrm_login_login', function(e) {
        e.preventDefault();
        var email = $('input[id*="login_username"]').val();
        var password = $('input[id*="login_password"]').val();
        var rememberme = $('input[id*="login_rememberme"]').val();
        checkForMissingFieldsInProfile(email).then(function(data) {
            var thereAreMissingFields = data;

            if (thereAreMissingFields == true) {
                dialog.open({
                    url: Urls.registeredFixAccount,
                    options: {
                        dialogClass: 'registered-intercept-modal',
                        title: Resources.REGISTERED_INTERCEPT_HEADING
                    },
                    data: {
                        un: email,
                        pw: password,
                        rm: rememberme
                    },
                    callback: function (data) {
                        new CaptchaLoader();
                        formPrepare.init({
                            formSelector: 'form[id$="RegistrationFormModal"]',
                            continueSelector: 'none'
                        });
                        var pastDate = new Date();
                        pastDate = pastDate.getFullYear() - 130;

                        $('input.datepicker').datepicker({
                            autoclose: true,
                            changeYear: true,
                            changeMonth: true,
                            dateFormat: 'mm/dd/yy',
                            yearRange: pastDate +':2019',
                            onChangeMonthYear: function(year, month, inst){
                                $(this).datepicker( "setDate", month + '/1/' + year );
                            },
                            onSelect: function(selectedDate) {
                                $('#ui-datepicker-div table td a').attr('href', 'javascript:;');
                                $('#ui-datepicker-div').hide();
                                $(this).next('input.datepicker').focus();
                                $(this).val(selectedDate);
                                //$(this).valid();
                            }
                        });
                        findAndFillFieldsInModal(email);

                        $('#RegistrationFormModal').on('submit', function(e) {
                            var captcha = $('[name$="_captcha"]').val(),
                                $captchaWrapper = $('.data-captcha-box');
                            if (!$(this).valid() || captcha == '') {
                                e.preventDefault();
                                $captchaWrapper.find('.error').remove();
                                $('.registration-modal-header').hide();
                                $('.error-header').show();
                                if (captcha == '') {
                                    $captchaWrapper.find('.data-captcha').append('<span class="error">' + Resources.VALIDATE_REQUIRED + '</span>');
                                }
                            }
                        }).on('click', '.button[name$="cancel"]', function(e) {
                            e.preventDefault();
                            dialog.close();
                        });
                    },
                    close: function() {
                        window.location.assign(Urls.cartShow);
                    }
                });
                $(window).on('resize', debounce(function() {
                    dialog.$container.dialog("option", "position", { my: "center", at: "center", of: window });
                }, 150));
            } else {
                $('#dwfrm_login')
                    .attr('action',Urls.loginFromIntercept)
                    .trigger('submit');
            }
        });
    });

    $('body').on('submit', '#dwfrm_login', function (e) {
        e.preventDefault();
        if (!$(this).valid()) {
            return false;
        }
        var url = util.appendParamToURL($(this).attr('action'), 'format', 'ajax');
        var applyName = $(this).find('#dwfrm_login_login').attr('name');
        var options = {
            url: url,
            data: $(this).serialize() + '&' + applyName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            if (data.success && window.dataLayer) {
                window.dataLayer.push({
                    event: 'gtmEvent',
                    eventCategory: 'engagement',
                    eventAction: 'account',
                    eventLabel: 'login success',
                    interactionEvent: false
                });
            }
            window.location.href = (data.redirectUrl);
        });
    });

    $(document)
        .on('click', '.bonus-item-actions a, .js_bonusproducts a', function(e) {
            e.preventDefault();
            bonusProductsView.show(this.href);
        })
        .on('click', '.ui-widget-overlay', function() {
            bonusProductsView.close();
        });

    // override enter key for coupon code entry
    $('form input[name$="_couponCode"]').on('keydown', function (e) {
        if (e.which === 13 && $(this).val().length === 0) { return false; }
    });

    //AJAX call for remove product from cart
    $('.cart-items-form-wrapper').on('click', 'button[name$="deleteProduct"]', function(e) {
        if ($(this).hasClass('js_remove-loyalty-pli')) {
            return;
        }

        e.preventDefault();
        var $container = $(this).closest('.cart-row');
        removeProduct($container);
    });

    //AJAX call for remove product from cart
    $('.cart-items-form-wrapper').on('click', 'button[name$="deleteGiftCertificate"]', function(e) {
        e.preventDefault();
        var $container = $(this).closest('.rowgiftcert');
        var uuid = $container.data('uuid');
        updateCart(uuid, 0);
        gtmAddOrRemoveEvent($container, 'Remove from Cart', 1);
        SmarterHQDataPush();
    });

    //AJAX call for remove Comfort Club product from cart
    $('.cart-items-form-wrapper').on('click', '[name$="notagreeCCbutton_done"]', function(e) {
        e.preventDefault();
        var $container = $(this).closest('.js_comfort-club-product__container');
        removeProduct($container);
    });

    //AJAX call for remove loyalty product from cart
    $('.cart-items-form-wrapper').on('click', '.js_remove-loyalty-pli', function(e) {
        e.preventDefault();
        var $loyaltyRemoveBtn = $(this);
        var $container = $loyaltyRemoveBtn.closest('.cart-row');

        dialog.open({
            url: Urls.comfortClubRemoveDialog,
            options: {
                open: function () {
                    if ($('.main-pagewrap').hasClass('registered-user')) {
                        $('.js_remove-loyalty-pli-dialog').find('.remove-loyalty__signin').remove();
                    }

                    var totalSavings = getComfortClubSavings();
                    if (totalSavings > 200) {
                        $('.js_remove-loyalty-save').html($('.js-can-savings-source').html());
                    } else {
                        $('.js_remove-loyalty__main-title').hide();
                        $('.js_remove-loyalty__zero-title').show();
                    }

                    $('.remove-loyalty__agree').on('click', function () {
                        dialog.close();
                        removeProduct($container);
                    });

                    $('.remove-loyalty__cancel, .js_remove-loyalty-pli-dialog .ui-dialog-titlebar-close').on('click', function (e) {
                        e.preventDefault();
                        dialog.close();
                    });
                },
                dialogClass: 'remove-loyalty-pli-dialog js_remove-loyalty-pli-dialog'
            }
        });
    });

    //AJAX call for quantity selectors
    $('.cart-items-form-wrapper').on('click', '.js_custom-iterator span', function () {
        var input = $(this).siblings('input');
        var qty = parseInt(input.val());
        var $container = $(this).closest('.cart-row');
        var uuid = $container.data('uuid');
        if ($(this).hasClass('minus')) {
            input.val(qty-1);
            gtmAddOrRemoveEvent($container, 'Remove from Cart', 1);
        } else {
            input.val(qty+1);
            gtmAddOrRemoveEvent($container, 'Add to Cart', 1);
        }
        qty = parseInt(input.val());
        updateCart(uuid, qty);
    })

    //AJAX call for adding to whishlist
    $('.cart-items-form-wrapper').on('click', '.add-to-wishlist', function (e) {
        var isAuthenticated = $('.main-pagewrap').hasClass('authenticated');
        e.preventDefault();
        var $container = $(this).closest('.cart-row');
        var pid = $container.data('pid');
        var qty = $container.find('.js_custom-iterator input').val();
        var url;
        var customProductEnding = '-CUSTOM';
        if (pid.indexOf(customProductEnding) === pid.length - customProductEnding.length) {  // IE-safe endsWith
            // Custom product
            var uuid = $container.data('uuid');
            url = util.appendParamsToUrl(Urls.addToWishlist, {'uuid': uuid, 'qty': qty});
        } else {
            url = util.appendParamsToUrl(Urls.addToWishlist, {'pid': pid, 'qty': qty});
        }

        if (isAuthenticated) {
            $.ajax({
                method: 'GET',
                url: url,
                success: function(){
                    $container.find('.in-wishlist, .add-to-wishlist').toggleClass('d-none');
                }
            });
        } else {
            // The source is added to be able to redirect back to the cart page after
            // logging in and adding the product to the wishtlist.
            url = util.appendParamsToUrl(url, {'source': 'cart'});
            window.location = url;
        }
    });

    //AJAX call for adding coupons
    $('.js-cart-order-totals').on('click', '.cart-coupon-code #add-coupon', function (e) {
        e.preventDefault();
        var couponCode = $(this).prev().find('input').val();
        var $couponWrapper = $('.cart-coupon-code');
        if (couponCode) {
            var $loader = $('#table-loading');
            var url = util.appendParamToURL(Urls.addCouponAJAX, 'cc', couponCode);
            $.ajax({
                type: 'GET',
                contentType: 'application/json',
                url: url,
                success: function (response) {
                    var productsAmount = $('#order-price').data('cart-qty') || 0;
                    if ($('.cart-coupon-code .error').length > 0) {
                        $('.cart-coupon-code .error').remove();
                    }
                    if (response.status) {
                        $('<div/>', {
                            "class": 'error',
                            html: response.status
                        }).appendTo($('.cart-coupon-code'));
                    } else {
                        $loader.addClass('loading-wrapper--show');
                        var $priceContainer = $('.js-cart-order-totals');
                        var $cartTableWrapper = $('#cart-table-wrapper');
                        $cartTableWrapper.html(response);
                        updateMinicart(productsAmount);
                        ajax.load({
                            url: Urls.getCartPrice,
                            target: $priceContainer,
                            callback: function () {
                                $loader.removeClass('loading-wrapper--show');
                                couponToggleInit();
                                tooltip.init();
                                calculateComfortClubSavings();
                                updateCCProductLineItem();
                                toggleOldCCMessages();
                                if ((typeof affirm !== 'undefined') && affirm.ui && (typeof affirm.ui.refresh === 'function')) {
                                    affirm.ui.refresh();
                                }

                                if ($('body').find('.coupon-status') && $('body').find('.coupon-status').data('couponstatus') ==='NO_APPLICABLE_PROMOTION') {
                                    $('<div/>', {
                                        "class": 'error',
                                        html: Resources.NO_APPLICABLE_PROMOTION
                                    }).appendTo($('.cart-coupon-code'));
                                } else {
                                    $('<div/>', {
                                        "class": 'success',
                                        html: Resources.COUPON_APPLIED
                                    }).appendTo($('.cart-coupon-code'));
                                }
                            }
                        });
                    }
                }
            });
        } else {
            if ($('.cart-coupon-code .error').length > 0) {
                $('.cart-coupon-code .error').remove();
            }
            $('<div/>', {
                "class": 'error',
                html: Resources.COUPON_CODE_MISSING
            }).appendTo($couponWrapper);
        }
    });

    //AJAX call for removing coupons
    $('.cart-items-form-wrapper').on('click', '.rowcoupons button', function (e) {
        e.preventDefault();
        var couponCode = $.trim($(this).closest('.rowcoupons').find('.cartcoupon .value').text());
        var $loader = $('#table-loading');
        var $priceContainer = $('.js-cart-order-totals');
        var $cartTableWrapper = $('#cart-table-wrapper');
        var url = util.appendParamToURL(Urls.removeCouponAJAX, 'cc', couponCode);
        $loader.addClass('loading-wrapper--show');
        ajax.load({
            url: url,
            target: $cartTableWrapper,
            callback: function () {
                ajax.load({
                    url: Urls.getCartPrice,
                    target: $priceContainer,
                    callback: function () {
                        $loader.removeClass('loading-wrapper--show');
                        couponToggleInit();
                        if ((typeof affirm !== 'undefined') && affirm.ui && (typeof affirm.ui.refresh === 'function')) {
                            affirm.ui.refresh();
                        }
                        calculateComfortClubSavings();
                        updateCCProductLineItem();
                        toggleOldCCMessages();
                        tooltip.init();
                    }
                });
            }
        });
    });

    $('body').on('click', '.js-add-cc-to-cart', function(e) {
        e.preventDefault();
        addCCItem();
    });

    $('.digital-buy-accordion').accordion({
        active: false,
        collapsible: true,
        header: '.accordion-title',
        heightStyle: 'content',
        icons: false
    });

    // open the digital buy modal when the user selects a financing option
    $('.dbuyform').on('click', '.input-radio', function () {
        $(this).closest('.dbuyform').addClass('active').find('.modal-button').trigger('click');
    });

    furnitureProtection.init();

    // synchrony digital buy modal close event
    window.addEventListener("message", function (event) {
        if (typeof event.data == 'string' && (event.data === 'Close Model' || event.data === 'Return To Merchant Shipping')) {
            $('.dbuyform.active').attr('action', Urls.consumerInfoModalResponse).attr('target', '').trigger('submit');
        }
    });

    // Sticky order totals for IE
    if (typeof Stickyfill !== 'undefined') {
        Stickyfill.add($('.cart-order-totals'));
    }

    //replace CC upsell content $ placeholder with value saved by CC member
    calculateComfortClubSavings();
    updateCCProductLineItem();

    initCCEvents();
    couponToggleInit();

    window.onload = function() {
        if ($('.checkoutlogin input[name*="_login_username"]').length > 0) {
            window.chromeCheck = window.setInterval(checkAutoFill, 333);
        }
    }

    $(window).on('loadedRecommendations', function (e) {
        initGTMTile();
    });

    setAttributeSize();
    $(window).on('delayed-resize', function () {
        setAttributeSize();
    });

    $(document)
        .on('custom:update:cart', function (e, data) {
            updateCart(data.uuid, data.qty);
        })
        .on('custom:cancel:cc:description:dialog', notagreeAddCCSelected)
        .on('custom:cc:product:added', ccProductAdded);

    $(document).on('click', '.js_wishlist-link', function () {
        updateCart($(this).data('lineitemuuid'), 0);
    });
}


/**
 * Creates product tiles events for GTM
 */
 function initGTMTile() {

    // Product tile impression event
    var listName = "Recommended products";
    var customDimensions = JSON.parse($('.js-gtm-data-source').attr('data-gtm-custom-dimensions'));
    var obj = {
        event: 'eecEvent',
        eventCategory: 'Ecommerce',
        eventAction: 'Product Impressions',
        eventLabel: 'None',
        eventValue: 0,
        interactionEvent: false,
        ecommerce : {
            currencyCode: $('.js-gtm-data-source').attr('data-gtm-currency'),
            impressions: []
        }
    };
    $.extend(obj, customDimensions);
    $('.product-tile').each(function () {
        var $impressionData = $(this).data('gaitem');
        if (typeof $impressionData === 'undefined' || $impressionData === null) {
            return true;
        }
        obj.ecommerce.impressions.push({
            'name': $impressionData.name,                      // Name or ID is required.
            'id': $impressionData.id,
            'price': $impressionData.price,
            'category': $impressionData.category,
            'variant': $impressionData.variant,
            'dimension10': $impressionData.customizable,
            'dimension11': $impressionData.dimension11,
            'position': $impressionData.position,
            'brand': $impressionData.brand,
            'list':  listName
        });
    });

    if (obj.ecommerce.impressions.length > 0) {
        window.dataLayer.push(obj);
    }

    // Product tile click event
    $(document).on('click', '.product-tile', function () {
        var gaItem = $(this).data('gaitem');
        if (gaItem) {
            var obj = {
                event: 'eecEvent',
                eventCategory: 'Ecommerce',
                eventAction: 'Product Click',
                eventLabel: gaItem.name,
                eventValue: 0,
                interactionEvent: true,
                'ecommerce': {
                    'click': {
                        'actionField': {'list': listName},
                        'products': [{
                            'name': gaItem.name,                      // Name or ID is required.
                            'id': gaItem.id,
                            'price': gaItem.price,
                            'category': gaItem.category,
                            'variant': gaItem.variant,
                            'dimension10': gaItem.customizable,
                            'dimension11': gaItem.dimension11,
                            'position': gaItem.position,
                            'brand': gaItem.brand
                        }]
                    }
                },
                'eventCallback': function() {
                    document.location = gaItem.url
                }
            };
            $.extend(obj, customDimensions);
            window.dataLayer.push(obj);
        };
    });
    $(document).on('click', '.product-listing button.add-to-cart', function(){
        let gtmData = $(this).closest('.product-tile').data('gaitem');
        let $form = $(this).closest('form');
        let gtmObj = {
            event: 'eecEvent',
            eventCategory: 'Ecommerce',
            eventAction: 'Add to Cart',
            eventLabel: gtmData.name.toUpperCase(),
            eventValue: 0,
            interactionEvent: true,
            'ecommerce': {
                'currencyCode': $form.find('input[name="currency"]').val(),
                'add': {
                    'products': []
                }
            }
        }
        gtmObj.ecommerce.add.products.push(gtmData);
        window.dataLayer.push(gtmObj);
    });
}


/**
 * Add info for SmarterHQ in dataLayer
 */
 function SmarterHQDataPush() {
    // Product tile impression event
    var $cartProductImages = $('#cart-table .item-image');
    if ($cartProductImages.length) {
        let dataObj = {
            'event': 'smarterHQevent',
            'PageType': 'BasketPage',
            'smarterHQData': {
                'products': []
            }
        };

        $cartProductImages.each(function() {
            const productURL = $(this).find('a').prop('href');
            const productImage = $(this).find('img').prop('src');
            let productObj = {
                'imageurl': productImage,
                'linkurl': productURL
            }
            dataObj.smarterHQData.products.push(productObj);
        })

        window.dataLayer.push(dataObj);
    }
}

exports.calculateComfortClubSavings = calculateComfortClubSavings;
exports.initLoyaltyItemCheckDialog = initLoyaltyItemCheckDialog;
exports.updateCart = updateCart;

exports.init = function () {
    initializeEvents();
    if (SitePreferences.STORE_PICKUP) {
        cartStoreInventory.init();
    }
    account.initCartLogin();
    gtm.fppImpression({ isCartPage: true });
};
