'use strict';

var cart = require('./cart');
var util = require('../util');

function initGTM() {
    if (window.dataLayer) {
        if ($('.js_customer-just-created').length) {
            window.dataLayer.push({
                event: 'gtmEvent',
                eventCategory: 'engagement',
                eventAction: 'account',
                eventLabel: 'account creation success',
                interactionEvent: false
            });

        }

        var $container = $('.js_customer-subscribed');
        if ($container.length) {
            window.dataLayer.push({
                event: 'gtmEvent',
                eventCategory: 'engagement',
                eventAction: 'email signup',
                eventLabel: 'order confirmation',
                interactionEvent: false,
                email: $container.data('customer-email')
            });
        }
    }
}

$(document).on('ready', function () {
    createFindMineTag();
    initGTM();
});

function createFindMineTag() {
    var fmSessionID = util.getCookie('_fmSession');
    var $fmContainer = $('.js-findmine-replace');
    var fmURL = $fmContainer.data('fmurl');
    if (fmSessionID && fmURL) {
        var newURL = fmURL + '&fm_session_id=' + fmSessionID
        var $fmTag = $(
            '<img class="confirmation-find-mine-img" alt="Find Mine" src="' +
            newURL +'"></img>'
        );
        $fmContainer.replaceWith($fmTag);
    }
}

cart.calculateComfortClubSavings();