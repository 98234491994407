'use strict';

var globalVars = {
  breakpoints: {
    min: 320,
    small: 576,
    medium: 768,
    large: 1024,
    xl: 1200,
    xxl: 1360
  }
}

module.exports = globalVars;