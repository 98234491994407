'use strict';

var address = require('./address'),
    billing = require('./billing'),
    dialog = require('../../dialog'),
    multiship = require('./multiship'),
    shipping = require('./shipping'),
    cart = require('../cart'),
    formPrepare = require('./formPrepare'),
    util = require('../../util'),
    zipEvents = require('../../shipping-zip'),
    settings = {
        'keyCodes': {
            'SPACE': 32,
            'ENTER': 13,
            'TAB': 9,
            'DOWN': 40,
            'UP': 38,
            'ESCAPE': 27
        }
    };

function submitModal(url) {
    var $csrf = $('input[name="csrf_token"]');
    var input = $csrf.length > 0 ? $csrf.get(0).outerHTML : '';
    var form = $('<form action="' + url + '" method="post">' + input + '</form>');
    $('body').append(form);
    form.trigger('submit');
}

/**
 * @function checkoutErrorFocus provides enhanced error focusing for checkout
 */
function checkoutErrorFocus() {
    // watch for click event on disabled button

    /* continue to billing on shipping step */
    var continueToBillingWrapper = document.querySelector('.continue-to-billing-wrapper');

    if (continueToBillingWrapper) {
        continueToBillingWrapper.addEventListener('click', function(e) {
            var continueToBilling = document.querySelector('.continue-to-billing');

            if (continueToBilling.disabled) {
                $('.address').validate().form();

                //check for errors after the validation
                var errors = document.querySelectorAll('.error');

                if (errors.length) {
                    errors[0].scrollIntoView();
                }
            }
        });
    }

    /* continue to review on billing step */
    var continueToReviewWrapper = document.querySelector('.continue-to-review-wrapper');

    if (continueToReviewWrapper) {
        continueToReviewWrapper.addEventListener('click', function(e) {
            var continueToReview = document.querySelector('.continue-to-review');

            if (continueToReview.disabled) {
                $('.address').validate().form();

                //check for errors after the validation
                var errors = document.querySelectorAll('.error');

                if (errors.length) {
                    errors[0].scrollIntoView();
                } else {
                    continueToReview.prop('disabled', false);
                }
            }
        });
    }

    /* highlight errors if scroll past zip */
    var zip = document.querySelector('.postal');
    var zipRect = zip ? zip.getBoundingClientRect() : null;

    if (zipRect) {
        window.addEventListener('scroll', function(e) {
            if (window.scrollY > (zipRect.y + zipRect.height)) {
                // if on billing step do no validate the whole form
                // only validate address
                if ($('form[id*=_billing]:visible').length) {
                    var billingAddressFields = $('[id*="_billing"] [id*="_addressFields_"].required');

                    for (var i = 0; i < billingAddressFields.length; ++i) {
                        $('.address').validate().element(billingAddressFields[i]);
                    }
                } else {
                    $('.address').validate().form();
                }
            }
        });
    }
}

/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
exports.init = function () {
    address.init();
    zipEvents.init();
    if ($('.checkout-shipping').length > 0) {
        shipping.init();
        onCheckout(1);
    } else if ($('.checkout-multi-shipping').length > 0) {
        multiship.init();
        onCheckout(1);
    } else if ($('.continue-to-review').length > 0) {
        billing.init();
        onCheckout(2);
    }

    $('body').on('click', '.submit-order-button', function () {
        var isMembershipPurchase = $(this).attr('data-membership-purchase') === 'true';
        if (isMembershipPurchase) {
            window.dataLayer.push({
                event: 'membership_purchased'
            });
        }
    });

    cart.initLoyaltyItemCheckDialog();
    checkoutErrorFocus();

    // synchrony digital buy modal close event
    if ($('#dbuybilling').length > 0) {
        window.addEventListener("message", function (event) {
            if (typeof event.data == 'string' && (event.data == 'Close Model' || event.data == 'Return To Merchant Shipping')) {
                $('#dwfrm_billing').attr('action', Urls.billingModalResponse).attr('target', '').submit();
            }
        });
    } else if ($('#dbuycart').length > 0) {
        window.addEventListener("message", function (event) {
            if (typeof event.data == 'string' && event.data == 'Close Model') {
                submitModal(Urls.orderCompletionModalResponse);
            }
        });
    }

    // Sticky order totals for IE
    if (typeof Stickyfill !== 'undefined') {
        Stickyfill.add($('#secondary.nav'));
    }

    //if on the order review page and there are products that are not available diable the submit order button
    cart.calculateComfortClubSavings();

    // order review page
    if ($('.order-summary-footer').length > 0) {
        //MGBW-1033 set step to 3
        onCheckout(3);
        // disable the submit order button if there are products that are not available
        if ($('.notavailable').length > 0) {
            $('.order-summary-footer .submit-order-button').attr('disabled', 'disabled');
        }

        // handle express checkout cvn entry
        if ($('.express-checkout').length > 0) {
            var $cvnForm = $('#express-checkout-cvn');
            var $submitOrderForm = $('form.submit-order');

            formPrepare.init({
                formSelector: '#express-checkout-cvn',
                continueSelector: '.submit-order-button'
            });

            $submitOrderForm.on('submit', function() {
                var cvn = $cvnForm.find('input').val();
                $('input[name$="_cvn"]').val(cvn);
            });

            $cvnForm.on('submit', function(e) {
                e.preventDefault();
                $submitOrderForm.find('.submit-order-button').trigger('click');
            });
        }

        $(document).on('submit', 'form.submit-order', function () {
            $(".submit-order-button").attr('disabled', 'disabled');
        });

        var $stickyPlaceOrder = $('.order-summary-footer.fixed'),
            $stickyButtonForm = $stickyPlaceOrder.find('form'),
            $mobilePlaceOrder = $('.order-summary-footer.mobile-only:not(.fixed)'),
            $totalsPlaceOrder = $('#secondary .order-summary-footer');
        if ($stickyPlaceOrder.length > 0 && $mobilePlaceOrder.length > 0 && $totalsPlaceOrder.length > 0) {
            $(window).on('scroll', function() {
                if ($(window).width() < 768) {
                    var topButtonVisible = util.elementInViewport($totalsPlaceOrder.get(0)),
                        bottomButtonVisible = util.elementInViewport($mobilePlaceOrder.get(0));
                    if ($stickyPlaceOrder.hasClass('hidden') && !topButtonVisible && !bottomButtonVisible) {
                        $stickyPlaceOrder.removeClass('hidden');
                        $stickyButtonForm.slideDown();
                    } else if (topButtonVisible || bottomButtonVisible) {
                        $stickyPlaceOrder.addClass('hidden');
                        $stickyButtonForm.slideUp();
                    }
                }
            });
        }
    }

    $('body').on('change custom:blur', 'select[name$="state"]', function () {
        var $form = $(this).closest('form');
        $form.find('input[name$="_postal"]').focus();
    });

    // Custom behavior of switching checkout inputs on pressing the Tab button
    $('body').on('keydown', '.ba-options', function (e) {
        var keyCode = e.keyCode || e.which;

        if (keyCode === settings.keyCodes.TAB) {
            e.preventDefault();

            var $form = $(this).closest('form');
            $('.ba-select-close').trigger('click');

            if (e.shiftKey) {
                $form.find('input[name$="_city"]').focus();
            }
        }
    });

    $('body').on('change', 'input[name$="address1"]', function () {
        $('input[name$="_postal"]').focus();
    });

    $('body').on('click', '.address-item .field', function () {
        var aid = $('.address-item .radio-label.checked').parents('.address-item').find('input').val();
        $(".shipping-address-select").val(aid).change();
    });

    $('body').on('click', '.add-new-address', function (e) {
        e.preventDefault();
        $('.clear-form-button').trigger('click');

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $('.address-new-form').addClass('d-none');
            if ($('.address-item .radio-label.checked').length) {
                $('.continue-to-billing').removeAttr('disabled');
                $('input[name$="_shippingAddress_save"]').removeAttr('disabled');
                $('.address-item .radio-label.checked').parents('.address-item').find('input').trigger('click');
            }
        } else {
            $(this).addClass('active');
            $('.address-new-form').removeClass('d-none');
            if (!$('.add-to-address-book').find('label').hasClass('checked')) {
                $('.add-to-address-book').find('label').trigger('click');
            }
        }
        $(document).trigger('update:selects');
    });

    $('body').on('keydown', 'input[name$="city"]', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === settings.keyCodes.TAB && !e.shiftKey) {
            e.preventDefault();
            $('.state-row').find('.ba-options').trigger('click');
        }
    });

    $('body').on('change', 'input[name$="address1"]', function () {
        $(this).focus();
    });

};
