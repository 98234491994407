'use strict';

var productStickyBar = function () {
    var requestFame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame || window.oRequestAnimationFrame,
    $window = $(window),
    lastScrollTop = $window.scrollTop();

    if (requestFame) {
        loop();
    }

    function loop() {
        const scrollTop = $window.scrollTop();
        if (lastScrollTop !== scrollTop) {
            lastScrollTop = scrollTop;
            scroll();
        }
        requestFame(loop);
    }
    var scroll = function () {
        const $tray = $('.product-sticky-bar');
        const $footerContainer = $('.footer-container-outer');
        const $productDetailImg = $('.product-detail-img');
        if (!($tray.length && $footerContainer.length && $productDetailImg.length)) {
            return;
        }

        const footerTop = $footerContainer.offset().top;
        const productdetailimg = $productDetailImg.offset().top;
        const footerhight = $footerContainer.height();
        const footerfinallytop = footerTop - (footerhight + 70);
        const win = $window.scrollTop();

        if (win <= footerfinallytop && win >= productdetailimg) {
            $tray.removeClass('m-hide');
        } else {
            $tray.addClass('m-hide');
        }
    }
}

module.exports = function () {
    productStickyBar();
}
