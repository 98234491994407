/**
 * @package     Blueacorn/forms
 * @version     2.0
 * @author      Blue Acorn <code@blueacorn.com>
 * @copyright   Copyright © 2016 Blue Acorn.
 */

(function (root, factory) {
    if (typeof exports === 'object') {
        // CommonJS
        module.exports = factory(window.jQuery, require('./blueacorn-core'));
    } else if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery', 'baCore'], function (jquery, ba) {
            return (factory(jquery, ba));
        });
    } else {
        // Global Variables
        root.ba.header = factory(root.jQuery, root.ba);
    }

}(this, function ($, ba) {
    'use strict';

    var forms = {

        settings: {
            inputWhiteList: 'input.input-text, textarea.input-textarea'
        },

        init: function (options) {

            // Overrides the default settings
            ba.overrideSettings(this.settings, options);

            // Start the debugger
            ba.setupDebugging(this.settings);

            this.initObservers();
            this.activateFormsOnLoad();
        },

        initObservers: function() {
            var self = this;

            $(self.settings.inputWhiteList).on('focus', self.activateFocusFire);
            $(self.settings.inputWhiteList).on('blur', self.releaseFocus);
        },

        activateFormsOnLoad: function() {
            $(this.settings.inputWhiteList).each(function(){
                var $this = $(this);
                if ($this.val() !== ''){
                    $this.parent().parent('.form-row').addClass('input-focused');
                }
            });
        },

        activateFocusFire: function() {
            $(this).parent().parent('.form-row').addClass('input-focused');
        },

        releaseFocus: function() {
            var $this = $(this);
            if (!$this.hasClass('datepicker') && $.trim($this.val()).length === 0){
                $this.parent().parent('.form-row').removeClass('input-focused');
            }
        }

    };

    $(document).on('baCoreReady dialogopen update:form:inputs', function() {
        /**
         * The parameter object is optional.
         * Must be an object.
         */
        forms.init();
    });

    return forms;
}));
