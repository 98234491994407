'use strict';

const util = require('./util');

/**
 * @function detectCreditCard
 * @description Detect credit card number, change card type for form and display appropriate card image
 * @param {jQuery Object} $card number input element
 */
 function detectCreditCard($card) {

    const creditCard = $card.validateCreditCard();
    const $form = $('form.checkout-billing');

    $('.card-icons img').css('display', 'none');

    if (creditCard.card_type != null && creditCard.card_type.name != null) {
        if (!$card.hasClass('amex') && creditCard.card_type.name === 'Amex'){
            $card.addClass('amex');
            util.toggleCreditCardMask($form, true, 'ZZZZ ZZZZZZ Z0000');
        } else if (creditCard.card_type.name !== 'Amex'){
            $card.removeClass('amex');
            util.toggleCreditCardMask($form, true, 'ZZZZ ZZZZ ZZZZ 0000');
        }
        let cardType = creditCard.card_type.name;
        if (cardType === 'MasterCard') {
            cardType = 'Master Card';
        }

        $('.card-type .input-select').val(cardType);
        $('.card-icons .' + creditCard.card_type.name.toLowerCase()).css('display', 'block');
    }
}

/**
 * @function updateCardMask
 * @description Update mask for number and date after form initialization and before submit
 * @param {jQuery Object} $form form to validate
 */
function updateCardMask($form) {
    if (typeof $form === "undefined" || $form.length < 1) {
        return;
    }

    $('.expirationdate').mask('00/0000');
    $form.on('submit', function(){
        if ($form.valid()) {
            var $ccField = $form.find('.card-number input');
            if ($ccField.length > 0) {
                $ccField.val($ccField.cleanVal());
            }
        }
    });
}

module.exports = {
    detectCreditCard: detectCreditCard,
    updateCardMask: updateCardMask
};
