'use strict';

var util = require('./util');
var cart = require('./pages/cart');
var progress = require('./progress');
var tooltip = require('./batooltip');
var billing = require('./pages/checkout/billing');

/**
 * Gift card handler
 * @param {Function} callback callback function that being executed after gift card applied successfully
 * @returns
 */
function giftCardHandler(callback) {
    var code = $('#checkout-gift-cards').find('input[name$="_giftCertCode"]').val();
    var $error = $('#checkout-gift-cards').find('.giftcert-error');
    $error.html();

    if (code.length === 0) {
        $error.html(Resources.GIFT_CERT_MISSING);
        return;
    }

    var url = util.appendParamsToUrl(Urls.redeemGiftCert, {giftCertCode: code, format: 'ajax'});
    $.getJSON(url, function (data) {
        var fail = false;
        var msg = '';
        if (!data) {
            msg = Resources.BAD_RESPONSE;
            fail = true;
        } else if (!data.success) {
            msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
            fail = true;
        }
        if (fail) {
            $error.html(msg);
            return;
        } else {
            callback();
        }
    });
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
 function updateSummary(checkoutStep) {
    var $summary = $('#secondary');
    // indicate progress
    progress.show($summary);

    var url = !checkoutStep ? Urls.summaryRefreshURL : util.appendParamsToUrl(Urls.summaryRefreshURL, {checkoutStep: checkoutStep});
    // load the updated summary area
    $.ajax(url).done(function (response) {
        // hide edit shipping method link
        $summary.empty().html(response);
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();

        cart.calculateComfortClubSavings();
        tooltip.init();
        progress.hide();
    });
}

/**
 * Update gift card container
 */
function updateGiftCardContainer (type) {
    var $container = $('body').find('.checkout-gift-certificate-container');
    $.ajax(Urls.updateGiftCardContainer).done(function (response) {
        $container.html(response);
        $container.find('.js-reset-gift-input').addClass('input-focused');
        if (type === 'remove') {
            var removeMessage = document.createElement('div');
            removeMessage.className = 'giftcert-removed';
            removeMessage.innerHTML = $container.data('remove-gift');
            $container.append(removeMessage);
        }
    });
}

function updatePaymentMethodsContainer() {
    var $container = $('body').find('.checkout-payment-section');
    $.ajax(Urls.updatePaymentContainer).done(function (response) {
        $container.empty().html(response);
        tooltip.init();
        $(document).trigger('baCoreReady');
        billing.init();
        if ($('body').find('.gift-cert-used').length && $('body').find('.gift-cert-used').find('#noPaymentNeeded').length) {
            $('body').find('[name$="billing_save"]').removeAttr('disabled');
        } else {
            $('body').find('[name$="billing_save"]').attr('disabled', 'disabled');
        }
    });
}

module.exports = {
    init: () => {
        $('body').on('click', '#checkout-gift-cards #add-giftcert', (e) => {
            e.preventDefault();
            giftCardHandler(function () {
                updateGiftCardContainer();
                updateSummary(4);
                updatePaymentMethodsContainer();
            });
        });

        $('body').on('click', '#checkout-gift-cards .remove-gift-certificate', (e) => {
            var url = $('body').find('.remove-gift-certificate').attr('href');
            e.preventDefault();

            $.getJSON(url, function (data) {
                updateGiftCardContainer('remove');
                updateSummary(4);
                updatePaymentMethodsContainer();
            });
        });
    }
};
