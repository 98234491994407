/**
 * @package     Blueacorn/AjaxForm
 * @version     1.0
 * @author      Blue Acorn <code@blueacorn.com>
 * @copyright   Copyright © 2016 Blue Acorn.
 */

(function (root, factory) {
    if (typeof exports === 'object') {
        module.exports = factory(
            window.jQuery,
            require('./blueacorn-core'),
            require('../ajax'),
            require('../validator'),
            require('../util')
        );
    } else if (typeof define === 'function' && define.amd) {
        define(['jquery', 'baCore', 'ajaxJSON', 'validator', 'util'], function (jquery, ba, ajaxJSON, validator, util) {
            return (factory(jquery, ba, ajaxJSON, validator, util));
        });
    } else {
        root.ba.header = factory(root.jQuery, root.ba);
    }
}(this, function ($, ba, ajax, validator, util) {

    var AjaxForm = {
        form: {
            el: null,
            action: null,
            confirm: false,
            submit: null,
            invalidFields: null
        },
        data: {
            formData: null,
            dataTarget: null
        },
        flags: {
            canSubmit: false
        },
        init: function (form, ajax, validator) {
            if (form.length > 0) {
                this.form.el = form;
                this.form.submit = form.find('button[type="submit"]');
                this.form.action = form.data('action');

                if (form.data('confirm') !== undefined) {
                    this.form.confirm = form.data('confirm');
                }
            }

            // Validate form using native DW $.validator plugin
            var result = validator.initForm(this.form.el);

            if (result.errorList.length < 1) {
                this.collectData();
                this.flags.canSubmit = this.confirmFormRequest();
            } else {
                util.scrollBrowser(parseInt(this.form.el.offset().top));
            }

            if (this.flags.canSubmit) {
                this.makeRequest(this, ajax);
            } else {
                $(document).trigger('form:loader:hide');
            }
        },
        setObservers: function (ajax, validator) {
            $(document).on('submit', '[data-ajax-form]', {ajax: ajax, validator: validator}, function (e) {
                e.preventDefault();
                var form = $(this);

                if (form.length > 0) {
                    var formMsg = form.find('span[data-form-msg]');

                    if (formMsg.length > 0) {
                        $(document).trigger('form:message:init', formMsg);
                    }
                }

                AjaxForm.init($(this), ajax, validator);
            });

            $(document).on('focusin.validate', function () {
                $(document).trigger('form:message:clear');
            });
        },
        collectData: function () {
            this.data.formData = this.form.el.serialize();
        },
        confirmFormRequest: function () {
            return this.form.confirm !== false ? confirm(this.form.confirm) : true;
        },
        makeRequest: function (inst, ajax) {
            $(document)
                .trigger('form:loader:show form:message:clear')
                .trigger('form:loader:show')
                .trigger('form:message:clear');

            // Ajax POST call using native DW library.
            ajax.getJson({
                async: true,
                method: 'POST',
                url: inst.form.action,
                data: inst.data.formData,
                callback: inst.formCallback.bind(inst)
            });
        },
        formCallback: function (resp) {
            $(document).trigger('form:ajax:done');
            $(document).trigger('form:loader:hide');
            $(document).trigger('form:alert:add', resp);

            if (typeof resp == 'object') {
                $(document).trigger('form:message:show', resp).trigger('form:alert:show');
                $(document).trigger('form:ajax:response', {
                    response: resp,
                    form: this.form
                });
            }
        }
    };

    $(document).on('baCoreReady', {ajax: ajax, validator: validator}, function () {
        AjaxForm.setObservers(ajax, validator);
    });

    return AjaxForm;
}));
