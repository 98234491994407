'use strict';

/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
module.exports = {
    init: function() {
        var self = this;

        this.initializeTooltipster();

        // call this event after ajax calls to ensure tooltipster is reinitialized
        $(document).on('update:tooltipster dialogopen', function() {
            self.initializeTooltipster();
        });
    },

    initializeTooltipster: function() {
        var tooltipConfig = {
            theme: 'tooltipster-borderless',
            debug: false,
            maxWidth: (typeof pageContext !== 'undefined' && pageContext.ns === "product") ? 164 : null,
            side: (typeof pageContext !== 'undefined' && pageContext.ns === "product") ? ['bottom', 'top', 'right', 'left'] : ['top', 'bottom', 'right', 'left'],
            trigger: 'custom',
            triggerOpen: {
                mouseenter: true,
                touchstart: true,
                click: true,
                tap: true
            },
            triggerClose: {
                click: true,
                scroll: true,
                mouseleave: true,
                originClick: true,
                touchleave: true,
                tap: true
            }
        };

        $('.tooltip').each(function(i, val) {
            var $currentTooltip = $(this);
            var $cvnWrapper = $currentTooltip.closest('.cvn');
            // only show custom tooltip when it's on the order review page for express checkout
            var isCvn = $cvnWrapper.length > 0 && $('.review-order').length > 0;
            var maxWidth = 320;

            // check to see if HTML content is provided as a child or sibling node
            if ($currentTooltip.find('.tooltip-content').length || $currentTooltip.next('.tooltip-content').length) {

                // modify the tooltipConfig object to read the HTML content
                tooltipConfig['functionInit'] = function(instance, helper) {
                    var content;

                    // set the content accordingly
                    if ($(helper.origin).find('.tooltip-content').length) {
                        content = $(helper.origin).find('.tooltip-content').removeClass('tooltip-content').detach();
                    } else {
                        content = $(helper.origin).next('.tooltip-content').removeClass('tooltip-content').detach();
                    }

                    instance.content(content);
                }

                // Allow users to click elements inside
                tooltipConfig.interactive = true;

                // set a maxWidth
                tooltipConfig.maxWidth = maxWidth;
                const tooltipTheme = $currentTooltip.data('theme');
                const tooltipArrow = $currentTooltip.data('arrow');
                const tooltipSide = $currentTooltip.data('side');

                if (tooltipTheme) {
                    tooltipConfig.theme = tooltipTheme;
                }

                if (typeof tooltipArrow !== 'undefined') {
                    tooltipConfig.arrow = tooltipArrow;
                }

                if (tooltipSide) {
                    tooltipConfig.side = tooltipSide;
                }
            };

            if (isCvn) {
                var cvnTooltipConfig = $.extend(true, {}, tooltipConfig);

                // ensure cvn tooltip displays below card info if there is room
                cvnTooltipConfig.side = ['bottom', 'top'];
                cvnTooltipConfig.maxWidth = 260;

                // center tooltip and arrow beneath entire input instead of icon
                cvnTooltipConfig.functionPosition = function(instance, helper, position) {
                    var $tooltip = $(instance.elementTooltip());
                    var fieldWidth = $cvnWrapper.find('.field-wrapper').width();
                    var $icon = $cvnWrapper.find('.form-field-tooltip');
                    var iconWidth = $icon.width();
                    var rightSpace = parseInt($icon.css('right'));
                    var windowWidth = $(window).width();

                    if (fieldWidth && iconWidth) {
                        var toCenter = (iconWidth / 2) - (fieldWidth / 2);
                        if (rightSpace) {
                            toCenter += rightSpace;
                        }
                        if (windowWidth > 767) {
                            position.coord.left += toCenter;
                        } else {
                            position.coord.left = (windowWidth - maxWidth) / 2;
                        }
                        position.target += toCenter;
                    }

                    if ($tooltip.length > 0) {
                        $tooltip.addClass('tooltipster-cvn');
                    }

                    return position;
                };

                $currentTooltip.tooltipster(cvnTooltipConfig);
                // open cvn tooltip on express checkout order review
                $currentTooltip.tooltipster('open');
            } else if($currentTooltip.data('clickonly')) {
                tooltipConfig.triggerOpen = {
                    touchstart: true,
                    click: true,
                    tap: true
                };
                tooltipConfig.triggerClose = {
                    click: true,
                    originClick: true,
                    touchleave: true,
                    tap: true
                }
                $currentTooltip.tooltipster(tooltipConfig);
            } else {
                // and initialize tooltipster
                $currentTooltip.tooltipster(tooltipConfig);
            }
        });

        $('.share-link').on('click', function (e) {
            e.preventDefault();
            var target = $(this).data('target');
            if (!target) {
                return;
            }
            $(target).toggleClass('active');
        });
    }
};
