'use strict';

var util = require('./util'),
    ba = require('./blueacorn/blueacorn-core'),
    loyalty = require('./pages/product/loyalty'),
    gtm = require('./pages/gtm'),
    bonusProductsView = require('./bonus-products-view');

var minicart = {
    settings: {
        openMinicartClass: 'open',
        openFPMinicartClass: 'open',
        containerFPMinicartClass: 'fp-mini-cart',
        bodyOpenMinicartClass: 'hide-live-chat js_minicart-open'
    },

    init: function () {
        this.$el = $('.mini-cart.header');
        this.$content = $('.mini-cart-content');
        this.$totals = $('.mini-cart-total');
        this.$wrapper = $('#wrapper');
        this.$totalCS = $('.total-club-savings');

        this.moveContent();
        this.initObservers();
        calculateComfortClubSavings();
    },

    moveContent: function(){
        this.$content.detach().prependTo('#wrapper');
    },

    initObservers: function() {
        var self = this;

        $('.mini-cart-product').eq(0).find('.mini-cart-toggle').addClass('fa-caret-down');
        $('.mini-cart-product').not(':first').addClass('collapsed')
            .find('.mini-cart-toggle').addClass('fa-caret-right');

        $('.mini-cart-toggle').on('click', function () {
            $(this).toggleClass('fa-caret-down fa-caret-right');
            $(this).closest('.mini-cart-product').toggleClass('collapsed');
        });

        $('.cancelterms').on('click', function(e){
            var actionUrl = $(this).data('cancel-action-url');
            $.ajax({
                url: actionUrl,
                    success: function(data){
                        minicart.show(data);
                        triggerCloseFPMinicart();
                    }
                });
        });

        // Escape key closeout of minicart
        $(document).keyup(function(event) {
            if (event.keyCode === 27 && self.$content.hasClass(self.settings.openMinicartClass)) {
                self.closeCallback();
            }
        });

        //do not open mincart on checkout header - just let it link back to cart
        if ($('.pt_checkout').length > 0 || $('.pt_cart').length > 0) {
            return
        } else {
            this.$totals.children('.mini-cart-link').on('click', self.openClick.bind(self));
        }
        this.$content.children('.close, .js_fp-mini-cart__close, .js_fp-mc-asset__no').on('click', self.close.bind(self));
    },

    showFPMinicart: function () {
        var self = this;
        var $fppMiniCartContainer = $('.' + self.settings.containerFPMinicartClass);

        if ($fppMiniCartContainer.length) {
            var fppPrice = $fppMiniCartContainer.data('fpp-price');

            $fppMiniCartContainer.addClass(self.settings.openFPMinicartClass);
            $('.js_fp-mc-asset__plan-top-price').html(fppPrice);
        }
    },

    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
    show: function (html) {
        var $html = $(html);
        // this.$el.html(html);
        this.$totals.html($html.siblings('.mini-cart-total').html());
        this.$totalCS.html($html.siblings('.total-club-savings').html());

        if (!this.$content.length){
            this.$content = $html.siblings('.mini-cart-content').prependTo('#wrapper');
        } else {
            this.$content.html($html.siblings('.mini-cart-content').html());
        }
        this.showFPMinicart();
        calculateComfortClubSavings();

        util.scrollBrowser(0);
        this.initObservers();
        bonusProductsView.loadBonusOption();
        this.open();

        setTimeout(function () {
            gtm.fppImpression({ isMiniCart: true });
        }, 500);
    },

    open: function() {
        var self = this;

        self.$content.addClass(self.settings.openMinicartClass);
        $('body').addClass(self.settings.bodyOpenMinicartClass);

        if ((typeof affirm !== 'undefined') && affirm.ui && (typeof affirm.ui.refresh === 'function')) {
            affirm.ui.refresh();
        }
        self.$wrapper.addClass('overlay-active');
        ba.utils.bindHtmlClick('minicart', true, self.close.bind(self));
    },

    openClick: function(event) {
        var $target = $(event.target);

        if ($target.hasClass('mini-cart-empty') || $target.closest('.mini-cart-empty').length){
            return;
        }

        event.preventDefault();
        event.stopImmediatePropagation();

        this.open();
    },

    close: function(event) {
        var $target = $(event.target), self = this;
        event.stopImmediatePropagation();

        if ($('.' + self.settings.containerFPMinicartClass).is(':visible')) {
            var $fpContainer = $target.closest('.' + self.settings.containerFPMinicartClass);
            if (($fpContainer.length && ($target.hasClass('js_fp-mini-cart__close') || $target.hasClass('js_fp-mc-asset__no'))) || !$fpContainer.length){
                self.closeFPCallback();
            }
            return;
        }

        if (($target.hasClass('close') && $target.closest('.mini-cart-content').length) || !$target.closest('.mini-cart-content, .autorenewterms, .loader').length){
            self.closeCallback();
        }
    },

    closeFPCallback: function() {
        var self = this;

        $('.' + self.settings.containerFPMinicartClass).removeClass(self.settings.openFPMinicartClass);
    },

    closeCallback: function() {
        var self = this;

        self.$content.removeClass(self.settings.openMinicartClass);
        $('body').removeClass(self.settings.bodyOpenMinicartClass);
        ba.utils.bindHtmlClick('minicart', false);

        // using fadeout to time these animations correctly
        $('#wrapper .wrapper-overlay').fadeOut(200, function() {
            $(this).removeAttr('style');
            self.$wrapper.removeClass('overlay-active');
        });
    }
};

function triggerCloseFPMinicart() {
    $('.js_fp-mini-cart__close').trigger('click');
}

/**
 * @private
 * @function
 * @description calculates the total cart savings for Comfort Club members
 */
function calculateComfortClubSavings() {
    var $orderTotalsTable = $('.js-order-totals-table');

    if ($orderTotalsTable.length) {
        return;
    }

    var $savings = $('.js-can-savings-source-value');

    if ($savings.length) {
        var totalSavings = Number($savings.html().replace(',', ''));

        if (totalSavings > 0) {
            $('.js-can-savings').html($('.js-can-savings-source').html());
        } else {
            $('.js-total-club-savings').hide();
        }
    }
}

function ccProductAdded(e, data) {
    if (!$('.pt_cart').length) {
        var $response = $('<div/>');

        if (data && data.response) {
            $response = $(data.response)
        }

        minicart.show($response);
        triggerCloseFPMinicart();
    }
}

$('body').on('click', ' #addLoyaltyToCartBtnMiniCart', function(e) {
    e.preventDefault();
    loyalty.addLoyaltyProduct();
});

$('body').on('click', '.js_fp-mc-asset__add', function(e) {
    e.preventDefault();
    var pid = $('#fpp-pid').val();
    var data = {
        pid: pid,
        fpp: true
    };
    var $uuidToCover = $('#fpp-uuidtocover');
    if ($uuidToCover.length) {
        data.uuidToCover = $uuidToCover.val();
    }

    var $gtmContainer = $(this).closest('.js_gtm-fp-mini-cart');
    var fppId = $gtmContainer.data('fpp-name');
    var fppName = $gtmContainer.data('fpp-name');
    var fppPrice = $gtmContainer.data('fpp-price-value');

    $.ajax({
        type: 'POST',
        url: Urls.minicartAddFurnitureProtectionPlan,
        data: data,
        success: function (response) {
            gtm.fppAddToCart({
                isMiniCart: true,
                id: fppId,
                name: fppName,
                price: fppPrice
            });

            var $response = $('<div/>');

            if (response) {
                $response = $(response)
            }

            minicart.show($response);
        }
    });
});

$('body').on('click', '.js_fp-mc-asset__no', function(e) {
    e.preventDefault();
    var fppCode = $('#fpp-code').val();
    $.ajax({
        type: 'POST',
        url: Urls.minicartDeclineFurnitureProtectionPlan,
        data: {
            fppCode: fppCode
        }
    });
});

$(document).on('custom:cc:product:added', ccProductAdded);

module.exports = minicart;
