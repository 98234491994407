'use strict';

var dialog = require('./dialog'),
    progress = require('./progress'),
    validator = require('./validator'),
    cart = require('./pages/cart'),
    util = require('./util');

var openWishlistModalUrl = '';

function gtmWishlist(eventLabel) {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'gtmEvent',
            eventCategory: 'engagement',
            eventAction: 'wishlist',
            eventLabel: eventLabel,
            interactionEvent: false
        });
    }
}

function gtmLogin(eventLabel) {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'gtmEvent',
            eventCategory: 'engagement',
            eventAction: 'account',
            eventLabel: eventLabel,
            interactionEvent: false
        });
    }
}

function resetModalPosition($dialog) {
    $dialog.dialog('option', 'position', { my: 'center', at: 'center', of: window });
}

function checkFilledForm($input) {
    if (!($input && $input.length)) {
        return;
    }

    var $button = $input.closest('form').find('button');
    $button.prop('disabled', !($input.val() && $input.val().length));
}

function openWishlistModal(url, state, cpv) {
    if ($('#customizedProductValues').length && $('#customizedProductValues').val()) {
        url = util.removeParamFromURL(url, 'customizedProductValues');
        url = util.appendParamToURL(url, 'customizedProductValues', $('#customizedProductValues').val());
    } else if(cpv) {
        url = util.removeParamFromURL(url, 'customizedProductValues');
        url = util.appendParamToURL(url, 'customizedProductValues', cpv);
    }
    openWishlistModalUrl = url;
    dialog.open({
        url: url,
        options: {
            open: function () {
                var dialog = $(this);

                $('.wl-dialog-add__wrapper').addClass(state ? state : '');

                checkFilledForm(dialog.find('.js_wl-name'));
                resetModalPosition(dialog);
                validator.init();

                dialog
                    .on('click', '.js_close', function () {
                        dialog.dialog('close');
                    });

                if ($('.js_wl-dialog-auth').is(':visible')) {
                    gtmLogin('login modal view');
                } else {
                    gtmWishlist('Modal View');
                }
            },
            dialogClass: 'wishlist-ui-dialog'
        }
    });
}

function addToWishlist(e) {
    e.preventDefault();
    e.stopPropagation();
    if($(e.target).closest('.js_wishlist-link').data('cpv')) {
        openWishlistModal($(e.target).closest('.js_wishlist-link').attr('href'), null, JSON.stringify($(e.target).closest('.js_wishlist-link').data('cpv')));
    } else {
        openWishlistModal($(e.target).closest('.js_wishlist-link').attr('href'));
    }
}

function enableAllListsView() {
    var $mainContent = $('.wl-dialog-add__wrapper');
    var $createList = $('.wl-dialog-add__create-list');
    $mainContent.removeClass('after-login after-registration new-list');
    $createList.removeClass('hide');
}

function enableNewListView() {
    var $mainContent = $('.wl-dialog-add__wrapper');
    var $createList = $('.wl-dialog-add__create-list');
    $mainContent.removeClass('after-login after-registration').addClass('new-list');
    $createList.addClass('hide');
    $mainContent.find('.js_wl-name').val('').trigger('change');
    $mainContent.find('[data-form-msg]').empty();
}

function loadAllLists() {
    var $wlDialogList = $('.js_wl-dialog-list');
    var url = $wlDialogList.data('all-lists-url');
    if ($('#customizedProductValues').length && $('#customizedProductValues').val()) {
        url = util.removeParamFromURL(url, 'customizedProductValues');
        url = util.appendParamToURL(url, 'customizedProductValues', $('#customizedProductValues').val());
    }
    $.ajax({
        type: 'GET',
        url: url,
        beforeSend: function () {
            progress.show();
        },
        success: function (response) {
            if (response.success) {
                var $container = $('.js_wl-dialog-add__list-wrapper');
                $container.html(response.list);
            }
        },
        complete: function () {
            progress.hide();
        }
    });
}

function initializeEvents() {
    $(document)
        .on('click', '.js_wishlist-link', addToWishlist)
        .on('click', '.ui-widget-overlay', function (e) {
            if ($('.wishlist-ui-dialog').is(":visible")) {
                $('.wishlist-ui-dialog').find('.js_close').trigger('click');
            }
        })
        .on('blur change keyup', '.js_wl-name', function () {
            checkFilledForm($(this));
        })
        .on('focusin', '.js_wl-name', function () {
            $(this).closest('form').addClass('focused');
        })
        .on('focusout', '.js_wl-name', function () {
            $(this).closest('form').removeClass('focused');
        })
        .on('click', '.js_wl-dialog-add__create-list-link', function () {
            enableNewListView();
        })
        .on('click', '.js_wl-dialog-list__link', function (e) {
            e.preventDefault();
            var $currentLink = $(this);
            var $container = $currentLink.closest('.js_wl-dialog');
            var url = $currentLink.attr('href');
            var whishlistID = $currentLink.data('wishlist-id');
            var pid = $container.data('pid');
            if ($('#customizedProductValues').length && $('#customizedProductValues').val()) {
                url = util.removeParamFromURL(url, 'customizedProductValues');
                url = util.appendParamToURL(url, 'customizedProductValues', $('#customizedProductValues').val());
            }
            $.ajax({
                type: 'GET',
                url: url,
                success: function (response) {
                    if (response.success) {
                        var $container = $('.js_wl-dialog-add__list-wrapper');
                        $container.html(response.list);
                        var $currentLinkUpdated = $container.find('[data-wishlist-id="' + whishlistID + '"]');
                        var isSaving = $currentLinkUpdated.hasClass('has-product');
                        $currentLinkUpdated.toggleClass('product-added', isSaving);
                        var productInList = $('.js_wl-dialog-list__link.has-product').length > 0;
                        var $currentWishlistlink = $('.js_wishlist-link');

                        if ($('.pt_product-search-result, .js_wishlist-single, .js_sti-flyout').length) {
                            $currentWishlistlink = $('.js_product-tile[data-itemid="' + pid + '"]');
                        }

                        $currentWishlistlink.find('.wishlist-icon-wrapper').toggleClass('item-added', productInList);

                        if (isSaving) {
                            gtmWishlist('Save Item');
                        }
                    }
                }
            });
        })
        .on('form:ajax:response', function (e, data) {
            if (!data.response.success) {
                return;
            }

            var element = data.form.el;
            var $formAddList = $('.wl-dialog-add__form');
            if ($formAddList.is(element)) {
                gtmWishlist('Create List');
                enableAllListsView();
                loadAllLists();
                return;
            }

            if (!openWishlistModalUrl) {
                return;
            }

            if($('#cart-table-wrapper').length) {
                cart.updateCart();
            }

            var $loginForm = $('.js_wl-dialog-auth__login-form');
            if ($loginForm.is(element)) {
                $(document).trigger('custom:update:login:dropdown');
                gtmLogin('login success');
                openWishlistModal(openWishlistModalUrl, 'after-login');
                return;
            }

            var $registrationForm = $('.js_wl-dialog-auth__registration-from');
            if ($registrationForm.is(element)) {
                $(document).trigger('custom:update:login:dropdown');
                gtmLogin('account creation success');
                openWishlistModal(openWishlistModalUrl, 'after-registration');
            }
        })
        .on('click', '.js_wl-dialog-auth__tab', function (e) {
            var tabName = $(this).data('auth-tab-name');
            $('[data-auth-tab-name]').removeClass('active');
            $(`[data-auth-tab-name="${tabName}"]`).addClass('active');
        })
        .on('click', '.js_wl-dialog-auth__create-account-link', function (e) {
            $('[data-auth-tab-name]').removeClass('active');
            $('[data-auth-tab-name="create"]').addClass('active');
        })
        .on('click', '.js_wl-dialog-auth__forgot-password-link', function (e) {
            $('.js_wl-dialog-auth__login-form').addClass('hide');
            $('.js_wl-dialog-auth__password-reset-from').removeClass('hide');
        });
}

var wishlistDialogs = {
    init: function () {
        initializeEvents();
    }
};

module.exports = wishlistDialogs;
