/**
 * @package     Blueacorn/CollectionZoom
 * @version     2.0
 * @author      Blue Acorn <code@blueacorn.com>
 * @copyright   Copyright © 2016 Blue Acorn.
 */

(function (root, factory) {
    if (typeof exports === 'object') {
        // CommonJS
        module.exports = factory(window.jQuery, require('./blueacorn-core'), require('../vendor/enquire/enquire.min'));
    } else if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery', 'baCore', 'enquireJs'], function (jquery, ba, enquire) {
            return (factory(jquery, ba, enquire));
        });
    } else {
        // Global Variables
        root.ba.productSticky = factory(root.jQuery, root.ba, root.enquire);
    }

}(this, function ($, ba, enquire) {
    'use strict';

    var collectionZoom = {

        settings: {
            collectionZoomIcon: '.showcase .has-collection',
            collectionClass: '.product-collection'
        },

        init: function (options) {
            this.setObservers();
        },

        setObservers: function() {
            var self = this;
            var $collectionDiv = $(self.settings.collectionZoomIcon);

            $collectionDiv.on('click', self.productCollectionOpen.bind(self));

            enquire.register('(max-width: ' + ba.bp.large + 'px)', {
                match: function () {
                    $collectionDiv.addClass('disabled');
                },
                unmatch: function() {
                    $collectionDiv.removeClass('disabled');
                }
            });
        },

        productCollectionOpen: function(event) {
            var $target = $(event.target), $html = $('html'),
                self = this,
                $collection, $section;

            if (!$(self.settings.collectionZoomIcon).hasClass('disabled')){
                event.preventDefault();
            } else {
                return true;
            }

            $section = $target.closest('.section');
            $collection = $section.children(self.settings.collectionClass).clone().prependTo('#wrapper .wrapper-overlay').data('collection-title', $section.find('img').attr('title'));
            $html.addClass('collection-active');
            $collection.addClass('open');

            $collection.flexslider({
                animation: 'slide',
                slideshow: false,
                start: function() {
                    $(window).trigger('flexslider:start');
                }
            }).addClass('show');

            // bind the close action
            $collection.find('.close').on('click', self.productCollectionClose.bind(self))
        },

        productCollectionClose: function(event) {
            $(this.settings.collectionClass + '.open').fadeOut('200', function() {
                $(this).remove();
            });
            $('html').removeClass('collection-active');
        }

    };

    $(document).on('baCoreReady', function() {

        /**
         * The parameter object is optional.
         * Must be an object.
         */
        if (typeof pageContext !== 'undefined' && (pageContext.ns === 'storefront' || pageContext.ns === 'search')){
            collectionZoom.init();
        }
    });

    return collectionZoom;
}));
