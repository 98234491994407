'use strict';

const globalVars = require('./global-vars');
/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
exports.init = function () {
    $(document).on('mouseover click', '.tool-tip', function () {
        var $toolTip = $(this);
        var $toolTipWidth = $toolTip.outerWidth();
        var $content = $toolTip.find('.tool-tip-content');
        var contentWidth = $content.outerWidth();
        var halfContentWidth = contentWidth / 2;
        var $offsetLeft = $toolTip.offset().left;
        var containerWidth = window.innerWidth;
        var scrollWidth = containerWidth >= globalVars.breakpoints.small ? 17 : 0;
        var $lifestyleContainer = $(this).closest('.js_lifestyle-product-list');

        $content.removeAttr('style');

        if ($lifestyleContainer.length && containerWidth >= globalVars.breakpoints.large) {
            containerWidth = $lifestyleContainer.outerWidth() - scrollWidth;
            $offsetLeft -= $lifestyleContainer.offset().left;
        }

        if((containerWidth / 2) < $offsetLeft) {
            if ((containerWidth - $offsetLeft - $toolTipWidth - scrollWidth) < halfContentWidth) {
                $content.css('right', (containerWidth - $offsetLeft) / -2);
            } else {
                $content.css('right', -1 * halfContentWidth);
            }
        } else {
            if ($offsetLeft < halfContentWidth) {
                $content.css('left', $offsetLeft / -2);
            } else {
                $content.css('left', -1 * halfContentWidth);
            }
        }

        var $gridTile = $(this).closest('.grid-tile');
        var $productTile = $gridTile.find('.js_product-tile');
        var isProductTile = $productTile.length > 0;

        if (isProductTile) {
            $('.grid-tile').css({ 'z-index': 'initial' });
            $gridTile.css({ 'z-index': 1 });
        }
    });
};
