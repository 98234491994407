'use strict';

var addressAutocomplete = require('../address-autocomplete'),
    page = require('../page');


/**
 * @private
 * @function
 * @description Binds the events of the catalog to the catalog page
 */
function initializeEvents() {

    if (SitePreferences.GOOGLE_ADDRESS_AUTOCOMPLETE_ENABLED) {
        addressAutocomplete.init();
    }	
};

var catalog = {
    init: function () {
        initializeEvents();
    }
};

module.exports = catalog;
