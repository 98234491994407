/**
 * @package     Blueacorn/cart
 * @version     2.0
 * @author      Blue Acorn <code@blueacorn.com>
 * @copyright   Copyright © 2016 Blue Acorn.
 */

(function (root, factory) {
    if (typeof exports === 'object') {
        // CommonJS
        module.exports = factory(
            window.jQuery,
            require('./blueacorn-core')
        );
    } else if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery', 'baCore'], function (jquery, ba) {
            return (factory(jquery, ba));
        });
    } else {
        // Global Variables
        root.ba.product = factory(root.jQuery, root.ba);
    }

}(this, function ($, ba) {
    'use strict';

    var CaptchaLoader = function () {

        this.captchaInstances = [];

        this.init();

        return this;
    }

    CaptchaLoader.prototype = {
        init: function () {
            var self = this,
                captchaBox = $('[data-captcha]');

            captchaBox.each(function () {
                var captcha = $(this);

                if (captcha.length > 0) {
                    self.captchaInstances.push({
                        el: captcha,
                        captchaArea: captcha.find('span'),
                        input: captcha.find('input')
                    })
                }
            })

            self.renderCaptchas();
        },
        renderCaptchas: function () {
            var self = this;

            if (self.captchaInstances.length > 0) {
                for (var k in self.captchaInstances) {
                    var captcha = self.captchaInstances[k];

                    grecaptcha.render(captcha.captchaArea[0], {
                        sitekey: captcha.el.data('sitekey'),
                        callback: function (resp) {
                            self.handleCaptchaCallback(captcha, resp);
                        },
                        theme: 'light'
                    });
                }
            }
        },
        handleCaptchaCallback: function (captcha, resp) {
            var captcha = captcha;
            captcha.input.val(resp);
            $('input[name$="_postal"]').trigger('change');
        }
    }

    return CaptchaLoader;
}));
