/**
 * @package     Blueacorn\OwlCarouselPatch
 * @version     2.0
 * @author      Blue Acorn <code@blueacorn.com>
 * @copyright   Copyright © 2016 Blue Acorn.
 */

(function(window, $){
    // Check if owlCarousel exists
    if (
        typeof $.fn.owlCarousel === 'function' &&
        typeof $.fn.owlCarousel.Constructor.prototype.initialize === 'function'
    ){

        /**
         * Monkey patch to Owl Carousel
         * Allows the use of long carousels that the browsers will
         * usually not allow based on the width of th elements within the
         * container being to large.
         *
         * @see jQuery.width
         * @see :42
         * @returns {boolean}
         */
        $.fn.owlCarousel.Constructor.prototype.initialize = function() {
            this.trigger('initialize');

            this.$element
                .addClass(this.settings.baseClass)
                .addClass(this.settings.themeClass)
                .toggleClass('owl-rtl', this.settings.rtl);

            // check support
            this.browserSupport();

            if (this.settings.autoWidth && this.state.imagesLoaded !== true) {
                var imgs, nestedSelector, width;
                imgs = this.$element.find('img');
                nestedSelector = this.settings.nestedItemSelector ? '.' + this.settings.nestedItemSelector : undefined;
                width = this.$element.children(nestedSelector).width();

                if (this.$element.parent('#collection-pieces').length <= 0 && (imgs.length && width <= 0)) {
                    this.preloadAutoWidthImages(imgs);
                    return false;
                }
            }

            this.$element.addClass('owl-loading');

            // create stage
            this.$stage = $('<' + this.settings.stageElement + ' class="owl-stage"/>')
                .wrap('<div class="owl-stage-outer">');

            // append stage
            this.$element.append(this.$stage.parent());

            // append content
            this.replace(this.$element.children().not(this.$stage.parent()));

            // set view width
            this._width = this.$element.width();

            // update view
            this.refresh();

            this.$element.removeClass('owl-loading').addClass('owl-loaded');

            // attach generic events
            this.eventsCall();

            // attach generic events
            this.internalEvents();

            // attach custom control events
            this.addTriggerableEvents();

            this.trigger('initialized');
        };
    }

})(this, jQuery);
