'use strict';

var image = require('./image'),
    progress = require('../../progress'),
    productStoreInventory = require('../../storeinventory/product'),
    productCarousels = require('./productCarousels'),
    tooltip = require('../../batooltip'),
    util = require('../../util'),
    validator = require('../../validator'),
    globalVars = require('../../global-vars'),
    dialog = require('../../dialog'),
    video = require('../../video'),
    companyCallouts = require('./companyCallouts'),
    wallpaperCalculator = require('./wallpaperCalculator');

function setPrintImage() {
    if ($('#product-cylindo-viewer').length) {
        return;
    }

    var $container = $('.js_product-detail-img');
    var $mainImg = $container.find('.slick-current .js_primary-image');

    if (!$mainImg.length) {
        $mainImg = $container.find('.product-primary-image-carousel__slick-slide:not(.slick-cloned) .js_primary-image').eq(0);
    }

    var $thumbImgs = $container.find('.slick-active:not(.slick-current) .js_productthumbnail');

    $('.print-product-main-img').attr('src', $mainImg.attr('src'));

    for (let i = 0; i < 3; i++) {
        var src = $thumbImgs.eq(i).attr('src');

        if (typeof src !== 'undefined') {
            $('.print-product-main-img-' + i).attr('src', src).show();
        } else {
            $('.print-product-main-img-' + i).hide();
        }
    }
}

var showMobileSwatchInfo = function () {
    const $pdpMain = $('#pdpMain.v2');
    const $selectedSwatch = $pdpMain.find('.swatches > li.selected .swatch-detail-overlay.swatch-variant').clone();
    const $swatchDetailsWrapper = $pdpMain.find('.swatch-details-wrapper');
    var $btn = $swatchDetailsWrapper.find('.show-details > button');

    if (window.innerWidth <= globalVars.breakpoints.large) {
        var isExpanded = $btn.attr('aria-expanded') === 'true';

        $selectedSwatch.removeClass('m-hide');
        $swatchDetailsWrapper.html($selectedSwatch);

        if (isExpanded) {
            $swatchDetailsWrapper.find('.show-details > button').trigger('click');
        }
    } else {
        $selectedSwatch.removeAttr('style').removeClass('active');
    }
}

var updatePricingDescription = function (response) {
    var pricing = $($.parseHTML(response)).find('.product-price').html();
    var equalPayPrice = $($.parseHTML(response)).find('.equal-pay-price').html();
    var affirmPayPrice = $($.parseHTML(response)).find('.affirm-as-low-as');
    $('#product-price-description').empty().html(pricing);
    $('.equal-pay-price').empty().html(equalPayPrice);

    if ((typeof affirm !== 'undefined') && affirm.ui && (typeof affirm.ui.refresh === 'function')) {
        $('.add-to-cart-section .affirm-as-low-as').empty().replaceWith(affirmPayPrice);
        affirm.ui.refresh();
    }
}

/**
 * Helper function to update the cylindo images.
 * This will update the product and features, if necessary.
 * @param {Object} $response jQuery response
 */
function updateCylindoImages($response) {
    let $responseCylindoProductId = $response.find('#cylindoProductId'),
        responseCylindoPid,
        responseCylindoFeatures,
        cylindoUpdates = {};

    if (!$responseCylindoProductId.length) {
        return;
    }

    responseCylindoPid = $responseCylindoProductId.data('cylindopid');
    responseCylindoFeatures = $responseCylindoProductId.data('features');

    // Update the Cylindo product, if necessary
    if (responseCylindoPid) {
        cylindoUpdates.productCode = responseCylindoPid;
    }

    // Update the Cylindo features, if necessary
    if (responseCylindoFeatures) {
        cylindoUpdates.features = responseCylindoFeatures;
    }

    $(document).trigger('cylindoviewer:update', cylindoUpdates);
}

/**
 * Helper function to update sticky bar prices.
 * @param {Object} $responsePrice jQuery response
 */
function updateStickyBarPrice($responsePrice) {
    let $stickyBarPrice = $('.js-product-sticky-price .js-product-price');

    // Update product price info
    if ($stickyBarPrice.length >= 1 && $responsePrice.length >= 1) {
        $stickyBarPrice.html($responsePrice.html());
    }
}

function updateSuggestedProducts() {
    var $suggestedProductsContainer = $('.js_suggested-products');
    var params = {
        pid: $('.js-product-ajax-container').data('pid'),
        format: 'ajax'
    };

    $.ajax({
        type: 'GET',
        url: util.appendParamsToUrl(Urls.suggestedProducts, params),
        success: function (response) {
            $suggestedProductsContainer.html(response);
            $(document).trigger('update:selects');
        },
        error: function (response) {
            throw new Error(response.error);
        }
    });
}

function moveState($old) {
    $('.js_product-attribute-accordion-item').each(function (index) {
        var $newItem = $(this);
        var $oldItem = $old.eq(index);
        $newItem.toggleClass('active', $oldItem.hasClass('active'));
    });
}

function updateNamePricePromoContainer($response) {
    const $namePricePromo = $response.find('.js_product-name-price-promo');
    $('.js_product-name-price-promo').replaceWith($namePricePromo.eq(0));

    const $responsePrice = $namePricePromo.find('.js-product-price');
    updateStickyBarPrice($responsePrice);
}

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (href) {
    var $pdpForm = $('.pdpForm');
    var qty = $pdpForm.find('input[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
    };

    progress.show($('#pdpMain'));

    $.ajax({
        type: 'GET',
        url: util.appendParamsToUrl(href, params),
        success: function (response) {
            var $productContainer = $('.js-product-ajax-container');
            var $wallpaperCalculator = $productContainer.find('.js_wallpaper-calculator');
            const $accordionItems = $('.js_product-attribute-accordion-item');

            $productContainer.replaceWith(response);
            $productContainer = $('.js-product-ajax-container'); // update variable after replace DOM

            $productContainer.find('.js_wallpaper-calculator').replaceWith($wallpaperCalculator);
            updateNamePricePromoContainer($(response));
            $('#update-name-price-promo-container').remove();
            moveState($accordionItems);
            updateSuggestedProducts();

            var $contractCOM = $productContainer.data('contract-com');
            $('.contract-com').html($contractCOM);

            var updatedUrl = $productContainer.data('product-url');
            if (!$('#QuickViewDialog').length && updatedUrl && updatedUrl.length) {
                history.replaceState(null, '', updatedUrl);
            }

            if (SitePreferences.STORE_PICKUP) {
                productStoreInventory.init();
            }
            image.replaceImages();
            $(document).trigger('product:update', [response]);
            $(document).trigger('update:selects');

            if (!util.isCustomizationEnabled()) {
                updateCylindoImages($(response));
            }

            if (!util.isProductV2()) {
                if ((typeof affirm !== 'undefined') && affirm.ui && (typeof affirm.ui.refresh === 'function')) {
                    affirm.ui.refresh();
                }
            }

            productCarousels.initCompleteLookCarousel();
            tooltip.init();

            $('.product-accordions').accordion({
                collapsible: true,
                heightStyle: 'content',
                icons: {
                    header: 'icon-plus',
                    activeHeader: 'icon-minus'
                }
            });
            $('#pdpMain')
                .trigger('updateAddtoCartButton')
                .trigger('updateHQData');

            if (util.isProductV2()) {
                showMobileSwatchInfo();
                updatePricingDescription(response);
            } else {
                validator.init();
            }

            if (util.isCustomizationEnabled()) {
                $(document).trigger('custom:customization:display:swatch:details');
            }

            video.init();
            $(document)
                .trigger('update:form:inputs')
                .trigger('custom:calculate:wallpaper:rolls', {form: $wallpaperCalculator.find('form')})
                .trigger('custom:lazy:load:update')
                .trigger('update:checkboxes')
                .trigger('custom:send:find:mine:request')

            if (util.isCustomizationEnabled()) {
                $(document)
                    .trigger('custom:customization:display:swatch:details')
                    .trigger('custom:customization:update:count:displayed:options');
            }
            setPrintImage();
        },
        error: function (response) {
            throw new Error(response.error);
        },
        complete: function () {
            $(document).trigger('bmattribute:select')
            progress.hide();
        }
    });
};

/**
 * @description update width of the dropdown
 * @param {Object} elem - its a jQuery Object of the element
 **/
var updateDropdownWidth = function (elem) {
    var dropdownWrapper = elem.find('.ba-select-container');
    var optionList = elem.find('.ba-options .option');
    var maxWidth = 130;

    optionList.each(function (index, value) {
        var text = $(this).text();
        if (maxWidth < (text.length * 10 + 60)) {
            maxWidth = text * 10 + 60;
        }
    });

    dropdownWrapper.css('width', maxWidth + 'px');
}

module.exports = function () {
    var $pdpMain = $('#pdpMain');

    $(document)
        .on('click', '.js_tab-title', function () {
            var $currentItem = $(this).closest('.js_tabs-panel');

            if ($currentItem.hasClass('active')) {
                $currentItem.find('.tab-body').slideUp(300, function () {
                    $(this).closest('.js_tabs-panel').removeClass('active');
                });
                return;
            }

            $(this).closest('.js_product-tabs-content').find('.tab-body').slideUp(300, function () {
                $(this).closest('.js_tabs-panel').removeClass('active');
            });

            $currentItem.find('.tab-body').slideDown(300, function () {
                $(this).closest('.js_tabs-panel').addClass('active');
            });
        })
        .on('click', '.js_product-attribute-accordion-item-heading', function (e) {
            var $target = $(e.target);

            if ($target.closest('.tool-tip').length) {
                return;
            }

            var $currentItem = $(this).closest('.js_product-attribute-accordion-item');

            if ($currentItem.hasClass('active')) {
                $currentItem.find('.product-attribute-accordion-item__content').slideUp(300, function () {
                    $(this).closest('.js_product-attribute-accordion-item').removeClass('active');
                });
                return;
            }

            $(this).closest('.product-addtocart-wrapper').find('.product-attribute-accordion-item__content').slideUp(300, function () {
                $(this).closest('.js_product-attribute-accordion-item').removeClass('active');
            });

            $currentItem.find('.product-attribute-accordion-item__content').slideDown(300, function () {
                $(this).closest('.js_product-attribute-accordion-item').addClass('active');
            });
        })
        .on('click', '.js_cylindo-variants-customization-icon', function () {
            var $firstAccordionItem = $('.js_customization-accordion-first');

            if ($firstAccordionItem.hasClass('active')) {
                return;
            }

            $firstAccordionItem.find('.js_product-attribute-accordion-item-heading').trigger('click');
        });

    // click on swatch - should replace product content with new variant
    $pdpMain.on('click', '.product-detail .swatchanchor', function (e) {
        e.preventDefault();

        if ($(this).closest('li').hasClass('unselectable') ||
            $(this).closest('li').hasClass('ssipsop-unavailable') ||
            $(this).closest('li').hasClass('selected') ||
            $(this).closest('.product-set-item').length > 0 ||
            $(this).hasClass('custiomization-swatch')) {
            return;
        }

        var url = util.isProductV2() ? this.dataset.href : this.href;

        if ($(this).data('originaly-selected') && !$(this).parent().hasClass('selected')) {
            url = $(this).data('origin-url')
        }

        updateContent(url);
    });

    $pdpMain.on('updateAddtoCartButton', function () {
        var disableAddtoCart = false;
        var productAttributes = SitePreferences.IMAGE_SWATCH_ATTRIBUTES + ',' + SitePreferences.MULTI_SELECT_TEXT_SWATCH_ATTRIBUTES;

        if (productAttributes && productAttributes.length) {
            productAttributes.split(',').forEach(function(productAttributes) {
                var selector = $('.product-variations .attribute.' + productAttributes);
                if(!disableAddtoCart && selector.length) {
                    disableAddtoCart = !selector.find('li.selected').length;
                }
            });
        }

        if (disableAddtoCart) {
            $('#add-to-cart').prop('disabled', true);
        }
    });

    $pdpMain.on('click', '.mobile-fabric-detail-desc-close-container button', function (e) {
        if (window.innerWidth <= globalVars.breakpoints.large) {
            e.preventDefault();

            // hide the swatch detail
            $('.mobile-fabric-detail.selected').removeClass('active');

            // remove extra margin that accounts for detail
            $('div.fabric-name.selected.show-swatch-detail').removeClass('show-swatch-detail');

            // remove extra padding that accounts for detail
            $('.choices').removeClass('show-swatch-detail');

            $pdpMain.find('.swatches > li.selected').css('margin-bottom', 7);
        }
    });

    // Trigger the swatch overlay modal - desktop only

    $('body').on('mouseover', '.swatchanchor', function () {
        var qv = $('#QuickViewDialog');
        if (qv.length && qv.is(':visible')) {
            return;
        }

        if (window.innerWidth > 1024) {
            $('.swatchanchor').removeClass('active');
            $('.swatch-overlay').removeClass('active');
            var $swatchOverlay = $(this).parent().find('.swatch-overlay');

            $swatchOverlay.addClass('active');
        }
    });

    $('body').on('mouseleave', '.swatchanchor', function () {
        if (window.innerWidth > 1024) {
            $('.swatchanchor').removeClass('active');
            $('.swatch-overlay').removeClass('active');
        }
    });

    // change drop down variation attribute - should replace product content with new variant
    $pdpMain.on('change', '.variation-select', function () {
        if ($(this).val().length === 0 || ($(this).parents('.product-set-item').length > 0)) {
            return;
        }
        updateContent($(this).val());
    });

    if ($('.attribute.size').length) {
        setTimeout(function () {
            updateDropdownWidth($('.attribute.size'));
        }, 100);
    }

    if (util.isProductV2()) {
        showMobileSwatchInfo();

        $(document).on('variant:update', function(event, url) {
            updateContent(url);
        });

        $pdpMain.on('click', '.show-details > button', function (e) {
            e.preventDefault();
            const $this = $(this);
            const $swatchDetails = $pdpMain.find('.swatch-details-wrapper .additional-variants-details');
            const $detailsButtonText = $this.find('span');
            let swatchDetailsClosed = false;

            $swatchDetails.toggleClass('m-hide');
            $detailsButtonText.toggleClass('m-hide');
            swatchDetailsClosed = $swatchDetails.hasClass('m-hide');
            $this.attr('aria-expanded', !swatchDetailsClosed);
            $(document).trigger('custom:lazy:load:update');
        });

        function updateSwatchDetailElement(elementName, $element) {
            if(!(elementName === 'null' || elementName === '')) {
                $element.text(elementName).prev().removeClass('m-hide');
            } else {
                $element.text('').prev().addClass('m-hide');
            }
        }

        $pdpMain.on('mouseover focus', '.swatch-detail-hover', function (e) {
            var qv = $('#QuickViewDialog');
            if (qv.length && qv.is(':visible')) {
                return;
            }

            e.preventDefault();
            if (window.innerWidth >= globalVars.breakpoints.large) {
                var $this = $(this),
                    swatchTooltipData = $this.data('swatchdetail'),
                    $tooltipContent = $('.swatch-detail-overlay.tooltipster-box:not(.product-customization-selected-option-details-result)'),
                    $tooltipSwatchContent = $this.parent().find('.swatch-detail-overlay.swatch-variant');
                if($this.hasClass('swatch-detail')) {
                    // In Stock Styles
                    $tooltipSwatchContent.fadeIn(200);
                } else {
                    // Customizer
                    $('.product-customization-swatch-theme').add($tooltipContent).fadeIn(200);

                    updateSwatchDetailElement(swatchTooltipData.swatchName, $tooltipContent.find('.customization-swatch-name'));
                    updateSwatchDetailElement(swatchTooltipData.descriptionContents, $tooltipContent.find('.description-contents'));
                    updateSwatchDetailElement(swatchTooltipData.description, $tooltipContent.find('.description-detail'));
                    updateSwatchDetailElement(swatchTooltipData.grade, $tooltipContent.find('.description-grade'));
                    updateSwatchDetailElement(swatchTooltipData.descriptionCareinstructions, $tooltipContent.find('.description-careinstructions'));
                    $tooltipContent.find('.img img').attr('src', swatchTooltipData.image);
                }
            }
        });

        $pdpMain.on('mouseout focusout', '.swatch-detail-hover', function () {
            if ($(this).hasClass('swatch-detail')) {
                $('.swatch-detail-overlay.swatch-variant').hide();
            } else {
                $('.product-customization-swatch-theme').hide();
            }
        });

        // Reorients swatch details overlay when screen size or orientation changes on larger devices
        $(window).on('delayed-resize', function () {
            showMobileSwatchInfo();
        });

        // Closes dialog
        $('body').on('click', '.close-swatch-detail-overlay-dialog', function() {
            dialog.close();
        });
    }

    companyCallouts.init();
    wallpaperCalculator.init();

    $(window).on('load', function () {
        setPrintImage();
    })
};
