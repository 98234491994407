'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    progress = require('../../progress'),
    tooltip = require('../../batooltip'),
    util = require('../../util'),
    baCustomForms = require('../../blueacorn/blueacorn-custom-form-elements'),
    toggleFieldsets = require('./toggle-fieldsets'), // Don't remove. It needs for 'Use as billing address' at least
    cart = require('../cart'),
    addressAutocomplete = require('../../address-autocomplete');

/**
 * @function
 * @description Initializes gift message box, if shipment is gift
 */
function giftMessageBox() {
    // show gift message box, if shipment is gift
    var $giftMessageBox = $('.gift-message-text'),
        giftMessageInputVal = $('input[name$="_shippingAddress_isGift"]:checked').val();

    if (giftMessageInputVal === 'true') {
        $giftMessageBox.removeClass('hidden');
    } else {
        $giftMessageBox.addClass('hidden');
    }
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 * @param {String} checkoutStep parameter that will be passed to indicate which summary version should we show.
 * @param {String} [zipcode] zip code for updating shipping summary information.
 */
function updateSummary(checkoutStep, zipcode , params) {
    var $summary = $('#secondary');
    // indicate progress
    progress.show($summary);
    var url;
    if (!checkoutStep && params) {
        url = util.appendParamsToUrl(Urls.summaryRefreshURL,{stateCode: params.stateCode, countryCode: params.countryCode, postalCode: params.postalCode});
    } else if (checkoutStep && params) {
        url = util.appendParamsToUrl(Urls.summaryRefreshURL, {checkoutStep: checkoutStep, stateCode: params.stateCode, countryCode: params.countryCode,  postalCode: params.postalCode});
    } else if (checkoutStep && !params) {
        url = util.appendParamsToUrl(Urls.summaryRefreshURL, {checkoutStep: checkoutStep});
    } else if (!checkoutStep && !params) {
        url = Urls.summaryRefreshURL;
    }

    if(typeof(zipcode) !== "undefined" && params != null) {
        url = util.appendParamsToUrl(url, {zipcode: zipcode, stateCode: params.stateCode, countryCode: params.countryCode});
    }

    // load the updated summary area
    $.ajax(url)
    .done(function (response) {
        // hide edit shipping method link
        $summary.empty().html(response);
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();

        cart.calculateComfortClubSavings();
        // exports.init();
        tooltip.init();
    });
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
    var $form = $('.address');
    var params = {
        address1: $form.find('input[name$="_address1"]').val(),
        address2: $form.find('input[name$="_address2"]').val(),
        countryCode: $form.find('select[id$="_country"]').val(),
        stateCode: $form.find('select[id$="_state"]').val(),
        postalCode: $form.find('input[name$="_postal"]').val(),
        city: $form.find('input[name$="_city"]').val()
    };
    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 *  @param {String} [zipcode] zip code for updating shipping summary information.
 */
function updateShippingMethodList(zipcode) {
    var $shippingMethodList = $('#shipping-method-list');
    if (!$shippingMethodList.length) {
        // There is no 'shipping-method-list'
        return;
    }

    var $address = $('.address');
    if (!($address.find('input[name$="_addressFields_address1"]').val() &&
        $address.find('select[name$="_addressFields_states_state"]').val() &&
        $address.find('input[name$="_addressFields_city"]').val() &&
        $address.find('input[name$="_addressFields_postal"]').val())) {
        $address.addClass('hide-shipping-list');
    } else {
        $address.removeClass('hide-shipping-list');
    }

    var $giftFields = $('.js_gift-fields');
    if (!$giftFields.length) {
        // It's parcel delivery
        return;
    }

    var url = getShippingMethodURL(Urls.shippingMethodsJSON);

    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert('Couldn\'t get list of applicable shipping methods.');
                return false;
            }

            var $form = $('.address');
            var params = {
                countryCode: $form.find('select[id$="_country"]').val(),
                stateCode: $form.find('select[id$="_state"]').val(),
                postalCode: $form.find('input[name$="_postal"]').val(),
            };

            // indicate progress
            $('.js_shipping-method-list').addClass('updating');

            // load the shipping method form
            var smlUrl = getShippingMethodURL(Urls.shippingMethodsList);
            $shippingMethodList.load(smlUrl, function () {
                // update the summary
                if(typeof(zipcode) !== "undefined") {
                    updateSummary(3, zipcode, params);
                } else {
                    updateSummary(undefined, undefined, params);
                }
                $('.js_shipping-method-list').removeClass('updating');
                baCustomForms.init();
            });
        }
    });
}

/**
 * @description pushes shipping type info to the data layer via the checkutOption event
 * @param {String} shippingType - shipping type. parcel, premium-in-home or mixed
 * @param {String} shippingRate - shipping rate.
 */
function onCheckoutOption(shippingType, shippingRate) {
    var checkoutOption = null;
    if (shippingType == 'parcel') {
        checkoutOption = 'Parcel Delivery';
    } else if (shippingType == 'premium-in-home') {
        checkoutOption = 'Premium In Home Delivery [' + shippingRate + ']';
    } else if (shippingType == 'mixed') {
        checkoutOption = 'Premium In Home + Parcel Delivery [' + shippingRate + ']';
    }
    if (!checkoutOption) {
        return;
    }
    dataLayer = window.dataLayer || [];
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        'event': 'checkoutOption',
        'ecommerce': {
            'checkout_option': {
                'actionField': { 'step': 1, 'option': checkoutOption }
            }
        }
    });
}

function callUpdateSummary(deliveryValue) {
    var val = deliveryValue;
    let url;
    let $priceContainer = $('.js-cart-order-totals');

    if ($priceContainer.length) {
        url = Urls.getCartPrice;
    } else {
        $priceContainer = $('.js-checkout-order-totals').parent();
        url = Urls.summaryRefreshURL;
    }

    if (!$priceContainer.length) {
        return;
    }

    url = util.appendParamToURL(url, 'deliveryOption', val);
    ajax.load({
        url: url,
        target: $priceContainer,
        callback: function () {
            tooltip.init();
        }
    });
}

// Function to generate radio buttons dynamically
function generateShippingMethods() {
    const container = $('.main-shipping-method__title');
    const shippingMethods = container.data('deliveryoptions');

    if (!shippingMethods) {
        console.warn("No shipping methods found.");
        return null;
    }

    const baseShippingPrice = $('.js_shipping-value').data('baseshipping');
    const selectedDelivery = $('.js_shipping-value').data('selecteddelivery');
    const formattedShippingPrice = baseShippingPrice ? parseFloat(baseShippingPrice.match(/[0-9,.]+/)[0]) : 0;

    container.empty(); // Clear existing content

    // Sort shipping methods based on SortOrder
    const sortedMethods = Object.keys(shippingMethods)
        .filter(key => shippingMethods[key].Enable) // Only enabled methods
        .sort((a, b) => shippingMethods[a].SortOrder - shippingMethods[b].SortOrder);

    if (sortedMethods.length === 0) {
        console.warn("No enabled shipping methods available.");
        return null;
    }

    // Determine the default shipping method (first enabled one after sorting)
    let defaultMethod = sortedMethods[0];

    // If user previously selected a delivery method, use that
    if (selectedDelivery && sortedMethods.some(key => shippingMethods[key].value === selectedDelivery)) {
        defaultMethod = sortedMethods.find(key => shippingMethods[key].value === selectedDelivery);
    }

    let html = '';

    sortedMethods.forEach((method, index) => {
        const shippingMethod = shippingMethods[method];

        // Ensure correct comparison for checked state
        const isChecked = (method === defaultMethod) ? "checked" : "";

        // Calculate the final shipping price
        const finalPrice = shippingMethod.name === "Customer pick up"
            ? parseFloat(shippingMethod.ServiceFee)
            : parseFloat(shippingMethod.ServiceFee) + formattedShippingPrice;

        // Generate radio button markup
        html += `
            <label class="shipping-method__label" for="shipping_info_method_${index + 1}" style="display:flex;align-items: center;">
                <input class="delivery-radio" type="radio" id="shipping_info_method_${index + 1}"
                    name="shipping_info_method" value="${shippingMethod.value}" ${isChecked}
                    style="background-color: #fff; border: 1px solid #595959; color: #595959; display: block; height: 20px; width: 20px; z-index: 8;" />
                <span class="label">
                    ${shippingMethod.name}: $${finalPrice.toFixed(2)}
                </span>
            </label>
            <p class="body-medium main-shipping-method__body" style="margin-top:0;">${shippingMethod.Description}</p>
        `;
    });

    // Append all radio buttons at once
    container.append(html);

    return shippingMethods[defaultMethod].value;
}

function updateAddressType(addressId) {
    var url = Urls.updateAddressType;
    url = util.appendParamToURL(url, 'addressId', addressId);
    ajax.getJson({
        url: url,
        callback: function (data) {
            tooltip.init();
            let selectedValue = data.value; // Assuming response contains the selected value

            // Set value in the actual select element
            $('#dwfrm_singleshipping_shippingAddress_addressType').val(selectedValue).trigger('change');

            // Update the custom dropdown display
            $('.ba-shiv-content').text(selectedValue);

            // Update the selected class in the dropdown list
            $('.ba-options .option').removeClass('selected'); // Remove previous selection
            $('.ba-options .option[data-value="' + selectedValue + '"]').addClass('selected');
        },
        error: function(error) {
            console.error('Error:', error);
        }
    });
}

// Handle radio button click events
$(document).on('click', 'input[name="shipping_info_method"]', function () {
    callUpdateSummary($(this).val());
});

// Generate shipping methods when the page loads
$(window).on('load', function() {
    const shippingMethods = $('.main-shipping-method__title').data('deliveryoptions');
    const selectedDelivery = $('.js_shipping-value').data('selecteddelivery');

    if (shippingMethods) {
        const defaultMethod = generateShippingMethods();
        if (!selectedDelivery) {
            callUpdateSummary(defaultMethod);
        } else {
            callUpdateSummary(selectedDelivery);
        }
    }
});

// listener to enable/disable the Associate field when a referral store is picked
$('#dwfrm_singleshipping_storeReferral_associatedStore').change(function(){
    if ($(this).val().length > 0) {
        // a store is selected - make the associate field available and mandatory
        $('#dwfrm_singleshipping_storeReferral_associateName').removeAttr('disabled').attr('aria-required', 'true').addClass('required');
        $('span.associate-optional').hide();
        $('span.associate-required').show();
    } else {
        // a store is not selected - make the associate field unavailable (and not mandatory!)
        $('#dwfrm_singleshipping_storeReferral_associateName').attr('disabled', 'disabled').attr('aria-required', 'false').removeClass('required').blur();
        $('span.associate-optional').show();
        $('span.associate-required').hide();
    }
});

exports.init = function () {
    formPrepare.init({
        continueSelector: '[name$="shippingAddress_save"]',
        formSelector:'[id$="singleshipping_shippingAddress"]'
    });
    $('input[name$="_shippingAddress_isGift"]').on('formElement:clicked', function() {
        giftMessageBox();
    });

    $('.address').on('change',
        'input[name$="_addressFields_address1"], select[name$="_addressFields_states_state"], input[name$="_addressFields_postal"]', function(){
            var $postalCode = $("#dwfrm_singleshipping_shippingAddress_addressFields_postal");
            var $state = $('#dwfrm_singleshipping_shippingAddress_addressFields_states_state');

            if (!($postalCode.length && $state.length)) {
                return;
            }
            
            if (!($postalCode.valid() && $state.valid())) {
                return;
            }
            // updateShippingMethodList($postalCode.val());
            updateAddressType();
        }
    );

    var $giftMessageBox = $('.gift-message-text'),
        $giftMessageTextbox = $giftMessageBox.find('textarea'),
        giftMessageText = $giftMessageTextbox.val();
    $('.input-radio[name$="_isGift"]').on('click', function() {
        if ($(this).val() == 'false') {
            if ($giftMessageTextbox.val() !== '') {
                giftMessageText = $giftMessageTextbox.val();
                $giftMessageTextbox.val('').trigger('input').trigger('blur');
            } else {
                giftMessageText = '';
            }
        } else if ($giftMessageTextbox.val() == '' && typeof giftMessageText == 'string' && giftMessageText !== '') {
            $giftMessageTextbox.val(giftMessageText).trigger('input').trigger('blur');
            giftMessageText = '';
        }
    });

    $('.ascii').on('input', function() {
        if (!/^[\n\r -~]*$/.test($(this).val()) || !formPrepare.validateForm()) {
            $('[name$="shippingAddress_save"]').attr('disabled', 'disabled');
        } else {
            $('[name$="shippingAddress_save"]').removeAttr('disabled');
        }
    });

    $('.checkout-shipping').on('submit', function(e) {
        if ($('.gift-message-text textarea').length > 0 && !$('.gift-message-text textarea').valid()) {
            e.preventDefault();
            $('[name$="shippingAddress_save"]').attr('disabled','disabled');
        }
        onCheckoutOption($('.main-shipping-method__title').data('shippingtype'), $('.js_shipping-value').text().trim());
    });

    if (SitePreferences.GOOGLE_ADDRESS_AUTOCOMPLETE_ENABLED) {
        addressAutocomplete.init();
    }
    giftMessageBox();
    // updateShippingMethodList();

    $('span.associate-required').hide();

    tooltip.init();
    $(document).tooltip({
        items: '.tooltip-shipping',
        track: true,
        content: function () {
            return $(this).find('.tooltip-content').html();
        }
    });

    $('.clear-form-button').on('click', function() {
        // clearing inputs
        $('fieldset.reset-anchor-js input:text, fieldset.reset-anchor-js input[type="tel"]').val('');
        $('.state-row ul li:eq(0)').trigger('click');
    });

    //wire up click to show/hide the coupon code entry section
    $('body').on('click', '.coupon-fake-checkbox', function () {
        $('.cart-coupon-code').toggleClass('open')
    })

    // Wire up events to resize/relocate the coupon code field's label
    $('body').on('focusin', '#dwfrm_cart_couponCode', function () {
        $(this).parent().addClass('active');
    }).on('focusout', function () {
        if ($(this).val().length > 0) {
            return;
        } else {
            $(this).parent().removeClass('active');
        }
    })

    $('body').on('click', '#add-coupon', function(e) {
        e.preventDefault();
        var $couponWrapper = $('.cart-coupon-code');
        var code = $('input[name$="_couponCode"]').val();
        var $couponMessages = $('.cart-coupon-code .error, .cart-coupon-code .success');
        if ($couponMessages) {
            $couponMessages.remove();
        }
        if (code.length === 0) {
            $('<div/>', {
                "class": 'error',
                html: Resources.COUPON_CODE_MISSING
            }).appendTo($couponWrapper);
            return;
        }

        var url = util.appendParamsToUrl(Urls.addCoupon, {couponCode: code, format: 'ajax'});
        $.getJSON(url, function (data) {
            if (!data || !data.success) {
                $('<div/>', {
                    "class": 'error',
                    html: data.message
                }).appendTo($couponWrapper);
            } else {
                $('<div/>', {
                    "class": 'success',
                    html: Resources.COUPON_APPLIED
                }).appendTo($couponWrapper);
                updateSummary(3);
                progress.hide();
            }
        });
    });

    // trigger events on enter
    $('body').on('keydown', 'input[name$="_couponCode"]', function (e) {
        if (e.key === 13) {
            e.preventDefault();
            $addCoupon.trigger('click');
        }
    });

    $(document).ready(function(){
        var $emailWrapper = $('.shipping-email input[name*="dwfrm_singleshipping_shippingAddress_email_emailAddress"]');
        if ($emailWrapper.length) {
            $emailWrapper.parent().parent().addClass('input-focused');
            if ($('.js-email-pass').length) {
                $emailWrapper.parent().parent().next().addClass('input-focused');
            }
        }
    })
};

exports.updateShippingMethodList = updateShippingMethodList;
exports.updateAddressType = updateAddressType;
