'use strict';

var ajax = require('../../ajax'),
    tooltip = require('../../batooltip'),
    util = require('../../util');

function productSetImpressions() {
    var $mainContainer = $('#pdpMain');

    if (!($mainContainer.hasClass('pdp-bundle') || $mainContainer.hasClass('pdp-set') || $mainContainer.hasClass('pdp-set-alt') || $mainContainer.hasClass('js_pdp-fake-set'))) {
        return;
    }

    var $dataSource = $('.js-gtm-data-source');
    var customDimensionsString = $dataSource.attr('data-gtm-custom-dimensions');
    var currencyCodeString = $dataSource.attr('data-gtm-currency');

    if (!(customDimensionsString && customDimensionsString.length && currencyCodeString && currencyCodeString.length)) {
        return;
    }

    var customDimensions = JSON.parse(customDimensionsString);
    var obj = {
        event: 'eecEvent',
        eventCategory: 'Ecommerce',
        eventAction: 'Product Impressions',
        eventLabel: 'None',
        eventValue: 0,
        interactionEvent: false,
        'gtm.uniqueEventId': 10,
        ecommerce : {
            currencyCode: currencyCodeString,
            impressions: []
        }
    };

    $.extend(obj, customDimensions);

    $('.product-set-item').each(function () {
        var $impressionData = $(this).data('gaitem');

        if (typeof $impressionData === 'undefined' || $impressionData === null) {
            return true;
        }

        obj.ecommerce.impressions.push({
            name: $impressionData.name,
            id: $impressionData.id,
            price: $impressionData.price,
            category: $impressionData.category,
            variant: $impressionData.variant,
            dimension10: $impressionData.dimension10,
            dimension11: $impressionData.dimension11,
            brand: $impressionData.brand,
            list:  $impressionData.list
        });
    });

    if (obj.ecommerce.impressions.length > 0) {
        window.dataLayer.push(obj);
    }
}

function updateAddToCartButtons () {
    var $list = $('#product-set-list');
    if (!$('.fake-set').length && $list.length) {
        if ($list.find('.add-to-cart[disabled]').length > 0) {
            // product set does not have an add-to-cart button, but product bundle does
            $('#add-all-to-cart, #add-to-cart').attr('disabled', 'disabled');
        } else {
            $('#add-all-to-cart, #add-to-cart').removeAttr('disabled');
        }
    }
}

function updateImages () {
    var $cylindoViewerImages = $('.cylindo-viewer-container ul');
    var $setImages = $('.js-change-cylindo');
    var $cylindoImageTrigger = $('.js-cylindo-enabled');

    if (!($cylindoImageTrigger.length || $cylindoViewerImages.length || $setImages.length)) {
        return;
    }

    $setImages.replaceWith($cylindoViewerImages.clone().addClass('js-change-cylindo'));
}

// click on swatch for product set
function updateBuyWithAffirmButton () {
    if ($('#product-set-list').find('.add-to-cart[disabled]').length > 0) {
        $('#js-affirm-checkout-now').hide();
    } else {
        $('#js-affirm-checkout-now').show();
        document.dispatchEvent(new CustomEvent('affirm-checkout-button-rendered'));
    }
}

/**
 * Helper function to mrege cylindo features.
 * we use it because cylindo save data in arrays e.g. ['<key1>', '<val1>', '<key2>','<val2>']
 * @param {Array} previousCylindoFeatures - old values wich we should merge e.g. ['<key1>', '<val1>', '<key2>','<val2>']
 * @param {Array} newCylindoFeatures - new values wich we should merge e.g. ['<key2>','<val2_2>']
 * @returns {Array} - Final cylindoObject ['<key1>', '<val1>', '<key2>','<val2_2>']
 */
function mergeFeatures(previousCylindoFeatures, newCylindoFeatures) {
    let mergedFeaturesArr = previousCylindoFeatures.concat(newCylindoFeatures);
    let filteredFeaturesArr = [];
    for (var i=0; i < mergedFeaturesArr.length; i++) {
        let currentEl = mergedFeaturesArr[i];
        if (i !== mergedFeaturesArr.lastIndexOf(currentEl)) {
            i++;
        } else {
            filteredFeaturesArr.push(currentEl)
        }
    }
    return filteredFeaturesArr;
}

/**
 * Helper function to update the cylindo images.
 * This will update the product and features, if necessary.
 * @param {Object} cylindoData jQuery response
 */
function updateCylindoImages(cylindoData) {
    let $responseCylindoProductId = cylindoData,
        responseCylindoFeatures,
        cylindoUpdates = {};

    if (!$responseCylindoProductId.length) {
        return;
    }

    responseCylindoFeatures = $responseCylindoProductId.data('features');

    // Update the Cylindo features, if necessary
    if (responseCylindoFeatures) {
        const $cylindoViewer = $('#product-cylindo-viewer');
        const savedFeatures = $cylindoViewer.data('features')
        const defaultFeatures = $cylindoViewer.data('cylindoconfig')
        let features = [];

        if (savedFeatures) {
            features = savedFeatures;
        } else if (defaultFeatures && defaultFeatures.features) {
            features = defaultFeatures.features;
        }
        if (features.length) {
            cylindoUpdates.features = mergeFeatures(features, responseCylindoFeatures);
        } else {
            cylindoUpdates.features = responseCylindoFeatures;
        }
        $cylindoViewer.data('features', cylindoUpdates.features);
    }

    $(document).trigger('cylindoviewer:update', cylindoUpdates);
}

/**
 * Formats a integer into a formated currency based string
 *
 * @param price - int
 * @param places
 * @param symbol
 * @param thousand
 * @param decimal
 * @returns {string}
 */
function formatMoney(price, places, symbol, thousand, decimal) {
    places = !isNaN(places = Math.abs(places)) ? places : 2;
    symbol = symbol !== undefined ? symbol : '$';
    thousand = thousand || ',';
    decimal = decimal || '.';

    var number = price,
        negative = number < 0 ? '-' : '',
        i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + '',
        j = (j = i.length) > 3 ? j % 3 : 0;

    return symbol + negative + (j ? i.substr(0, j) + thousand : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : '');
}

function updateProductSetPrice() {
    var $priceWrappers = $('.js-add-sub-product .product-price'),
        qty = 1;

    // main Add to cart price
    var overallPriceContainer = $('.js-add-all-to-cart-wrapper .product-price');

    var productSetStandardPrice = 0, productSetSalePrice = 0, productSetStandardClubPrice = 0, productSetSaleClubPrice = 0;


    $priceWrappers.each(function(){
        var currentPriceWrapper = $(this);
        qty = parseInt(currentPriceWrapper.closest('.add-sub-product').find('.sub-product-quantity').val()) || qty;

        var productStandardPrice = currentPriceWrapper.find('.product-price-non-member .price-standard').first().attr('content');
        var productStandardTotalPrice = productStandardPrice ? productStandardPrice * qty : 0;
        productSetStandardPrice += productStandardTotalPrice;

        var productSalePrice = currentPriceWrapper.find('.product-price-non-member .price-sales').first().attr('content');
        var productSaleTotalPrice = productSalePrice ? productSalePrice * qty : productStandardTotalPrice;
        productSetSalePrice += productSaleTotalPrice;

        var productStandardClubPrice = currentPriceWrapper.find('.product-club-pricing .price-standard').first().attr('content');
        var productStandardClubTotalPrice = productStandardClubPrice ? productStandardClubPrice * qty : 0;
        productSetStandardClubPrice += productStandardClubTotalPrice;


        var productSaleClubPrice = currentPriceWrapper.find('.product-club-pricing .price-sales').first().attr('content');
        var productSaleClubTotalPrice = productSaleClubPrice ? productSaleClubPrice * qty : productStandardClubTotalPrice;
        productSetSaleClubPrice += productSaleClubTotalPrice;
    });
    if (productSetSaleClubPrice > 0 && productSetSaleClubPrice < productSetStandardClubPrice){
        overallPriceContainer.find('.product-club-pricing .price-standard').first().text(formatMoney(productSetStandardClubPrice)).addClass('line-through');
        overallPriceContainer.find('.product-club-pricing .price-sales').first().text(formatMoney(productSetSaleClubPrice)).removeClass('hide-always');
    } else {
        overallPriceContainer.find('.product-club-pricing .price-standard').text(formatMoney(productSetStandardClubPrice));
    }
    if(productSetSalePrice > 0 && productSetSalePrice < productSetStandardPrice) {
        overallPriceContainer.find('.product-price-non-member .price-standard').first().text(formatMoney(productSetStandardPrice)).addClass('line-through');
        overallPriceContainer.find('.product-price-non-member .price-sales').first().text(formatMoney(productSetSalePrice)).removeClass('hide-always');
    } else {
        overallPriceContainer.find('.product-price-non-member .price-standard').text(formatMoney(productSetStandardPrice));
    }
}

module.exports = function () {
    var $productSetList = $('#product-set-list, .js_suggested-products');

    if ($productSetList.length > 0) {
        updateAddToCartButtons();
    }

    // click on swatch for product set
    $productSetList.on('click', '.product-set-item .swatchanchor', function (e) {
        e.preventDefault();
        if ($(this).parents('li').hasClass('unselectable')) { return; }
        var url = Urls.getSetItem + this.search;
        var $container = $(this).closest('.product-set-item');
        var qty = $container.find('form input[name="Quantity"]').first().val();

        if ($(this).closest('.js_product-set-item__alt').length) {
            url = util.appendParamToURL(url, 'alt', 'true');
        }

        ajax.load({
            url: util.appendParamToURL(url, 'Quantity', isNaN(qty) ? '1' : qty),
            target: $container,
            callback: function (response) {
                const $response = $(response);
                updateAddToCartButtons();
                updateBuyWithAffirmButton();
                $(document).trigger('product:update', [response, true]);
                tooltip.init();
                $(document).trigger('update:selects');
                updateCylindoImages($response.find('.js-cylindo-options'));
                updateProductSetPrice();
            }
        });
    });

    $productSetList.on('change', '.product-set-item .variation-select', function (e) {
        e.preventDefault();
        if ($(this).parents('li').hasClass('unselectable')) { return; }
        var customUrl = new URL($(this).find('option:selected').val());
        var url = Urls.getSetItem + customUrl.search;
        var $container = $(this).closest('.product-set-item');
        var qty = $container.find('form input[name="Quantity"]').first().val();
        ajax.load({
            url: util.appendParamToURL(url, 'Quantity', isNaN(qty) ? '1' : qty),
            target: $container,
            callback: function (response) {
                updateAddToCartButtons();
                $(document).trigger('product:update', [response, true]);
                tooltip.init();
                $(document).trigger('update:selects');
                updateProductSetPrice();
            }
        });
    });

    productSetImpressions();

    $(document)
        .on('update:qty', function(){
            updateProductSetPrice();
        })
        .on('updateSetImages', function(){
            updateImages();
        })
        .ready(updateProductSetPrice);
}
