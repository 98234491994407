/**
 * @package     Blueacorn/cart
 * @version     2.0
 * @author      Blue Acorn <code@blueacorn.com>
 * @copyright   Copyright © 2016 Blue Acorn.
 */

(function (root, factory) {
    if (typeof exports === 'object') {
        // CommonJS
        module.exports = factory(
            window.jQuery,
            require('./blueacorn-core'),
            require('../vendor/enquire/enquire.min'),
            require('imagesloaded')
        );
    } else if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery', 'baCore', 'enquireJs','imagesloaded'], function (jquery, ba, enquire, imagesloaded) {
            return (factory(jquery, ba, productSticky, imagesloaded));
        });
    } else {
        // Global Variables
        root.ba.product = factory(root.jQuery, root.ba, root.enquire, root.productSticky);
    }

}(this, function ($, ba, enquire, productSticky, imagesloaded) {
    'use strict';

    var cart = {
        settings: {
            productCarouselLimit: 4
        },

        init: function(options){

            // Overrides the default settings
            ba.overrideSettings(this.settings, options);

            // Start the debugger
            ba.setupDebugging(this.settings);

            this.checkCourselArrows();
            this.productCarouselInit();
            this.refreshCarouselArrows();
            this.checkFeaturedProducts();
            this.predictiveRecsCarouselInit();
            this.cartpredictiveRecsCarouselInit();
        },

        /**
         * if no featured products in content slot,
         * don't show the block at all
         */
        checkFeaturedProducts: function () {
            var $element = $('.featured-products');

            if ($element.find('.search-result-items').children('li').length >= 0) {
                $element.hide();
            }
        },

        productCarouselInit: function() {
            /*
             * TODO: Code Cleanup
             * this is virtually identical to product.productRecentlyViewedCarousel in
             * blueacorn-product.js
             *
             */
            var $container = $('.js-product-carousel .tiles-container'),
                self = this;

            if ($container.children().length > 1){
                this.productCarousel = $container
                    .on('initialized.owl.carousel changed.owl.carousel', function() {
                        $(document).trigger('custom:lazy:load:update');
                    })
                    .on('initialized.owl.carousel', function() {
                        $(document).trigger('custom:owl:carousel:initialized', {
                            carousel: $(this)
                        });
                    })
                    .owlCarousel({
                        loop: true,
                        nav: true,
                        items: 4,
                        dots: false,
                        responsive: {
                            0: {
                                items: 2
                            },
                            1128: {
                                items: 3
                            },
                            1440: {
                                items: 4
                            }
                        }
                    });

                $container.each(function(i, current) {
                    if ($(current).data('owlCarousel')._items.length >= self.settings.productCarouselLimit || ($('html').hasClass('resp-mobile') && $(current).data('owlCarousel')._items.length !== 1)) {
                        $(current).find('.owl-controls').show();
                    } else {
                        $(current).addClass('no-carousel');
                    }
                });
            }
        },

        checkCourselArrows: function() {
            /*
             * TODO: Code Cleanup
             * this is identical to product.checkCarouselArrows in
             * blueacorn-product.js
             *
             */
            var self = this;

            enquire
                .register('screen and (min-width:' + ba.bp.xxsmall + 'px) and (max-width:459px)', function(){
                    self.settings.productCarouselLimit = 1;
                    self.settings.collectionsCarouselLimit = 1;
                })
                .register('screen and (min-width:450px) and (max-width:' + ba.bp.medium + 'px)', function(){
                    self.settings.productCarouselLimit = 2;
                    self.settings.collectionsCarouselLimit = 1;
                })
                .register('screen and (min-width:' + (ba.bp.medium + 1) + 'px)', function(){
                    self.settings.productCarouselLimit = 4;
                    self.settings.collectionsCarouselLimit = 3;
                });
        },

        refreshCarouselArrows: function() {
            /*
             * TODO: Code Cleanup
             * this is identical to product.refreshCarouselArrows in
             * blueacorn-product.js
             *
             */
            var whiteList = [this.productCarousel],
                self = this, $container, item, limit;

            $.each(whiteList, function(index){
                $container = whiteList[index];

                if (typeof $container !== 'undefined'){
                    $container.on('resized.owl.carousel', function(event) {
                        var $that = $(this);
                        item = event.item.count;
                        limit = ($that.parent('#collection-pieces').length > 0) ? self.settings.collectionsCarouselLimit : self.settings.productCarouselLimit;

                        if (item >= limit){
                            $that.find('.owl-controls').show();
                        } else {
                            $that.find('.owl-controls').hide();
                        }
                    });
                }
            });
        },

        predictiveRecsCarouselInit: function() {
            var self = this;

            var intervalID = setInterval(function () {
                var $container = $('#predictive-recommendations .tiles-container');
                if ($container.children().length > 0) {
                    self.prodRecsCarouselInit();
                    clearInterval(intervalID);
                    self.changePriceDisplay();
                }
            }, 500);
        },

        prodRecsCarouselInit: function() {
            var self = this;
            var $container = $('#predictive-recommendations .tiles-container');

            // Remove lazy load on the iOS Safari
            $container.find('img.lazy-load').each(function (index, current) {
                $(current).attr('src', $(current).data('lazy-src'));
            });

            if ($container.closest('.carousel-refresh').length > 0) {
                var $items = $container.find('.js_grid-tile');
                var itemsCount = $items.length;

                $container.closest('.pagewrap').removeClass('pagewrap');
                $container.closest('.cart-recommendations').removeClass('cart-recommendations');
                $container.closest('.recommendations').removeClass('recommendations');
                $container
                    .on('initialized.owl.carousel changed.owl.carousel', function() {
                        $(document).trigger('custom:lazy:load:update');
                    })
                    .on('initialized.owl.carousel', function() {
                        $(document).trigger('custom:owl:carousel:initialized', {
                            carousel: $(this)
                        });
                    })
                    .owlCarousel({
                        loop: true,
                        mouseDrag: true,
                        nav: true,
                        navRewind: false,
                        responsive: {
                            0: {
                                items: 2,
                                nav: false,
                                dots: itemsCount > 2
                            },
                            1128: {
                                items: 3,
                                dots: itemsCount > 3
                            },
                            1440: {
                                items: 4,
                                dots: itemsCount > 4
                            }
                        }
                    });
            } else if ($container.children().length > 1){
                if ($(window).width() < 1024) {
                    $container.closest('.cart-recommendations').appendTo('.main-pagewrap');
                }
                this.productCarousel = $container
                    .on('initialized.owl.carousel changed.owl.carousel', function() {
                        $(document).trigger('custom:lazy:load:update');
                    })
                    .on('initialized.owl.carousel', function() {
                        $(document).trigger('custom:owl:carousel:initialized', {
                            carousel: $(this)
                        });
                    })
                    .owlCarousel({
                        loop: true,
                        nav: true,
                        items: 4,
                        dots: false,
                        responsive: {
                            0: {
                                items: 2,
                                dots: true
                            },
                            1128: {
                                items: 3
                            },
                            1440: {
                                items: 4
                            }
                        }
                    });

                $container.each(function(i, current) {
                    if ($(current).data('owlCarousel')._items.length >= self.settings.productCarouselLimit || ($('html').hasClass('resp-mobile') && $(current).data('owlCarousel')._items.length !== 1)) {
                        $(current).find('.owl-controls').show();
                        $(current).find('.club_price').addClass('logged-in');
                    } else {
                        $(current).addClass('no-carousel');
                    }
                });
            }
        },

        cartpredictiveRecsCarouselInit: function() {
            var self = this;

            var intervalID = setInterval(function () {
                var $container = $('#cart-predictive-recommendations .tiles-container');
                if ($container.children().length > 0) {
                    self.cartProdsRecsCarouselInit();
                    clearInterval(intervalID);
                    self.changePriceDisplay();
                }
            }, 500);
        },

        cartProdsRecsCarouselInit: function() {
            var $container = $('#cart-predictive-recommendations .tiles-container');
            var $items = $container.find('.js_grid-tile');
            var itemsCount = $items.length;

            // Remove lazy load on the iOS Safari
            $container.find('img.lazy-load').each(function (index, current) {
                $(current).attr('src', $(current).data('lazy-src'));
            });

            $container
                .on('initialized.owl.carousel changed.owl.carousel', function() {
                    $(document).trigger('custom:lazy:load:update');
                })
                .on('initialized.owl.carousel', function() {
                    $(document).trigger('custom:owl:carousel:initialized', {
                        carousel: $(this)
                    });
                })
                .owlCarousel({
                    loop: true,
                    nav: true,
                    responsive: {
                        0: {
                            items: 2,
                            nav: false,
                            dots: itemsCount > 2
                        },
                        1128: {
                            items: 3,
                            dots: itemsCount > 3
                        }
                    }
                });
        },

        changePriceDisplay: function() {
            if ($('.login-link a').attr('title') === 'Log Out') {
                // Add logged-in class to product-price for styling
                $('ul.tiles-container').find('.product-pricing').addClass('logged-in');
            }
        }
    }

    $(document).on('baCoreReady', function() {
        if (typeof pageContext !== 'undefined' && pageContext.ns === 'cart'){
            cart.init();
        }
    });

    return cart;
}));
