'use strict';

var dialog = require('./dialog'),
    product = require('./pages/product'),
    util = require('./util'),
    _ = require('lodash');


var makeUrl = function (url, source, productListID) {
    if (source) {
        url = util.appendParamToURL(url, 'source', source);
    }
    if (productListID) {
        url = util.appendParamToURL(url, 'productlistid', productListID);
    }
    return url;
};

var quickview = {
    settings: {
        swatchCarouselLimitPS: 4,
        swatchCarouselLimit: 9
    },

    init: function () {
        var self = this;

        if (!this.exists()) {
            this.$container = $('<div/>').attr('id', 'QuickViewDialog').addClass('quickview-content').appendTo(document.body);
        }
        this.productLinks = $('#search-result-items .thumb-link').map(function (index, thumbLink) {
            return $(thumbLink).attr('href');
        });

        $(document).on('product:update', this.reinitialize.bind(self));
    },

    setup: function () {
        this.productThumbsCarousel();

        product.initializeEvents();
        this.qtyButtonObservers();
        this.tabsObserver();
    },

    gtmEvents: function () {
        var data = $('.js_gtm-qv').data('gtm-options');
        if (data) {
            window.dataLayer = window.dataLayer || [];

            if (Array.isArray(data)) {
                window.dataLayer = window.dataLayer.concat(data);
            } else {
                window.dataLayer.push(data);
            }
        }
    },

    /**
     * @description show quick view dialog
     * @param {Object} options
     * @param {String} options.url - url of the product details
     * @param {String} options.source - source of the dialog to be appended to URL
     * @param {String} options.productlistid - to be appended to URL
     * @param {Function} options.callback - callback once the dialog is opened
     */
    show: function (options) {
        var self = this;
        var url;
        if (!this.exists()) {
            this.init();
        }
        url = makeUrl(options.url, options.source, options.productlistid);

        var dialogOptions = {
            width: 955,
            resizable: false,
            draggable: false,
            open: function () {
                self.setup();
                self.gtmEvents();
                var dialog = $(this);
                dialog.dialog('option', 'position', { my: 'center', at: 'center', of: window });

                $(document)
                    .off('click.outside_qv')
                    .on('click.outside_qv', '.ui-widget-overlay', function () {
                        dialog.dialog('close');
                    });

                if (typeof options.callback === 'function') {
                    options.callback();
                }

                $(document).trigger('custom:lazy:load:update');
                $('.js_ui-dialog-quickview').focus();
            },
            close: function () {
                $(document).off('click.outside_qv');
            }
        }

        if (options.title) {
            dialogOptions.title = options.title;
        }

        if (options.dialogClass) {
            dialogOptions.dialogClass  = options.dialogClass;
        }

        dialog.open({
            target: this.$container,
            url: url,
            options: dialogOptions
        });
    },

    exists: function () {
        return this.$container && (this.$container.length > 0);
    },

    /* CODE CLEANUP:
     * The following 2 methods are pulled from blueacorn/blueacorn-product.js
     * there should probably be a more modular approach
     */
    qtyButtonObservers: function() {
        var self = this,
            $inventory = this.$container.find('.main-ctas .inventory');

        $inventory.children('.js_custom-iterator').children('.minus').on('click', self.changeQtyInput.bind(self));
        $inventory.children('.js_custom-iterator').children('.plus').on('click', self.changeQtyInput.bind(self));
    },

    /**
     * Add/Subtracts QTY from the qty input based on the plus and minus triggers
     *
     * @param event
     */
    changeQtyInput: function(event) {
        var $qtyInput = $('.input-quantity'),
            $qty = parseInt($qtyInput.val()),
            $target = $(event.target);

        if ($target.closest('.js_custom-iterator').hasClass('disabled')) {
            return;
        }

        // If on product set, we change the input to the current sub item
        if ($target.parent().children('.sub-product-quantity').length > 0){
            $qtyInput = $target.parent().children('input');
            $qty = parseInt($qtyInput.val());
        }

        if ($target.hasClass('minus') && $qty > 1) {
            $qtyInput.val(($qty - 1));
        }

        if ($target.hasClass('plus')) {
            $qtyInput.val(($qty + 1));
        }
    },

    addSelectedValue: function(element, selectedValue, withSeperator) {
        /*
         * TODO: Code Cleanup
         * this is identical to product.addSelectedValue in
         * blueacorn-product.js
         *
         */
        element.html((withSeperator ? '<span>|</span>' : '') + selectedValue);
    },

    reinitialize: function() {
        this.productThumbsCarousel();
        this.qtyButtonObservers();
    },

    productThumbsCarousel: function () {
        var $thumbnailWrapper = $('#thumbnails'),
            $slides = $thumbnailWrapper.children('.slides');

        $slides.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
        $slides.find('.owl-stage-outer').children().unwrap();

        $slides
            .on('initialized.owl.carousel changed.owl.carousel', function() {
                $(document).trigger('custom:lazy:load:update');
            })
            .on('initialized.owl.carousel', function() {
                $(document).trigger('custom:owl:carousel:initialized', {
                    carousel: $(this)
                });
            })
            .owlCarousel({
                nav: true,
                loop: true,
                navRewind: false,
                items: 4,
                margin: 20,
                dots: false,
                navText: ['', '']
            });

        $thumbnailWrapper.css('visibility', 'visible');
    },

    tabsObserver: function () {
        $(document).on('click', '.js_qv-vd__tab', function () {
            $(this).closest('.js_qv-vd').attr('data-active-tab', $(this).data('tab'));
        });
    }
};

module.exports = quickview;
