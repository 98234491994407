'use strict';

var video = {
    getDimensions: function () {
        var dimensions = {
            height: '390',
            width: '640',
        };

        var $imageContainer = $('#pdpMain .product-primary-image');

        if ($imageContainer.length) {
            dimensions.height = $imageContainer.height();
            dimensions.width = '100%';
        }

        return dimensions;
    },

    setDimensions: function (dimensions) {
        var self = this;

        if (!self.player) {
            return;
        }

        if (!dimensions) {
            dimensions = self.getDimensions();
        }

        $('.product-video-player').attr({
            'height': dimensions.height,
            'width': dimensions.width
        });
    },

    play: function () {
        if (!(this.player && typeof this.player.playVideo === 'function')) {
            return;
        }

        this.player.playVideo();
    },

    pause: function () {
        if (!(this.player && typeof this.player.pauseVideo === 'function')) {
            return;
        }

        this.player.pauseVideo();
    },

    stop: function () {
        if (!(this.player && typeof this.player.stopVideo === 'function')) {
            return;
        }

        this.player.stopVideo();
    },

    onPlayerReady: function () {
        var self = this;
        var $tempVideoImage = $('.js_product-video-player-temp-image');

        if ($('#product-video-player').is(':visible')) {
            self.play();
        }

        var dimensions = self.getDimensions();

        if ($tempVideoImage.length) {
            dimensions.height = $tempVideoImage.height();
            $tempVideoImage.remove();
        }

        self.setDimensions(dimensions);
    },

    onPlayerStateChange: function (e) {
        if (e.data === YT.PlayerState.ENDED) {
            this.play();
        }
    },

    init: function () {
        var $productVideoPlayer = $('#product-video-player');
        if (!$productVideoPlayer.length) {
            return;
        }

        var self = this;
        var dimensions = self.getDimensions();
        var videoID = $productVideoPlayer.data('video-id');

        try {
            self.player = new YT.Player('product-video-player', {
                height: 1,
                width: dimensions.width,
                videoId: videoID,
                playerVars: {
                    autoplay: 1,
                    controls: 0,
                    enablejsapi: 1,
                    loop: 1,
                    modestbranding: 1,
                    mute: 1,
                    playsinline: 1,
                    rel: 0
                },
                events: {
                    'onReady': self.onPlayerReady.bind(self),
                    'onStateChange': self.onPlayerStateChange.bind(self)
                }
            });
        } catch (e) {
            window.console.error('The YouTube API has not been downloaded yet.');
        }
    }
};

module.exports = video;
