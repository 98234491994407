'use strict';

/**
 * https://www.andreaverlicchi.eu/vanilla-lazyload/
 *
 */

var LazyLoad = require('vanilla-lazyload');

var lazyLoadInstance;

function loaded() {
    $(document).trigger('custom:lazy:load:loaded');
    $(document).trigger('custom:set:grid:slot');
}

function enter(el) {
    if ($(el).hasClass('lazy-load-bg') && !$(el).hasClass('lazy-load-bg-moved')) {
        $(el)
            .addClass('lazy-load-bg-moved')
            .attr('style', $(el).attr('style') + $(el).data('lazy-load-bg'));
    }
}

function initLazyLoadInstance() {
    lazyLoadInstance = new LazyLoad({
        elements_selector: 'img.lazy-load, iframe.lazy-load, .lazy-load-bg',
        threshold: 50,
        data_src: 'lazy-src',
        data_srcset: 'lazy-srcset',
        unobserve_entered: true,
        callback_enter: enter,
        callback_loaded: loaded
    });
}

function preloadImage(url) {
    var img = new Image();
    img.src = url;
}

function initDefaultImage() {
    preloadImage(Urls.defaultTileImageUrl);
}

function lazyLoadUpadte() {
    $(document).trigger('custom:handle:image:error');
    initDefaultImage();

    if (!lazyLoadInstance) {
        initLazyLoadInstance();
    }

    lazyLoadInstance.update();
}

var lazyLoad = {
    init: function () {
        lazyLoadUpadte();
        $(document).on('custom:lazy:load:update', lazyLoadUpadte);
    }
};

module.exports = lazyLoad;
