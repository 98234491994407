'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    progress = require('../../progress'),
    util = require('../../util'),
    cart = require('../cart'),
    addressAutocomplete = require('../../address-autocomplete'),
    creditCard = require('../../creditCard');
/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
    var $creditCard = $('[data-method="CREDIT_CARD"]');
    var $form = $('form.checkout-billing');
    $creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger('change');
    $creditCard.find('select[name$="_type"]').val(data.type).trigger('change');
    $creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');
    $creditCard.find('input[name*="_creditCard_expirationdate"]').val(data.expirationdate).trigger('change');
    $creditCard.find('input[name*="_creditCard_cvn"]').val('').trigger('change');

    if (data.type) {
        if (data.type === 'Amex') {
            util.toggleCreditCardMask($form, true, 'ZZZZ ZZZZZZ ZZ000');
        } else {
            util.toggleCreditCardMask($form, true, 'ZZZZ ZZZZ ZZZZ Z000');
        }
        $('.card-icons .' + data.type.toLowerCase()).css('display', 'block');
    }
    $(document).trigger('update:form:inputs');
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID) {
    // load card details
    var url = util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID);
    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert(Resources.CC_LOAD_ERROR);
                return false;
            }
            setCCFields(data);
        }
    });
}

function onCheckoutOption(paymentMethodID) {
    var step = paymentMethodID;
    var checkoutOption = paymentMethodID
    if (paymentMethodID === 'DIGITALBUY_PAY') {
        step = 'MGBW Credit';
        checkoutOption = 'MGBW Credit';
    }
    window.dataLayer.push({
        event: 'eecEvent',
        eventCategory: 'Ecommerce',
        eventAction: 'Checkout Option',
        eventLabel: checkoutOption,
        eventValue: 0,
        interactionEvent: true,
        ecommerce: {
            checkout_option: {
                actionField: {
                    step: 2,
                    option: checkoutOption,
                    action: 'checkout_option'
                }
            }
        }
    });
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID) {
    var $paymentMethods = $('.payment-method'),
        $addressFields = $('.address-fieldset'),
        $continueButtonContainer = $('.regular-billing'),
        $digitalBuyButton = $('.digital-buy-billing'),
        isSameAsShipping = checkBillingOption();

    $paymentMethods.removeClass('payment-method-expanded');

    var $selectedPaymentMethod = $paymentMethods.filter('[data-method="' + paymentMethodID + '"]');
    // If payment method not found default to CC
    if ($selectedPaymentMethod.length === 0 && paymentMethodID !== 'DIGITALBUY_PAY' && paymentMethodID !== 'Affirm') {
        $selectedPaymentMethod = $('[data-method="CREDIT_CARD"]');
    }

    // hide address fields if the user selects digital buy payment method
    if (paymentMethodID === 'DIGITALBUY_PAY') {
        $addressFields.hide();
        $continueButtonContainer.hide();
        $digitalBuyButton.show();
        $selectedPaymentMethod = $('[data-method="DIGITALBUY_PAY"]');
    } else if (paymentMethodID === 'Affirm') {
        $addressFields.hide();
        $digitalBuyButton.hide();
        $continueButtonContainer.show();
        $selectedPaymentMethod = $('[data-method="Affirm"]');
    }
    else {
        $digitalBuyButton.hide();
        if (!isSameAsShipping) {
            $addressFields.show();
        }
        $continueButtonContainer.show();
        if ($selectedPaymentMethod.length === 0) {
            $selectedPaymentMethod = $('[data-method="Custom"]');
        }
    }

    $selectedPaymentMethod.addClass('payment-method-expanded');
    $('button[name$="billing_save"]').text($selectedPaymentMethod.attr('data-cta-text'));

    // ensure checkbox of payment method is checked
    $('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
    $('input[value=' + paymentMethodID + ']').prop('checked', 'checked');

    formPrepare.validateForm();
}

/**
 * @function checkBillingOption
 * @description check if billing addres checkbox is on same as shipping or on new address
 */
function checkBillingOption() {
    return $('#dwfrm_billing_billingAddress_sameAsShipping').next().hasClass('checked')
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary(checkoutStep) {
    var $summary = $('#secondary');
    // indicate progress
    progress.show($summary);

    var url = !checkoutStep ? Urls.summaryRefreshURL : util.appendParamsToUrl(Urls.summaryRefreshURL, {checkoutStep: checkoutStep});
    // load the updated summary area
    $.ajax(url)
    .done(function (response) {
        // hide edit shipping method link
        $summary.empty().html(response);
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();

        cart.calculateComfortClubSavings();
        // exports.init();
        tooltip.init();
    });
}

function moveToDeclinedError() {
    var $anchor = $('.js_card-decline-error');
    if ($anchor.length) {
        util.scrollBrowser($anchor.eq(0).offset().top);
    }
}

$(document).on('ready', function() {
    setTimeout(moveToDeclinedError, 500);
});

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
exports.init = function () {
    var $checkoutForm = $('.checkout-billing');
    var $selectPaymentMethod = $('.payment-method-options');
    var $ccNum = $('input[name*="_creditCard_number"]');
    var $sameAsShipping = $('.same-as-shipping');
    formPrepare.init({
        formSelector: 'form[id$="billing"]',
        continueSelector: '[name$="billing_save"]'
    });
    if (SitePreferences.GOOGLE_ADDRESS_AUTOCOMPLETE_ENABLED) {
        addressAutocomplete.init();
    }
    // default payment method to 'CREDIT_CARD'
    updatePaymentMethod('CREDIT_CARD');
    $selectPaymentMethod.on('click', '.payment-method-radio', function () {
        updatePaymentMethod($(this).val());
    });

    // select credit card from list
    $('[name$="_creditCardList"]').on('change', function () {
        var cardUUID = $(this).val();
        if (!cardUUID) {
            $(document).find('[data-value="Visa"]').trigger('click');
            return;
        }
        populateCreditCardForm(cardUUID);

        // remove server side error
        $('.required.error').removeClass('error');
        $('.error-message').remove();
    });

    // Credit card type detection (returns credit card name when found)
    $ccNum.on('keypress change', function () {
        creditCard.detectCreditCard($(this))
    });

    //wire up click to show/hide the coupon code entry section
    $('body').on('click', '.coupon-fake-checkbox', function () {
        $('.cart-coupon-code').toggleClass('open')
    })

    // Wire up events to resize/relocate the coupon code field's label
    $('body').on('focusin', '#dwfrm_cart_couponCode', function () {
        $(this).parent().addClass('active');
    }).on('focusout', function () {
        if ($(this).val().length > 0) {
            return;
        } else {
            $(this).parent().removeClass('active');
        }
    })

    $('body').on('click', '#add-coupon', function(e) {
        e.preventDefault();
        var $couponWrapper = $('.cart-coupon-code');
        var code = $('input[name$="_couponCode"]').val();
        var $couponMessages = $('.cart-coupon-code .error, .cart-coupon-code .success');
        if ($couponMessages) {
            $couponMessages.remove();
        }
        if (code.length === 0) {
            $('<div/>', {
                "class": 'error',
                html: Resources.COUPON_CODE_MISSING
            }).appendTo($couponWrapper);
            return;
        }

        var url = util.appendParamsToUrl(Urls.addCoupon, {couponCode: code, format: 'ajax'});
        $.getJSON(url, function (data) {
            if (!data || !data.success) {
                $('<div/>', {
                    "class": 'error',
                    html: data.message
                }).appendTo($couponWrapper);
            } else {
                $('<div/>', {
                    "class": 'success',
                    html: Resources.COUPON_APPLIED
                }).appendTo($couponWrapper);
                updateSummary(4);
                progress.hide();
            }

        });
    });
    $('body').on('click', '#dwfrm_billing .coupon-fields .redemption.coupon button[name*="_deleteCoupon"]', function (e) {
        e.preventDefault();
        window.location = Urls.billingDeleteCoupon;
    });

    // trigger events on enter
    $('body').on('keydown', 'input[name$="_couponCode"]', function (e) {
        if (e.key === 13) {
            e.preventDefault();
            $addCoupon.trigger('click');
        }
    });

    // trigger google tag manager datalayer function on billing page

    $(document).on('click', '[name$="billing_save"]', function() {
        onCheckoutOption($('.payment-method-options').find('.payment-method-radio:checked').val());
    });

    $(document).on('submit', '#dwfrm_billing', function() {
        $('button[name$="billing_save"]').attr('disabled','disabled');
    });

    var cardUUID = $('#creditCardList').val();
    if (!cardUUID) {
        $(document).find('[data-value="Visa"]').trigger('click');
    }

    var $form = $('form.checkout-billing');
    util.toggleCreditCardMask($form, true, 'ZZZZ ZZZZ ZZZZ 0000');
    creditCard.updateCardMask($checkoutForm);

    var $shippingAddress = $sameAsShipping.find('.address'),
        $sameAsShippingRadio = $sameAsShipping.find('.form-row .field-wrapper').eq(0);

    if ($shippingAddress.length > 0 && $sameAsShippingRadio.length > 0) {
        $sameAsShippingRadio.append($shippingAddress);
    }
    $('.clear-form-button').click(function() {
        // clearing inputs
        $('fieldset.reset-anchor-js input:text, fieldset.reset-anchor-js input[type="tel"]').val('');
        $('.state-row ul li:eq(0)').trigger('click');
    });
    $('input[value="true"][name="dwfrm_billing_billingAddress_sameAsShipping"]').on('click', function () {
        var $elem = $(this).closest('fieldset');
        $('#dwfrm_billing_billingAddress_addressFields_firstName').val($elem.attr('data-address-firstname'));
        $('#dwfrm_billing_billingAddress_addressFields_lastName').val($elem.attr('data-address-lastname'));
        $('#dwfrm_billing_billingAddress_addressFields_address1').val($elem.attr('data-address-address1'));
        if ($elem.attr('data-address-address2').length > 0 && $elem.attr('data-address-address2') != 'null') {
            $('#dwfrm_billing_billingAddress_addressFields_address2').val($elem.attr('data-address-address2'));
        }
        $('#dwfrm_billing_billingAddress_addressFields_city').val($elem.attr('data-address-city'));
        $('#dwfrm_billing_billingAddress_addressFields_postal').val($elem.attr('data-address-postalcode'));
        $('#dwfrm_billing_billingAddress_addressFields_states_state').val($elem.attr('data-address-state')).change();
        $('#dwfrm_billing_billingAddress_addressFields_phone').val($elem.attr('data-address-phone'));
    });

    $(document).on('click', '.edit-billing-address', function() {
        $('.same-as-shipping').find('.field-inline:last').find('.label').trigger('click');
    });
};
