/**
 * @package     Blueacorn/toggleFieldsets
 * @version     1.0
 * @author      Blue Acorn <code@blueacorn.com>
 * @copyright   Copyright © 2016 Blue Acorn.
 */

(function (root, factory) {
    if (typeof exports === 'object') {
        module.exports = factory(window.jQuery, require('../../blueacorn/blueacorn-core'));
    } else if (typeof define === 'function' && define.amd) {
        define(['jquery', 'baCore'], function (jquery, ba) {
            return (factory(jquery, ba));
        });
    } else {
        root.ba.header = factory(root.jQuery, root.ba);
    }
}(this, function ($, ba) {

    'use strict';

    var toggleFieldsets = {
        wrapper:null,
        target:null,
        inputs:null,
        toggleValue:null,
        canToggle:false,
        init:function(){
            var toggleElementsWrapper = $('[data-toggle-fields]');

            if( toggleElementsWrapper.length > 0 ){
                toggleElementsWrapper.each(function() {
                    var $wrapper = $(this),
                        targetName = $wrapper.data('toggle-fields'),
                        toggleTarget = $('[data-toggle-target="' + targetName +'"]');

                    var toggle = {
                        wrapper:null,
                        target:null,
                        inputs:null,
                        toggleValue:null,
                        canToggle:false,

                        setObservers: function(){
                            this.wrapper.on( 'formElement:clicked', this, function( e, data ){
                                var $input = $(e.target).parent().find('input');
                                var selected = $input.val() == e.data.toggleValue;

                                if ($input.prop('type') == 'checkbox') {
                                    selected = $input.closest('.form-row').find('label').hasClass('checked').toString() == e.data.toggleValue;
                                }

                                if ( selected ) {
                                    e.data.showTargetFields();
                                } else {
                                    e.data.hideTargetFields();
                                }

                                $(document).trigger('update:selects');
                            })
                        },
                        alreadyToggled:function(){
                            var alreadyToggled = true,
                                checkedInput   = this.wrapper.find('input:checked');

                            if( checkedInput.length > 0 ){
                                if( checkedInput.val() == this.toggleValue ){
                                    alreadyToggled = false;
                                }
                            }

                            return alreadyToggled;
                        },
                        showTargetFields:function(){
                            this.target.show();
                        },
                        hideTargetFields:function(){
                            this.target.hide();
                        }
                    }

                    toggle.wrapper     = $wrapper;
                    toggle.inputs      = $wrapper.find('input');
                    toggle.toggleValue = String( $wrapper.data('toggle-value') );

                    if( toggleTarget.length > 0 ){
                        toggle.target = toggleTarget;
                    }

                    if( toggle.wrapper != null && toggle.target != null ){
                        toggle.canToggle = true;
                    }

                    if( toggle.canToggle && toggle.alreadyToggled() ){
                        toggle.hideTargetFields();
                    }

                    if( toggle.canToggle ){
                        toggle.setObservers();
                    }

                });
            }

            $(document).trigger('update:selects');
        }
    };

    $(document).on('baCoreReady', function() {
        toggleFieldsets.init();
    });

    return toggleFieldsets;
}));
