'use strict';

var dialog = require('../../dialog'),
    productStoreInventory = require('../../storeinventory/product'),
    util = require('../../util'),
    addToCart = require('./addToCart'),
    availability = require('./availability'),
    image = require('./image'),
    loyalty = require('./loyalty'),
    productCarousels = require('./productCarousels'),
    productSet = require('./productSet'),
    customization = require('./customization'),
    variant = require('./variant'),
    cylindo = require('./cylindo'),
    globalVars = require('../../global-vars'),
    video = require('../../video');

function sendFindMine() {
    if (!window.FindMine) {
        return;
    }

    var $container = $('.js-product-ajax-container');
    var params = $container.data('find-mine');

    if (!params) {
        return;
    }

    try {
        window.FindMine.match(params);
    } catch (e) {
        window.console.error('The FindMine params were not found.');
    }
}

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {
    $('.product-accordions').accordion({
        collapsible: true,
        heightStyle: 'content',
        icons: {
            header: 'icon-plus',
            activeHeader: 'icon-minus'
        }
    });

    productCarousels.initProductColectionCarousel();
    productCarousels.initCompleteLookCarousel();
    productCarousels.waitForRecommendations();
}

function customScrollToElement() {
    $('body').on('click', '[data-scroll-to]', function(e) {
        e.preventDefault();

        var classEl = $(this).data('scroll-to');
        if (!classEl.length) {
            return;
        }

        var $el = $('[data-scroll-target=' + classEl + ']').first();

        if ($el.length) {
            $el.trigger('click');
            setTimeout(function () {
                // The timeout needs for a delay while accordion change state
                var position = $el.offset().top;
                util.scrollBrowser(position);
            }, 300);
        }
    });
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
    var $pdpMain = $('#pdpMain');

    addToCart();
    availability();
    variant();
    image();
    cylindo();

    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }

    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="wishlist"], [data-action="gift-registry"]', function () {
        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute('href', url);
    });

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
        e.preventDefault();
    });
    $('a.scrollLink').on('click', function(event) {
        event.preventDefault();
        $('html, body').animate({scrollTop: $($(this).attr('href')).offset().top}, 2000);
    });

    //"4 ways to shop" PDP overlay behavior
    if (document.cookie.indexOf('hideOverlay=true') > -1) {
        $('.pdp-overlay').addClass('hide-mobile');
    }
    $('.pdp-overlay span.close').on('click', function(event) {
        event.stopPropagation();
        $('.pdp-overlay').addClass('hide-mobile');
        document.cookie = 'hideOverlay=true';
    });

    $pdpMain.on('click', '.pdp-overlay.hide-mobile', function () {
        $(this).removeClass('hide-mobile');
        document.cookie = 'hideOverlay=false';
    })

    $('.size-chart-link a').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });

    $pdpMain.on('click', '.js_add-on-tooltip', function (e) {
        e.stopPropagation();
        var $toolTip = $(this);
        var $container = $toolTip.closest('.js_add-on');
        var $content = $container.find('.js_add-on-dialog');
        $content.removeAttr('style');

        if ($container.hasClass('tooltip-show')) {
            $container.removeClass('tooltip-show tooltip-above');
            return;
        }

        var $toolTipWidth = $toolTip.outerWidth();
        var halfContentWidth = $content.outerWidth() / 2;
        var $offsetLeft = $toolTip.offset().left;
        var windowWidth = window.innerWidth;
        var scrollWidth = windowWidth >= globalVars.breakpoints.small ? 17 : 0;

        if((windowWidth / 2) < $offsetLeft) {
            if ((windowWidth - $offsetLeft - $toolTipWidth - scrollWidth) < halfContentWidth) {
                $content.css('right', (windowWidth - $offsetLeft) / -2);
            } else {
                $content.css('right', -1 * halfContentWidth);
            }
        } else {
            if ($offsetLeft < halfContentWidth) {
                $content.css('left', $offsetLeft / -2);
            } else {
                $content.css('left', -1 * halfContentWidth);
            }
        }

        $container.addClass('tooltip-show');

        if (($content.offset().top + $content.outerHeight()) > (window.innerHeight + window.scrollY)) {
            $container.addClass('tooltip-above');
        }

        $(document).one('click.add-on-dialog', function () {
            $('.js_add-on').removeClass('tooltip-show tooltip-above');
        });
    });

    $pdpMain.on('click', '.js_add-on-product-name', function (e) {
        if (window.innerWidth >= globalVars.breakpoints.medium) {
            return;
        }

        e.stopPropagation();
        $('.js_add-on-tooltip').trigger('click');
    });

    $pdpMain.on('click', '#addOOSMail', function(e) {
        e.preventDefault();
        var $form = $(this).closest('form');
        var $email = $form.find('input[type="email"]');
        var email = $email.val();
        if (!$email.hasClass('error') && email.length > 0) {
            var pid = $form.find('input[name="pidid"]').val()
            $.ajax({
                url: Urls.addToMailListOnStock,
                method: 'POST',
                data: {pid: pid, email: email},
                dataType: 'json',
            }).done(function (response) {
                var $formMsg = $form.find('span[data-form-msg]');
                $formMsg.removeAttr('class');
                if ($formMsg.length > 0) {
                    $formMsg.addClass(response.success ? 'success' : 'error');
                    $formMsg.fadeIn();
                    $formMsg.html(response.msg);
                    $formMsg.fadeOut(5000);
                }
            })
        }
    });

    /**
     * Tabs section on PDP click functionality
     */

     $pdpMain.on('click', '.tabs-button', function () {
        var $this = $(this),
            $datahas = $this.data('tab-has'),
            $tabclass = '.tab-'+$datahas;
        $this.addClass('m-active').siblings().removeClass('m-active');
        $($tabclass).addClass('m-active').siblings().removeClass('m-active');
        $this.attr('aria-selected','true').siblings().attr('aria-selected','false');
    });

    $(window)
        .on('loadedRecommendations', initGTMTile)
        .on('load', function () {
            $(document).trigger('custom:send:find:mine:request');
        });

    $pdpMain.on('updateHQData', SmarterHQDataPush);

    SmarterHQDataPush();

    if ($('.swatches.size').length) {
        var maxW = 0;
        $('.swatches.size li').each(function() {
            if ($(this).width() > maxW) {
                maxW = $(this).width();
            }
        });
        $('.swatches.size li').width(maxW);
    }

    loyalty.initLoyaltyProduct();
    video.init();

    if (util.isProductV2()) {
        customization();
        $(window).on('load', initGTMTileOnLoad);

        $pdpMain.on('mouseenter mouseleave', '.option-tool-tip, .customization-tooltip-content', function(){
            var $this = $(this),
                offset = $this.offset(),
                windowWidth = $(window).innerWidth(),
                pdpContainerWidth = $('.product-detail-container').innerWidth(),
                $tooltipContent = $('.customization-tooltip-content');
            if (!$this.hasClass('customization-tooltip-content')) {
                var cloneContent = $this.find('.tool-tip-content-option').html();
                $tooltipContent.html(cloneContent);
                $tooltipContent.toggleClass("box-hover");
                if (windowWidth < globalVars.breakpoints.small) {
                    $tooltipContent.css({"left": "13%", "top": (offset.top - 183)});
                } else if(windowWidth < globalVars.breakpoints.large){
                    $tooltipContent.css({"left": (offset.left - 150 ), "top": (offset.top - 183)});
                } else if(windowWidth <= pdpContainerWidth){
                    $tooltipContent.css({"right": -(windowWidth - offset.left)  + 60  + 'px', "top": (offset.top - 205)});
                } else {
                    $tooltipContent.css({"left": (offset.left - 350 ), "top": (offset.top - 205)});
                }
            }
        });

        $pdpMain.on('.cylindo-thumbnail-list li', 'click',function () {
            var $this = $(this);
            if ($this.hasClass('environment-thumb')) {
                $('.cylindo-drag-to-rotate-tooltip').addClass('visually-hidden');
            } else {
                $('.cylindo-drag-to-rotate-tooltip').removeClass('visually-hidden');
            }
        });
    } else {
        customScrollToElement();
    }

    productSet();

    $(document)
        .on('custom:youtube:api:loaded', video.init.bind(video))
        .on('custom:send:find:mine:request', sendFindMine);
}

/**
 * Creates product tiles events for GTM
 */
function initGTMTile() {
    var qv = $('#QuickViewDialog');
    if (qv.length && qv.is(':visible')) {
        return;
    }

    // Product tile impression event
    var listName = "Recommended products";
    var customDimensions = JSON.parse($('.js-gtm-data-source').attr('data-gtm-custom-dimensions'));
    var obj = {
        event: 'eecEvent',
        eventCategory: 'Ecommerce',
        eventAction: 'Product Impressions',
        eventLabel: 'None',
        eventValue: 0,
        interactionEvent: false,
        ecommerce : {
            currencyCode: $('.js-gtm-data-source').attr('data-gtm-currency'),
            impressions: []
        }
    };
    $.extend(obj, customDimensions);
    $('.product-tile').each(function () {
        var $impressionData = $(this).data('gaitem');
        if (typeof $impressionData === 'undefined' || $impressionData === null) {
            return true;
        }
        obj.ecommerce.impressions.push({
            'name': $impressionData.name,                      // Name or ID is required.
            'id': $impressionData.id,
            'price': $impressionData.price,
            'category': $impressionData.category,
            'variant': $impressionData.variant,
            'dimension10': $impressionData.customizable,
            'dimension11': $impressionData.dimension11,
            'position': $impressionData.position,
            'brand': $impressionData.brand,
            'list':  listName
        });
    });

    if (obj.ecommerce.impressions.length > 0) {
        window.dataLayer.push(obj);
    }

    // Product tile click event
    $(document).on('click', '.product-tile', function () {
        var gaItem = $(this).data('gaitem');
        if (gaItem) {
            var obj = {
                event: 'eecEvent',
                eventCategory: 'Ecommerce',
                eventAction: 'Product Click',
                eventLabel: gaItem.name,
                eventValue: 0,
                interactionEvent: true,
                'ecommerce': {
                    'click': {
                        'actionField': {'list': listName},
                        'products': [{
                            'name': gaItem.name,                      // Name or ID is required.
                            'id': gaItem.id,
                            'price': gaItem.price,
                            'category': gaItem.category,
                            'variant': gaItem.variant,
                            'dimension10': gaItem.customizable,
                            'dimension11': gaItem.dimension11,
                            'position': gaItem.position,
                            'brand': gaItem.brand
                        }]
                    }
                },
                'eventCallback': function() {
                    document.location = gaItem.url
                }
            };
            $.extend(obj, customDimensions);
            window.dataLayer.push(obj);
        };
    });
}

/**
 * Add info for SmarterHQ in dataLayer
 */
function SmarterHQDataPush() {
    var qv = $('#QuickViewDialog');
    if (qv.length && qv.is(':visible')) {
        return;
    }

     // Product tile impression event
    let productURL = window.location.href;
    let productImage;
    let $container = $('.js-product-ajax-container');

    if (util.isProductV2()) {
        // Product tile impression event
        let $productPreviousInfo = $('.js-product-previous-url');
        let productPreviousURL = $productPreviousInfo.data('product-previous-url');

        if (productPreviousURL !== productURL) {
            productImage = $container.data('image-url');
            $productPreviousInfo.data('product-previous-url', productURL);
        } else {
            return;
        }
    } else {
        productImage = $container.data('image-url');
    }

    let dataObj = {
        'event': 'smarterHQevent',
        'PageType': 'ProductPage',
        'smarterHQData': {
            'products': [{
                'imageurl': productImage,
                'linkurl': productURL
            }]
        }
    };

    window.dataLayer.push(dataObj);
}

/**
 * Creates product tiles events for GTM on load
 */
function initGTMTileOnLoad() {
    var qv = $('#QuickViewDialog');
    if (qv.length && qv.is(':visible')) {
        return;
    }

    // Product tile impression event
    var listName = "Product Set";
    var customDimensions = JSON.parse($('.js-gtm-data-source').attr('data-gtm-custom-dimensions'));
    var obj = {
        event: 'eecEvent',
        eventCategory: 'Ecommerce',
        eventAction: 'Product Impressions',
        eventLabel: 'None',
        eventValue: 0,
        interactionEvent: false,
        "gtm.uniqueEventId": 10,
        ecommerce : {
            currencyCode: $('.js-gtm-data-source').attr('data-gtm-currency'),
            impressions: []
        }
    };

    $.extend(obj, customDimensions);

    if (obj.ecommerce.impressions.length > 0) {
        window.dataLayer.push(obj);
    }

    // Product tile click event
    $(document).on('click', '.product-tile', function () {
        var gaItem = $(this).data('gaitem');
        if (gaItem) {
            var obj = {
                event: 'eecEvent',
                eventCategory: 'Ecommerce',
                eventAction: 'Product Click',
                eventLabel: gaItem.name,
                eventValue: 0,
                interactionEvent: true,
                'ecommerce': {
                    'click': {
                        'actionField': {'list': listName},
                        'products': [{
                            'name': gaItem.name,                      // Name or ID is required.
                            'id': gaItem.id,
                            'price': gaItem.price,
                            'category': gaItem.category,
                            'variant': gaItem.variant,
                            'dimension10': gaItem.customizable,
                            'dimension11': gaItem.dimension11,
                            'brand': gaItem.brand
                        }]
                    }
                },
                'eventCallback': function() {
                    document.location = gaItem.url
                }
            };
            $.extend(obj, customDimensions);
            window.dataLayer.push(obj);
        }
    });
}

var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        initializeEvents();
    }
};

module.exports = product;
