'use strict';

var _ = require('lodash');

var $form, $continue, $requiredInputs, validator;

var hasEmptyRequired = function () {
    // filter out only the visible fields
    var requiredValues = $requiredInputs.filter(':visible').map(function () {
        return $(this).val();
    });
    return _(requiredValues).contains('');
};

var validateForm = function () {
    // only validate form when all required fields are filled to avoid
    // throwing errors on empty form
    if (!validator) {
        return;
    }
    if (!hasEmptyRequired()) {
        if (validator.form()) {
            $continue.removeAttr('disabled');
            return true;
        }
    } else {
        $continue.attr('disabled', 'disabled');
        return false;
    }
};

var validateEl = function () {
    // remove error class on element and span if check passes
    try {
        if (validator.check(this)) {
            if (this.classList && this.classList.contains('error')) {
                this.classList.remove('error');

                var parentElement = this.parentElement;
                var errorSpan = (parentElement && parentElement.querySelector('span[class="error"]')) ? parentElement.querySelector('span[class="error"]') : null;

                if (errorSpan) {
                    errorSpan.remove();
                }
            }
        }

        if ($(this).val() === '') {
            $continue.attr('disabled', 'disabled');
        } else {
            // enable continue button on last required field that is valid
            // only validate single field
            if (validator.check(this) && !hasEmptyRequired()) {
                $continue.removeAttr('disabled');
            } else {
                $continue.attr('disabled', 'disabled');
            }
        }
    } catch (e) {
        // do nothing with exception
        // thrown from jquery validate for custom
        // drop downs if exception uncaught will break some
        // functionality
    }
};

var init = function (opts) {
    if (!opts.formSelector || !opts.continueSelector) {
        throw new Error('Missing form and continue action selectors.');
    }

    $form = $(opts.formSelector);
    $continue = $(opts.continueSelector);
    validator = $form.validate({
    });
    $requiredInputs = $('.required', $form).find(':input');
    validateForm();
    // start listening
    $requiredInputs.on('change', validateEl);
    $requiredInputs.filter('input').on('keyup', _.debounce(validateEl, 200));
};

exports.init = init;
exports.validateForm = validateForm;
exports.validateEl = validateEl;
