'use strict';

var dialog = require('../../dialog'),
    globalVars = require('../../global-vars'),
    imagesLoaded = require('imagesloaded'),
    jqueryZoom = require('jquery-zoom'),
    PinchZoom = require('pinch-zoom-js'),
    progress = require('../../progress'),
    util = require('../../util'),
    video = require('../../video');

/**
 * Init zoom on one item on mobile view
 */
function initPinchZoom() {
    if (window.innerWidth >= globalVars.breakpoints.large) {
        return;
    }

    var $container = $('.product-primary-image');
    var $pinchItemWrap = $('.js_pinch-zoom-media-item-wrap');

    if ($container.hasClass('zoom-on-mobile-loaded') || !$pinchItemWrap.is(':visible')) {
        return;
    }

    $container.addClass('zoom-on-mobile-loaded');

    $pinchItemWrap.each(function () {
        new PinchZoom.default($(this).get(0), {
            // lockDragAxis: true,
            draggableUnzoomed: false,
            setOffsetsOnce: true,
            minZoom: 1,
            maxZoom: 4,
            tapZoomFactor: 3,
            zoomOutFactor: 1.5,
            animationDuration: 300
        });
    });
}

function updatePinchZoom() {
    initPinchZoom();
}

function destroyHoverZoom() {
    $('.js_product-image-zoom-container')
        .removeClass('zoom-on-desktop-initiated')
        .trigger('zoom.destroy');
}

/**
 * Init one zoom on desktop view
 */
function initHoverZoom() {
    if (window.innerWidth < globalVars.breakpoints.large) {
        return;
    }

    var $itemWrap = $('.js_product-image-zoom-container');

    if (!$itemWrap.length || $itemWrap.hasClass('zoom-on-desktop-initiated') || !$itemWrap.is(':visible')) {
        return;
    }

    $itemWrap.each(function () {
        var $img = $(this).find('.primary-image');

        $(this)
            .zoom({
                url: $img.attr('data-zoom-image'),
                duration: 400,
                touch: false,
                callback: function () {
                    $(this).addClass('zoom-on-desktop-initiated');
                },
                onZoomIn: function () {
                    $(this)
                        .removeClass('zoom-out')
                        .addClass('zoom-in');
                },
                onZoomOut: function () {
                    $(this)
                        .removeClass('zoom-in')
                        .addClass('zoom-out');
                }
            });
    });
}

function updateHoverZoom() {
    destroyHoverZoom();
    initHoverZoom();
}

function updateZooms() {
    var $productCylindoViewer = $('#product-cylindo-viewer');

    if ($productCylindoViewer.length) {
        return;
    }

    updateHoverZoom();
    updatePinchZoom();
}

/**
 * Init zoom for mobile and desktop
 */
function initZoom() {
    var $productCylindoViewer = $('#product-cylindo-viewer');

    if ($productCylindoViewer.length) {
        return;
    }

    initHoverZoom();
    initPinchZoom();
}

/**
 * @description Enables the zoom viewer on the product detail page
 */
var loadZoom = function () {
    var $imgZoom = $('#pdpMain .main-image'),
        hiresUrl;

    if ($imgZoom.length === 0 || dialog.isActive()) {
        return;
    }

    hiresUrl = $imgZoom.data('href');

    if (hiresUrl && hiresUrl !== 'null' && hiresUrl.indexOf('noimagelarge') === -1) {
        $imgZoom.add('.zoom-expand').on('click', loadHiResCarousel);
        $(document).on('click', '.zoomImageCarousel .close', productCollectionClose);

        // Escape key closeout of navigation
        $(document).keyup(function(event) {
            if (event.keyCode === 27 && $('html').hasClass('collection-active')) {
                productCollectionClose();
            }
        });
    }
};

/**
 * Lazy loads the HiRes image carousel into view
 *
 * @param event - jQuery Event
 */
var loadHiResCarousel = function(event) {
    event.preventDefault();
    var $html = $('html');

    if ($html.hasClass('resp-mobile') || $html.hasClass('resp-tablet')){
        return false;
    }

    // Show the progress AJAX loader
    progress.show($('#pdpMain'));
    $('.wrapper-overlay').removeAttr('style');

    var $images = $('.zoomImages .item'),
        $collection = $('<div class="zoomImageCarousel"><ul class="slides"></ul></div>').prependTo('#wrapper .wrapper-overlay');

    if ($images.length > 0 && $collection.find('.item').length < 1){
        $images.each(function(){
            var $this = $(this).clone(),
                imgUrl = $this.data('img');

            if (typeof imgUrl !== typeof undefined && imgUrl !== false) {
                var imgToLoad = $this.append('<img class="visually-hidden" src="' + imgUrl + '" alt=""/>');

                $this.css('background-image', 'url(' + imgUrl + ')');
                $collection.children('.slides').append(imgToLoad);
            }
        });
    }

    imagesLoaded($collection,  function () {
        progress.hide();
        $('html').addClass('collection-active');
        $collection.addClass('open');
        var options = {
            animation: 'slide',
            slideshow: false
        };

        if (!util.isProductV2()) {
            var $items = $('.product-thumbnails .item:not(.slick-cloned)');
            var startAt = 0;

            for (var i = 0; i < $items.length; i++) {
                var $item = $($items[i]);

                if ($item.hasClass('slick-current')) {
                    break;
                }

                if ($item.find('.thumb-video-play').length) {
                    continue;
                }

                startAt++;
            }

            options.startAt = startAt;
        }

        $collection.flexslider(options).addClass('show');

    });
};

var productCollectionClose = function(event) {
    $('.zoomImageCarousel.open').fadeOut('200', function() {
        $(this).remove();
    });
    $('html').removeClass('collection-active');
};

/**
 * @description Replaces the images in the image container, for eg. when a different color was clicked.
 */
var replaceImages = function () {
    var $newImages = $('#update-images'),
        $imageContainer = $('#pdpMain .product-image-container');
    if ($newImages.length === 0) { return; }

    $imageContainer.html($newImages.html());
    $newImages.remove();
    updateZooms();
    loadZoom();
};

/* @module image
 * @description this module handles the primary image viewer on PDP
 **/

/**
 * @description by default, this function sets up zoom and event handler for thumbnail click
 **/
module.exports = function () {
    if (dialog.isActive()) {
        $('#pdpMain .main-image').removeAttr('href');
    }

    initZoom();
    loadZoom();

    // handle product thumbnail click event
    $('#pdpMain').on('click', '.productthumbnail, .thumb-video-play', function () {
        // if outward360 thumb, show the 360 view; otherwise, hide it & change the main image
        if ($(this).hasClass('outward-thumbnail')) {
            $('.simple.wishlist').fadeOut('fast');
            $('.primary-image').fadeOut('fast');
            $('#myComposer').fadeIn('fast');
        } else if ($('#myComposer:visible').length > 0) {
            $('.simple.wishlist').fadeIn('fast');
            $('.primary-image').fadeIn('fast');
            $('#myComposer').fadeOut('fast');
        }
    });
};

module.exports.replaceImages = replaceImages;
