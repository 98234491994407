/**
 * @package     Blueacorn/productSticky
 * @version     2.0
 * @author      Blue Acorn <code@blueacorn.com>
 * @copyright   Copyright © 2016 Blue Acorn.
 */

(function (root, factory) {
    if (typeof exports === 'object') {
        // CommonJS
        module.exports = factory(window.jQuery, require('./blueacorn-plugin-template'));
    } else if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery'], function (jquery) {
            return (factory(jquery));
        });
    } else {
        // Global Variables
        root.ba.productSticky = factory(root.jQuery);
    }

}(this, function ($) {
    'use strict';

    var productSticky = {

        settings: {
            scrollToDiv: $('.product-addtocart-wrapper'),
            stickyDiv: $('.product-stickyheader'),
            selectedTempl: '<li><span class="attr-label">{{ label }}</span><span class="attr-value">{{ value }}</span></li>',
            selectedSyntax: /(^|.|\r|\n)(\{{\s*(\w+)\s*}})/,
            offsetVariable: 100,
            stickBarActivated: false
        },

        init: function () {
            var self = this, $pdpMain = $('#pdpMain');

            if (
                (!$pdpMain.hasClass('simple') && !$pdpMain.hasClass('master')) ||
                ($pdpMain.hasClass('simple') && $('#add-to-cart').length < 1)
            ){
                return;
            }

            if ($pdpMain.hasClass('simple')){
                this.settings.stickyDiv.find('button.add-to-cart').prop('disabled', false);
            } else {
                this.addSelections(null, null, true);
            }

            this.determineActivation();
            this.scrollObserver();
            this.backToTop();
            this.truncateProductTitle();
            this.settings.stickyDiv.show();

            // AJAX product update
            $(document).on('product:update', function(event, response){
                self.addSelections(true, response);
                self.updateDelivery();
            });

            this.settings.stickyDiv.find('button.add-to-cart').on('click', self.addToCartClick.bind(self));
        },

        /**
         * Looks at the screen size and determines
         * if the feature should be shown or not.
         */
        determineActivation: function() {
            var self = this;

            enquire.register('screen and (min-width:' + (ba.bp.large + 1) + 'px)', {
                match: function() {
                    self.settings.stickBarActivated = true;
                },
                unmatch: function() {
                    self.settings.stickBarActivated = false;
                    self.settings.stickyDiv.removeClass('show');
                }
            });
        },

        /**
         * Triggers the sticky bar based on some qualifiers
         * and a `trigger point`.
         *
         * Qualifiers
         *  - Must be on Desktop sizes viewport
         * Trigger point
         *  - reaching bottom offset of `addtocart-wrapper` div
         *    with an offsetVariable
         *
         * @see this.settings.offsetVariable
         */
        scrollObserver: function() {
            var self = this,
                addToCartOffset = this.settings.scrollToDiv.offset().top,
                addToCartHeight = this.settings.scrollToDiv.outerHeight(true),
                currentScrollPos;

            // calculate the trigger point
            var triggerPoint = (addToCartOffset + addToCartHeight + self.settings.offsetVariable);

            $(window).scroll(self, function(){
                currentScrollPos = $(this).scrollTop();

                if ((currentScrollPos > triggerPoint) && self.settings.stickBarActivated === true){
                    self.settings.stickyDiv.addClass('show');
                } else {
                    self.settings.stickyDiv.removeClass('show');
                }
            });
        },

        backToTop: function() {
            var self = this;

            self.settings.stickyDiv.find('.backtotop').on('click', function(event) {
                event.preventDefault();

                $('html, body').animate({
                    scrollTop: (self.settings.scrollToDiv.offset().top - $(window).height()/2)
                }, 600);
            });
        },

        truncateProductTitle: function() {
            var text = this.settings.stickyDiv.find('.product-title').text();
            this.settings.stickyDiv.find('.product-title').text(text.truncate(11));
        },

        /**
         * adds current variation attribute
         * selections to stick bar for display
         *
         * @param clearOut - boolean
         */
        addSelections: function(clearOut, response, onPageLoad) {
            var self = this,
                stickySelections = this.settings.stickyDiv.find('.selections .value ul'),
                swatches = $('.product-variations .attribute .swatches'),
                response = (typeof response !== 'undefined' && response !== null) ? response : null,
                $response = $(response),
                showButton   = false;

            if (clearOut){
                stickySelections.empty();
            }

            if (!$('button#add-to-cart').prop('disabled') && onPageLoad) {
                showButton = true;
            }

            // Check if button is disabled or not
            if (showButton || (response !== null && !$response.eq(0).find('button#add-to-cart').prop('disabled'))){
                this.settings.stickyDiv.find('button.add-to-cart').prop('disabled', false);
            } else {
                this.settings.stickyDiv.find('button.add-to-cart').prop('disabled', true);
            }

            // Always show the button if ecommerce is not disabled
            if (!$response.hasClass('ecommerce-disabled')) {
                this.settings.stickyDiv.find('.content .right').show();
            } else {

                // Show the CTA based on product level eCommerceItem field
                if (response !== null || showButton){
                    this.settings.stickyDiv.find('.content .right').show();
                } else {
                    this.settings.stickyDiv.find('.content .right').hide();
                }
            }

            swatches.each(function() {
                var $this = $(this),
                    selected = $this.find('li.selected'),
                    selectedLabel = $this.data('attr-label');

                if (selected.length > 0){
                    var html = $.htmlTemplate(self.settings.selectedTempl, self.settings.selectedSyntax);
                    html = html.evaluate({label: selectedLabel, value: selected.children('a').html()});
                    html = $(html);

                    if (selected.hasClass('text-small')){
                        html.addClass('text-small');
                    }

                    html.appendTo(stickySelections);
                }
            });
        },

        updateDelivery: function() {
            var $delivery = $('.product-addtocart-wrapper .estimated-shipping');
            var $stickyBarDelivery = this.settings.stickyDiv.find('.estimated-shipping');

            if ($delivery.length > 0){
                if ($stickyBarDelivery.length > 0){
                    $stickyBarDelivery.html($delivery.html());
                } else {
                    this.settings.stickyDiv.find('.center').append($delivery.clone());
                }
            } else {
                $stickyBarDelivery.remove();
            }

        },

        addToCartClick: function(event) {
            var $target = $(event.target);
            event.preventDefault();

            if (!$target.hasClass('disabled')){
                $('button#add-to-cart').trigger('click');
            }
        }

    };

    return productSticky;
}));
