'use strict';

var util = require('./util'),
    ajax = require('./ajax'),
    tooltip = require('./batooltip'),
    postalReg = /^\d{5}(-?\d{4})?$/;

function initializeEvents() {
    $('body').on('click', '.shipping-tooltip', function(e) {e.preventDefault()});

    $('body').on('click', '.js-open-shipping-form', function(e) {
        e.preventDefault();
        $('.js-shipping-form').parent().removeClass('d-none');
        $('.order-shipping-zip-edit').slideUp();
        $('.js-open-shipping-form').slideUp();
        $('.js-shipping-form').addClass('opened', 500);
    });

    $('body').on('click', '.js-check-shipping-zip', function(e) {
        e.preventDefault();

        let zipCode, url;
        let $priceContainer = $('.js-cart-order-totals');
        if ($priceContainer.length) {
            url = Urls.getCartPrice;
        } else {
            $priceContainer = $('.js-checkout-order-totals').parent()
            url = Urls.summaryRefreshURL;
        }

        if (!$priceContainer.length) {
            return;
        }

        const ZipValue = $('#zip-input').val();
        const $error = $priceContainer.find('.js-zip-error');

        if (!ZipValue.length) {
            $error.html(Resources.VALIDATE_REQUIRED);
            $error.removeClass('d-none');
            return;
        }
        if (postalReg.test(ZipValue)) {
            zipCode = ZipValue;
        } else {
            $error.html(Resources.INVALID_ZIP);
            $error.removeClass('d-none');
            return;
        }
              
        url = util.appendParamToURL(url, 'zipcode', zipCode);
        ajax.load({
            url: url,
            target: $priceContainer,
            callback: function () {
                tooltip.init();
            }
        });
    });
}

exports.init = function () {
    initializeEvents();
};