/*
 * All javascript logic for Google's Address Autocomplete.
 *
 * The code relies on the jQuery JS library to also be loaded.
 *
 */

'use strict';

var AddressAutocomplete = {
    // configuration parameters and required object instances
    autocomplete: {},
    triggerField: {},
    componentForm: {
        street_number: 'short_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'short_name',
        postal_code: 'short_name'
    },

    /* 
     * Create the autocomplete object, restricting the search to geographical
     * location types.
     */
    init: function () {
        var $input = $('input[data-trigger-autocomplete="true"]');
        if (!$input.length) {
            return;
        }

        AddressAutocomplete.triggerField = $input;

        AddressAutocomplete.autocomplete = new google.maps.places.Autocomplete(
            (AddressAutocomplete.triggerField.get(0)),
            {
                types: ['geocode'],
                componentRestrictions: {country: ['us']} //restrict to US and Canada
            }
        );


        // When the user selects an address from the dropdown, populate the address fields in the form.
        AddressAutocomplete.autocomplete.addListener('place_changed', AddressAutocomplete.fillInAddress);
    },
    
    /*
     * Sets the autocomplete object to the user's geographical location,
     * as supplied by the browser's 'navigator.geolocation' object.
     */
    geolocate: function (autocomplete) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                var geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    },
                    circle = new google.maps.Circle({
                        center: geolocation,
                        radius: position.coords.accuracy
                    });
                autocomplete.setBounds(circle.getBounds());
            });
        }
    },
    
    /*
     * Get the place details from the autocomplete object.
     */
    fillInAddress: function () {
        // Get the place details from the autocomplete object.
        var place = AddressAutocomplete.autocomplete.getPlace(),
            resetField;

        // Get each component of the address from the place details and fill the corresponding field on the form.
        if (place && place.address_components) {
            // Make fields writable for the autocomplete object
            for (var component in AddressAutocomplete.componentForm) {
                resetField = $('form').find("[data-autocomplete-mapping='" + component + "']");

                resetField.val('');
                resetField.prop('disabled', false);    
            }

            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (AddressAutocomplete.componentForm[addressType]) {
                    
                    var formField = $('form').find("[data-autocomplete-mapping='" + addressType + "']"),
                        value = place.address_components[i][AddressAutocomplete.componentForm[addressType]],
                        matchingValue;

                    // Assign the returned values to the relevant fields
                    if (formField.is('select')) {
                        // Field is a dropdown, so match up value returned with value in dropdown list
                        matchingValue = formField.find('option').filter(function () { 
                            return this.value.toLowerCase() == value.toLowerCase(); 
                        }).attr('value');  
                        
                        formField.val(matchingValue);
                    } else if (addressType === 'street_number') {
                        // Merge 'street_number' and 'route' data points, as they make up the address1 field
                        formField.val(place.address_components[0]['long_name'] + ' ' + place.address_components[1]['long_name']);
                    } else if (addressType === 'postal_code') {
                        // remove spaces for Canada zip codes
                        value = value.replace(' ', '');
                        // Field is related to an input, so just output the returned value
                        formField.val(value);
                        formField.trigger('focus').trigger('blur');
                    } else {
                        // Field is related to an input, so just output the returned value
                        formField.val(value);
                        formField.trigger('focus').trigger('blur');
                    }
                    $(document).trigger('update:selects');
                    if ($('.ascii').length > 0) {
                        $('.ascii').trigger('input');
                    }
                    if ($('input[name$="_postal"]').length) {
                        $('input[name$="_postal"]').focus();
                        $('input[name$="_postal"]').trigger('change');
                    }
                }
            }

            // fix for street address https://issuetracker.google.com/issues/35824620
            const streetField = $('form').find("[data-autocomplete-mapping='street_number']");

            if (!streetField.val()) {
                const splitedStreet = place.formatted_address.split(',')[0];
                streetField.val(splitedStreet)
            }
        }
    }
};

module.exports = AddressAutocomplete;
