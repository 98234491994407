'use strict';

var giftcert = require('../giftcert'),
    tooltip = require('../batooltip'),
    util = require('../util'),
    dialog = require('../dialog'),
    page = require('../page'),
    validator = require('../validator'),
    formPrepare = require('../pages/checkout/formPrepare'),
    $checkBox = $('input.autorenew-check'),
    addressAutocomplete = require('../address-autocomplete'),
    creditCard = require('../creditCard');

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm() {
    var $form = $('#edit-address-form');
    if ($('.add-new-address-account .country input.country').val() == '') {
        $('.add-new-address-account .country input.country').val('US');
    }
    $form.find('input[name="format"]').remove();
    tooltip.init();

    $form.on('click', '.apply-button', function (e) {
        e.preventDefault();
        if (!$form.valid()) {
            return false;
        }
        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        var applyName = $form.find('.apply-button').attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            if (typeof(data) !== 'string') {
                if (data.success) {
                    dialog.close();
                    page.refresh();
                } else {
                    window.alert(data.message);
                    return false;
                }
            } else {
                $('#dialog-container').html(data);
                account.init();
                tooltip.init();
            }
        });
    })
        .on('click', '.cancel-button, .close-button', function (e) {
            e.preventDefault();
            dialog.close();
        })
        .on('click', '.delete-button', function (e) {
            e.preventDefault();
            if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
                var url = util.appendParamsToUrl(Urls.deleteAddress, {
                    AddressID: $form.find('#addressid').val(),
                    format: 'ajax'
                });
                $.ajax({
                    url: url,
                    method: 'POST',
                    dataType: 'json'
                }).done(function (data) {
                    if (data.status.toLowerCase() === 'ok') {
                        dialog.close();
                        page.refresh();
                    } else if (data.message.length > 0) {
                        window.alert(data.message);
                        return false;
                    } else {
                        dialog.close();
                        page.refresh();
                    }
                });
            }
        });

    validator.init();

}
/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder () {
    $('.order-items')
        .find('li.hidden:first')
        .prev('li')
        .append('<a class="toggle">View All</a>')
        .children('.toggle')
        .on('click', function () {
            $(this).parent().siblings('li.hidden').show();
            $(this).remove();
        });
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var addresses = $('#addresses');
    if (addresses.length === 0) { return; }

    addresses.on('click', '.address-edit, .address-create', function (e) {
        e.preventDefault();
        dialog.open({
            url: this.href,
            options: {
                open: initializeAddressForm,
                dialogClass: 'add-new-address-account',
                height: '550'
            },
            callback: function () {
                validator.initForm($('form[id$="edit-address-form"]'));
                formPrepare.init({
                    formSelector: 'form[id$="edit-address-form"]',
                    continueSelector: '[name$="_profile_address_edit"]'
                });
            }
        });
    });
    
    addresses.on('click', '.delete', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
            $.ajax({
                url: util.appendParamToURL($(this).attr('href'), 'format', 'ajax'),
                dataType: 'json'
            }).done(function (data) {
                if (data.status.toLowerCase() === 'ok') {
                    page.redirect(Urls.addressesList);
                } else if (data.message.length > 0) {
                    window.alert(data.message);
                } else {
                    page.refresh();
                }
            });
        }
    });
}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
    const $cardForm = $('#newcreditcard');
    const $paymentList = $('.payment-list');

    // Init payment form if the user accidentally redirected on this page PaymentInstruments-PaymentForm
    if ($cardForm.length) {
        creditCard.updateCardMask($cardForm);
    }

    $('.add-card').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                height: '500'
            },
            callback: function() {
                validator.init();
                // Don't remove this line because new modal = new form and we should update listener
                const $cardFormModal = $('#newcreditcard');
                creditCard.updateCardMask($cardFormModal);
            }
        });
    });

    $('body').on('keypress', 'input[name*="_newcreditcard_number"]', function() {
        creditCard.detectCreditCard($(this));
    })

    $('body').on('change', 'input[name*="_newcreditcard_number"]', function() {
        creditCard.detectCreditCard($(this));
        $('input[name*="_newcreditcard_number"]').addClass('cc-number');
    })

    if (!$paymentList.length) {
        return;
    }

    util.setDeleteConfirmation($paymentList, String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD));

    const $removePaymentForm = $('.payment-remove-form');

    if ($removePaymentForm.length) {
        $('form[name="payment-remove"]').on('submit', function (e) {
            e.preventDefault();
            // override form submission in order to prevent refresh issues
            var button = $(this).find('.delete');
            $('<input/>').attr({
                type: 'hidden',
                name: button.attr('name'),
                value: button.attr('value') || 'delete card'
            }).appendTo($(this));
            var data = $(this).serialize();
            $.ajax({
                type: 'POST',
                url: $(this).attr('action'),
                data: data
            }).done(function () {
                page.redirect(Urls.paymentsList);
            });
        });
    }
}

/**
 * @private
 * @function
 * @description init events for the loginPage
 */
function initLoginPage() {
    //o-auth binding for which icon is clicked
    $('.oAuthIcon').bind('click', function () {
        $('#OAuthProvider').val(this.id);
    });

    //toggle the value of the rememberme checkbox
    $('#dwfrm_login_rememberme').bind('change', function () {
        if ($('#dwfrm_login_rememberme').attr('checked')) {
            $('#rememberme').val('true');
        } else {
            $('#rememberme').val('false');
        }
    });
    $('#password-reset, .modal-existing-customer-error a').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                open: function () {
                    validator.init();
                    var $requestPasswordForm = $('[name$="_requestpassword"]'),
                        $input = $('[name="dwfrm_requestpassword_email"]'),
                        $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');

                    // tigger focus to activate the custom label
                    $input.focus();

                    $($submit).on('click', function (e) {
                        if (!$requestPasswordForm.valid()) {
                            return;
                        }
                        e.preventDefault();
                        dialog.submit($submit.attr('name'));
                    });
                }
            }
        });
    });
}
function initializeTerms() {
    tooltip.init();
    $.ajax({
        url: util.appendParamToURL(Urls.autorenewalFullterms, 'format', 'ajax'),
        success: function(data){
            $('.scrollarea-content').html(data);
        }
    });
    $('button.cancelterms, .autorenewterms .ui-dialog-titlebar-close').on('click', function (e) {
        e.preventDefault();
        dialog.close();
    });
    $('.agreeterms').on('click', function (e) {
        e.preventDefault();
        var $agreeterms = $(this);
        if (!$agreeterms.hasClass('renewal')) {
            dialog.close();
            if ($agreeterms.hasClass('autorenew-agree')) {
                $checkBox.prop('checked', true);
            }
            $('button.autorenew-button').trigger('click');
        }
    });
}

/**
 * This is the step that handles company information in form review step
 */
function handleCompanyInformation () {
    var companyDetails = $('body').find('.company-details-container');
    var primaryDetails = $('body').find('.primary-details-container');
    var formreview = $('body').find('.form-review-page.step-4-container');
    if (companyDetails.find('.companyname input').val()) {
        formreview.find('.review-company-name').html(companyDetails.find('.companyname input').val());
    }

    if (companyDetails.find('.business-type select').val()) {
        formreview.find('.review-business-type').html(companyDetails.find('.business-type select').val());
    }

    if (companyDetails.find('.discovery-about-us select').val()) {
        formreview.find('.review-discovery-about-us').html(companyDetails.find('.discovery-about-us select').val());
    }

    if (companyDetails.find('.register-state select').val()) {
        formreview.find('.review-business-location-state').html(companyDetails.find('.register-state select').val());
    }

    if (companyDetails.find('.sales-tax-status select').val()) {
        formreview.find('.review-sales-tax-status').html(companyDetails.find('.sales-tax-status select').val());
    }

    if (companyDetails.find('.surya-representative-name input').val()) {
        formreview.find('.review-surya-representative-name').html(companyDetails.find('.surya-representative-name input').val());
    }

    if (companyDetails.find('.tax-exemption-number input').val()) {
        formreview.find('.review-tax-exemption-number').html(companyDetails.find('.tax-exemption-number input').val());
    }

    if (companyDetails.find('.state-issued-resale-certificate input').val()) {
        formreview.find('.review-resale-certificate').html(companyDetails.find('.state-issued-resale-certificate input').val());
    }

    if (primaryDetails.find('.first-name input').val()) {
        formreview.find('.review-first-name').html(primaryDetails.find('.first-name input').val());
    }

    if (primaryDetails.find('.last-name input').val()) {
        formreview.find('.review-last-name').html(primaryDetails.find('.last-name input').val());
    }

    if (primaryDetails.find('.organization-title select').val()) {
        formreview.find('.review-organization-title').html(primaryDetails.find('.organization-title select').val());
    }

    if (primaryDetails.find('.input-phone input').val()) {
        formreview.find('.review-phone').html(primaryDetails.find('.input-phone input').val());
    }

    if (primaryDetails.find('.input-email input').val()) {
        formreview.find('.review-email').html(primaryDetails.find('.input-email input').val());
    }
}

/**
 * This is the step that handles Business overview section
 */
function handleBusinessOverview () {
    var businessTypeForm , businessTypeContainer, businessTypeReview;
    var businessType = $('body').find('.business-type select').val();
    var formreview = $('body').find('.form-review-page.step-4-container');

    if (businessType === 'Design') {
        businessTypeForm = $('body');
        businessTypeContainer = $('body').find('.business-type-design-review');
        businessTypeContainer.show();
        businessTypeReview = formreview.find('.review-business-overview .card-body').html(businessTypeContainer);

        if (businessTypeForm.find('.website-link input').val()) {
            businessTypeReview.find('.review-website-link').html(businessTypeForm.find('.website-link input').val());
        }

        if (businessTypeForm.find('.number-of-designers-working input').val()) {
            businessTypeReview.find('.review-number-of-designers').html(businessTypeForm.find('.number-of-designers-working input').val());
        }

        if (businessTypeForm.find('.office-work-type select').val()) {
            businessTypeReview.find('.review-office-work-type').html(businessTypeForm.find('.office-work-type select').val());
        }

        if (businessTypeForm.find('.have-librarian-staff select').val()) {
            businessTypeReview.find('.review-have-librarian-staff').html(businessTypeForm.find('.have-librarian-staff select').val());
        }

        if (businessTypeForm.find('.average-projects-per-year input').val()) {
            businessTypeReview.find('.review-average-projects-per-year').html(businessTypeForm.find('.average-projects-per-year input').val());
        }

        if (businessTypeForm.find('.average-budget-per-year input').val()) {
            businessTypeReview.find('.review-average-budget-per-year').html(businessTypeForm.find('.average-budget-per-year input').val());
        }

        if (businessTypeForm.find('.form-segment select').val()) {
            businessTypeReview.find('.review-segment').html(businessTypeForm.find('.form-segment select').val());
        }

        if (businessTypeForm.find('.urgency-of-first-order select').val()) {
            businessTypeReview.find('.review-urgency-of-first-order').html(businessTypeForm.find('.urgency-of-first-order select').val());
        }
    }

    if (businessType === 'Retail') {
        businessTypeForm = $('body');
        businessTypeContainer = $('body').find('.business-type-retail-review');
        businessTypeContainer.show();
        businessTypeReview = formreview.find('.review-business-overview .card-body').html(businessTypeContainer);

        if (businessTypeForm.find('.website-link input').val()) {
            businessTypeReview.find('.review-website-link').html(businessTypeForm.find('.website-link input').val());
        }

        if (businessTypeForm.find('.total-retail-locations input').val()) {
            businessTypeReview.find('.review-total-retail-locations').html(businessTypeForm.find('.total-retail-locations input').val());
        }

        if (businessTypeForm.find('.offer-home-design-services select').val()) {
            businessTypeReview.find('.offer-home-design-services').html(businessTypeForm.find('.offer-home-design-services select').val());
        }

        if (businessTypeForm.find('.annual-sales-for-accessories-at-retail-value select').val()) {
            businessTypeReview.find('.review-annual-sales-for-accessories-at-retail-value').html(businessTypeForm.find('.annual-sales-for-accessories-at-retail-value select').val());
        }

        if (businessTypeForm.find('.annual-sales-for-lighting-at-retail-value select').val()) {
            businessTypeReview.find('.review-annual-sales-for-lighting-at-retail-value').html(businessTypeForm.find('.annual-sales-for-lighting-at-retail-value select').val());
        }

        if (businessTypeForm.find('.form-segment select').val()) {
            businessTypeReview.find('.review-segment').html(businessTypeForm.find('.form-segment select').val());
        }

        if (businessTypeForm.find('.number-of-sales-associates input').val()) {
            businessTypeReview.find('.review-number-of-sales-associates').html(businessTypeForm.find('.number-of-sales-associates input').val());
        }

        if (businessTypeForm.find('.average-square-feet-per-location input').val()) {
            businessTypeReview.find('.review-average-square-feet-per-location').html(businessTypeForm.find('.average-square-feet-per-location input').val());
        }

        if (businessTypeForm.find('.urgency-of-first-order select').val()) {
            businessTypeReview.find('.review-urgency-of-first-order').html(businessTypeForm.find('.urgency-of-first-order select').val());
        }
    }

    if (businessType === 'ECommerce') {
        businessTypeForm = $('body');
        businessTypeContainer = $('body').find('.business-type-ecommerce-review');
        businessTypeContainer.show();
        businessTypeReview = formreview.find('.review-business-overview .card-body').html(businessTypeContainer);

        if (businessTypeForm.find('.website-link input').val()) {
            businessTypeReview.find('.review-website-link').html(businessTypeForm.find('.website-link input').val());
        }

        if (businessTypeForm.find('.website-go-live-date input').val()) {
            businessTypeReview.find('.review-website-go-live-date').html(businessTypeForm.find('.website-go-live-date input').val());
        }

        if (businessTypeForm.find('.website-go-live-date input').val()) {
            businessTypeReview.find('.review-home-decor-vendors').html(businessTypeForm.find('.website-go-live-date input').val());
        }

        if (businessTypeForm.find('.annual-sales-for-accessories-at-retail-value select').val()) {
            businessTypeReview.find('.review-annual-sales-for-accessories-at-retail-value').html(businessTypeForm.find('.annual-sales-for-accessories-at-retail-value select').val());
        }

        if (businessTypeForm.find('.annual-sales-for-lighting-at-retail-value select').val()) {
            businessTypeReview.find('.review-annual-sales-for-lighting-at-retail-value').html(businessTypeForm.find('.annual-sales-for-lighting-at-retail-value select').val());
        }
    }

    if (businessType === 'Contract') {
        businessTypeForm = $('body');
        businessTypeContainer = $('body').find('.business-type-contract-review');
        businessTypeContainer.show();
        businessTypeReview = formreview.find('.review-business-overview .card-body').html(businessTypeContainer);

        if (businessTypeForm.find('.website-link input').val()) {
            businessTypeReview.find('.review-website-link').html(businessTypeForm.find('.website-link input').val());
        }

        if (businessTypeForm.find('.number-of-designers-working input').val()) {
            businessTypeReview.find('.review-number-of-designers').html(businessTypeForm.find('.number-of-designers-working input').val());
        }

        if (businessTypeForm.find('.have-librarian-staff select').val()) {
            businessTypeReview.find('.review-have-librarian-staff').html(businessTypeForm.find('.have-librarian-staff select').val());
        }

        if (businessTypeForm.find('.average-projects-per-year input').val()) {
            businessTypeReview.find('.review-average-projects-per-year').html(businessTypeForm.find('.average-projects-per-year input').val());
        }

        if (businessTypeForm.find('.average-budget-per-year input').val()) {
            businessTypeReview.find('.review-average-budget-per-year').html(businessTypeForm.find('.average-budget-per-year input').val());
        }

        if (businessTypeForm.find('.purchase-or-specify-both select').val()) {
            businessTypeReview.find('.review-purchase-or-specify-both').html(businessTypeForm.find('.purchase-or-specify-both select').val());
        }
    }
}

/**
 * This is the step that handles Billing address section
 */
function handleBillingAddress () {
    var billingAddress = $('body').find('.step-3-container.billing-address');
    var formreview = $('body').find('.form-review-page.step-4-container');

    if (billingAddress.find('.bill-to-name input').val()) {
        formreview.find('.review-bill-to-name').html(billingAddress.find('.bill-to-name input').val());
    }

    if (billingAddress.find('.address-1 input').val()) {
        formreview.find('.review-address-1').html(billingAddress.find('.address-1 input').val());
    }

    if (billingAddress.find('.address-2 input').val()) {
        formreview.find('.review-address-2').html(billingAddress.find('.address-2 input').val());
    }

    if (billingAddress.find('.address-3 input').val()) {
        formreview.find('.review-address-3').html(billingAddress.find('.address-3 input').val());
    }

    if (billingAddress.find('.zipcode input').val()) {
        formreview.find('.review-zipcode').html(billingAddress.find('.zipcode input').val());
    }

    if (billingAddress.find('.city input').val()) {
        formreview.find('.review-city').html(billingAddress.find('.city input').val());
    }

    if (billingAddress.find('.state select').val()) {
        formreview.find('.review-state').html(billingAddress.find('.state select').val());
    }

    if (billingAddress.find('.country input').val()) {
        formreview.find('.review-country').html(billingAddress.find('.country input').val());
    }

    if (billingAddress.find('.phone input').val()) {
        formreview.find('.review-phone').html(billingAddress.find('.phone input').val());
    }

    if (billingAddress.find('.billing-address-type select').val()) {
        formreview.find('.review-address-type').html(billingAddress.find('.billing-address-type select').val());
    }

    if (billingAddress.find('.dock-door select').val()) {
        formreview.find('.review-dock-door').html(billingAddress.find('.dock-door select').val());
    } else {
        formreview.find('.review-dock-door').html('--');
    }

    if (billingAddress.find('.accounts-payable-first-name input').val()) {
        formreview.find('.review-accounts-payable-first-name').html(billingAddress.find('.accounts-payable-first-name input').val());
    }

    if (billingAddress.find('.accounts-payable-last-name input').val()) {
        formreview.find('.review-accounts-payable-last-name').html(billingAddress.find('.accounts-payable-last-name input').val());
    }

    if (billingAddress.find('.accounts-payable-email input').val()) {
        formreview.find('.review-accounts-payable-email').html(billingAddress.find('.accounts-payable-email input').val());
    }
}

/**
 * This is the form review step and this function handes form review step
 */
function handleFormReviewStep () {
    handleCompanyInformation();
    handleBusinessOverview();
    handleBillingAddress();
}

/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    toggleFullOrder();
    initAddressEvents();
    initPaymentEvents();
    initLoginPage();
    validator.initForm($('form[id$="OTAForm"]'));
    formPrepare.init({
        formSelector: 'form[id$="OTAForm"]',
        continueSelector: '[name$="profile_confirm"]'
    });
    $('body').on('click', '#dwfrm_login_login', function (e) {
        var $form = $('#dwfrm_login_login').closest('form');
        e.preventDefault();
        if (!$form.valid()) {
            return false;
        }
        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        var applyName = $form.find('#dwfrm_login_login').attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            if (data.success && window.dataLayer) {
                window.dataLayer.push({
                    event: 'log_in_success',
                    loggedInStatus: true,
                    'membership_status': data.membershipStatus,
                    'user_id': data.userID,
                    membership: data.membership,
                    'membership_date': data.membershipDate
                });

                window.dataLayer.push({
                    event: 'gtmEvent',
                    eventCategory: 'engagement',
                    eventAction: 'account',
                    eventLabel: 'login success',
                    interactionEvent: false
                });
            }
            window.location.href = (data.redirectUrl);
        });
    });

    // $(document).on('submit', '#OTAForm', function (e) {
    //     e.preventDefault();
    //     var $form = $(this);
    //     // var captcha = $('[name$="_captcha"]').val(),
    //     //     $captchaWrapper = $('.data-captcha-box');
    //     validator.initForm($('form[id$="OTAForm"]'));
    //     if (!$form.valid()) {
    //         // $captchaWrapper.find('.error').remove();
    //         $('.registration-modal-header').hide();
    //         $('.error-header').show();
    //         // if (captcha === '') {
    //         //     $captchaWrapper.find('.data-captcha').append('<span class="error">' + Resources.VALIDATE_REQUIRED + '</span>');
    //         // }
    //         return false;
    //     }

    //     var $email = $form.find('.email');
    //     var email = $email.val();
    //     var $checkbox = $form.find('.js_addtoemaillist input[type="checkbox"]');
    //     var isChecked = $checkbox.is(':checked');
    //     var isEdit = $form.hasClass('js_reg-form-redesign-edit');
    //     var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
    //     var applyName = $form.find('button[type="submit"]').attr('name');
    //     var options = {
    //         url: url,
    //         data: $(this).serialize() + '&' + applyName + '=x',
    //         type: 'POST'
    //     };
    //     $.ajax(options).done(function (data) {
    //         if (data.success && window.dataLayer) {
    //             window.dataLayer.push({
    //                 event: 'gtmEvent',
    //                 eventCategory: 'engagement',
    //                 eventAction: 'account',
    //                 eventLabel: isEdit ? 'account edit success' : 'account creation success',
    //                 interactionEvent: false
    //             });

    //             if (isChecked) {
    //                 window.dataLayer.push({
    //                     event: 'gtmEvent',
    //                     eventCategory: 'engagement',
    //                     eventAction: 'email signup',
    //                     eventLabel: isEdit ? 'edit account' : 'create account',
    //                     interactionEvent: false,
    //                     email: email && email.length ? email.toLowerCase() : email
    //                 });
    //             }
    //         }
    //         window.location.href = (data.redirectUrl);
    //     });
    // });

    $(document).on('click', '.js_contact-us-btn', function (e) {
        var $form = $(this).closest('form');
        var $captcha = $form.find('[name$="_captcha"]'),
            captcha = $captcha.val(),
            $captchaWrapper = $('.data-captcha-box');

        if (!$form.valid() || captcha === '') {
            e.preventDefault();
            $captchaWrapper.find('.error').remove();

            if (captcha === '') {
                $('<span class="error">' + Resources.VALIDATE_REQUIRED + '</span>').insertAfter($captcha);
            }

            return false;
        }
    });

    $('.state-certificate-button').on('click', function() {
        $('.state-certificate-input input').click();
    });

    $('.checkbox-copy').on('click', function (e) {
        var checkVal = $('input.autorenew-check').is(':checked');
        if ($('label.checkbox-label').hasClass('checked')) {
            e.preventDefault();
            dialog.open({
                url: Urls.autorenewalDescription,
                options: {
                    open: initializeTerms,
                    dialogClass: 'autorenewterms'
                },
                callback: function() {
                    var $renewCheckbox = $('.auto-renew-terms-container .checkbox-label');
                    var $renewAgree = $('.auto-renew-terms-container .agreeterms');
                    $renewCheckbox.on('click', function () {
                        if ($renewCheckbox.hasClass('checked')) {
                            $renewAgree.prop('disabled', false);
                        } else {
                            $renewAgree.prop('disabled', 'disabled');
                        }
                    });
                    $('.comfort-club-terms-container').hide();
                    $('.auto-renew-terms-container').show();
                    $('.renew-checkbox-container .checkbox-label').removeClass('checked');
                    $('.agreeterms.autorenew-agree').prop('disabled', 'disabled');
                    $('input.autorenew-check').prop('checked', false);
                }
            });
        }
    });

    $('body').on('click', '.membership-cancelation button', function(e) {
        e.preventDefault();
        dialog.open({
            url: Urls.autorenewalCancelation,
            options: {
                dialogClass: 'cancelautorenewterms'
            }
        });
    });
    $('body').on('click', '#AutoRenewCancel', function(e) {
        window.location = (Urls.cancelLoyalty);
    });
    $('body').on('click', '#AutoRenewCloseModal', function(e) {
        dialog.close();
    });
    window.onload = function() {
        if ($('.col-1 input[name*="_login_username"]').length > 0) {
            window.chromeCheck = window.setInterval(checkAutoFill, 333);
        }
    }
    function checkAutoFill() {
        var hasValue = $(".col-1 input[name*='_login_username']").val().length > 0;//Normal
        var hasPassword = $(".col-1 input[name*='_login_password']").val().length > 0;
        if (!hasValue) {
            hasValue = $(".col-1 input[name*='_login_username']:-webkit-autofill").length > 0;//Chrome
        }
        if (!hasPassword) {
            hasPassword = $(".col-1 input[name*='_login_username']:-webkit-autofill").length > 0;
        }
        if (hasValue) {
            $(".col-1 input[name*='_login_username']").parent().parent().addClass('input-focused');
            if (hasPassword) {
                $(".col-1 input[name*='_login_password']").parent().parent().addClass('input-focused');
            }
            clearInterval(window.chromeCheck);
        }
    }
    if (SitePreferences.GOOGLE_ADDRESS_AUTOCOMPLETE_ENABLED) {
        addressAutocomplete.init();
    }

    // if ($('.account-type-radio:checked').val() == "business") {
    //         $('.companyname').removeClass('hide');
    //     } else {
    //         $('.companyname').addClass('hide');
    //     }

    // $('body').on('change', '.account-type-radio', function() {
    //     if ($('.account-type-radio:checked').val() == "business") {
    //         $('.companyname').removeClass('hide');
    //     } else {
    //         $('.companyname').addClass('hide');
    //     }
    // });
    var currentStep, nextStep, previousStep;
    $('body').on('click', '.cta.next-button button', function (e) {
        e.preventDefault();
        currentStep = $(this).parents('.open-trade-account-step');
        nextStep = $(this).parents('.open-trade-account-step').next();

        if ((currentStep.hasClass('company-information')) && (!$('.company-information fieldset').find(':input').valid())) {
            $('.cta.next-button button').attr('disabled', true);
            $('.privacy-policy-consent label').removeClass("checked");
            $('.privacy-policy-consent input').removeAttr("checked");
            return false;
        } else if ((currentStep.hasClass('business-overview')) && (!$('.business-overview fieldset').find(':input').valid())) {
            return false;
        } else if ((currentStep.hasClass('billing-address')) && (!$('.billing-address fieldset').find(':input').valid())) {
            return false;
        }

        var data;
        nextStep.show();
        currentStep.hide();
        $('html, body').animate({
            scrollTop: $('#OTAForm').offset().top
        }, 500);

        $(".progress-bar li").eq($(".open-trade-account-step").index(currentStep)).removeClass("active");
        $(".progress-bar li").eq($(".open-trade-account-step").index(nextStep)).addClass("active");

        if (nextStep.hasClass('form-review-page')) {
            handleFormReviewStep();
        }

        if (nextStep.hasClass('business-overview')) {
            $(document).trigger('baCoreReady');
        }
    });

    function updateBusinessType() {
        var businessTypeValue = $('.business-type select').val();
        var data;
        if (businessTypeValue === 'Design') {
            $('body').find('.business-type-container').hide();
            data = $('body').find('.business-type-container.business-type-design').html();
            $('body').find('.step-2-container.business-overview').html(data);
        }
        if (businessTypeValue === 'Retail') {
            $('body').find('.business-type-container').hide();
            data = $('body').find('.business-type-container.business-type-retail').html();
            $('body').find('.step-2-container.business-overview').html(data);
        }
        if (businessTypeValue === 'ECommerce') {
            $('body').find('.business-type-container').hide();
            data = $('body').find('.business-type-container.business-type-ecommerce').html();
            $('body').find('.step-2-container.business-overview').html(data);
        }
        if (businessTypeValue === 'Contract') {
            $('body').find('.business-type-container').hide();
            data = $('body').find('.business-type-container.business-type-contract').html();
            $('body').find('.step-2-container.business-overview').html(data);
        }
    }

    function updateTaxSection() {
        if ($('body').find('.sales-tax-status select').attr('disabled') && ($('.register-state select').val() != '')) {
            $('body').find('.sales-tax-status select').removeAttr('disabled');
            $('body').find('.sales-tax-status .ba-select-container').removeClass('disabled');
            $('body').find('.sales-tax-status .ba-select-box').removeClass('disabled');
            $('body').find('.company-information .tax-details').removeClass('d-none');
        }
    }

    $('body').on('change','.business-type select', function (e) {
        updateBusinessType();
    });

    $(document).ready(function() {
        updateBusinessType();
        updateTaxSection();
        if ($('.privacy-policy-consent input').is("[checked]")) {
            $('.privacy-policy-consent input').removeAttr("checked");
        }
    });

    $('body').on('click','.cta.previous-button button', function (e) {
        e.preventDefault();
        currentStep = $(this).parents('.open-trade-account-step');
        previousStep = $(this).parents('.open-trade-account-step').prev();
        previousStep.show();
        currentStep.hide();
        $('html, body').animate({
            scrollTop: $('#OTAForm').offset().top
        }, 500);

        $(".progress-bar li").eq($(".open-trade-account-step").index(currentStep)).removeClass("active");
        $(".progress-bar li").eq($(".open-trade-account-step").index(previousStep)).addClass("active");

        if (previousStep.hasClass('business-overview')) {
            $(document).trigger('baCoreReady');
        }
    });

    $('body').on('change', '#OTAForm .register-state select', function () {
        updateTaxSection();
    });

    $('body').on('change', '#OTAForm .sales-tax-status select', function () {
        if ($('body').find('.sales-tax-status select').val() === 'Exempt') {
            $('body').find('.sales-tax-status .form-caption').html('If Exempt, please provide your Resale Certificate or Exemption form for each state in which you are claiming an exemption.');
            $('body').find('.tax-exemption-number input').attr("aria-required", 'true').addClass('required');
            $('body').find('.tax-exemption-number').attr("aria-required", 'true').addClass('required');
            if (!$('.tax-exemption-number label').text().includes("*")) {
                $('body').find('.tax-exemption-number label').append(" *");
            }
            $('body').find('.state-certificate-input input').attr("aria-required", 'true').addClass('required');
            if (!$('.state-certificate-input-container label').text().includes("*")) {
                $('body').find('.state-certificate-input-container label').append(" *");
            }
        } else if ($('body').find('.sales-tax-status select').val() === 'Non-Exempt') {
            $('body').find('.sales-tax-status .form-caption').html('If Non-Exempt or if no certificate is provided, the appropriate rate of Sales Tax will be applied to your orders.');
            $('body').find('.tax-exemption-number input').attr("aria-required", 'false').removeClass('required');
            $('body').find('.tax-exemption-number').attr("aria-required", 'false').removeClass('required');
            $('body').find('.tax-exemption-number input').val('');
            $('body').find('.tax-exemption-number label').text(function(_, text) {
                return text.replace(' *', ''); // Remove * from the label text
            });
            // $('body').find('.state-certificate-input input').attr("required", 'false').removeClass('required');
            $('body').find('.state-certificate-input input').attr("aria-required", 'false').removeClass('required');
            $('body').find('.state-certificate-input-container label').text(function(_, text) {
                return text.replace(' *', ''); // Remove * from the label text
            });
        }
    });

    $('body').on('change', '#OTAForm .billing-address-type select', function () {
        if ($('body').find('.billing-address-container .billing-address-type select').val() === 'Business') {
            $('body').find('.dock-door').removeClass('d-none');
            $('body').find('.dock-door select').attr("aria-required", 'true').addClass('required');
            $('body').find('.dock-door').attr("aria-required", 'true');
            if (!$('.dock-door label').text().includes("*")) {
                $('body').find('.dock-door label').append(" *");
            }
        } else if (!$('body').find('.dock-door').hasClass('d-none')) {
            $('body').find('.dock-door').addClass('d-none');
            $('body').find('.dock-door select').attr("aria-required", 'false').removeClass('required');
            $('body').find('.dock-door').attr("aria-required", 'false');
            $('body').find('.dock-door select').val('');
        }
    });

    $('body').on('click', '#OTAForm .privacy-policy-checkbox', function (e) {
        if ($(this).is(':checked')) {
            $('.cta.next-button button').removeAttr('disabled');
        } else if (!($(this).is(':checked')) && !($('.cta.next-button').attr('disabled'))) {
            $('.cta.next-button button').attr('disabled', true);
        }
    });

    $('body').on('click', '#OTAForm .declaration-policy-checkbox', function (e) {
        if ($(this).is(':checked')) {
            $('.cta.submit-button button').removeAttr('disabled');
        } else if (!($(this).is(':checked')) && !($('.cta.submit-button').attr('disabled'))) {
            $('.cta.submit-button button').attr('disabled', true);
        }
    });

    $('body').on('click', '#OTAForm .same-as-primary-contact', function (e) {
        if ($(this).is(':checked')) {
            var primaryDetails = $('body').find('.step-1-container.company-information .primary-details-container');
            var accountsPayable = $('body').find('.step-3-container.billing-address .accounts-payable-container');
            if (primaryDetails.find('.first-name input').val()) {
                accountsPayable.find('.accounts-payable-first-name input').val(primaryDetails.find('.first-name input').val());
            }

            if (primaryDetails.find('.last-name input').val()) {
                accountsPayable.find('.accounts-payable-last-name input').val($(primaryDetails).find('.last-name input').val());
            }

            if (primaryDetails.find('.input-email input').val()) {
                accountsPayable.find('.accounts-payable-email input').val($(primaryDetails).find('.input-email input').val());
            }
        }
    });

    $('body').on('click', '#OTAForm .edit-section ', function (e) {
        $('body').find('.open-trade-account-step').hide();
        var targeStep = $(this).data('target-step');
        $('body').find('.' + targeStep).show();

        $(".progress-bar li").removeClass("active");
        var targetProgressbarStep = $(this).data('progress-bar-step');
        $(".progress-bar li").eq(targetProgressbarStep).addClass('active');
    });
}

var account = {
    init: function () {
        initializeEvents();
        giftcert.init();
    },
    initCartLogin: function () {
        initLoginPage();
    }
};

module.exports = account;
