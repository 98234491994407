'use strict';

var dialog = require('./dialog');

var promoBannerDialogClass = 'promo-banner-ui-dialog';

function formatNumber(num) {
    return (num < 10 ? '0' : '') + num;
}

function setHeaderPromoCountdounTimer() {
    var $container = $('.js_header-countdown');

    if (!($container && $container.length)) {
        return;
    }

    var endDate = $container.data('end-date');

    if (!(endDate && endDate.length)) {
        return;
    }

    // Set the date we're counting down to
    var countDownDate = new Date(endDate).getTime();

    // Update the count down every 1 second
    var x = setInterval(function() {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // If the count down is over, write some text
        if (distance < 1) {
            $container.closest('.js_countdown-container').remove();
            clearInterval(x);
        } else {
            var formattedDate =
                (days > 0 ? (formatNumber(days) + ':') : '') +
                formatNumber(hours) + ':' + formatNumber(minutes) + ':' + formatNumber(seconds);
            $container.html(formattedDate);
        }
    }, 1000);
}

function initializeEvents() {
    setHeaderPromoCountdounTimer();

    $('body').on('click', '.js_promo-banner', function (e) {
        e.preventDefault();

        var url = $(this).attr('href');

        dialog.open({
            url: url,
            options: {
                open: function () {
                    var dialog = $(this);

                    dialog.dialog('option', 'position', { my: 'center', at: 'center', of: window });

                    dialog.on('click', '.js_close', function () {
                        dialog.dialog('close');
                    });
                },
                dialogClass: promoBannerDialogClass
            }
        });
    });
}

exports.init = function () {
    initializeEvents();
};
