'use strict';

function initCarousel() {
    $('.js_ds-layout-carousel').each(function () {
        var $container = $(this);
        var $carousel = $container.find('.ds-layout-carousel__owl-carousel');
        var $controls = $container.find('.ds-layout-carousel__controls');
        var $prev = $controls.find('.custom-owl-prev');
        var $next = $controls.find('.custom-owl-next');

        $carousel
            .on('initialized.owl.carousel changed.owl.carousel', function () {
                $('body').trigger('resetlazy', $(this)[0]);
            })
            .on('initialized.owl.carousel', function () {
                $(document).trigger('custom:owl:carousel:initialized', {
                    carousel: $(this)
                });
            })
            .owlCarousel({
                items: 2,
                dots: false,
                nav: true,
                margin: 10,
                stagePadding: 30,
                loop: true,
                lazyLoad: true,
                responsive: {
                    768: {
                        items: 4,
                        stagePadding: 0,
                        margin: 24
                    },
                    1024: {
                        items: 4,
                        margin: 50
                    }
                }
            });

        $prev.on('click', function () {
            $carousel.trigger('prev.owl.carousel');
        });

        $next.on('click', function () {
            $carousel.trigger('next.owl.carousel');
        });
    });
}

function sendGTMEvent() {
    if (!window.dataLayer) {
        return;
    }

    window.dataLayer.push({
        event: 'gtmEvent',
        eventCategory: 'engagement',
        eventAction: 'design services requst',
        eventLabel: 'request consultation',
        interactionEvent: false
    });
}

function sendEmailSignupGTMEvent($form) {
    if (!window.dataLayer) {
        return;
    }

    var $checkbox = $form.find('input[type="checkbox"]');

    if ($checkbox.is(':checked')) {
        var $email = $form.find('input[type="email"]');
        var email = $email.val();

        window.dataLayer.push({
            event: 'gtmEvent',
            eventCategory: 'engagement',
            eventAction: 'email signup',
            eventLabel: 'design services',
            interactionEvent: false,
            email: email && email.length ? email.toLowerCase() : email
        });
    }
}

function initQudiniEvent() {
    window.addEventListener('message', function (event) {
        if (event.data && event.data.customMessageSender === 'Qudini' && window.dataLayer) {
            event.data.event = "QudiniTrack";
            window.dataLayer.push(event.data);
        }
    }, false);
}

/**
 * @private
 * @function
 * @description Binds the events of the design page to design page
 */
function initializeEvents() {
    $('#main').addClass('default-max-width');
    initCarousel();
    initQudiniEvent();

    $(document).on('form:ajax:response', function (e, data) {
        if (!data.response.status) {
            return;
        }

        var $form = $(data.form.el);
        if (!$form.hasClass('js_process-design-request-form')) {
            return;
        }

        sendGTMEvent();
        sendEmailSignupGTMEvent($form);
    });
}

var designservices = {
    init: initializeEvents
};

module.exports = designservices;
