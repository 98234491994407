'use strict';

var addProductToCart = require('./product/addToCart'),
    dialog = require('../dialog'),
    page = require('../page'),
    search = require('./search'),
    util = require('../util');

function gtmWishlist(eventLabel) {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'gtmEvent',
            eventCategory: 'engagement',
            eventAction: 'wishlist',
            eventLabel: eventLabel,
            interactionEvent: false
        });
    }
}

function resetModalPosition($dialog) {
    $dialog.dialog('option', 'position', { my: 'center', at: 'center', of: window });
}

function checkFilledForm($input) {
    if (!($input && $input.length)) {
        return;
    } else {
        var regex = new RegExp($input.attr('regex'));
        var $button = $input.closest('form').find('button');
        if (regex.test($input.val())) {
            $button.prop('disabled', false);
        } else {
            $button.prop('disabled', true);
        }
    }
}

function closeWishlistLPModal() {
    $('.lp-wishlist-ui-dialog').find('.js_close').trigger('click');
}

function updateWishlistName(newName) {
    $('.js_wishlist-single__name').text(newName);
}

function openWishlistLPModal(url) {
    dialog.open({
        url: url,
        options: {
            open: function () {
                var dialog = $(this);

                checkFilledForm(dialog.find('.js_wl-name'));
                resetModalPosition(dialog);

                dialog
                    .on('click', '.js_close', function () {
                        dialog.dialog('close');
                    });

                gtmWishlist('Modal View');
            },
            dialogClass: 'lp-wishlist-ui-dialog'
        }
    });
}

exports.init = function () {
    addProductToCart();

    if ($('.js_wishlist-single').length) {
        search.init();
    }

    $(document)
        .on('click', function (e) {
            if (!$(e.target).closest('.js_open-create-wl-modal, .lp-wishlist-ui-dialog').length) {
                closeWishlistLPModal();
            }
        })
        .on('click', '.js_open-create-wl-modal, .js_wishlist-share-modal, .js_wishlist-edit-modal', function (e) {
            e.preventDefault();
            openWishlistLPModal($(this).data('href'));

            if ($(this).hasClass('js_wishlist-share-modal')) {
                gtmWishlist('Share List');
            }
        })
        .on('click', '.js_wishlist-delete-modal', function (e) {
            e.preventDefault();
            $(this).closest('.wl-lp-dialog-edit').toggleClass('wl-lp-dialog-edit wl-lp-dialog-delete');
        })
        .on('blur change keyup', '.js_wl-name', function () {
            checkFilledForm($(this));
        })
        .on('form:message:show', function (e, data) {
            var $container = $('.lp-wishlist-ui-dialog');

            if (data.success && $container.is(':visible')) {
                var $editButton = $container.find('.wl-lp-dialog-edit__button');
                if ($editButton.length && $editButton.is(':visible')) {
                    updateWishlistName(data.new_name);
                    closeWishlistLPModal();
                    return;
                }

                if ($container.find('.wl-lp-dialog-create__button').length) {
                    gtmWishlist('Create List');
                }

                setTimeout(function () {
                    location.reload();
                }, 1000);
            }
        })
        .on('click', '.js_wishlist-copy-to-clipboard', function () {
            var $successMessage = $('.js_wishlist-copied-to-clipboard');

            if ($successMessage.is(':visible')) {
                return;
            }

            var $source = $('.js_wishlist-copy-to-clipboard-source');
            var text = $source.val();
            util.copyToClipboard(text);
            $successMessage
                .fadeIn()
                .fadeOut(3000);
        });

    $('#editAddress').on('change', function () {
        page.redirect(util.appendParamToURL(Urls.wishlistAddress, 'AddressID', $(this).val()));
    });

    //add js logic to remove the , from the qty feild to pass regex expression on client side
    $('.option-quantity-desired input').on('focusout', function () {
        $(this).val($(this).val().replace(',', ''));
    });

    //add listner for redirecct after login
    $('body').on('click', '#dwfrm_login_login', function (e) {
        var $form = $('#dwfrm_login_login').closest('form');
        e.preventDefault();
        if (!$form.valid()) {
            return false;
        }
        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        var applyName = $form.find('#dwfrm_login_login').attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            if (data.success) {
                window.dataLayer.push({
                    'event': 'logged in',
                    'loggedInStatus': true,
                    'membership_status': data.membershipStatus,
                    'user_id': data.userID,
                    'membership': data.membership,
                    'membership_date': data.membershipDate
                });
            }
            window.location.href = (data.redirectUrl);
        });
    });

    $(window).on('load', function () {
        if ($('.js_wishlist-single').length) {
            gtmWishlist('View List');
        }
    });
};
