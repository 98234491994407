'use strict';

var debounce = require('lodash.debounce'),
    util = require('../../util');

function initProductColectionCarousel() {
    if (!util.isProductV2()) {
        return;
    }

    var $productColectionCarousel = $('.product-collection-content .tiles-container');

    if ($productColectionCarousel.length > 0) {
        initCarousel($productColectionCarousel);
    }
}

function initCompleteLookCarousel() {
    var $variantCarousel = $('.js-product-ajax-container .complete-look');
    if ($variantCarousel.length > 0 && !util.isProductV2()) {
        $('#pdpMain > .complete-look').replaceWith($variantCarousel.eq(0));
    }
    var $completeLookCarousel = $('.complete-look-carousel .tiles-container');
    var $items = $completeLookCarousel.find('.grid-tile');
    var itemsCount = $items.length;

    if ($('.complete-look.carousel-refresh').length > 0 && !util.isProductV2()) {
        initCarousel($completeLookCarousel);
    } else if ($completeLookCarousel.length > 0 && itemsCount > 0) {
        var carouselOptions = util.isProductV2()
            ? {
                loop: true,
                mouseDrag: true,
                nav: true,
                navRewind: false,
                responsive: {
                    0: {
                        items: 2,
                        nav: false,
                        dots: itemsCount > 2
                    },
                    1128: {
                        items: 3,
                        dots: itemsCount > 3
                    },
                    1440: {
                        items: 4,
                        dots: itemsCount > 4
                    }
                }
            }
            : {
                loop: false,
                mouseDrag: true,
                nav: true,
                navRewind: false,
                items: 3,
                dots: false,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 3
                    }
                }
            };

        $completeLookCarousel
            .on('initialized.owl.carousel changed.owl.carousel', function() {
                $(document).trigger('custom:lazy:load:update');
            })
            .on('initialized.owl.carousel', function() {
                $(document).trigger('custom:owl:carousel:initialized', {
                    carousel: $(this)
                });
            })
            .owlCarousel(carouselOptions);
    } else {
        $completeLookCarousel.closest('.complete-look').hide();
    }
}

function waitForRecommendations() {
    var $recommendationsWrapper = $('.pdp-predictive-recommendations, .pdp-recently-viewed, .empty-cart-recently-viewed, .no-hits-recently-viewed');

    var carouselObserver = new MutationObserver(function(mutationsList, observer) {
        mutationsList.forEach(function(mutation) {
            initPredictiveRecommendations(mutation.target);
        });
    });

    $recommendationsWrapper.each(function() {
        carouselObserver.observe(this, {childList: true, subtree: true});
    });
}

function initPredictiveRecommendations(wrapper) {
    var $recommendations = $(wrapper).find('.tiles-container');

    // remove empty image tile if there's no content/slot
    if ($recommendations.find('.image-tile img').length === 0) {
        $recommendations.find('.image-tile').remove();
    }

    if ($recommendations.closest('.carousel-refresh').length > 0) {
        initCarousel($recommendations);
    } else {
        if (util.isProductV2()) {
            return;
        }

        $(window).on('resize', debounce(function() {
            initMobileOnlyCarousel($recommendations);
        }, 150));

        initMobileOnlyCarousel($recommendations);
    }
}

function initMobileOnlyCarousel($carousel) {
    if ($carousel.length > 0 && $(window).width() < 768) {
        var $imageTile = $carousel.find('.image-tile');
        var $mobileImage = $imageTile.find('.show-mobile');
        $carousel.attr('data-image-tile', $imageTile.prop('outerHTML'));
        if ($mobileImage.length > 0) {
            $carousel.closest('.product-carousel').prepend($mobileImage);
        }
        $imageTile.remove();
        $carousel
            .on('initialized.owl.carousel changed.owl.carousel', function() {
                $(document).trigger('custom:lazy:load:update');
            })
            .on('initialized.owl.carousel', function() {
                $(document).trigger('custom:owl:carousel:initialized', {
                    carousel: $(this)
                });
            })
            .owlCarousel({
                loop: false,
                nav: true,
                navRewind: false,
                items: 1,
                dots: false,
                responsive: false
            });
    } else if (typeof $carousel.data('owlCarousel') !== 'undefined') {
        $carousel.data('owlCarousel').destroy();
        $carousel.removeClass('owl-carousel owl-loaded owl-hidden');
        $carousel.find('.owl-stage-outer').children().unwrap();
        $carousel.siblings('.show-mobile').remove();
        $carousel.prepend($carousel.data('image-tile'));
        $carousel.removeData();
    }
}

function initCarousel($carousel) {
    if ($carousel.length > 0) {
        // Remove lazy load on the iOS Safari
        $carousel.find('img.lazy-load').each(function (index, current) {
            $(current).attr('src', $(current).data('lazy-src'));
        });

        var $items = $carousel.find('.js_grid-tile');
        var itemsCount = $items.length;

        $carousel
            .on('initialized.owl.carousel', function() {
                $(document).trigger('custom:owl:carousel:initialized', {
                    carousel: $(this)
                });
            })
            .owlCarousel({
                loop: true,
                mouseDrag: true,
                nav: true,
                navRewind: false,
                responsive: {
                    0: {
                        items: 2,
                        nav: util.isProductV2(),
                        dots: itemsCount > 2
                    },
                    1128: {
                        items: 3,
                        dots: itemsCount > 3
                    },
                    1440: {
                        items: 4,
                        dots: itemsCount > 4
                    }
                }
            });
    }
}

module.exports = {
    initCompleteLookCarousel: initCompleteLookCarousel,
    initProductColectionCarousel: initProductColectionCarousel,
    waitForRecommendations: waitForRecommendations
}
