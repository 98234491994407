'use strict';

var dialog = require('../dialog');
var util = require('../util');

var dialogClass = 'singlecustomdialog';

function setFurnitureProtectionPricing(assetID) {
    var url = util.appendParamToURL(Urls.furnitureProtectionPricing, 'cid', assetID);

    return $.ajax({
        type: 'GET',
        url: url,
        success: function (response) {
            $('.' + dialogClass).find('.furniture-pricing-rows').replaceWith(response);
        }
    });
}

function initializeEvents() {
    $('body').on('click', '.js_ex-custom-link-dialog', function (e) {
        var assetID = $(this).data('asset-id');
        if (!assetID || assetID.length === 0) {
            return;
        }

        e.preventDefault();

        var url = $(this).attr('href');
        var isEnableFurnitureProtectionPricingLogic = $(this).data('enable-furniture-protection-pricing-logic') === true;

        var uiDialogClasses = dialogClass;

        if (isEnableFurnitureProtectionPricingLogic) {
            uiDialogClasses += ' furniture-protection-pricing-ui-dialog';
        }

        dialog.open({
            url: url,
            options: {
                open: function () {
                    var dialog = $(this);
                    
                    function resetModalPosition(){
                        dialog.dialog("option", "position", { my: "center", at: "center", of: window })
                    }
  
                    if (isEnableFurnitureProtectionPricingLogic) {
                        setFurnitureProtectionPricing(assetID).then(resetModalPosition);
                    }

                    dialog.on('click', '.js_close', function () {
                        dialog.dialog('close');
                    });
                },
                dialogClass: uiDialogClasses
            }
        });
    });
}

exports.init = function () {
    initializeEvents();
};
