/**
 * @package     Blueacorn/Core
 * @version     1.0
 * @author      Blue Acorn <code@blueacorn.com>
 * @copyright   Copyright © 2016 Blue Acorn.
 *
 * @todo Be able to convert this into a simpler JS template.
 * @todo comment parsing?
 * @todo better naming convention
 */

(function (root, factory) {
    if (typeof exports === 'object') {
        // CommonJS
        module.exports = factory(window.jQuery, require('../vendor/requirejs/domReady'), require('./blueacorn-utils'), require('../util'));
    } else if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery', 'domReady', 'baUtils', 'util'], function (jquery, domReady, baUtils, util) {
            return (factory(jquery, domReady, baUtils, util));
        });
    } else {
        // Global Variables
        root.ba = factory(root.jQuery, root.domReady);
    }

}(this, function ($, domReady, utils, util) {
    'use strict';

    var blueAcornCore = {
        utils: utils,
        config: {},

        settings: {
            debug: false,
            moduleName : 'blueAcornCore'
        },

        bp: {
            xxsmall: 320,
            xsmall: 480,
            small: 640,
            medium: 767,
            large: 1023,
            xlarge: 1279
        },

        init: function (options) {

            // Overrides the default settings
            this.overrideSettings(this.settings, options);

            // Overrides the default BP settings since M1 has them globally
            if (typeof window.bp !== 'undefined'){
                this.overrideSettings(this.bp, window.bp);
            }

            // Start the debugger
            if (this.settings.debug === true || this.config['styleguide/development/enable_development'] === true) {
                this.setupDebugging(this.settings);
            }

            this.addWindowsClass();
            this.addIEClass();
            this.activateVideoModal();
            this.triggerCustomEvent();
            this.triggerDelayedResize();
            this.enableDatePickerFields();
            this.scrollToSection();
        },

        enableDatePickerFields: function () {
            var pastDate = new Date();
            pastDate = pastDate.getFullYear() - 130;

            $('input.datepicker').datepicker({
                autoclose: true,
                changeYear: true,
                changeMonth: true,
                dateFormat: 'mm/dd/yy',
                yearRange: pastDate +':2019',
                onChangeMonthYear: function(year, month, inst){
                    $(this).datepicker('setDate', month + '/1/' + year);
                },
                onSelect: function(selectedDate) {
                    $('#ui-datepicker-div table td a').attr('href', 'javascript:;');
                    $('#ui-datepicker-div').hide();
                    $(this).next('input.datepicker').focus();
                    $(this).val(selectedDate);
                    //$(this).valid();
                }
            });
        },

        activateVideoModal: function() {
            var videoButton = $('.video-modal');

            if (videoButton.length > 0){
                videoButton.fancybox({
                    type: 'iframe',
                    padding: 59,
                    margin: 0,
                    swf: {wmode: 'transparent'}
                });
            }
        },

        /**
         * scrolls user to the current location based on if
         * `scrollTo` exists in the URL
         */
        scrollToSection: function() {
            var params = util.getQueryStringParams(window.location.search);

            if (params.hasOwnProperty('scrollTo')) {
                var $scrollToDiv = $('.' + params.scrollTo);

                if (this.checkForElement($scrollToDiv)){
                    $('html, body').animate({
                        scrollTop: ($scrollToDiv.offset().top - 100)
                    }, 600);
                }
            }
        },

        /**
         * Takes default settings in object scope, and
         * merges the optional object passed in on initiation
         * of the class.
         */
        overrideSettings: function (settings, options) {
            if (typeof options === 'object') {
                settings = jQuery.extend(settings, options);
            }
        },

        setupDebugging: function (moduleSettings) {
            if (typeof moduleSettings === 'object'){
                this.watchConsole(moduleSettings.moduleName + ' Loaded!!!');
                this.watchConsole(moduleSettings);
            }
        },

        triggerCustomEvent: function() {
            $(document).trigger('baCoreReady');
        },

        /**
         * Generic, efficient window resize handler
         * Using setTimeout since Web-Kit and some other browsers call the resize function constantly upon window resizing.
         */
        triggerDelayedResize: function() {
            var resizeTimer;

            $(window).resize(function (e) {
                clearTimeout(resizeTimer);

                resizeTimer = setTimeout(function () {
                    $(window).trigger('delayed-resize', e);
                }, 250);
            });
        },

        /**
         * Checks if the specified jQuery element exists.
         *
         * If regular HTML element is passed, will change into
         * jQuery selector for use in this function.
         *
         * @param $element - jQuery object
         * @returns {boolean}
         */
        checkForElement: function($element){
            if (!($element instanceof jQuery)){
                $element = jQuery($element);
            }
            return $element.length >= 1;
        },

        /**
         * Adds console log if degubbing is true
         * @param string
         */
        watchConsole: function (message) {
            if (!$('.ie6, .ie7, .ie8, .ie9').length && typeof console !== 'undefined' && (this.settings.debug || this.config['styleguide/development/enable_development'])) {
                console.log(message);
            }
        },

        isWindows: function(){
            return navigator.platform.toUpperCase().indexOf('WIN') !== -1;
        },

        getBrowser: function() {
            var ua = window.navigator.userAgent;

            // IE 9
            if (ua.indexOf('MSIE 9.') > 0) {
                return 'IE nine';
            }

            // IE 10 or older
            if (ua.indexOf('MSIE 10.') > 0) {
                return 'IE ten';
            }

            // IE 11
            if (ua.indexOf('Trident/') > 0) {
                return 'IE eleven';
            }

            // EDGE
            if (ua.indexOf('Edge/') > 0) {
                return 'IE edge';
            }

            // If Firefox
            if (ua.indexOf('Firefox/') > 0) {
                return 'firefox';
            }

            return false;
        },

        addWindowsClass: function() {
            if (this.isWindows()){
                $('html').addClass('windows');
            }
        },

        addIEClass: function() {
            var version = this.getBrowser(), $html = $('html');
            if (version !== false){
                $html.addClass(version);
            }
        },

        /**
         * Returns a function that will only be triggered once, after inactivity of (wait) ms
         * Ported from: http://underscorejs.org/docs/underscore.html
         *
         * @param func
         * @param wait
         * @param immediate
         * @returns {Function}
         */
        debounce: function (func, wait, immediate) {
            var timeout;

            return function() {
                var context = this,
                    args = arguments;

                var later = function() {
                    timeout = null;

                    if (!immediate) {
                        func.apply(context, args);
                    }
                };

                if (immediate && !timeout) {
                    func.apply(context, args);
                }

                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
            };
        },

        /**
         * Converts Class into css selector.
         * @param classString setting value you wish to convert to css selector.
         * @returns {string} css selector.
         */
        formatClass: function(classString) {
            return '.' + this.settings.classes[classString];
        }
    };

    if (typeof domReady === 'function') {
        domReady(function () {

            /**
             * Since M1/M2 and DW defer in js config object names
             * we need to watch for both and and save them to the ba
             * object so we can use them regardless of platform
             */

            // M1 and M2
            if (typeof mageConfig !== 'undefined' && typeof mageConfig === 'object'){
                blueAcornCore.config = mageConfig;
            }

            // DW
            if (typeof SitePreferences !== 'undefined' && typeof SitePreferences === 'object'){
                blueAcornCore.config = SitePreferences;
            }

            /**
             * Launch BA into outerspace
             */
            blueAcornCore.init();
        });
    }

    return blueAcornCore;
}));
