'use strict';

var productTile = require('./product-tile');
var util = require('./util');

function closeCallback() {
    if (!$('#wrapper').hasClass('sti-flyout-open')) {
        return;
    }

    // using fadeout to time these animations correctly
    $('#wrapper .wrapper-overlay').fadeOut(200, function() {
        $('.js_sti-flyout').removeClass('active');
        $(this).removeAttr('style');
        $('#wrapper').removeClass('sti-flyout-open overlay-active');
    });
}

function close(e) {
    var $target = $(e.target);

    if ($target.hasClass('js_sti-flyout__close') || !$target.closest('.js_sti-flyout, .js_wl-dialog, .loader').length){
        closeCallback();
    }
}

function initObservers() {
    if (!$('.js_sti-flyout').length) {
        return;
    }

    productTile.init();

    $(document)
        .on('click', '.js_sti-icon', function (e) {
            let $stiFlyout = $('.js_sti-flyout[data-sti-bind-id=' + $(this).data('sti-bind-id') + ']');

            if (!$stiFlyout.length) {
                return;
            }

            e.preventDefault();
            e.stopImmediatePropagation();

            $stiFlyout.addClass('active');
            $('.wrapper-overlay').removeAttr('style');
            $('#wrapper').addClass('sti-flyout-open overlay-active');
            util.scrollBrowser(0);
        })
        .on('click', close)
        .keyup(function(e) {
            // Escape key closeout of Shop this image flyout
            if (e.keyCode === 27 && $('#wrapper').hasClass('sti-flyout-open')) {
                closeCallback();
            }
        });
}

var stiFlyout = {
    init: function() {
        initObservers();
    }
};

module.exports = stiFlyout;
