'use strict';

var util = require('../../util');

function initLoyaltyProduct() {
    var ellipsestext = '...';

    $('.truncate').each(function() {
        var content = $(this).html();
        if (content.length) {
            var c = content.substr(0, content.indexOf('|||'));
            var h = content.substring(content.indexOf('|||') + 3);
            var html =
                c +
                '<span class="truncate-text" style="display:inline">' +

               '<span class="moreellipses">' +
               ellipsestext +
              '&nbsp;&nbsp;<a href="" class="moreless more"><br/><span>+</span> Read More</a></span></span></span><span class="truncate-text" style="display:none">' +
              h +
              '<a href="" class="moreless less"><br/><span>-</span> Read Less</a></span></span>';

            $(this).html(html);
        }
    });

    $('.moreless').on('click', function() {
        var thisEl = $(this);
        var cT = thisEl.closest('.truncate-text');

        var tX = '.truncate-text';

        if (thisEl.hasClass('less')) {
            cT.slideToggle(500, function() {
                cT.prev(tX).show();
            });
        } else {
            cT.hide();
            cT.next(tX).slideToggle(500, function() {
                cT.next(tX).css('display','inline')
            });

        }
        return false;
    });
}

/**
 * @function
 * @description Add loyalty product in cart
 * @returns {void}
 */
function addLoyaltyProduct() {
    const isMiniCartOpen = $('.js_minicart-open').length > 0;
    const isCartPage = $('#table-loading').length > 0;
    const data = {
        pid: 'Comfort_Club',
        Quantity: 1
    };

    if (isMiniCartOpen) {
        $('.mini-cart-loading-wrapper').addClass('mini-cart-loading-wrapper--show');
    } else if (isCartPage) {
        $('#table-loading').addClass('loading-wrapper--show');
    }

    Promise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.addProduct),
        data: data
    })).then(function (response) {

        if (isMiniCartOpen) {
            $('.mini-cart-loading-wrapper').removeClass('mini-cart-loading-wrapper--show');
        } else if (isCartPage) {
            $('#table-loading').removeClass('loading-wrapper--show');
        }

        if (response.error) {
            throw new Error(response.error);
        } else {
            $(document).trigger('custom:cc:product:added', {response: response});
        }
    });
}

$(document).on('custom:addloyaltyproduct', addLoyaltyProduct);

module.exports = {
    initLoyaltyProduct: initLoyaltyProduct,
    addLoyaltyProduct: addLoyaltyProduct
}
