'use strict';

function setQty($iterator, value) {
    var qty = Math.ceil(value);

    if (isNaN(qty) || qty < 1 || qty > 999) {
        qty = 1;
    }

    $iterator.val(qty).trigger('blur');
}

function calculateRolls($form) {
    var $container = $form.closest('.js-product-ajax-container');
    var $selectedSize = $container.find('.product-variations .attribute.size .selected.selectable .swatchanchor');
    var sizes = $selectedSize.text().match(/\d+[.,]?\d+/g);

    if (!sizes || sizes.length !== 2) {
        return;
    }

    var $qty = $container.find('.js_custom-iterator input');
    var state = $form.find('.js_add-extra').prop('checked');
    var sqFt = parseFloat($form.find('.input-sq-ft').val());
    var rollSqFt = parseFloat(sizes[0]) * parseFloat(sizes[1]) / 144; // 1 foot is equal to 12 inches
    var rolls = sqFt / rollSqFt;

    if (state) {
        rolls++;
    }

    setQty($qty, rolls);
}

function calculateSquareFt($form) {
    var result = 0;

    $form.find('.js_walls .js_wall').each(function () {
        var $wall = $(this);
        var height = $wall.find('.input-height').val();
        var width = $wall.find('.input-width').val();

        if ($wall.find('input').hasClass('error') || !height || !width) {
            return;
        }

        var wallSqFt = parseFloat(height) * parseFloat(width);

        if (!isNaN(wallSqFt)) {
            result += wallSqFt;
        }
    });

    $form.find('.input-sq-ft').val(result)
        .closest('.form-row').addClass('input-focused');
}

function calculateWallpaperRolls($form) {
    if (!($form && $form.length)) {
        return;
    }

    calculateSquareFt($form);
    calculateRolls($form);
}

module.exports = {
    init: function () {
        if (!$('.js_wallpaper-calculator').length) {
            return;
        }

        $(document)
            .trigger('update:form:inputs')
            .on('custom:calculate:wallpaper:rolls', function (e, data) {
                var $form = data.form;
                calculateWallpaperRolls($form);
            })
            .on('click', '.js_add-wall', function (e) {
                e.preventDefault();
                var $form = $(this).closest('form');
                var countWalls = $form.find('.wallpaper-calculator-form__row-wall').length;

                if (countWalls > 4) {
                    return;
                }

                if (countWalls > 3) {
                    $(this).addClass('d-none');
                }

                var $rowWall = $form.find('.js_row-wall-template').clone();
                $rowWall.removeClass('d-none js_row-wall-template');
                $rowWall.find('label').each(function () {
                    $(this).attr('for', $(this).attr('for') + countWalls);
                });
                $rowWall.find('input').each(function () {
                    $(this).attr('id', $(this).attr('id') + countWalls);
                });
                $form.find('.js_walls').append($rowWall);

                $(document).trigger('update:form:inputs');
            })
            .on('click', '.js_remove-wall', function (e) {
                e.preventDefault();
                var $form = $(this).closest('form');
                $(this).closest('.wallpaper-calculator-form__row-wall').remove();
                $form.find('.js_add-wall').removeClass('d-none');
                calculateWallpaperRolls($form);
            })
            .on('formElement:clicked', '.js_add-extra', function () {
                var state = $(this).prop('checked');
                var $iteratorInput = $(this).closest('.js-product-ajax-container').find('.js_custom-iterator input');
                var value = parseInt($iteratorInput.val());

                if (state) {
                    value += 1;
                } else {
                    value -= 1;
                }

                setQty($iteratorInput, value);
            })
            .on('focus', '.wallpaper-calculator-form .input-select-all', function () {
                $(this).select();
            })
            .on('blur', '.js_wall input', function () {
                var $form = $(this).closest('form');
                calculateWallpaperRolls($form);
            })
            .on('blur', '.input-sq-ft', function () {
                var $form = $(this).closest('form');
                calculateRolls($form);
            });
    }
};
